import React, { useState, useEffect } from "react";
import { Row, Col, Grid } from "antd";
import { SpinLoading } from "antd-mobile";
import { useQuery } from "react-query";
import nf from "@tuplo/numberfmt";

import InsightTile from "./InsightTile";
import StatsCard from "./Demographic/StatsCard";
import CircularStatsCard from "./Demographic/CircularStatsCard";

import { getCreatorData } from "../../../../../handlers/getCreatorData";

import styles from "./stats.module.css";

const { useBreakpoint } = Grid;

const Stats = ({ creatorId }) => {
  const screens = useBreakpoint();

  const [getCreatorKey, getCreatorFn] = getCreatorData(creatorId);
  const [platform, setPlatform] = useState("");

  const {
    data: creatorData,
    isLoading,
    isError,
  } = useQuery(getCreatorKey, getCreatorFn, {
    enabled: creatorId !== null,
  });

  useEffect(() => {
    if (creatorData) {
      setPlatform(
        creatorData?.data?.socialMediaData[0]?.data?.socialMediaPlatform
      );
    }
    return () => {
      setPlatform("");
    };
  }, [creatorData]);

  if (!creatorId) {
    return null;
  }

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SpinLoading color="primary" />
      </div>
    );
  }

  if (isError) {
    return <h1 style={{ textAlign: "center" }}>Something went wrong</h1>;
  }

  return (
    <Row>
      {creatorData?.data?.socialMediaData?.map((platformItem) => {
        return (
          <Col
            xs={24}
            sm={24}
            lg={18}
            style={{
              paddingLeft: screens.lg || screens.xl || screens.xxl ? 0 : "16px",
              paddingRight:
                screens.lg || screens.xl || screens.xxl ? 0 : "16px",
              paddingTop: 0,
            }}
            offset={screens.lg ? 3 : 0}>
            <div>
              <Row
                justify="space-between"
                style={{ borderBottom: "3px solid #F0F0F0" }}
                align="middle">
                <p className={styles.handleName}>
                  @{platformItem?.data?.socialMediaName}
                </p>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/dev-cloutflow-platform.appspot.com/o/wp-site%2F${platformItem?.data?.socialMediaPlatform}.png?alt=media`}
                  alt="instagram-icon"
                  width={
                    screens.lg || screens.xl || screens.xxl ? "63px" : "32px"
                  }
                />
              </Row>
              <p className={styles.sectionHeading}>Insights</p>
              <Row
                gutter={screens.lg || screens.xl || screens.xxl ? 16 : 4}
                justify="center">
                {/* {platformItem.visibilityMatrix.user.followerCount && ( */}
                <Col span={8}>
                  <InsightTile
                    value={nf(platformItem?.data?.user?.followerCount, "a", {
                      locale: "en-US",
                    })}
                    label={"followers"}
                  />
                </Col>
                {/* )} */}
                {/* {platformItem.visibilityMatrix.content.aggregate.engagement && ( */}
                <Col span={8}>
                  <InsightTile
                    value={nf(
                      platformItem?.data?.content?.aggregate?.engagement,
                      "%",
                      {
                        locale: "en-US",
                      }
                    )}
                    label={"avg. engagement rate"}
                  />
                </Col>
                {/* )} */}
                {/* {platformItem.visibilityMatrix.content.aggregate.avgLikes && ( */}
                <Col span={8}>
                  <InsightTile
                    value={nf(
                      platformItem?.data?.content?.aggregate?.avgLikes,
                      "a",
                      {
                        locale: "en-US",
                      }
                    )}
                    label={"avg. Likes"}
                  />
                </Col>
                {/* )} */}
              </Row>
              <p className={styles.sectionHeading}>Audience Demographic</p>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={8}>
                  <StatsCard
                    title={platform === "instagram" ? "State" : "Country"}
                    items={Object.entries(
                      platformItem?.data?.audience?.location[
                        platform === "instagram" ? "state" : "country"
                      ] || [{ stateName: "India", views: 100 }]
                    )
                      .sort((a, b) => b[1] - a[1])
                      .reduce((prevItems, item, idx) => {
                        const [key, value] = item;
                        if (idx <= 3) {
                          return [
                            ...prevItems,
                            {
                              label: key,
                              percent: Number(
                                Math.round(
                                  (value * 100) /
                                    (platformItem?.data?.audience?.location
                                      ?.aggregate[
                                      platform === "instagram"
                                        ? "state"
                                        : "country"
                                    ] || 1)
                                )
                              ),
                            },
                          ];
                        } else {
                          return [
                            ...prevItems.slice(0, 4),
                            {
                              label: "Others",
                              percent: Number(
                                100 -
                                  prevItems
                                    .slice(0, 4)
                                    .reduce(
                                      (prevItem, currentItem) =>
                                        prevItem +
                                        Math.round(currentItem.percent),
                                      0
                                    ) ===
                                  0
                                  ? 100
                                  : prevItems
                                      .slice(0, 4)
                                      .reduce(
                                        (prevItem, currentItem) =>
                                          prevItem +
                                          Math.round(currentItem.percent),
                                        0
                                      )
                              ),
                            },
                          ];
                        }
                      }, [])}
                  />
                </Col>
                {platformItem?.data?.audience?.age && (
                  <Col xs={24} sm={24} md={8}>
                    <StatsCard
                      items={Object.entries(
                        platformItem?.data?.audience?.age
                      ).reduce((prevItems, item) => {
                        const [key, value] = item;

                        return [
                          ...prevItems,
                          {
                            label: key,
                            percent: Math.round(value),
                          },
                        ];
                      }, [])}
                      title={"Age Range"}
                    />
                  </Col>
                )}
                {platformItem?.data?.audience?.gender && (
                  <Col xs={24} sm={24} md={8}>
                    <CircularStatsCard
                      items={[
                        {
                          label: "Male",
                          percent: Math.round(
                            platformItem?.data?.audience?.gender?.male
                          ),
                        },
                        {
                          label: "Female",
                          percent: Math.round(
                            platformItem?.data?.audience?.gender?.female
                          ),
                        },
                        {
                          label: "Others",
                          percent: Math.round(
                            platformItem?.data?.audience?.gender?.unknown
                          ),
                        },
                      ]}
                      title={"Gender"}
                    />
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default Stats;
