import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Row } from "antd";

import { MdChatBubbleOutline } from "react-icons/md";

import { getWorkFlowDetailsV2 } from "handlers/campaign/workflows/v2/getWorkFlowDetailsV2";

import OngoingCampaignDetailUpdated from "components/Dashboard/OngoingCampaignDetailUpdated";

const OngoingCampaignScreen = () => {
  const { offerId } = useParams();

  const navigate = useNavigate();

  const { queryKey, queryFunction } = getWorkFlowDetailsV2(offerId);
  const { data: workflowData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(offerId),
  });

  return (
    <div>
      <Row justify="space-between" style={{ marginBlock: 40 }}>
        <h2>Ongoing Campaign</h2>
        <MdChatBubbleOutline
          style={{ fontSize: "20px" }}
          onClick={() => {
            workflowData && navigate(`/chat/${workflowData?.data.brand._id}`);
          }}
        />
      </Row>
      <OngoingCampaignDetailUpdated finalizedOfferId={offerId} />
    </div>
  );
};

export default OngoingCampaignScreen;
