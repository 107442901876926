import CustomModalWrapper from "components/Common/CustomModalWrapper";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { getUserStatus } from "../../handlers/me/getUserStatus";
import { getUserSocial } from "../../handlers/me/getSocial";

import useWindowWidth from "hooks/useWindowWidth";

import ConnectPlatform from "./ConnectPlatform";

const ConnectPlatformModal = ({
  openConnectPlatformModal,
  setOpenConnectPlatformModal,
}) => {
  const inDeskTop = useWindowWidth();
  const navigate = useNavigate();
  const { queryKey } = getUserStatus();
  const { queryKey: userSocialKey } = getUserSocial([
    "Connected",
    "Session-Expired",
  ]);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (inDeskTop && openConnectPlatformModal) {
      navigate("/connectPlatform");
      setOpenConnectPlatformModal(false);
    }
  }, [inDeskTop, openConnectPlatformModal]);

  return (
    <CustomModalWrapper
      openModal={openConnectPlatformModal && !inDeskTop}
      setOpenModal={setOpenConnectPlatformModal}
      heading="Connect Platform"
      handleInvalidate={() => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(userSocialKey);
      }}>
      <ConnectPlatform />
    </CustomModalWrapper>
  );
};

export default ConnectPlatformModal;
