import { sendRequest } from "../sendRequest";

export const getUCampaign = (campaignId, dealId) => {
  const queryKey = ["unregistered", "campaigns", campaignId, dealId];
  const queryFunction = () =>
    sendRequest(
      "get",
      `/public/campaign/by-id?campaignId=${campaignId}&dealId=${dealId}`
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
