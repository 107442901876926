import { useNavigate } from "react-router-dom";
import { useNavContext } from "context/NavContext";
import { getUCampaigns } from "handlers/public/getUCampaigns";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { PullToRefresh, DotLoading, Divider, Button } from "antd-mobile";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import UCampaignCard from "./UCampaignCard";

const UDashboard = ({ setOpenRegisterModal }) => {
  const { queryKey, queryFunction } = getUCampaigns();
  const { setOpenNav, setNavHeading } = useNavContext();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    setOpenNav(false);
    setNavHeading("");
  }, []);

  const {
    data: campaignData,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(queryKey, queryFunction, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  return (
    <>
      {/* <UnRegisteredCard setOpenRegisterModal={setOpenRegisterModal} /> */}
      <PullToRefresh
        onRefresh={async () => {
          await queryClient.invalidateQueries(queryKey);
        }}
        refreshingText={
          <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
        }
        canReleaseText={<AiOutlineArrowUp size={"20px"} />}
        pullingText={<AiOutlineArrowDown size={"20px"} />}
        completeText={<div />}
      >
        <div style={{ padding: "0px 24px", marginBottom: "100px" }}>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}> */}
          <div
            style={{
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "30px",
              // marginBottom: '17px',
            }}
          >
            Explore Campaigns
          </div>
          <Divider style={{ margin: "10px 0 28px 0" }} />
          {/* <span onClick={() => setOpenRegisterModal(true)}> see more</span> */}

          <InfiniteScroll
            pageStart={0}
            loadMore={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            style={{ display: "grid", gap: "16px" }}
          >
            {campaignData?.pages.map((page) =>
              page.data.brandCampaign.map((campaign) => {
                return (
                  <UCampaignCard
                    setOpenRegisterModal={setOpenRegisterModal}
                    key={campaign._id}
                    campaign={campaign}
                    unregistered={true}
                  />
                );
              })
            )}
          </InfiniteScroll>
          <Button
            color="primary"
            style={{
              borderRadius: "8px",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "25px",
              margin: "0 20px",
              width: "80%",
              position: "fixed",
              bottom: "10px",
            }}
            onClick={() => navigate("/onboarding")}
          >
            Apply for Cloutflow
          </Button>
        </div>
      </PullToRefresh>
    </>
  );
};

export default UDashboard;
