import { sendRequest } from "../sendRequest";

export const getState = (isV2 = false) => {
  const queryKey = ["state", isV2];
  const queryFunction = () =>
    sendRequest("put", `/influencer/social-media-access/state`, {}, {}, isV2)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  return { queryKey, queryFunction };
};
