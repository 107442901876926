import { sendRequest } from "../sendRequest";

export const getEarningsSum = () => {
  const queryKey = ["wallet", "earnings", "sum"];

  const queryFunction = () =>
    sendRequest("get", "/influencer/wallet/earnings/sum").then(
      (res) => res.data
    );
  return { queryKey, queryFunction };
};
