import React, { useState, createContext, useContext, useEffect } from "react";
const NavContext = createContext(null);

export default function NavContextProvider({ children }) {
  const [openNav, setOpenNav] = useState(false);
  const [navHeading, setNavHeading] = useState(null);
  const [navButton, setNavButton] = useState({
    custom: false,
    button: null,
  });

  const value = {
    navHeading,
    setNavHeading,
    openNav,
    setOpenNav,
    navButton,
    setNavButton,
  };
  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
}

export const useNavContext = () => {
  const navContext = useContext(NavContext);
  if (!navContext)
    throw new Error("useNavContext must be used within an NavContextProvider");
  return navContext;
};
