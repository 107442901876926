import { Button } from "antd-mobile";

import openInAppUrl from "../../Utils/openInAppUrl";

const ConnectError = ({ error, isWeb = false }) => {
  const errorType = error?.response?.data?.status;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        maxWidth: "500px",
        // height: "70%",
        padding: "24px",
        // marginTop: "80px",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItem: "center",
          justifyContent: "space-around",
        }}>
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "600",
            color: "#09121F",
            textAlign: "center",
          }}>
          {ErrorMap(errorType).primaryMessage}
        </h1>
        <h2
          style={{
            textAlign: "center",
            fontSize: "14px",
            color: "#09121F",
            fontWeight: "400",
            marginBottom: "20px",
          }}>
          {ErrorMap(errorType).secondaryMessage}
          In case you need help, You can refer to the <b>video</b> below or Go
          to Help in Profile Section of App
        </h2>

        <video
          loop=""
          muted={true}
          src={`/assets/video/InstagramConnect.mp4#t=1`}
          controls={true}
          style={{
            objectFit: "cover",
            borderRadius: "8px",
            marginBottom: "70px",
          }}
          width={"100%"}
          height={"200px"}
        />
      </div>

      <Button
        size="large"
        fill="solid"
        color="primary"
        block
        onClick={() =>
          openInAppUrl(
            !Boolean(isWeb)
              ? "https://link.cloutflow.com/app"
              : `${process.env.REACT_APP_URL}?type=web`
          )
        }>
        {!Boolean(isWeb) ? "Go to app & try again" : "Try Again"}
      </Button>
    </div>
  );
};

export default ConnectError;

const ErrorMap = (errorType) => {
  if (errorType === "ERR_SCOPES_MISMATCH") {
    return {
      primaryMessage: "We didn’t get all the needed permissions",
      secondaryMessage:
        "It looks like you didn’t give all the permissions. Please go back to mobile app and try again.",
    };
  }
  if (errorType === "ERR_NO_INSTA_LINKED_PAGE") {
    return {
      primaryMessage: "Facebook page not connected with Instagram",
      secondaryMessage: null,
    };
  }
  if (errorType === "ERR_NO_FACE_BOOK_PAGE_CREATED") {
    return {
      primaryMessage: "No Facebook page selected",
      secondaryMessage: "Please select a facebook page to connect",
    };
  }
  if (errorType === "ERR_NO_FACE_BOOK_PAGE_CREATED") {
    return {
      primaryMessage: "Permissions are not given ",
      secondaryMessage: "Please accept all the permissions to connect",
    };
  }
  if (errorType === "ERR_IG_PAGE_IG_USER_NOT_FOUND") {
    return {
      primaryMessage: "OOPS! Seems like you connected the wrong account",
      secondaryMessage: "Please try again with the correct instagram account",
    };
  }
  if (errorType === "SUCCESS_SOCIAL_MEDIA_NOT_REVOKED") {
    return {
      primaryMessage: "OOPS! Seems like you connected the wrong account",
      secondaryMessage: "Please try again with the correct instagram account",
    };
  } else {
    return {
      primaryMessage: "Something went wrong",
      secondaryMessage: "Something went wrong while connecting your profile. ",
    };
  }
};
