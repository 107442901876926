import React from "react";
import { Button } from "antd";

import styles from "./styles.module.css";

const PreviewMessage = ({ message }) => {
  return (
    <div
      className={styles.container}
      style={message?.default_action?.url ? { cursor: "pointer" } : {}}
      onClick={() => {
        if (message?.default_action?.url) {
          return window.open(message?.default_action?.url, "_blank");
        }
        return;
      }}>
      {message?.image_url && (
        <img className={styles.image} src={message?.image_url} />
      )}
      <div className={styles.innerContainer}>
        <p className={styles.title}>{message.title}</p>

        {message?.subtitle && (
          <p className={styles.subTitle}>{message.subtitle}</p>
        )}
        {(message?.buttons || []).length > 0 && (
          <div className={styles.buttonsGrid}>
            {message?.buttons?.map((bItem, idx) => (
              <Button
                className={styles.button}
                key={`bItem${idx}`}
                onClick={(e) => {
                  if (
                    bItem?.type === "postback" ||
                    !(bItem?.url || "")?.trim()
                  ) {
                    return;
                  }
                  e.stopPropagation();
                  return window.open(bItem?.url, "_blank");
                }}>
                {bItem.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewMessage;
