import { Input } from "antd";
import { Button } from "antd-mobile";
import { Toast } from "antd-mobile";
import { sendLiveV2, editLiveV2 } from "../../handlers/chat/";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { isLinkValid } from "../../Utils/isLinkValid";

const LiveUpload = ({
  setOpenModal,
  platform,
  workflowId,
  isEdit,
  prevLiveLinkTimeLineId,
  finalizedOfferId,
}) => {
  const [videoLink, setVideoLink] = useState("");
  const [showErr, setShowErr] = useState(false);
  const queryClient = useQueryClient();
  const sendLiveMutation = useMutation(sendLiveV2, {
    onSuccess: () => {
      Toast.show("Live Sent");
      queryClient.invalidateQueries([
        "campaigns",
        "workflow",
        finalizedOfferId,
      ]);
      setOpenModal(false);
    },
    onError: (err) => {
      console.log(err.code, err.message, "error-message", err);
    },
  });

  const editLiveMutation = useMutation(editLiveV2, {
    onSuccess: () => {
      Toast.show("Live Sent");
      setOpenModal(false);
    },
    onError: (err) => {
      console.log(err.code, err.message, "error-message", err);
    },
  });
  return (
    <div
      style={{
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        width: "90%",
        margin: "0 auto",
      }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            flex: "0.5",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="">
          {/* <div
            style={{ display: "flex", justifyContent: "center" }}
            className="">
            <img src="/assets/icons/unlisted.svg" alt="" />
          </div> */}
          {/* <p
            style={{
              width: "90%",
              fontSize: "14px",
              textAlign: "center",
              color: "#999999",
            }}>
            Upload your live link below:
          </p> */}
        </div>
        <div
          style={{
            display: "flex",
            flex: "0.5",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            marginBottom: "10px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}>
            <p style={{ fontWeight: 500, fontSize: "14px" }}>
              {" "}
              Upload your live link below:
            </p>
            <Input
              placeholder="Enter link"
              onChange={(e) => setVideoLink(e.target.value)}
              size="large"
            />
            {showErr && !isLinkValid(videoLink) && (
              <h6 style={{ color: "red" }}>Invalid Link</h6>
            )}
          </div>
        </div>
      </div>

      <Button
        // onClick={() =>

        //   sendDraftMutation.mutate({
        //     workflowId: workflowId,
        //     references: [
        //       {
        //         url: videoLink,
        //         type: "video",
        //         platform: platform,
        //       },
        //     ],
        //   })
        // }
        onClick={() => {
          if (!isLinkValid(videoLink)) {
            return setShowErr(true);
          }
          if (!isEdit) {
            sendLiveMutation.mutate({
              workflowId,
              references: [
                {
                  url: videoLink,
                  type: "link",
                  permalink: videoLink,
                  platform,
                  actualType: "link",
                },
              ],
            });
          } else {
            editLiveMutation.mutate({
              prevLiveLinkTimeLineId,
              workflowId,
              references: [
                {
                  url: videoLink,
                  type: "link",
                  permalink: videoLink,
                  platform,
                  actualType: "link",
                },
              ],
            });
          }
        }}
        disabled={
          videoLink === "" ||
          sendLiveMutation.isLoading ||
          editLiveMutation.isLoading
        }
        loading={sendLiveMutation.isLoading || editLiveMutation.isLoading}
        size="large"
        color="primary">
        Upload
      </Button>
    </div>
  );
};

export default LiveUpload;
