import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";

import {
  Row,
  Col,
  Divider,
  Input,
  Button,
  Space,
  Select,
  Upload,
  message,
  Alert,
} from "antd";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import cuid from "cuid";

import { PlusOutlined } from "@ant-design/icons";
import CustomInput from "../../Common/CustomInput";

import { auth } from "../../../Utils/Firebase";
import { isLinkValid } from "../../../Utils/isLinkValid";

import { addRule } from "../../../handlers/replies/addRule";

import useWindowWidth from "../../../hooks/useWindowWidth";

import PreviewMessage from "./PreviewMessage";

import globalStyles from "./styles.module.css";

import InfoPopOver from "./InfoPopOver";
import { MdDeleteForever } from "react-icons/md";

const AddMessage = ({ selected, handleBack, handleNext }) => {
  const isDesktop = useWindowWidth();

  const [userInput, setUserInput] = useState({
    title: "",
  });

  const [userInputErr, setUserInputErr] = useState({
    title: "",
    image_url: "",
    subtitle: "",
  });

  const getTrimmedLength = (val) => String(val || "").trim().length;

  const isDataValid = () => {
    let isError = false;

    let errorObj = userInputErr;

    if (getTrimmedLength(userInput?.title) <= 0) {
      isError = true;
      errorObj = { ...errorObj, title: "Please Enter a title" };
    } else {
      errorObj = { ...errorObj, title: "" };
    }

    if (
      getTrimmedLength(userInput?.default_action?.url) > 0 &&
      !isLinkValid(userInput?.default_action?.url || "")
    ) {
      isError = true;
      errorObj = {
        ...errorObj,
        default_action: { url: "Please Enter a valid Url", type: "web_url" },
      };
    } else {
      errorObj = {
        ...errorObj,
        default_action: { url: "", type: "web_url" },
      };
    }

    if ((userInput?.buttons || [])?.length > 0) {
      let buttonError =
        userInput?.buttons?.map((item) => {
          if (item.type === "web_url") {
            return { type: "web_url", url: "", title: "" };
          }

          return {
            type: "postback",
            title: "",
            payload: "DEVELOPER_DEFINED_PAYLOAD",
          };
        }) || [];
      userInput?.buttons?.forEach((button, index) => {
        if (getTrimmedLength(button.title) <= 0) {
          isError = true;
          buttonError[index].title = "Please Enter button Title";
        }

        if (button.type === "web_url") {
          if (getTrimmedLength(button.url) <= 0 || !isLinkValid(button.url)) {
            isError = true;
            if (buttonError[index].type === "web_url") {
              buttonError[index].url = "Please Enter a valid button URL";
            }
          }
        }
      });

      errorObj = { ...errorObj, buttons: buttonError };
    }

    setUserInputErr(errorObj);

    return !isError;
  };
  const handleButtonInputChange = (fieldName, fieldValue, id) => {
    const oldButtons = userInput?.buttons || [];

    const updatedButtons = oldButtons?.map((bItem, idx) => {
      if (idx === id) {
        bItem = { ...bItem, [fieldName]: fieldValue };
      }
      return bItem;
    });

    setUserInput((userInput) => ({ ...userInput, buttons: updatedButtons }));
  };

  const uploadButton = (
    <div className={globalStyles.uploadButtonContainer}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Allowed file formats are png, jpg and jpeg.
      </div>
    </div>
  );

  const UploadButtonProps = {
    name: "file",
    multiple: false,
    onDrop(e) {},
    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;
      const storage = getStorage();
      const fileName = file.name;

      const storageRef = ref(
        storage,
        `/replies/${auth?.currentUser?.uid}/img/${
          fileName.slice(0, 10) + cuid()
        }`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      // setLoading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          onProgress &&
            onProgress({
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            });
        },
        (error) => {
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setUserInput((inp) => ({ ...inp, image_url: downloadURL }));
        }
      );
    },
    accept: "image/png, image/jpeg, image/jpg",
    onRemove() {
      setUserInput((inp) => ({ ...inp, image_url: undefined }));
    },
  };

  const resetForm = () => {
    setUserInput({
      title: "",
      buttons: [],
      default_action: undefined,
      image_url: undefined,
      subtitle: undefined,
    });
    setUserInputErr({
      title: "",
      image_url: "",
      subtitle: "",
    });
  };

  const addRuleMutation = useMutation(addRule, {
    onSuccess: () => {
      message.success("Automation Added");
      resetForm();
      handleNext();
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  return (
    <div>
      <h3>Add Message Template</h3>
      <Alert
        message="Creators who will comment 'Test' on selected post will receive the following message as shown in preview "
        type="success"
        style={{ marginBlock: 10, width: "max-content" }}
      />
      <Row>
        <Col
          span={isDesktop ? 11 : 24}
          style={{
            display: "grid",
            gap: "12px",
            height: "550px",
            overflowY: "scroll",
          }}>
          <CustomInput
            label="Title"
            error={userInputErr?.title}
            children={
              <Input
                value={userInput.title}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    title: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />

          <CustomInput
            children={
              <Upload
                listType="picture"
                // style={{ width: "344px !important", height: "149px !important" }}
                fileList={
                  userInput?.image_url
                    ? [
                        {
                          uid: userInput?.image_url,
                          name: "banner.png",
                          status: "done",
                          url: userInput?.image_url,
                          type: "image/jpeg",
                        },
                      ]
                    : []
                }
                {...UploadButtonProps}>
                {userInput?.image_url ? null : uploadButton}
              </Upload>
            }
            label={
              <div className={globalStyles.titleLabel}>
                Message Banner (Optional)
                <InfoPopOver desc="Upload a reference image to let the creator know what this message is about " />
              </div>
            }
            error={userInputErr?.image_url}
          />

          <CustomInput
            label={
              <div className={globalStyles.titleLabel}>
                Subtitle (Optional)
                <InfoPopOver desc="Subtitle of the message" />
              </div>
            }
            error={userInputErr?.subtitle}
            // className={styles.inputContainer}
            children={
              <Input
                value={userInput?.subtitle}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    subtitle: e.target.value || "",
                  }))
                }
                size="large"
              />
            }
          />

          <CustomInput
            label={
              <div>
                <div className={globalStyles.titleLabel}>
                  Action Buttons (Optional)
                  <InfoPopOver desc="Message Buttons on which creator can take actions" />
                </div>
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    setUserInput((userInput) => ({
                      ...userInput,
                      buttons: [
                        ...(userInput.buttons || []),
                        { title: "", type: "web_url", url: "" },
                      ],
                    }))
                  }>
                  Add
                </Button>
              </div>
            }
            //   error={userInputErr?.subtitle}
            // className={styles.inputContainer}
            children={
              <ol style={{ display: "grid", gap: 5, marginTop: 10 }}>
                {userInput?.buttons?.map((bItem, idx) => (
                  <li key={idx}>
                    <Space size={15} align="start">
                      <div style={{ display: "grid", gap: "5px" }}>
                        <div>
                          <span>Button Type: </span>

                          <Select
                            value={bItem?.type}
                            defaultValue={bItem?.type}
                            style={{ width: 120 }}
                            onChange={(val) =>
                              handleButtonInputChange("type", val, idx)
                            }
                            options={[
                              {
                                value: "web_url",
                                label: "Web Url",
                              },
                              {
                                value: "postback",
                                label: "Message Reply",
                              },
                            ]}
                          />
                        </div>
                        <div>
                          <Input
                            value={bItem.title}
                            style={{ width: "252px" }}
                            prefix="Title : "
                            onChange={(e) =>
                              handleButtonInputChange(
                                "title",
                                e.target.value || "",
                                idx
                              )
                            }
                          />
                          {(userInputErr?.buttons || [])?.length > idx &&
                            userInputErr.buttons[idx]?.title && (
                              <p className={globalStyles.errorInput}>
                                {userInputErr.buttons[idx].title}
                              </p>
                            )}
                        </div>

                        {bItem.type === "web_url" && (
                          <div>
                            <Input
                              value={bItem.url}
                              prefix="Callback Url : "
                              onChange={(e) =>
                                handleButtonInputChange(
                                  "url",
                                  e.target.value || "",
                                  idx
                                )
                              }
                            />
                            {(userInputErr?.buttons || [])?.length > idx &&
                              userInputErr.buttons[idx]?.url && (
                                <p className={globalStyles.errorInput}>
                                  {userInputErr.buttons[idx]?.url}
                                </p>
                              )}
                          </div>
                        )}
                        {idx !== (userInput?.buttons || [])?.length - 1 && (
                          <Divider style={{ margin: "5px 0" }} />
                        )}
                      </div>

                      <Button
                        // shape="circle"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                        }}
                        icon={<MdDeleteForever size={16} />}
                        danger
                        // type="primary"
                        onClick={() =>
                          setUserInput((userInput) => ({
                            ...userInput,
                            buttons: (userInput?.buttons || []).filter(
                              (_fItem, _fIdx) => _fIdx !== idx
                            ),
                          }))
                        }>
                        Delete
                      </Button>
                    </Space>
                  </li>
                ))}
              </ol>
            }
          />

          <CustomInput
            label={
              <div className={globalStyles.titleLabel}>
                Callback Url on card click (Optional)
                <InfoPopOver desc="" />
              </div>
            }
            error={userInputErr?.default_action?.url}
            // className={styles.inputContainer}
            children={
              <Input
                value={userInput.default_action?.url}
                onChange={(e) =>
                  setUserInput((userInput) => ({
                    ...userInput,
                    default_action: {
                      url: e.target.value || "",
                      type: "web_url",
                    },
                  }))
                }
                size="large"
              />
            }
          />

          <Space>
            <Button
              onClick={() => {
                resetForm();

                handleBack();
              }}>
              Back
            </Button>

            <Button
              type="primary"
              style={{ width: "max-content" }}
              loading={addRuleMutation.isLoading}
              onClick={() => {
                if (isDataValid()) {
                  return addRuleMutation.mutate({
                    mediaId: selected?.igMediaId,
                    ig: {
                      ...userInput,
                      buttons: userInput?.buttons?.map((bItem) => {
                        if (bItem?.type === "postback") {
                          return {
                            type: bItem?.type,
                            title: bItem?.title,
                            payload: "DEVELOPER_DEFINED_PAYLOAD",
                          };
                        }
                        return bItem;
                      }),
                    },
                  });
                }

                return message.error("Please Recheck the form");
              }}>
              Submit
            </Button>
          </Space>
        </Col>
        {isDesktop ? (
          <Divider
            type="vertical"
            style={{ height: "auto", margin: "0 20px" }}
          />
        ) : (
          <Divider />
        )}

        <Col span={isDesktop ? 11 : 24}>
          <h4>Preview</h4>
          {userInput?.title && <PreviewMessage message={userInput} />}
        </Col>
      </Row>
    </div>
  );
};

export default AddMessage;
