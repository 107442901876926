import { Input } from "antd";

const CustomInput = ({
  label,
  label2,
  errorText,
  inputWidth,
  ...otherProps
}) => {
  return (
    <div
      style={{
        maxWidth: "350px",
        width: inputWidth ? inputWidth : "100%",
        marginTop: "8px",
      }}>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "500",
          marginBottom: "2px",
          lineHeight: "19px",
          color: "#09121F",
        }}>
        {label}
      </div>
      <div
        style={{
          fontSize: "14px",
          lineHeight: "16px",
          color: "#999999",
          margin: "8px 0",
        }}>
        {label2}
      </div>
      <Input
        status={errorText ? "error" : "success"}
        {...otherProps}
        style={{
          height: "48px",
          border: "1.5px solid #CCCCCC",
          borderRadius: "8px",
        }}
      />
      {errorText && <h6 style={{ color: "red" }}>{errorText}</h6>}
    </div>
  );
};

export default CustomInput;
