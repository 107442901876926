import { Button, Dialog, Switch, Toast } from "antd-mobile";
import { FcCheckmark } from "react-icons/fc";
import { CgClose } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";

import CardSelector from "components/Common/CardSelector";
import CustomDrawerWrapper from "components/Common/CustomDrawerWrapper";
import CustomInput from "components/Common/CustomInput";
import DateSelector from "components/Common/DateSelector";
import GenderSelector from "components/Common/GenderSelector";
// import { emailVerify } from "handlers/me/emailVerify";

import useUpdateMe from "handlers/me/useUpdateMe";
import styles from "./profileDrawers.module.css";
import { updateMe } from "handlers/me/useUpdateMe";
import { useAuthContext } from "context/AuthContext";

const ProfileDrawers = ({ type, setDrawerType, profileData }) => {
  const [openKycDrawer, setOpenKycDrawer] = useState(false);
  const queryClient = useQueryClient();

  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [openEditProfileDrawer, setOpenEditProfileDrawer] = useState(false);
  const { signOutDevice } = useAuthContext();

  const notificationMutation = useUpdateMe();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: profileData?.name,
      last_name: profileData?.lastname,
      dob: profileData?.dateOfBirth
        ? Date.parse(profileData?.dateOfBirth)
        : undefined,
      gender: profileData.gender,
      community: profileData.niche,
    },
  });

  const editProfileMutation = useMutation(updateMe, {
    onSuccess: () => {
      setIsEditEnabled(false);
      Toast.show("User Updated");
      queryClient.invalidateQueries(["user"]);
    },
    onError: () => {
      Toast.show("Something went wrong");
    },
  });

  const onSubmitHandlerProfile = (data) => {
    let updatedData = {};
    if (data.first_name !== "") updatedData.name = data.first_name;
    if (data.last_name !== "") updatedData.lastname = data.last_name;
    if (data.gender !== "None") updatedData.gender = data.gender;
    if (data.dob !== "")
      if (!isNaN(data.dob)) {
        updatedData.dateOfBirth = data.dob;
      }
    if (data.community.length !== 0) updatedData.niche = data.community;

    editProfileMutation.mutate({
      data: updatedData,
    });
  };

  useEffect(() => {
    if (type === "KYC") setOpenKycDrawer(true);
    else if (type === "NOTIFICATION") setOpenNotificationDrawer(true);
    else if (type === "EDIT_PROFILE") setOpenEditProfileDrawer(true);
  }, [type]);

  const [isEditEnabled, setIsEditEnabled] = useState(false);

  useEffect(() => {
    if (openEditProfileDrawer) setIsEditEnabled(false);
  }, [openEditProfileDrawer]);

  return (
    <>
      <CustomDrawerWrapper
        isOpen={openKycDrawer}
        handleClose={() => {
          setOpenKycDrawer(false);
          setDrawerType("");
        }}
        title="KYC Details"
        secondaryButtonOnClick={() => {}}
        secondaryButton={
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            {profileData.kyc.status === "Needs-Attention" ? (
              <CgClose size={18} color="#FF3141" />
            ) : profileData.kyc.status !== "Under-Review" ? (
              <FcCheckmark size={18} />
            ) : (
              ""
            )}

            <p
              style={{
                color:
                  profileData.kyc.status === "Needs-Attention"
                    ? "#FF3141"
                    : profileData.kyc.status === "Under-Review"
                    ? ""
                    : "#00B578",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
              }}>
              {profileData.kyc.status === "Verified"
                ? "Verified"
                : profileData.kyc.status === "Under-Review"
                ? "Under Review"
                : "Verification Failed"}
            </p>
          </div>
        }>
        <div className={styles.container}>
          <div className={styles.detail}>
            <p className={styles.label}>Phone Number</p>
            <p
              className={styles.desc}
              style={{
                color:
                  profileData?.phoneNumber === "+911234567899"
                    ? "Highlight"
                    : "",
              }}>
              {profileData.phoneNumber}
            </p>
          </div>
          {profileData.kyc.kycDetails.pan !== "" && (
            <div className={styles.detail}>
              <p className={styles.label}>Pan</p>
              <p className={styles.desc}>{profileData.kyc.kycDetails.pan}</p>
            </div>
          )}
          {profileData.kyc.kycDetails.gst !== "" && (
            <div className={styles.detail}>
              <p className={styles.label}>GST</p>
              <p className={styles.desc}>{profileData.kyc.kycDetails.gst}</p>
            </div>
          )}
          <div className={styles.detail}>
            <p className={styles.label}>Billing address</p>
            <p className={styles.desc}>
              {profileData.kyc.kycDetails.address.line},{" "}
              {profileData.kyc.kycDetails.address.street},{" "}
              {profileData.kyc.kycDetails.address.city},{" "}
              {profileData.kyc.kycDetails.address.state} -{" "}
              {profileData.kyc.kycDetails.address.pincode}
            </p>
          </div>
        </div>
      </CustomDrawerWrapper>
      <CustomDrawerWrapper
        isOpen={openNotificationDrawer}
        handleClose={() => {
          setOpenNotificationDrawer(false);
          setDrawerType("");
        }}
        title="Notification Settings">
        <div className={styles.container}>
          <div className={styles.options}>
            <p>SMS</p>
            <Switch
              onChange={(checked) => {
                notificationMutation.mutate({
                  data: {
                    smsEnable: checked,
                  },
                });
              }}
              defaultChecked={profileData.notificationPreferences.isSmsEnabled}
            />
          </div>
          <div className={styles.options}>
            <p>Whatsapp</p>
            <Switch
              onChange={(checked) => {
                notificationMutation.mutate({
                  data: {
                    whatsAppEnable: checked,
                  },
                });
              }}
              defaultChecked={
                profileData.notificationPreferences.isWhatsappEnabled
              }
            />
          </div>
          <div className={styles.options}>
            <p>Email</p>
            <Switch
              onChange={(checked) => {
                notificationMutation.mutate({
                  data: {
                    emailEnable: checked,
                  },
                });
              }}
              defaultChecked={
                profileData.notificationPreferences.isEmailEnabled
              }
            />
          </div>
        </div>
      </CustomDrawerWrapper>
      <CustomDrawerWrapper
        isOpen={openEditProfileDrawer}
        handleClose={() => {
          setOpenEditProfileDrawer(false);
          setDrawerType("");
        }}
        title="Edit Profile"
        secondaryButtonOnClick={() => {
          if (!isEditEnabled) {
            return setIsEditEnabled(true);
          } else {
            return handleSubmit(onSubmitHandlerProfile)();
          }
        }}
        secondaryButton={
          <>
            {!isEditEnabled ? (
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  textDecoration: "underline",
                }}>
                Edit
              </div>
            ) : (
              <FcCheckmark size={20} />
            )}
          </>
        }>
        <div
          className={styles.container}
          style={{ maxWidth: 400, margin: "0 auto" }}>
          <div className={styles.profilePicContainer}>
            {profileData.profilePicUrl ? (
              <img
                className={styles.profilePic}
                src={profileData.profilePicUrl}
                alt=""
              />
            ) : null}
          </div>
          <Controller
            control={control}
            name="first_name"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label="First name"
                placeholder="Enter your first name"
                autoComplete="first_name"
                value={value}
                onChange={onChange}
                errorText={errors?.first_name?.message}
                disabled={!isEditEnabled}
              />
            )}
          />
          <Controller
            control={control}
            name="last_name"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label="Last name"
                placeholder="Enter your last name"
                autoComplete="family_name"
                value={value}
                onChange={onChange}
                errorText={errors?.last_name?.message}
                disabled={!isEditEnabled}
              />
            )}
          />

          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <GenderSelector
                value={value}
                setValue={onChange}
                errorText={errors?.gender?.message}
                error={errors?.gender}
                disabled={!isEditEnabled}
              />
            )}
          />
          <Controller
            control={control}
            name="dob"
            render={({ field: { onChange, value } }) => (
              <DateSelector
                label="Date of birth"
                value={value}
                setValue={onChange}
                errorText={errors?.dob?.message}
                error={errors?.dob}
                disabled={!isEditEnabled}
              />
            )}
          />
          <Controller
            control={control}
            name="community"
            rules={{
              required: {
                value: true,
                message: "At least one community is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CardSelector
                value={value}
                setValue={onChange}
                isEdit={true}
                niche={profileData.niche}
                errorText={errors?.community?.message}
                disabled={!isEditEnabled}
              />
            )}
          />
          <Button
            color="primary"
            fill="outline"
            style={{
              marginTop: "2em",
            }}
            onClick={() => {
              Dialog.confirm({
                content: "Are you sure you want to delete your profile?",
                confirmText: "Yes",
                cancelText: "No",
                onConfirm: () => {
                  Dialog.confirm({
                    title:
                      "We are sorry to let you go, your profile will be completely deleted within 2 days, in case you change your mind you can log back in within 2 days to reinstate your account",
                    confirmText: "Confirm",
                    onConfirm: () => {
                      signOutDevice();
                    },
                    cancelText: "Cancel",
                  });
                },
              });
            }}>
            Delete profile
          </Button>
        </div>
      </CustomDrawerWrapper>
    </>
  );
};

export default ProfileDrawers;
