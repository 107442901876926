import React, { useState, useEffect } from "react";
import { Button, ErrorBlock, Modal, Toast, Input } from "antd-mobile";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

import { InputNumber, Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { sendOTP } from "../handlers/admin/sendOTP";
import { validateOTP } from "../handlers/admin/validateOTP";

import { useAuthContext } from "../context/AuthContext";
const CustomSignIn = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { customSignIn } = useAuthContext();
  const [state, setState] = useState({
    otp: "",
    uid: "",
    visible: false,
    op: "",
  });
  const { uid } = useParams();

  const otpMutation = useMutation(sendOTP, {
    onSuccess: (res) => {
      setState({ ...state, op: res.data.op });
      Toast.show({
        icon: "success",
        content: "OTP sent",
      });
    },
  });
  const validateOTPMutation = useMutation(validateOTP, {
    onSuccess: (res) => {
      customSignIn(res.data.customToken)
        .then(() => {
          queryClient.removeQueries();
          navigate("/");
        })
        .catch((err) => Toast.show({ icon: "error", content: err.message }));
    },
  });

  useEffect(() => {
    if (uid) {
      return setState({ ...state, uid });
    }
    return setState({ ...state, uid: "" });
  }, [uid]);

  return (
    <div>
      {!state.uid ? (
        <ErrorBlock
          style={{
            "--image-height": "150px",
          }}
          description={
            <span style={{ padding: "0 24px" }}>
              Route Not defined, please click the button to go back to{" "}
              <span onClick={() => setState({ ...state, visible: true })}>
                app
              </span>
            </span>
          }
          fullPage>
          <Button
            color="primary"
            onClick={() => {
              navigate("/");
            }}>
            Refresh App
          </Button>
        </ErrorBlock>
      ) : (
        <Row justify="center">
          <Button
            onClick={() => setState({ ...state, visible: true })}
            color="primary"
            style={{ marginTop: "40vh", width: "50%" }}>
            Sign In
          </Button>
          <Col span={24}>
            <Row justify="center">
              {" "}
              <Button
                onClick={() => navigate("/")}
                style={{ width: "50%", marginTop: "20px" }}>
                Go Home
              </Button>
            </Row>
          </Col>
        </Row>
      )}

      <Modal
        visible={state.visible}
        showCloseButton
        onClose={() => setState({ ...state, visible: false })}
        content={
          <>
            <h3>Enter Creator Number</h3>
            <Input
              value={state.uid}
              onChange={(value) => setState({ ...state, uid: value })}
              style={{
                width: "100%",
                border: "1px solid #d9d9d9",
                borderRadius: "2px",
                padding: "4px",
              }}
            />
            <div style={{ marginTop: "20px" }}>
              <h3>Enter OTP</h3>
              <InputNumber
                value={state.otp}
                onChange={(value) => setState({ ...state, otp: value })}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </>
        }
        actions={[
          {
            key: "otp",
            text: "Send OTP",
            onClick: () => {
              otpMutation.mutate({ phoneNumber: state.uid });
            },
            disabled: !state.uid,
          },
          {
            key: "SignIn",
            text: "SignIn",
            primary: true,
            disabled: !state.uid || !state.otp,
            onClick: () => {
              validateOTPMutation.mutate({ op: state.op, otp: state.otp });
            },
          },
        ]}
        closeOnMaskClick
      />
    </div>
  );
};

export default CustomSignIn;
