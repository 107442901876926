import React, { useState, useEffect } from "react";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { Toast, Button } from "antd-mobile";
import { formatPrice } from "../../Common/formatPrice";
import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";
import SelectAccountModal from "../SelectAccountModal";
import PaymentSuccessDrawer from "./PaymentSuccessDrawer";
import {
  getWalletInfo,
  getAccounts,
  withdrawMoney,
  getRecentTxns,
} from "../../../handlers/wallet";
import { getLogo } from "../../../Utils/getLogo";
import styles from "./withdraw.module.css";

import useWindowWidth from "../../../hooks/useWindowWidth";

const WithdrawalDrawer = ({ isOpen, handleClose }) => {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectAccountModal, setSelectedAccountModal] = useState(false);
  const [paymentSuccessDrawer, setPaymentSuccessDrawer] = useState({
    open: false,
    props: {},
  });

  const isDesktop = useWindowWidth();

  const queryClient = useQueryClient();

  const { queryKey: walletKey, queryFunction: walletFn } = getWalletInfo();
  const { queryKey: recentTnxKey } = getRecentTxns();
  const { data: walletData, isLoading } = useQuery(walletKey, walletFn);

  const withdrawMoneyMutation = useMutation(withdrawMoney, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(walletKey);
      queryClient.invalidateQueries(recentTnxKey);
      Toast.show({
        icon: "success",
        content: "Money withdrawal successful",
      });
      setPaymentSuccessDrawer({ open: true, props: response });
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  const { queryKey: accountsKey, queryFunction: accountsFn } = getAccounts();

  const { data: accountsData } = useQuery(accountsKey, accountsFn);

  useEffect(() => {
    if (isOpen) {
      setPaymentSuccessDrawer({
        open: false,
        props: {},
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (accountsData) {
      setSelectedAccount(
        accountsData.data.fundAccounts.filter(
          (item) => item.status === "Verified" && item.isActive
        )[0]
      );
    }
  }, [accountsData]);

  useEffect(() => {
    if (walletData)
      setWithdrawAmount(Math.min(100000, walletData?.data?.balance));
  }, [walletData]);

  return (
    <CustomDrawerWrapper
      handleClose={handleClose}
      isOpen={isOpen}
      title="Withdraw">
      <section className={styles.withdrawContainer}>
        <img
          src="/logo.png"
          alt="cloutflow"
          style={{
            width: "71px",
            height: "71px",
            marginBottom: "4px",
            margin: "0 auto",
            objectFit: "contain",
          }}
        />
        <div className={styles.headerHeading}>
          Withdrawing from Cloutflow Wallet
        </div>
        <div className={styles.balance}>
          {" "}
          Balance: ₹ {walletData ? formatPrice(walletData.data.balance) : "NaN"}
        </div>
      </section>
      <div className={styles.amountInput}>
        <span style={{ fontSize: "28px", lineHeight: "39px" }}> ₹ </span>
        <span className={styles.input}>{formatPrice(withdrawAmount)}</span>
      </div>
      {withdrawAmount >= 100000 && (
        <div className={styles.alert}>
          <img
            alt="info"
            src="/assets/icons/info--blue.svg"
            style={{ width: "18px", height: "18px" }}
          />
          Maximum limit per transaction is ₹1,00,000. You can withdraw the rest
          of the amount again.
        </div>
      )}

      <section
        className={styles.accountContainer}
        style={isDesktop ? { position: "absolute", bottom: 0, left: 0 } : {}}>
        <div className={styles.accountHeading}>
          Choose account to withdraw to
        </div>
        {selectedAccount ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setSelectedAccountModal(true)}>
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <img
                src={getLogo(
                  selectedAccount.fundAccountType,
                  selectedAccount.bankAccountName
                )}
                alt="bank"
                style={{ width: "52px", height: "52px", objectFit: "contain" }}
              />
              <div className={styles.bankName}>
                {" "}
                {selectedAccount.fundAccountType === "upi"
                  ? selectedAccount.vpaAddress
                  : `${
                      selectedAccount.bankAccountName +
                      " XX " +
                      selectedAccount.bankAccountNumber.slice(-4)
                    }`}
              </div>
            </div>
            <img
              src="/assets/icons/downArrow.svg"
              alt="chevron"
              style={{ width: "18px", height: "18px" }}
            />
          </div>
        ) : (
          <>
            {selectAccountModal && (
              <Button
                onClick={() => setSelectedAccountModal(true)}
                fill="none"
                style={{ padding: 0 }}>
                Please Add a account
              </Button>
            )}
          </>
        )}
        {/* <CustomSwipe
          text={"Swipe to withdraw"}
          onSubmit={() =>
            withdrawMoneyMutation.mutateAsync({
              fundAccountId: selectedAccount.fundAccountId,
              price: withdrawAmount,
            })
          }
          loading={withdrawMoneyMutation.isLoading}
          error={withdrawMoneyMutation.isError}
          initiallyDisabled={
            !Boolean(selectedAccount) || Number(withdrawAmount) <= 0
          }
        /> */}
        <div>
          {/* <h5 style={{ maxWidth: "250px", color: "red" }}>
            Our bank services are currently down, Will notify you once they are
            up. Thanks for your patience
          </h5> */}

          <Button
            onClick={() =>
              withdrawMoneyMutation.mutateAsync({
                fundAccountId: selectedAccount.fundAccountId,
                price: withdrawAmount,
              })
            }
            loading={withdrawMoneyMutation.isLoading}
            // While razorpay server is down
            disabled={!Boolean(selectedAccount) || Number(withdrawAmount) <= 0}
            color="primary"
            fill="solid"
            size="large"
            block>
            Withdraw
          </Button>
        </div>
        <div style={{ display: "grid", justifyContent: "center" }}>
          <span
            style={{
              fontWeight: 500,
              fontSize: "8px",
              lineHeight: "11px",
              textAlign: "center",
              letterSpacing: "0.12em",
              textTransform: "uppercase",
            }}>
            Powered by
          </span>
          <img src="/assets/img/razorpay.png" alt="razorpay" />
        </div>
      </section>
      <SelectAccountModal
        openModal={selectAccountModal}
        selectedAccount={selectedAccount}
        setOpenModal={setSelectedAccountModal}
        setSelectedAccount={setSelectedAccount}
      />
      <PaymentSuccessDrawer
        isOpen={paymentSuccessDrawer.open}
        handleClose={() => {
          setPaymentSuccessDrawer({
            open: false,
            props: {},
          });
          handleClose();
        }}
        paymentData={paymentSuccessDrawer.props}
      />
    </CustomDrawerWrapper>
  );
};

export default WithdrawalDrawer;
