import { Input } from "antd";
import { Button } from "antd-mobile";
import { Toast } from "antd-mobile";
import { uploadDraftsV2 } from "handlers/chat/v2/uploadDraftsV2";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import CustomModalWrapper from "./CustomModalWrapper";

import { isLinkValid } from "Utils/isLinkValid";

const YoutubeDraft = ({
  openModal,
  setOpenModal,
  platform,
  workflowId,
  finalizedOfferId,
}) => {
  const [videoLink, setVideoLink] = useState("");

  const [showErr, setShowErr] = useState(false);
  const queryClient = useQueryClient();
  const sendDraftMutation = useMutation(uploadDraftsV2, {
    onSuccess: () => {
      setOpenModal(false);
      queryClient.invalidateQueries([
        "campaigns",
        "workflow",
        finalizedOfferId,
      ]);
      Toast.show("Draft Sent");
    },
    onError: (err) => {
      console.log(err.code, err.message, "error-message", err);
    },
  });
  return (
    <CustomModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      heading={"Youtube draft"}>
      <div
        style={{
          height: "100%",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "90%",
          margin: "0 auto",
        }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              flex: "0.5",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="">
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="">
              <img src="/assets/icons/unlisted.svg" alt="" />
            </div>
            <p
              style={{
                width: "90%",
                fontSize: "14px",
                textAlign: "center",
                color: "#999999",
              }}>
              Upload your draft as an unlisted video on Youtube and share the
              link below:
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flex: "0.5",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              marginBottom: "10px",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}>
              <p style={{ fontWeight: 500, fontSize: "14px" }}>
                Enter unlisted video link
              </p>
              <Input
                placeholder="enter link"
                onChange={(e) => setVideoLink(e.target.value)}
                size="large"
              />
              {showErr && !isLinkValid(videoLink) && (
                <h6 style={{ color: "red" }}>Invalid Link</h6>
              )}
            </div>
          </div>
        </div>

        <Button
          onClick={() => {
            if (isLinkValid(videoLink)) {
              sendDraftMutation.mutate({
                workflowId: workflowId,
                references: [
                  {
                    url: videoLink,
                    type: "video",
                    platform: platform,
                  },
                ],
              });
            } else {
              return setShowErr(true);
            }
          }}
          disabled={videoLink === "" || sendDraftMutation.isLoading}
          loading={sendDraftMutation.isLoading}
          size="large"
          color="primary">
          Upload
        </Button>
      </div>
    </CustomModalWrapper>
  );
};

export default YoutubeDraft;
