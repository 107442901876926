export const getLogo = (label, labelRef) => {
  switch (label) {
    case "self":
      return "/logo.png";
    case "upi":
      return "/assets/img/upi.png";
    case "brand":
      return labelRef;
    default:
      return getBankLogo(labelRef);
  }
};

export const getBankLogo = (bankName) => {
  switch (bankName) {
    case "HDFC Bank":
      return "/assets/img/hdfc.png";
    case "Punjab National Bank":
      return "/assets/img/pnb.png";
    case "Central Bank of India":
      return "/assets/img/cbi.png";
    case "Kotak Mahindra Bank":
      return "/assets/img/kotak.png";
    case "DCB Bank":
      return "/assets/img/dcb.png";
    case "State Bank of India":
      return "/assets/img/sbi.png";
    case "Paytm Payments Bank":
      return "/assets/img/paytm.png";
    case "ICICI Bank":
      return "/assets/img/icici.png";
    case "Axis Bank":
      return "/assets/img/axis.png";
    case "Yes Bank":
      return "/assets/img/yesb.png";
    case "Canara Bank":
      return "/assets/img/canara.png";
    case "Bandhan Bank":
      return "/assets/img/bandhan.png";
    default:
      return "/assets/img/other_bank.png";
  }
};
