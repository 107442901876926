import { sendRequest } from "../sendRequest";

export const getCommercialCharges = ({ socialId }) => {
  const queryKey = [socialId, "charges"];
  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/social-media/price-id?socialMediaKitId=${socialId}`
    ).then((res) => res.data);
  return { queryKey, queryFunction };
};
