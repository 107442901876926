import { getCampaignOffers } from "handlers/campaign/offers";
import React from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Campaigncard from "../Campaigncard";
import { Button } from "antd-mobile";

const InvitedCampaignCard = () => {
  const { queryKey, queryFunction } = getCampaignOffers("Invited");
  const navigate = useNavigate();
  const { data: invitedCampaignData } = useInfiniteQuery(
    queryKey,
    queryFunction,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );

  return (
    <>
      {invitedCampaignData !== undefined &&
        invitedCampaignData?.pages[0]?.data?.offer.length !== 0 && (
          <div style={{ backgroundColor: "#111111" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "31px 24px 0 24px",
                alignItems: "center",
                //   marginBottom: "20px",

                color: "#ffffff",
              }}>
              <p style={{ fontWeight: "500", fontSize: "24px" }}>
                You've been invited
              </p>
              <Button
                onClick={() =>
                  navigate("/campaign", { state: { isOnDashboard: true } })
                }
                fill={"none"}
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  cursor: "pointer",
                  padding: "0",
                  color: "#ffffff",
                  textDecoration: "underline",
                }}>
                See more
              </Button>
            </div>
            <div
              style={{
                maxWidth: "100vw",
                padding: "36px 24px",

                marginBottom: "40px",
              }}>
              <Campaigncard
                campaign={invitedCampaignData.pages[0]?.data.offer[0]}
                isInvited={true}
                isOnDashboard={true}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default InvitedCampaignCard;
