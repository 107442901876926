// import ChatIndex from "components/Chat";
import ChatWindow from "components/Chat/index.jsx";
import ChatContextProvider from "context/ChatContext";
import React from "react";
import CustomDrawerWrapper from "../Common/CustomDrawerWrapper";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "hooks/useWindowWidth";

const ChatDrawer = ({ openModalProps, setOpenModalProps }) => {
  const isDesktop = useWindowWidth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop && openModalProps.open) {
      navigate(
        `/chat/${openModalProps?.props?.brandId}/${openModalProps?.props?.conversationId}`
      );

      setOpenModalProps((openModalProps) => {
        return { ...openModalProps, open: false };
      });
    }
  }, [isDesktop, openModalProps.open]);

  return (
    <CustomDrawerWrapper
      isOpen={openModalProps.open && !isDesktop}
      hasHeader={true}
      handleClose={() =>
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        })
      }
      zIndex={3000}>
      {openModalProps?.open && (
        <ChatContextProvider>
          <ChatWindow
            brandId={openModalProps?.props?.brandId}
            conversationId={openModalProps?.props?.conversationId}
          />
        </ChatContextProvider>
      )}
    </CustomDrawerWrapper>
  );
};

export default ChatDrawer;
