import { auth } from "../../Utils/Firebase";
import axios from "axios";
import { Toast } from "antd-mobile";

export const registerUser = async () => {
  let token = (await auth?.currentUser?.getIdToken()) || null;
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_URL}v1/influencer/register`,
    data: {
      idToken: token,
    },
    //withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  }).catch((err) => {
    if (process.env.REACT_APP_DEV_ERROR_SHOW) {
      var message = "";
      if (err?.response) {
        message = err?.response?.data?.message;
      } else if (err?.request) {
        message = "Request Error";
      } else {
        message = "No Defined";
      }

      Toast.show(message);
    }
    throw err;
  });
};
