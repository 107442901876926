import { Button, Toast } from "antd-mobile";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../context/AuthContext";
import CustomInput from "../Common/CustomInput";
import openInAppUrl from "../../Utils/openInAppUrl";
import { useMutation } from "react-query";

import { ImWhatsapp } from "react-icons/im";

import { logBackend } from "../../handlers/logBackend";

const OtpModal = ({
  verificationId,
  phoneNumber,
  setType,
  setVerificationId,
  setPhoneNumberG,
}) => {
  const { verifyOtp } = useAuthContext();
  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [retryProps, setRetryProps] = useState({
  //   count: 1,
  //   timer: 30,
  // });

  const changeNumber = () => {
    setType("phone");
    setPhoneNumberG(null);
    setVerificationId(null);
  };

  const submitOtp = async () => {
    if (otp.length !== 6) {
      return Toast.show({
        content: "Please enter a valid OTP",
        position: "top",
      });
    }
    setLoading(true);
    await verifyOtp(otp, verificationId)
      .catch(async (err) => {
        setLoading(false);
        await logBackend("firebase", "", { err: err?.code, phoneNumber });
        return Toast.show({
          content: err?.code + " ,Please Retry",
          position: "top",
        });
      })
      .catch(async (err) => {
        setLoading(false);
        console.log(err?.response?.data?.message);
        await logBackend("firebase/verifyOTP", "", {
          err: err?.response.data?.message,
          phoneNumber,
          otp,
          verificationId,
        });
        return Toast.show({
          content: err?.response?.data?.message + " , Please Retry",
          position: "top",
        });
      });

    // try {
    //   setLoading(true);
    //   const result = await verifyOtp(otp, verificationId);
    //   // if (!result) {
    //   //   Toast.show({ content: "Some error occured", position: "top" });
    //   // }
    //   if (result?.message === "Auth/invalid-verification-code") {
    //     Toast.show("Invalid OTP");
    //   }
    //   // console.log(result);
    //   setLoading(false);
    //   return null;
    // } catch (err) {
    //   setLoading(false);
    //   await logBackend("firebase", "", JSON.stringify(err));
    //   return Toast.show({
    //     content: "Some error , Please Retry",
    //     position: "top",
    //   });
    // }
  };

  // const resendOtpMutation = useMutation(
  //   ({ phoneNumber, count }) => sendOtp(phoneNumber, count),
  //   {
  //     onSuccess: () => {
  //       Toast.show({
  //         content: "OTP Resent successfully",
  //         position: "top",
  //       });
  //       setRetryProps((retryProps) => ({
  //         count: Math.min(retryProps.count + 1, 2),
  //         timer: 29,
  //       }));
  //     },
  //     onError: () => {
  //       Toast.show({
  //         content: "Error sending OTP, Please retry",
  //         position: "top",
  //       });
  //     },
  //   }
  // );

  // useEffect(() => {
  //   if (retryProps.timer > 0 && retryProps.count !== 30) {
  //     setTimeout(() => {
  //       setRetryProps((retryProps) => ({
  //         ...retryProps,
  //         timer: retryProps.timer - 1,
  //       }));
  //     }, 1000);
  //   }
  // }, [retryProps.timer]);

  // useEffect(() => {
  //   return () => {
  //     setRetryProps({ count: 1, timer: 30 });
  //   };
  // }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          color: "#333333",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "34px",
          maxWidth: "350px",
          alignSelf: "center",
        }}>
        Enter the code we've sent by SMS to <b>{phoneNumber}</b> <br />
        <Button
          onClick={changeNumber}
          style={{ color: "#2130ff", padding: "0" }}
          fill="none">
          Change number
        </Button>
      </div>
      <div style={{ alignSelf: "center" }}>
        <CustomInput
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          label="OTP"
          autoFocus
          type="number"
          pattern="\d*"
          placeholder="Enter the OTP"
        />

        {/* <div style={{ width: "100%", display: "flex" }}>
          <Button
            color="primary"
            fill="none"
            size="mini"
            loading={resendOtpMutation.isLoading}
            style={{ color: "rgba(0,0,0,0.6)", marginLeft: "auto" }}
            disabled={retryProps.timer !== 30 && retryProps.timer !== 0}
            onClick={() =>
              resendOtpMutation.mutate({
                phoneNumber,
                count: retryProps.count,
              })
            }>
            {" "}
            {retryProps.count === 2 ? "Send OTP" : "Resend OTP"}
            {retryProps.count === 2 && (
              <span>
                {" "}
                on <ImWhatsapp size={"12px"} style={{ margin: "auto 5px" }} />
              </span>
            )}
            {(retryProps.timer !== 30) & (retryProps.timer !== 0)
              ? `(${retryProps.timer})`
              : ""}
          </Button>
        </div> */}
        <div style={{ minHeight: "15px" }} />
        <Button
          loading={loading}
          onClick={submitOtp}
          style={{
            width: "100%",
            height: "48px",
            borderRadius: "8px",
            backgroundColor: "#2130FF",
            color: "white",
            fontWeight: "600",
            marginTop: "5px",
            maxWidth: "350px",
          }}>
          Continue
        </Button>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#333333",
            marginTop: "5px",
            maxWidth: "350px",
          }}>
          By clicking, I agree to Cloutflow's Terms of Service, Payments{" "}
          <span
            style={{ color: "#1890ff" }}
            onClick={() =>
              openInAppUrl("https://cloutflow.com/terms-condition/")
            }>
            Terms of Service
          </span>{" "}
          and acknowledge the{" "}
          <span
            style={{ color: "#1890ff" }}
            onClick={() =>
              openInAppUrl("https://cloutflow.com/privacy-policy/")
            }>
            Privacy Policy
          </span>
          .
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
