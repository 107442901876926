import React, { useEffect } from "react";
import { useQueryClient } from "react-query";

import { getUserStatus } from "../handlers/me/getUserStatus";
import { getUserSocial } from "../handlers/me/getSocial";

import ConnectPlatform from "../components/ConnectPlatform/ConnectPlatform";

const ConnectPlatformScreen = () => {
  const { queryKey } = getUserStatus();
  const { queryKey: userSocialKey } = getUserSocial([
    "Connected",
    "Session-Expired",
  ]);
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(userSocialKey);
    };
  }, []);

  return (
    <div>
      <h2 style={{ marginBottom: 40 }}>Connect Platform</h2>
      <ConnectPlatform />
    </div>
  );
};

export default ConnectPlatformScreen;
