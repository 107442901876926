import { useModalContext } from "context/ModalContext";
import { useAuthContext } from "../../context/AuthContext";
import UserStatusProgressBar from "./UserStatusProgressBar";

const { getUserStatus } = require("handlers/me/getUserStatus");
const { useState, useEffect } = require("react");
const { useQuery } = require("react-query");

function useUserStatusHook(show) {
  const { signedIn } = useAuthContext();
  const { setOpenConnectPlatformModal } = useModalContext();
  const [openKycModal, setOpenKycModal] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [loading, setloading] = useState(true);
  const [isAction, setIsAction] = useState(false);
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [userStatusCode, setUserStatusCode] = useState("");
  const [content, setContent] = useState(null);
  const { queryKey: getUserStatusKey, queryFunction: getUserStatusFn } =
    getUserStatus();

  const { data: userStatus } = useQuery(getUserStatusKey, getUserStatusFn, {
    enabled: signedIn,
  });

  useEffect(() => {
    if (userStatus) {
      if (userStatus.data.isOnBoarded) {
        if (userStatus.data.isPlatformConnectedFirstTime) {
          if (userStatus.data.isPlatformConnected) {
            if (userStatus.data.kycStatus === "Not-Initiated") {
              if (show === "show") {
                setContent(
                  <UserStatusProgressBar
                    userStatusCode={userStatusCode}
                    status={"KYC"}
                  />
                );
              } else if (show === "action") {
                if (isAction) {
                  setOpenKycModal(true);
                  setIsAction(false);
                }
              }
              return setUserStatusCode("Kyc-Not-Initiated");
            } else if (userStatus.data.kycStatus === "Needs-Attention") {
              if (show === "show") {
                setContent(
                  <UserStatusProgressBar
                    userStatusCode={userStatusCode}
                    status={"KYC"}
                  />
                );
              } else if (show === "action") {
                if (isAction) {
                  setOpenKycModal(true);
                  setIsAction(false);
                }
              }
              return setUserStatusCode("Kyc-Needs-Attention");
            } else if (userStatus.data.kycStatus === "Under-Review") {
              if (show === "show") {
                setContent(
                  <UserStatusProgressBar
                    userStatusCode={userStatusCode}
                    status={"KYC"}
                  />
                );
              } else if (show === "action") {
                if (isAction) {
                  setOpenKycModal(true);
                  setIsAction(false);
                }
              }
              return setUserStatusCode("Kyc-Under-Review");
            } else {
              setUserStatusCode("Done");
              setKycStatus("Verified");
              return setIsQueryEnabled(true);
            }
          } else {
            setUserStatusCode("Platform-Disconnected");
            if (userStatus.data.kycStatus === "Verified")
              return setIsQueryEnabled(true);
            if (show === "action" && isAction) {
              return setOpenConnectPlatformModal(true);
            }
          }
        } else {
          setUserStatusCode("Platform-Not-Connected");
          if (show === "show") {
            return setContent(
              <UserStatusProgressBar
                userStatusCode={userStatusCode}
                status={"Platform"}
              />
            );
          } else if (show === "action" && isAction) {
            return setOpenConnectPlatformModal(true);
          }
        }
      } else {
        return setUserStatusCode("Not-Registered");
      }
    }
  }, [userStatus, isAction]);

  return {
    loading,
    setIsAction,
    content,
    isQueryEnabled,
    userStatusCode,
    openKycModal,
    setOpenKycModal,
    setKycStatus,
    kycStatus,
  };
}

export default useUserStatusHook;
