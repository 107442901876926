import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";

import { doc, getDocFromServer } from "firebase/firestore";

import { db } from "Utils/Firebase";

const VersionContext = createContext(null);

export default function VersionContextProvider({ children }) {
  const [reloadApp, setReloadApp] = useState(false);

  const firstRender = useRef(false);
  const AppVersion = 72;

  const checkFirebaseVersion = async () => {
    const documentSnapshot = await getDocFromServer(
      doc(db, "AppVersion", "cloutflowCreatorApp")
    );

    const firebaseAppVersion = documentSnapshot.data()?.version;
    console.log(AppVersion, firebaseAppVersion);
    if (AppVersion < Number(firebaseAppVersion || 0)) {
      setReloadApp(true);
    }
  };

  useEffect(() => {
    if (!firstRender.current) {
      checkFirebaseVersion();
    }
    firstRender.current = true;
  }, []);

  const value = {
    reloadApp,
  };
  return (
    <VersionContext.Provider value={value}>{children}</VersionContext.Provider>
  );
}

export const useVersionContext = () => {
  const versionContext = useContext(VersionContext);
  if (!versionContext)
    throw new Error(
      "useVersionContext must be used within an VersionContextProvider"
    );
  return versionContext;
};
