import React, { useState } from "react";
import { Button } from "antd-mobile";
import {
  FaInstagram,
  FaYoutube,
  FaSnapchat,
  FaLinkedin,
  FaTiktok,
  FaCheckCircle,
} from "react-icons/fa";

import AddPlatformModal from "../AddPlatformModal";

const PlatformTile = ({ username, platform, isVerified }) => {
  const [addPlatformVisible, setAddPlatformVisible] = useState(false);
  const getPreTag = (platform) => {
    switch (platform) {
      case "instagram":
        return <FaInstagram />;

      case "youtube":
        return <FaYoutube />;

      case "tiktok":
        return <FaTiktok />;

      case "linkedin":
        return <FaLinkedin />;

      case "snapchat":
        return <FaSnapchat />;

      default:
        return <div />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          fontSize: "20px",
        }}>
        {getPreTag(platform)}
        {String(username || "").startsWith("@") ? username : `@${username}`}
      </div>
      {isVerified ? (
        <FaCheckCircle size={20} color="#41A317" />
      ) : (
        <Button
          // color=""
          size="small"
          disabled={platform !== "instagram" && platform !== "youtube"}
          onClick={() => setAddPlatformVisible(true)}>
          Verify
        </Button>
      )}
      <AddPlatformModal
        platform={platform}
        visible={addPlatformVisible}
        handleClose={() => setAddPlatformVisible(false)}
      />
    </div>
  );
};

export default PlatformTile;
