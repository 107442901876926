import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  collection,
  query,
  startAfter,
  orderBy,
  limit,
  getDocs,
  where,
  onSnapshot,
} from "firebase/firestore";

import { db } from "Utils/Firebase";
import { useAuthContext } from "context/AuthContext";

const NotifContext = createContext(null);

export default function NotifContextProvider({ children }) {
  const [newNotif, setNewNotif] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [docSnapshot, setDocSnapshot] = useState([]);
  const { currentUser } = useAuthContext();
  const firstRender = useRef(true);

  const fetchNotificationData = async () => {
    let arr = [...notifications];
    const firebaseQuery = query(
      collection(db, "Notifications"),
      where("userId", "==", currentUser?.uid),
      orderBy("createdDateTime", "desc"),
      limit(10)
    );
    const documentSnapshots = await getDocs(firebaseQuery);
    setDocSnapshot(documentSnapshots);
    setNotifications(arr);

    onSnapshot(firebaseQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (arr.findIndex((not) => not.id === change.doc.id) === -1) {
            if (firstRender.current) {
              arr.push({
                data: { ...change.doc.data() },
                id: change.doc.id,
              });
            } else {
              arr.unshift({
                data: { ...change.doc.data() },
                id: change.doc.id,
              });
            }
            setNewNotif(true);
          }
        }
        if (change.type === "modified") {
          const newArray = arr.map((item) => {
            if (item.id === change.doc.id) {
              return { data: { ...change.doc.data() }, id: change.doc.id };
            }
            return item;
          });
          arr = newArray;
        }
      });

      setNewNotif(arr.some((item) => !item.data.isRead));
      if (firstRender.current) {
        firstRender.current = false;
      }
      setNotifications(arr);
    });
  };

  useEffect(() => {
    if (currentUser) {
      fetchNotificationData();
    }
  }, [currentUser]);

  const loadMore = async () => {
    if (docSnapshot[docSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(db, "Notifications"),
        where("userId", "==", currentUser.uid),
        orderBy("createdDateTime", "desc"),
        startAfter(docSnapshot[docSnapshot.length - 1]),
        limit(10)
      );

      const documentSnapshots = await getDocs(next);

      let docData = [];

      documentSnapshots.forEach((doc) => {
        docData.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      setDocSnapshot(documentSnapshots.docs);
      setNotifications((notifications) => [...notifications, ...docData]);
    }
  };

  const value = {
    newNotif,
    notifications,
    loadMore,
    docSnapshot,
    setNewNotif,
    fetchNotificationData,
  };
  return (
    <NotifContext.Provider value={value}>{children}</NotifContext.Provider>
  );
}

export const useNotifContext = () => {
  const notifContext = useContext(NotifContext);
  if (!notifContext)
    throw new Error(
      "useNotifContext must be used within an NotifContextProvider"
    );
  return notifContext;
};
