import { Button } from "antd-mobile";
import KycDrawer from "components/GlobalDrawers/KycDrawer";
import { useModalContext } from "context/ModalContext";
import React, { useState } from "react";
import styles from "./UserStatusProgressBar.module.css";
const UserStatusProgressBar = ({ status }) => {
  const { setOpenConnectPlatformModal } = useModalContext();
  const [openKycModal, setOpenKycModal] = useState(false);

  return (
    <div className={styles.container}>
      {/* <p className={styles.header}>Profile incomplete</p>
      <p className={styles.subHeading}>
        To participate in campaigns and enjoy the full experience of Cloutflow,
        complete your profile.
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={styles.progressBar}>
          <div className={styles.barActive}></div>
          <div className={styles.barActive}></div>
          <div
            className={status === "Platform" ? styles.bar : styles.barActive}>
            {status === "KYC" && (
              <img
                className={styles.activeIcon}
                src="/assets/icons/progressTick.svg"
                alt=""
              />
            )}
          </div>
          <div
            className={
              status === "KYC"
                ? styles.bar
                : status !== "Platform"
                ? styles.barActive
                : styles.bar
            }>
            <img
              className={styles.activeIcon}
              src="/assets/icons/progressComplete.svg"
              alt=""
            />
          </div>
        </div>
      </div> */}
      {status === "Platform" && (
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <img src="/assets/icons/platforms.svg" alt="" />
            <p>Connect the platform you work on so that brands can find you</p>
          </div>
          <p className={styles.desc}>
            When you connect your platforms, it becomes easy for brands to find
            you.
          </p>
          <Button
            onClick={() => setOpenConnectPlatformModal(true)}
            color="primary"
            style={{ width: "100%" }}>
            Connect Platform
          </Button>
        </div>
      )}
      {status === "KYC" && (
        <div style={{ marginTop: "-55px" }}>
          <img
            src="/assets/img/glass.svg"
            style={{
              margin: "50px auto",
              width: "142px",
              height: "124px",
              display: "block",
            }}
            alt="magnifying glass"
          />
          <div className={styles.kycHeader}>Profile under review</div>
          <div className={styles.kycDesc} style={{ marginTop: "20px" }}>
            <div>
              We have received your application to be a part of Cloutflow. We’re
              currently reveiwing it and will let you know in 2-3 days.
            </div>
            <div style={{ marginTop: "20px" }}>
              If it’s taking longer or if you’re facing any issues, contact us
              at support@cloutflow.com
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserStatusProgressBar;
