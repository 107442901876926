import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import AuthContextProvider from "./context/AuthContext";
import "antd/dist/antd.min.css";
import "./index.css";
import NavContextProvider from "./context/NavContext";
import ModalContextProvider from "./context/ModalContext";
import NotifContextProvider from "context/NotificationContext";
import { ChatNotificationContextProvider } from "context/ChatNotificationContext";
import VersionContextProvider from "./context/VersionContext";
import { ConfigProvider } from "antd-mobile";
import enUS from "antd-mobile/es/locales/en-US";
// import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "./ErrorBoundary";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import ReactQue

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    <ConfigProvider locale={enUS}>
      <BrowserRouter>
        <VersionContextProvider>
          <AuthContextProvider>
            <NavContextProvider>
              <ModalContextProvider>
                <ChatNotificationContextProvider>
                  <NotifContextProvider>
                    <ErrorBoundary>
                      <App />
                    </ErrorBoundary>
                  </NotifContextProvider>
                </ChatNotificationContextProvider>
              </ModalContextProvider>
            </NavContextProvider>
          </AuthContextProvider>
        </VersionContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  </QueryClientProvider>
);

serviceWorkerRegistration.register();
