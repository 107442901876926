import React, { useState } from "react";
import { Card, Popover } from "antd";
import { useModalContext } from "../../../context/ModalContext";
import useWindowWidth from "../../../hooks/useWindowWidth";
import Chip from "./Chip";
import styles from "./campaignCard.module.css";

const CampaignCard = ({ campaign, isOnDashboard = false }) => {
  const { setCampaignModal, setExploreCampaignModal } = useModalContext();
  const [popupVisible, setPopupVisible] = useState(false);

  const isDesktop = useWindowWidth();

  return (
    <Card
      className={styles.container}
      bodyStyle={{ padding: 0 }}
      onClick={() => {
        setPopupVisible(false);
        setCampaignModal({
          open: true,
          props: {
            campaignId: campaign.campaignId,
            dealId: campaign.dealId,
          },
        });
        if (isDesktop) {
          setExploreCampaignModal(false);
        }
      }}>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <img
          className={styles.banner}
          src={campaign.campaign.campaignBannerUrl}
          alt="banner"
        />
        {campaign?.statusToShow && campaign?.statusToShow !== "None" && (
          <span className={styles.chip}>{campaign.statusToShow}</span>
        )}
      </div>
      <div
        style={{
          padding: "12px",
          display: "grid",
          gap: "8px",
          minHeight: "100px",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>
            <p style={{ marginBottom: "2px" }} className={styles.cName}>
              {campaign?.campaign?.campaignName}
            </p>
            <p className={styles.bName}>
              {campaign?.brand.businessDetails.name}
            </p>
          </div>
          <img
            style={{ width: "24px", height: "24px" }}
            src={`/assets/img/${campaign.campaign.platform}-disabled.png`}
            alt="platform"
          />
        </div>
        {!campaign?.campaign?.paymentTerms && (
          <div style={{ width: "100%", position: "relative" }}>
            <span style={{ opacity: 0 }}>s</span>
            <Popover
              content={
                <p>
                  Payment will be credited <br />
                  in your wallet in 24 hrs <br />
                  after the campaign is <br />
                  completed.
                </p>
              }
              placement="right"
              popupVisible={popupVisible}
              trigger="click"
              onOpenChange={(visible) => {
                setPopupVisible(visible);
              }}>
              <div
                className={styles.paymentChip}
                // style={{ width: "max-content" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPopupVisible(true);
                }}>
                Instant Payout
              </div>
            </Popover>
          </div>
        )}

        {campaign?.campaign?.filter?.niche?.length > 0 && (
          <div
            style={{
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
            }}>
            {campaign?.campaign?.filter?.niche.map((item, idx) => (
              <Chip label={item} key={idx} />
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default CampaignCard;
