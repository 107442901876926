import React from "react";
import clsx from "clsx";

import { HiBadgeCheck } from "react-icons/hi";

import styles from "./chip.module.css";

const Chip = ({ title = "", color = "blue" }) => {
  return (
    <div
      className={clsx(styles.chip, {
        [styles.blue]: color === "blue",
        [styles.green]: color === "green",
        [styles.orange]: color === "orange",
      })}>
      {title === "Approved" ? (
        <span
          style={{ display: "inline-flex", alignItems: "center", gap: "6px" }}>
          Done <HiBadgeCheck />
        </span>
      ) : (
        title
      )}
    </div>
  );
};

export default Chip;
