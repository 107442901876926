import { sendRequest } from "../../../sendRequest";

export const acceptSpecialOfferV2 = ({
  campaignId,
  socialMediaKitId,
  price,
}) => {
  return sendRequest(
    "POST",
    `/influencer/campaign/offer/apply-special-price`,
    {
      campaignId,
      socialMediaKitId,
      influencerPrice: price,
      brandPrice: price,
    },
    null,
    true
  ).then((res) => res.data);
};
