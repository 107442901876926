import { Button } from "antd-mobile";
import openInAppUrl from "../../Utils/openInAppUrl";

const ConnectErrorYt = ({ error, isWeb = false }) => {
  const errorType = error?.response?.data?.status;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        maxWidth: "500px",
        // height: "70%",
        padding: "24px",
        // marginTop: "80px",
        overflowY: "auto",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItem: "center",
          justifyContent: "space-around",
        }}>
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "600",
            color: "#09121F",
            textAlign: "center",
          }}>
          {ErrorMap(errorType || "ERR_SCOPES_MISMATCH").primaryMessage}
        </h1>
        <h2
          style={{
            textAlign: "center",
            fontSize: "14px",
            color: "#09121F",
            fontWeight: "400",
            marginBottom: "20px",
          }}>
          {ErrorMap(errorType || "ERR_SCOPES_MISMATCH").secondaryMessage}
          In case you need help, You can refer to the <b>video</b> below or Go
          to Help in Profile Section of App
        </h2>

        <video
          loop=""
          muted={true}
          src={`/assets/video/YoutubeConnect.mp4#t=1`}
          controls={true}
          style={{
            objectFit: "cover",
            borderRadius: "8px",
            marginBottom: "70px",
          }}
          width={"100%"}
          height={"200px"}
        />
      </div>

      <Button
        size="large"
        fill="solid"
        color="primary"
        block
        onClick={openInAppUrl(
          !Boolean(isWeb)
            ? "https://link.cloutflow.com/app"
            : `${process.env.REACT_APP_URL}?type=web`
        )}>
        {!Boolean(isWeb) ? "Go to app & try again" : "Try Again"}
      </Button>
    </div>
  );
};

export default ConnectErrorYt;

const ErrorMap = (errorType) => {
  if (errorType === "ERR_SCOPES_MISMATCH") {
    return {
      primaryMessage: "We didn’t get all the needed permissions",
      secondaryMessage:
        "It looks like you didn’t give all the permissions. Please go back to mobile app and try again.",
    };
  } else {
    return {
      primaryMessage: "Something went wrong",
      secondaryMessage: "Something went wrong while connecting your profile. ",
    };
  }
};
