import { sendRequest } from "../sendRequest";

export const getCommunityInvites = ({ platform }) => {
  const queryKey = ["community", "invites", platform];
  const queryFunction = () =>
    sendRequest("get", `/influencer/connect-request?platform=${platform}`).then(
      (res) => res.data
    );
  return { queryKey, queryFunction };
};
