import { sendRequest } from "../sendRequest";

export const getUser = (enabled = true) => {
  const queryKey = ["user"];
  const queryFunction = () =>
    enabled
      ? sendRequest("get", `/influencer/me?fields=all`, {}, undefined, true)
          .then((res) => res.data)
          .catch((err) => console.log(err))
      : {};
  return { queryKey, queryFunction };
};
