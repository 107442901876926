import { Button, Toast } from "antd-mobile";
import { Spin, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";

import { getStreamableUrl } from "../../../Utils/getStreamableUrl";
import { getSocialMediaInfo } from "../../../handlers/chat";

import useWindowWidth from "../../../hooks/useWindowWidth";

import postStyles from "../../Common/posts.module.css";

const SelectMedia = ({
  selected,
  setSelected,
  socialMediaId,
  handleNext,
  handleClose,
}) => {
  const [posts, setPosts] = useState([]);

  const isDesktop = useWindowWidth();

  const { queryKey, queryFunction } = getSocialMediaInfo({
    platform: "instagram",
    socialMediaKitId: socialMediaId,
  });

  const {
    data: socialMediaData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(queryKey, queryFunction, {
    enabled: true,
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  useEffect(() => {
    if (socialMediaData && !isFetching && !isLoading) {
      if (socialMediaData?.pages?.length > 0) {
        let oldPosts = [];
        socialMediaData?.pages?.forEach((item, idx) => {
          oldPosts = [...oldPosts, ...item.data.data];
        });
        setPosts(oldPosts);
      }
    }
  }, [socialMediaData, isFetching, isLoading]);

  const getUrl = (post) => {
    if (post?.type === "IMAGE") return post?.media_url;
    else if (post?.type === "VIDEO") return post?.thumbnail_url;
    else if (post?.type === "CAROUSEL_ALBUM") {
      if (post?.children[0]?.type === "IMAGE")
        return post?.children[0]?.media_url;
      else return post?.children[0]?.thumbnail_url;
    } else {
      return "";
    }
  };

  return (
    <div style={{ padding: 4 }}>
      <h3>Select Media to Enable Automation </h3>
      <div className={postStyles.container}>
        {isLoading ? (
          <Row justify="center" style={{ marginTop: "30px" }}>
            <Spin />
          </Row>
        ) : (
          <>
            <div
              className={postStyles.scroller}
              style={{ marginBottom: isDesktop ? 50 : 10 }}>
              <InfiniteScroll
                next={() => {
                  fetchNextPage();
                }}
                style={{ padding: "5px" }}
                inverse={false}
                hasMore={hasNextPage}
                height={"50vh"}
                dataLength={posts?.length}>
                <div className={postStyles.posts}>
                  {posts?.length > 0 &&
                    posts?.map((post, i) => (
                      <div key={i} style={{ position: "relative" }}>
                        <img
                          onClick={() => setSelected(post)}
                          className={postStyles.post}
                          src={getStreamableUrl(getUrl(post))}
                          alt=""
                        />
                        {selected?.type === "CAROUSEL_ALBUM" &&
                          post?.children !== undefined &&
                          (selected?.children?.length !== 0 ||
                            post?.children?.length !== 0) &&
                          selected?.children[0]?.permalink ===
                            post?.children[0]?.permalink && (
                            <img
                              className={postStyles.selected}
                              src="/assets/icons/selected.svg"
                              alt=""
                            />
                          )}
                        {(selected?.type === "VIDEO" ||
                          selected?.type === "IMAGE") &&
                          selected?.permalink === post?.permalink && (
                            <img
                              className={postStyles.selected}
                              src="/assets/icons/selected.svg"
                              alt=""
                            />
                          )}
                        {/* {post?.type === "VIDEO" ? (
                          typeDel === "video" ? (
                            <img
                              style={{ position: "absolute", top: 5, right: 5 }}
                              alt="Video"
                              src="/assets/icons/video.svg"
                            />
                          ) : (
                            <img
                              style={{ position: "absolute", top: 5, right: 5 }}
                              alt="Reel"
                              src="/assets/icons/reel.svg"
                            />
                          )
                        ) : (
                          <>
                            {post?.type === "IMAGE" ? (
                              <img
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                }}
                                alt="Post"
                                src="/assets/icons/post.svg"
                              />
                            ) : (
                              <img
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                }}
                                alt="Carousel"
                                src="/assets/icons/carousel.svg"
                              />
                            )}
                          </>
                        )} */}
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </div>
            {/* <div className={postStyles.buttonContainer}>
              <Button
                onClick={() => {
                  if (!isEdit)
                    sendLiveMutation.mutate({
                      workflowId,
                      references: getReferences(),
                    });
                  else
                    editLiveMutation.mutate({
                      prevLiveLinkTimeLineId,
                      workflowId,
                      references: getReferences(),
                    });
                }}
                loading={
                  sendLiveMutation.isLoading || editLiveMutation.isLoading
                }
                disabled={Object.keys(selected).length === 0}
                className={postStyles.button}
                block
                color="primary"
                size="large">
                {sendLiveMutation.isLoading || editLiveMutation.isLoading
                  ? "Uploading"
                  : "Upload"}
              </Button>
            </div> */}
          </>
        )}
      </div>
      <Space>
        <Button onClick={() => handleClose()}>Close</Button>
        <Button
          color="primary"
          onClick={() => handleNext()}
          disabled={!Boolean(selected?.permalink)}>
          Next
        </Button>
      </Space>
    </div>
  );
};

export default SelectMedia;
