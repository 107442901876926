import CustomDrawerWrapper from "components/Common/CustomDrawerWrapper";
import CampaignCard from "../Dashboard/Campaigncard";
import { useAuthContext } from "../../context/AuthContext";

import { useInfiniteQuery, useQueryClient } from "react-query";
import { PullToRefresh, DotLoading, InfiniteScroll } from "antd-mobile";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import { getAllCampaigns } from "../../handlers/campaign/getAllCampains";

const ExploreCampaignsDrawer = ({ isOpen, handleClose }) => {
  const { queryKey, queryFunction } = getAllCampaigns();

  const { signedIn } = useAuthContext();

  const queryClient = useQueryClient();

  const {
    data: campaignData,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(queryKey, queryFunction, {
    enabled: signedIn,
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>--- No More Results ---</span>
        )}
      </>
    );
  };

  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      title={"Explore Campaigns"}
    >
      <PullToRefresh
        onRefresh={async () => {
          await queryClient.invalidateQueries(queryKey);
        }}
        refreshingText={
          <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
        }
        canReleaseText={<AiOutlineArrowUp size={"20px"} />}
        pullingText={<AiOutlineArrowDown size={"20px"} />}
        completeText={<div />}
      >
        <div style={{ padding: "0px 12px" }}>
          {isLoading ? (
            <>
              <span>Loading</span>
              <DotLoading />
            </>
          ) : (
            <>
              <div style={{ display: "grid", gap: "12px" }}>
                {campaignData?.pages.map((page) =>
                  page.data.brandCampaign.map((campaign) => {
                    return (
                      <CampaignCard key={campaign._id} campaign={campaign} />
                    );
                  })
                )}
              </div>
              <InfiniteScroll
                loadMore={() => fetchNextPage()}
                hasMore={hasNextPage}
              >
                <InfiniteScrollContent hasMore={hasNextPage} />
              </InfiniteScroll>
            </>
          )}
        </div>
      </PullToRefresh>
    </CustomDrawerWrapper>
  );
};

export default ExploreCampaignsDrawer;
