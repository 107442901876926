import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { SpinLoading } from "antd-mobile";
import { Row } from "antd";
import { useEffect } from "react";
import Lottie from "react-lottie";

import { getUserStatus } from "../../handlers/me/getUserStatus";

import CustomDrawerWrapper from "../Common/CustomDrawerWrapper";

import animationData from "../../assets/lottie/settingAccountLoading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ApplicationSuccessDrawer = ({ showSuccess, handleClose }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { queryKey: userStatusKey } = getUserStatus();

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        queryClient.invalidateQueries(userStatusKey).then(() => {
          handleClose();
          navigate("/kycProcessing");
        });
      }, 5000);
    }
  }, [showSuccess]);

  return (
    <CustomDrawerWrapper isOpen={showSuccess} hasHeader={true}>
      <div style={{ paddingTop: "20vh", height: "100vh" }}>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            padding: "0 24px ",
          }}>
          {/* <Row justify="center">
            <SpinLoading color="primary" />
          </Row> */}
          <Lottie
            options={defaultOptions}
            height={150}
            width={150}
            // style={{ marginBottom: 20 }}
          />
          <div
            style={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              marginTop: 30,
            }}>
            Setting Up Your Account 🚀
          </div>
        </div>
      </div>
    </CustomDrawerWrapper>
  );
};

export default ApplicationSuccessDrawer;
