import React, { useState, useEffect } from "react";
import { Divider } from "antd-mobile";
import { db } from "../../../Utils/Firebase";

import {
  query,
  collection,
  limit,
  getDocs,
  startAfter,
  orderBy,
} from "firebase/firestore";

import RestaurantList from "../Modals/RestaurantList";
import RestaurantDescription from "../Modals/RestaurantDescription";

const Card = () => {
  const [listModalOpen, setListModalOpen] = useState(false);
  const [descModalProps, setDescModalProps] = useState({
    open: false,
    data: null,
  });

  const [docSnapshot, setDocSnapshot] = useState(null);

  const [restaurantList, setRestaurantList] = useState([]);

  const handleTileClick = (id) => {
    // setDescModalProps((descModalProps) => ({ ...descModalProps, open: false }));
    setDescModalProps({
      data: restaurantList?.find((item) => item.id === id).data,
      open: true,
    });
  };

  const fetchRestaurantList = async () => {
    let arr = [...restaurantList];
    const firebaseQuery = query(
      collection(db, "Restaurant"),
      orderBy("createdDateTime", "desc"),
      limit(10)
    );

    const querySnapshot = await getDocs(firebaseQuery);
    setDocSnapshot(querySnapshot);
    querySnapshot.forEach((doc) => {
      if (arr.findIndex((item) => item.id === doc.id) === -1) {
        arr.push({ data: doc.data(), id: doc.id });
      }
      // doc.data() is never undefined for query doc snapshots
    });
    setRestaurantList(arr);
  };

  const loadMore = async () => {
    if (docSnapshot[docSnapshot.length - 1] !== undefined) {
      const next = query(
        collection(db, "Restaurant"),
        startAfter(docSnapshot[docSnapshot.length - 1]),
        orderBy("createdDateTime", "desc"),
        limit(10)
      );

      const documentSnapshots = await getDocs(next);

      let docData = [];

      documentSnapshots.forEach((doc) => {
        if (docData.findIndex((item) => item.id === doc.id) === -1) {
          docData.push({ data: doc.data(), id: doc.id });
        }
      });
      setRestaurantList((restaurantList) => [...restaurantList, ...docData]);
      setDocSnapshot(documentSnapshots.docs);
    }
  };

  useEffect(() => {
    fetchRestaurantList();
  }, []);

  if (restaurantList.length === 0 || true) return <div />;

  return (
    <div style={{ margin: "24px" }}>
      <img
        src="/assets/img/Restraunt/banner--desc.png"
        alt="banner"
        style={{ width: "100%", height: "215px" }}
        onClick={() => setListModalOpen(true)}
      />
      <Divider style={{ marginTop: "17px", marginBottom: "0" }} />

      <RestaurantList
        isOpen={listModalOpen}
        handleClose={() => setListModalOpen(false)}
        handleTileClick={handleTileClick}
        restaurantList={restaurantList}
        loadMore={loadMore}
        hasMore={docSnapshot[docSnapshot.length - 1] !== undefined}
      />

      <RestaurantDescription
        isOpen={descModalProps.open}
        data={descModalProps.data}
        handleClose={() =>
          setDescModalProps((descModalProps) => ({
            ...descModalProps,
            open: false,
          }))
        }
      />
    </div>
  );
};

export default Card;
