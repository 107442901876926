import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Campaigns from "../components/Campaigns";
import Dashboard from "../components/Dashboard";
import Notifications from "components/Notifications/Notifications";
import Wallet from "../components/Wallet";
import Profile from "../components/Profile";
import ConnectPlatformScreen from "screens/ConnectPlatformScreen";
import CampaignDetailScreen from "../screens/CampaignDetailScreen";
import OngoingCampaignScreen from "screens/OngoingCampaignScreen";
import ChatDScreen from "../screens/ChatDScreen";
import MessagesScreen from "../screens/MessagesScreen";

const MainRoutes = () => {
  return (
    <ErrorBoundary FallbackComponent={Error} onReset={window.location.reload}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/campaign" element={<Campaigns />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/notification" element={<Notifications />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/connectPlatform" element={<ConnectPlatformScreen />} />
          <Route
            path="/campaignDetail/:campaignId/:dealId"
            element={<CampaignDetailScreen />}
          />
          <Route
            path="/ongoingCampaign/:offerId"
            element={<OngoingCampaignScreen />}
          />
          <Route path="/chats" element={<MessagesScreen />} />
          <Route path="/chat/:brandId/" element={<ChatDScreen />} />

          <Route
            path="/chat/:brandId/:conversationId"
            element={<ChatDScreen />}
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default MainRoutes;
