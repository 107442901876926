import { useEffect, useState } from "react";
import useConnectIg from "../../handlers/connectSocial/useConnectIg";
import useSelectIg from "../../handlers/connectSocial/useSelectIg";
import useReConnectIg from "../../handlers/connectSocial/reConnectIg";
// import LoadingScreen from "../Common/LoadingScreen";
// import FAVICON from "../../assets/FAVICON.png";
import { Button, DotLoading, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import ConnectError from "components/ConnectPlatform/ConnectError";

const AuthoriseInstagram = ({ isReconnect, isWeb = false }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const isLinkError = urlParams.get("error");
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const { mutate, isLoading, isError, data, error } = useConnectIg();
  const {
    mutate: reConnectMutateIg,
    isLoading: reconnectLoading,
    isSuccess: reconnectSuccess,
    isError: reconnectError,
    error: reconnectErrorData,
  } = useReConnectIg();
  const {
    mutate: selectMutate,
    isSuccess: isSuccessSelect,
    isError: isErrorSelect,
    isLoading: isLoadingSelect,
    data: dataSelect,
  } = useSelectIg();

  useEffect(() => {
    if (isSuccessSelect || reconnectSuccess) {
      navigate(
        `/public/authorise/instagram/sucess${Boolean(isWeb) ? "/web" : ""}`
      );
    }
  }, [isSuccessSelect, reconnectSuccess]);

  const [acessToken, setAcessToken] = useState(null);
  const [userIgs, setUserIgs] = useState();

  useEffect(() => {
    if (data) {
      if (data.status === 200) {
        setAcessToken(data.data.message.accessToken);
        setUserIgs(data.data.message.igUser);
      }
    }
  }, [data]);

  useEffect(() => {
    if (code && state) {
      if (isReconnect) {
        return reConnectMutateIg({
          data: {
            code: code,
            redirectUri: `${process.env.REACT_APP_URL}/public/${
              Boolean(isReconnect) ? "reauthorise" : "authorise"
            }/instagram${Boolean(isWeb) ? "/web" : ""}`,
          },
          token: state,
        });
      }
      mutate({
        data: {
          code: code,
          redirectUri: `${process.env.REACT_APP_URL}/public/${
            Boolean(isReconnect) ? "reauthorise" : "authorise"
          }/instagram${Boolean(isWeb) ? "/web" : ""}`,
        },
        token: state,
      });
    }
  }, []);

  const handleConnectPlatform = () => {
    const ig = selected;
    if (ig.igUserId) {
      selectMutate({
        data: {
          accessToken: acessToken,
          igUserId: ig.igUserId,
          fbPageId: ig.fbPageId,
        },
        token: state,
      });
    }
  };

  if (isErrorSelect) {
    Toast.show("Something went wrong");
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}>
      <div style={{ padding: "20px", width: "100%" }}>
        <img src={"/assets/FAVICON.png"} style={{ width: "50px" }} />
      </div>

      {/* main screen */}
      {isError ||
      !code ||
      !state ||
      isLinkError ||
      isErrorSelect ||
      reconnectError ? (
        <ConnectError
          error={Boolean(isReconnect) ? reconnectErrorData : error}
          isWeb={isWeb}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            maxWidth: "500px",
            height: "70%",
            padding: "24px",
          }}>
          {!Boolean(isReconnect) && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItem: "center",
                justifyContent: "space-around",
              }}>
              <h1
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#09121F",
                  textAlign: "center",
                }}>
                Select one profile
              </h1>
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#09121F",
                  fontWeight: "400",
                  marginBottom: "20px",
                }}>
                Right now we can only connect one profile with your Cloutflow
                account. Please select one and continue.
              </h2>
            </div>
          )}
          {isLoading || reconnectLoading ? (
            <DotLoading />
          ) : (
            <div>
              {userIgs?.map((ig, key) => {
                return (
                  <SelectCard
                    ig={ig}
                    selected={selected}
                    setSelected={setSelected}
                    key={key}
                  />
                );
              })}
            </div>
          )}
          <Button
            size="large"
            fill="solid"
            color="primary"
            block
            disabled={selected ? false : true}
            onClick={handleConnectPlatform}
            loading={isLoadingSelect}>
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

export default AuthoriseInstagram;

const SelectCard = ({ ig, selected, setSelected }) => {
  return (
    <div
      onClick={() => setSelected(ig)}
      style={
        selected?.igUserId === ig.igUserId
          ? {
              padding: "10px",
              border: "2px solid #2130FF",
              borderRadius: "8px",
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : {
              padding: "10px",
              border: "1px solid #CCCCCC",
              borderRadius: "8px",
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
      }>
      <h1
        style={
          selected?.igUserId === ig.igUserId
            ? {
                fontSize: "16px",
                fontWeight: "400",
                color: "#2130FF",
              }
            : { fontSize: "16px", fontWeight: "400", color: "#09121F" }
        }>
        @{ig.igUserName}
      </h1>
    </div>
  );
};
