import { useState } from "react";
// import OtpModal from "./OtpModal";
// import PhoneNumberModal from "./PhoneNumberModal";

import OtpModal from "../RegisterModal/OtpModal";
import PhoneNumberModal from "../RegisterModal/PhoneNumberModal";

const Login = () => {
  const [type, setType] = useState("phone");
  const [verificationId, setVerificationId] = useState(null);
  const [phoneNumberG, setPhoneNumberG] = useState(null);

  return (
    <>
      {type === "phone" && (
        <PhoneNumberModal
          setType={setType}
          setVerificationId={setVerificationId}
          setPhoneNumberG={setPhoneNumberG}
        />
      )}
      {type === "otp" && (
        <OtpModal
          verificationId={verificationId}
          phoneNumber={phoneNumberG}
          setType={setType}
          setVerificationId={setVerificationId}
          setPhoneNumberG={setPhoneNumberG}
        />
      )}
    </>
  );
};

export default Login;
