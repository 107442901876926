import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  DatePicker,
  // Input,
  Picker,
  List,
  Space,
  Selector,
  Divider,
  // Radio,
} from "antd-mobile";
import { Input, Radio } from "antd";
import { useQuery } from "react-query";

import {
  FaInstagram,
  FaYoutube,
  FaSnapchat,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";
import dayjs from "dayjs";

import { useMutation, useQueryClient } from "react-query";

import { updateUserV2 } from "../../handlers/me/updateUserV2";
import { getUserStatus } from "../../handlers/me/getUserStatus";
import { getUser } from "../../handlers/me/getUser";

import ApplicationSuccessDrawer from "./ApplicationSuccessDrawer";
import { VerifyPlatforms } from "../VerifyPlatforms";

import { useAuthContext } from "../../context/AuthContext";

import { IoChevronBack } from "react-icons/io5";

import { convertDateToYyyyMmDd } from "Utils/convertDateToYyyyMmDd";

// import AddPlatformModal from "./AddPlatform";

const OnboardingNew = ({ isRegistered }) => {
  // const [form] = Form.useForm();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { signOutDevice } = useAuthContext();

  const [platforms, setPlatforms] = useState([]);

  const [hasGst, setHasGst] = useState(false);
  const [gst, setGst] = useState("");
  const [showSuccesModal, setShowSuccesModal] = useState(false);
  const { queryKey: userStatusKey } = getUserStatus();

  const { queryKey: userKey, queryFn: userFn } = getUser(true);
  const { data: userData } = useQuery(userKey, userFn);

  const firstRender = useRef(true);

  const [form] = Form.useForm();

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z0-9]{1}[A-Z0-9]{1}$/;

  const updateUserV2Mutation = useMutation(updateUserV2, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(userKey);
      queryClient.invalidateQueries(userStatusKey).then(() => {
        if (isRegistered) return setShowSuccesModal(true);
        navigate("/?type=onbaording");
      });

      // setShowSuccesModal(true);
    },
    onError: (err) => console.log("err", err),
  });

  const onFinish = (values) => {
    // console.log(values?.dob, new Date(values?.dob));
    updateUserV2Mutation.mutate({
      name: values?.fName,
      lastname: values?.lName,
      gender: values?.gender,
      pan: values?.pan,
      gstNumber: hasGst ? gst : undefined,
      dateOfBirth: values?.dob
        ? new Date(values?.dob).toISOString()
        : undefined,
      userPlatforms: platforms
        .filter((item) => Boolean(String(item?.username || "").trim()))
        .map((item) => ({
          ...item,
          username: String(item?.username || "")
            .toLowerCase()
            .startsWith("@")
            ? String(item?.username || "")
                .toLowerCase()
                .slice(1)
            : String(item?.username || "").toLowerCase(),
        })),
      isOnBoarded: true,
      isBasicInfoDone: true,
      inviteCode: values?.inviteCode || "",
    });
  };

  const handleChangePlatformUsername = (platformToChange, value) => {
    setPlatforms((platforms) =>
      platforms.map((platItem) => {
        if (platItem.platform === platformToChange)
          return {
            platform: platformToChange,
            username: String(value || "").startsWith("@")
              ? String(value || "")
              : `@${value || ""}`,
          };
        return platItem;
      })
    );
  };

  const getPreTag = (platform) => {
    switch (platform) {
      case "instagram":
        return <FaInstagram />;

      case "youtube":
        return <FaYoutube />;

      case "tiktok":
        return <FaTiktok />;

      case "linkedin":
        return <FaLinkedin />;

      case "snapchat":
        return <FaSnapchat />;

      default:
        return <div />;
    }
  };

  useEffect(() => {
    return () => {
      firstRender.current = true;
    };
  }, []);

  useEffect(() => {
    if (firstRender.current && userData) {
      setPlatforms(
        (userData?.data?.userPlatforms || [])?.map((item) => ({
          ...item,
          username: (item?.username || "").startsWith("@")
            ? item?.username || ""
            : `@${item?.username || ""}`,
        }))
      );
      setHasGst(userData?.data?.kyc?.kycDetails?.isGstRegistered || false);
      setGst(userData?.data?.kyc?.kycDetails?.gst || "");

      form.setFieldsValue({
        fName: userData?.data?.name,
        lName: userData?.data?.lastname,
        gender: userData?.data?.gender,
        pan: userData?.data?.kyc?.kycDetails?.pan,
        dob: userData?.data?.dateOfBirth
          ? convertDateToYyyyMmDd(new Date(userData?.data?.dateOfBirth))
          : undefined,
        gst: userData?.data?.kyc?.kycDetails?.isGstRegistered ? "yes" : "no",
        inviteCode: userData?.data?.inviteCode || "",
      });

      firstRender.current = false;
    }
  }, [userData]);

  return (
    <div>
      <Button
        fill="none"
        style={{ padding: "24px 16px" }}
        onClick={() => {
          signOutDevice();
          navigate("/");
        }}>
        <IoChevronBack size={24} />
      </Button>
      <Form
        name="form"
        form={form}
        onFinish={onFinish}
        style={{
          position: "relative",
          paddingTop: "50px",
          // minHeight: "100vh",
          // padding: isRegistered ? "20px" : undefined,
          padding: "20px",
          "--border-top": "solid 0px red",
          "--border-inner": "solid 0px red",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "scroll",
        }}
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={updateUserV2Mutation.isLoading}
            // style={{ position: "absolute", bottom: 10, margin: "0 -10px" }}
          >
            Submit
          </Button>
        }>
        <h4>Enter Your Basic Details</h4>
        <Form.Item name="fName" label="First Name" rules={[{ required: true }]}>
          <Input
            placeholder=" Please enter your first name "
            autoComplete="give-name"
          />
        </Form.Item>
        <Form.Item name="lName" label="Last Name" rules={[{ required: true }]}>
          <Input
            placeholder=" Please enter your Last name "
            autoComplete="family-name"
          />
        </Form.Item>

        <Form.Item
          name="dob"
          label="Date of Birth"
          // trigger="onConfirm"
          rules={[{ required: true, message: "Please select dob" }]}
          // onClick={(e, datePickerRef) => {
          //   datePickerRef.current?.open();
          // }}
        >
          <Input
            type="date"
            style={{ width: "100%" }}
            placeholder=" Please enter DOB"
            autoComplete="bday"
          />
          {/* <DatePicker
            min={
              new Date(new Date().setFullYear(new Date().getFullYear() - 70))
            }>
            {(value) =>
              value ? dayjs(value).format("YYYY-MM-DD") : "Please select a Date"
            }
          </DatePicker> */}
        </Form.Item>

        <Form.Item
          label="Gender"
          name="gender"
          rules={[{ required: true, message: "Please select your Gender" }]}
          //   trigger="onConfirm"
          //   onClick={(e, datePickerRef) => {
          //     console.log(datePickerRef);
          //     datePickerRef.current?.open();
          //    //setOpen(true);
          //   }}
        >
          {/* <Picker
            columns={[genderOptions]}
            cancelText="Cancel"
            confirmText="Confirm">
            {(items) => {
              return (
                <Space align="center">
                  {items?.every((item) => item === null)
                    ? "Not selected"
                    : items?.map((item) => item?.label ?? "").join("")}
                </Space>
              );
            }}
          </Picker> */}

          <Radio.Group>
            {/* <Space direction="horizontal" style={{ padding: "12px" }}> */}
            <Radio.Button
              value={"Male"}
              // style={{
              //   "--icon-size": "18px",
              //   "--font-size": "14px",
              //   "--gap": "6px",
              // }}
            >
              Male
            </Radio.Button>
            <Radio.Button
              value={"Female"}
              // style={{
              //   "--icon-size": "18px",
              //   "--font-size": "14px",
              //   "--gap": "6px",
              // }}
            >
              Female
            </Radio.Button>
            <Radio.Button
              value={"Other"}
              // style={{
              //   "--icon-size": "18px",
              //   "--font-size": "14px",
              //   "--gap": "6px",
              // }}
            >
              Others
            </Radio.Button>
            {/* </Space> */}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="platforms"
          label="Platforms"
          rules={[
            {
              validator: async (_, names) => {
                if (
                  platforms?.filter(
                    (item) =>
                      item.platform === "instagram" ||
                      item.platform === "youtube"
                  ).length <= 0
                ) {
                  return Promise.reject(
                    new Error(
                      "Please select at least one platform (instagram/youtube)"
                    )
                  );
                }

                if (
                  platforms.some((item) =>
                    Boolean((item.username || "").trim().length < 2)
                  )
                ) {
                  return Promise.reject(
                    new Error("Please enter the usernames of option Selected")
                  );
                }

                return Promise.resolve();
              },
            },
          ]}>
          <Selector
            // columns={3}
            multiple
            value={platforms?.map((item) => item?.platform)}
            onChange={(items) =>
              setPlatforms((platforms) => {
                return items.map((item) => ({
                  platform: item,
                  username:
                    platforms?.find(
                      (platformItem) => platformItem.platform === item
                    )?.username || "@",
                }));
              })
            }
            options={[
              { label: "Instagram", value: "instagram" },
              { label: "Youtube", value: "youtube" },
              { label: "Tiktok", value: "tiktok" },
              { label: "LinkedIn", value: "linkedin" },
              { label: "Snapchat", value: "snapchat" },
            ]}
          />
          <div style={{ display: "grid", gap: 8, marginBlock: 15 }}>
            {platforms?.map((platformItem) => (
              <div>
                <Input
                  prefix={
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        margin: "-4px 0px -4px -11px",
                        height: "32px",
                        padding: "11px",
                      }}>
                      {getPreTag(platformItem.platform)}
                    </span>
                  }
                  value={platformItem.username}
                  onChange={(e) =>
                    handleChangePlatformUsername(
                      platformItem.platform,
                      e.target.value || ""
                    )
                  }
                  placeholder={`Enter ${platformItem.platform} username`}
                />
                <Divider />
              </div>
            ))}
          </div>
        </Form.Item>

        <Form.Item
          name="pan"
          label="PAN"
          rules={[
            { required: true, message: "Please Enter Valid Pan" },
            {
              validator: async (_, names) => {
                if (names && !panRegex.test(names)) {
                  return Promise.reject(new Error("Invalid PAN Format!"));
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Input placeholder=" Please enter your PAN" />
        </Form.Item>

        <Form.Item
          name="gst"
          label=" Do you have GSTIN?"
          rules={[
            {
              validator: async (_, names) => {
                if (hasGst && !gstRegex.test(gst)) {
                  return Promise.reject(new Error("Invalid GST Number!"));
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Radio.Group
            value={hasGst ? "yes" : "no"}
            onChange={(e) => {
              // console.log(val);

              return setHasGst(e.target.value === "yes");
            }}>
            <Space direction="horizontal" style={{ padding: "12px" }}>
              <Radio
                value={"yes"}
                style={{
                  "--icon-size": "18px",
                  "--font-size": "14px",
                  "--gap": "6px",
                }}>
                Yes
              </Radio>
              <Radio
                value={"no"}
                style={{
                  "--icon-size": "18px",
                  "--font-size": "14px",
                  "--gap": "6px",
                }}>
                No
              </Radio>
            </Space>
            {hasGst && (
              <Input
                value={gst}
                onChange={(e) => setGst(e.target.value || "")}
                placeholder={`Enter GSTIN`}
                style={{ padding: "12px" }}
              />
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item name="inviteCode" label="Invite Code (Optional)">
          <Input placeholder="Invite Code " />
        </Form.Item>
      </Form>
      <VerifyPlatforms
        visible={showSuccesModal}
        handleClose={() => setShowSuccesModal(false)}
      />
      {/* <ApplicationSuccessDrawer showSuccess={showSuccesModal} /> */}
    </div>
  );
};

export default OnboardingNew;
