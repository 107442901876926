import { sendRequest } from "../sendRequest";

export const editCommercialCharges = ({ socialId, platform, prices }) => {
  return sendRequest(
    "post",
    `/influencer/social-media/price-id?&socialMediaKitId=${socialId}`,
    {
      ...prices,
    }
  ).then((res) => res.data);
};
