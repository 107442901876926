import React, { useState, useEffect } from "react";
import { Button, Dialog } from "antd-mobile";
import { IoChevronBack } from "react-icons/io5";
import { useQuery, useQueryClient } from "react-query";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CompleteKYC from "components/Onboarding/CompleteKYC";
import ConnectPlatformScreen from "components/Onboarding/ConnectPlatformScreen";
import Login from "components/Onboarding/Login";
import Register from "components/Onboarding/Register";
import { useAuthContext } from "../../context/AuthContext";
import { useNavContext } from "../../context/NavContext";

import HelpModalButton from "../../components/Common/HelpModalButton";

import RegisterProgressBar from "../../components/Common/RegisterProgressBar";
import { getUserStatus } from "../../handlers/me/getUserStatus";
import { LoadingOutlined } from "@ant-design/icons";

import OnboardingNew from "../../components/Onboarding/OnboardingNew";

const Onboarding = () => {
  const [screen, setScreen] = useState(0);
  const { signedIn, signOutDevice } = useAuthContext();
  const { queryKey, queryFunction } = getUserStatus();
  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: signedIn,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { setOpenNav, setNavHeading } = useNavContext();

  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      return;
    } else {
      if (!signedIn) {
        return setScreen(0);
      }
      if (signedIn && data) {
        setInitialized(true);

        if (data.data.isOnBoarded) {
          return navigate("/");
        }
        setScreen(1);
        // if (!data.data.isBasicInfoDone) {
        //   return setScreen(1);
        // }

        // return setScreen(2);
      }
    }
  }, [signedIn, data]);

  useEffect(() => {
    setOpenNav(false);
    setNavHeading("");
  }, []);

  const handleInvalidate = () => {
    queryClient.invalidateQueries(queryKey);
    setScreen(2);
  };

  if (isLoading) {
    return <LoadingOutlined />;
  }

  return (
    <div
      style={{
        height: "100vh",
        overflow: "scroll",
      }}>
      {screen === 0 && (
        <Button
          fill="none"
          style={{ margin: "24px 20px 16px 24px", padding: "0" }}
          onClick={() => {
            signOutDevice();
            navigate("/");
          }}>
          <IoChevronBack size={24} />
        </Button>
      )}
      {/* ) : (
         <div
           style={{
             display: "flex",
             justifyContent: "space-between",
             margin: "24px 20px 16px 24px",
           }}>
           <Button
             fill="none"
             style={{ padding: "0" }}
             onClick={() => {
               if (screen === 1) {
                 return Dialog.confirm({
                   content: "Are you sure you want to go back?",
                   confirmText: "Yes",
                   cancelText: "No",
                   onConfirm: async () => {
                     setScreen((screen) => screen - 1);
                   },
                 });
               }
               setScreen((screen) => screen - 1);
             }}>
             <IoIosArrowBack size={24} />
           </Button>
           <HelpModalButton />
         </div>
       )} */}
      {/* {screen !== 0 && <RegisterProgressBar currIndex={screen} />} */}

      <div style={{ padding: "0 24px", marginTop: "24px" }}>
        {screen === 0 ? (
          <Login />
        ) : (
          <div style={{ margin: "-24px auto", maxWidth: "700px" }}>
            <OnboardingNew />
          </div>
        )}
        {/* ) : screen === 1 ? (
         <Register setScreen={setScreen} handleInvalidate={handleInvalidate} />
       ) : screen === 2 ? (
         <ConnectPlatformScreen setScreen={setScreen} />
       ) : (
         <CompleteKYC />
       )} */}
      </div>
    </div>
  );
};

export default Onboarding;
