import React from "react";
import openExternalUrl from "../../Utils/openExternalUrl";
import { Dialog, Button } from "antd-mobile";

import { MdHelpOutline } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";

const HelpModalButton = ({ message = "" }) => {
  return (
    <div
      onClick={() =>
        Dialog.alert({
          content: (
            <p style={{ textAlign: "center" }}>
              Are you facing some issues , Get instant help by contacting us on
              whatsapp
            </p>
          ),
          onConfirm: () =>
            openExternalUrl("https://wa.me/message/TGONBHANLW34C1"),
          confirmText: (
            <Button color="primary" block>
              <div
                style={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                Contact us
                <RiWhatsappFill size={"24px"} />
              </div>
            </Button>
          ),
          closeOnMaskClick: true,
        })
      }>
      {message ? message : <MdHelpOutline fontSize="24px" />}
    </div>
  );
};

export default HelpModalButton;
