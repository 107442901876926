import { DotLoading, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getCategories } from "../../handlers/getCategories";

const CardSelector = ({
  value,
  setValue,
  errorText,
  isEdit,
  niche,
  disabled,
}) => {
  const { queryKey, queryFunction } = getCategories();
  const { data, isLoading } = useQuery(queryKey, queryFunction);
  const [communities, setCommunities] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (data?.message?.categories) {
      const temp = [];
      let alreadySelectedCount = 0;
      data.message.categories.forEach((item) => {
        // For Edit profile - to mark existing community as selected in edit profile drawer
        if (isEdit) {
          if (niche.findIndex((ele) => ele === item) !== -1) {
            alreadySelectedCount++;
            temp.push({ community: item, selected: true });
          } else temp.push({ community: item, selected: false });
        } else {
          if (value.findIndex((ele) => ele === item) !== -1) {
            alreadySelectedCount++;
            temp.push({ community: item, selected: true });
          } else temp.push({ community: item, selected: false });

          // temp.push({ community: item, selected: false });
        }
      });
      setCount(alreadySelectedCount);
      setCommunities(temp);
    }
  }, [data]);

  const handleClick = (item, index) => {
    // if selected set set in selectedCommunity
    // else remove from selectedCommunity

    if (count > 2 && !item.selected) {
      return Toast.show({ content: "You can select only 3 communities" });
    }
    const tempArray = communities;
    tempArray[index] = { community: item.community, selected: !item.selected };
    setCommunities(tempArray);

    //if item.selected then push to value array
    //else remove from value array
    if (item.selected) {
      //This is the case when the item is already selected
      //so we need to remove it from the value array
      setCount(count - 1);
      let tempArray = value.filter((e) => e !== item.community);

      setValue(tempArray);
    }
    if (!item.selected) {
      //This is the case when the item is not selected
      //so we need to add it to the value array
      setCount(count + 1);
      setValue([...value, item.community]);
    }
  };

  return (
    <div style={{ maxWidth: "350px", width: "100%" }}>
      <div style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
        Select your community
      </div>
      {isLoading ? (
        <DotLoading />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            marginTop: "5px",
            marginBottom: "5px",
            flexWrap: "wrap",
            gap: "5px",
          }}>
          {communities.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => !disabled && handleClick(item, index)}
                style={
                  item.selected
                    ? {
                        color: disabled ? "rgba(0,0,0,.25)" : "white",
                        borderRadius: "32px",
                        minWidth: "60px",
                        textAlign: "center",
                        backgroundColor: disabled ? "#f5f5f5" : "#2130FF",
                        padding: "4px 10px",
                        borderColor: disabled && "#d9d9d9",
                        boxShadow: disabled && "none",
                        cursor: disabled && "not-allowed",
                        opacity: disabled && "1",

                        // margin: "7px",
                      }
                    : {
                        color: disabled ? "rgba(0,0,0,.25)" : "black",
                        borderRadius: "32px",
                        minWidth: "60px",
                        textAlign: "center",
                        backgroundColor: disabled ? "#f5f5f5" : "#F0EFF8",
                        padding: "4px 10px",
                        borderColor: disabled && "#d9d9d9",
                        boxShadow: disabled && "none",
                        cursor: disabled && "not-allowed",
                        opacity: disabled && "1",
                        // margin: "7px",
                      }
                }>
                {item.community}
              </div>
            );
          })}
        </div>
      )}
      <div>{errorText && <h6 style={{ color: "red" }}>{errorText}</h6>}</div>
    </div>
  );
};

export default CardSelector;
