import React, { useState, useEffect } from "react";
import {
  Button,
  Skeleton,
  PullToRefresh,
  DotLoading,
  Dialog,
} from "antd-mobile";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import { formatPrice } from "../Common/formatPrice";

import Tile from "./Tile";
import AccountModal from "./AccountModal";
import RecentTxnDrawer from "./RecentTxnDrawer";
import EarningsDrawer from "./EarningsDrawer";
import WithdrawalDrawer from "./WithdrawalDrawer";
import ExploreCard from "../Common/ExploreCard";

import { useNavContext } from "../../context/NavContext";
import { useModalContext } from "../../context/ModalContext";

import { getLogo } from "../../Utils/getLogo";

import {
  getWalletInfo,
  getRecentTxns,
  getEarningsSum,
  getAccounts,
} from "../../handlers/wallet";

import styles from "./wallet.module.css";
import useUserStatusHook from "screens/Common/useUserStatusHook";
// import PullToRefresh from "rmc-pull-to-refresh";

const Wallet = () => {
  const { content, isQueryEnabled, userStatusCode, kycStatus } =
    useUserStatusHook("show");
  const queryClient = useQueryClient();

  const { setExploreCampaignModal } = useModalContext();

  const { queryKey: walletKey, queryFunction: walletFn } = getWalletInfo();
  const { queryKey: earningsKey, queryFunction: earningsFn } = getEarningsSum();
  const { queryKey: accountsKey, queryFunction: accountsFn } = getAccounts();
  const { queryKey: txnsKey, queryFunction: txnsFn } = getRecentTxns();

  const { data: walletData, isLoading } = useQuery(walletKey, walletFn, {
    enabled: isQueryEnabled,
  });
  const { data: earningsData, isLoading: isLoadingEarning } = useQuery(
    earningsKey,
    earningsFn,
    { enabled: isQueryEnabled }
  );
  const { data: accountsData, isLoading: isLoadingAccounts } = useQuery(
    accountsKey,
    accountsFn,
    { enabled: isQueryEnabled }
  );
  const { data: txnsData, isLoading: isTxnsDataLoading } = useInfiniteQuery(
    txnsKey,
    txnsFn,
    {
      enabled: isQueryEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );

  const { setOpenNav, setNavHeading, setNavButton } = useNavContext();
  const [isAccountModalOpen, setAccountModalOpen] = useState(false);
  const [isTxnDrawerOpen, setTxnDrawerOpen] = useState(false);
  const [isEarningDrawerOpen, setEarningDrawerOpen] = useState(false);
  const [withdrawalDrawerOpen, setWithdrawalDrawerOpen] = useState(false);

  useEffect(() => {
    setOpenNav(true);
    setNavHeading("Wallet");
    setNavButton({
      open: true,
      button: navButton({ setAccountModalOpen }),
    });

    return () => {
      setNavButton({
        open: false,
        button: null,
      });
    };
  }, []);

  return (
    <div style={{ pointerEvents: isAccountModalOpen ? "none" : "auto" }}>
      {isLoading ||
      isTxnsDataLoading ||
      isLoadingEarning ||
      isLoadingAccounts ||
      userStatusCode === "" ? (
        <div style={{ padding: "40px 20px 0" }}>
          <Skeleton className={styles.customSkeleton} />
          <Skeleton.Paragraph lineCount={9} />
        </div>
      ) : (
        <div>
          {walletData &&
          txnsData &&
          userStatusCode === "Done" &&
          kycStatus === "Verified" ? (
            <div>
              <PullToRefresh
                onRefresh={async () => {
                  await queryClient.invalidateQueries(walletKey);
                  await queryClient.invalidateQueries(earningsKey);
                  await queryClient.invalidateQueries(txnsKey);
                  await queryClient.invalidateQueries(accountsKey);
                }}
                refreshingText={
                  <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
                }
                canReleaseText={<AiOutlineArrowUp size={"20px"} />}
                pullingText={<AiOutlineArrowDown size={"20px"} />}
                completeText={<div />}>
                <div style={{ padding: "0 20px", maxWidth: "100vw" }}>
                  <section
                    style={{
                      textAlign: "center",
                      marginTop: "62px",
                      marginBottom: "43px",
                    }}>
                    <div className={styles.walletText}>Wallet Balance</div>
                    <div
                      style={{ marginBottom: "7px" }}
                      className={styles.walletPriceWrapper}>
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "24px",
                          lineHeight: "34px",
                        }}>
                        ₹
                      </span>
                      {formatPrice(walletData.data.balance)}
                    </div>
                    <Button
                      onClick={async () => {
                        if (
                          accountsData.data?.fundAccounts.length > 0 &&
                          accountsData?.data?.fundAccounts.some(
                            (item) =>
                              item.status === "Verified" && item.isActive
                          )
                        )
                          return setWithdrawalDrawerOpen(true);
                        else {
                          const result = await Dialog.confirm({
                            title: "Add account",
                            content: (
                              <div style={{ textAlign: "center" }}>
                                Add a bank account to withdraw your earnings to.
                              </div>
                            ),
                            confirmText: "Continue",
                            cancelText: "Cancel",
                          });
                          result && setAccountModalOpen(true);
                        }
                      }}>
                      Withdraw
                    </Button>
                  </section>
                  {earningsData.data.earnings && (
                    <section className={styles.earningBanner}>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}>
                        <img
                          src="/assets/icons/clock.svg"
                          style={{ width: "41px", height: "45px" }}
                          alt="clock"
                        />
                        <div
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#ffffff",
                          }}>
                          <div>Upcoming Earnings</div>
                          <div style={{ fontWeight: 700 }}>
                            ₹ {formatPrice(earningsData.data.earnings)}
                          </div>
                        </div>
                      </div>
                      <Button
                        fill="none"
                        style={{ padding: 0 }}
                        className={styles.manageEarning}
                        onClick={() => setEarningDrawerOpen(true)}>
                        See all earnings
                      </Button>
                    </section>
                  )}
                  <section>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "39px 0 20px 0",
                      }}>
                      <h2 className={styles.transactionHeading}>
                        Recent Transactions
                      </h2>
                      <Button
                        style={{ padding: 0 }}
                        fill="none"
                        className={styles.view}
                        onClick={() =>
                          txnsData?.pages[0]?.data?.recentTnx.length !== 0 &&
                          setTxnDrawerOpen(true)
                        }>
                        {" "}
                        View All
                      </Button>
                    </div>
                    <div>
                      {txnsData?.pages?.map((pageItem) => {
                        return pageItem?.data?.recentTnx
                          ?.slice(0, 5)
                          ?.map((item, idx) => {
                            return (
                              <Tile
                                key={idx}
                                description={format(
                                  new Date(item.createDateTime),
                                  "d MMMM,uuuu"
                                )}
                                heading={item.labelName}
                                logo={getLogo(item.label, item.labelRef)}
                                priceDesc={item?.isTdsCollected ? "-TDS" : ""}
                                priceString={`${
                                  item.isPositive ? "+" : "-"
                                }${formatPrice(item.amount)}`}
                                offer={item}
                              />
                            );
                          });
                      })}
                      {txnsData?.pages[0]?.data?.recentTnx.length === 0 && (
                        <ExploreCard
                          title={"No transactions"}
                          description={
                            "There haven’t been any transactions in your wallet. Participate in campaigns to start earning."
                          }
                          handleClick={() => setExploreCampaignModal(true)}
                        />
                      )}
                    </div>
                    <AccountModal
                      openModal={isAccountModalOpen}
                      setOpenModal={setAccountModalOpen}
                    />
                    <RecentTxnDrawer
                      isOpen={isTxnDrawerOpen}
                      handleClose={() => setTxnDrawerOpen(false)}
                    />
                    <EarningsDrawer
                      isOpen={isEarningDrawerOpen}
                      handleClose={() => setEarningDrawerOpen(false)}
                    />
                    <WithdrawalDrawer
                      isOpen={withdrawalDrawerOpen}
                      handleClose={() => setWithdrawalDrawerOpen(false)}
                    />
                  </section>
                </div>
              </PullToRefresh>
            </div>
          ) : (
            <div style={{ width: "100%", padding: "18px" }}>{content}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Wallet;

const navButton = ({ setAccountModalOpen }) => {
  return (
    <Button
      fill="none"
      style={{ padding: 0 }}
      className={styles.manageHeading}
      onClick={() => setAccountModalOpen(true)}>
      Manage accounts
    </Button>
  );
};
