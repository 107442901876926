import { useMutation } from "react-query";
import { sendRequest } from "../sendRequest";

export const selectIg = async ({ data, token }) => {
  const response = await sendRequest(
    "post",
    "/influencer/social-media-access/instagram",
    { ...data },
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response;
};

export default function useSelectIg() {
  return useMutation(selectIg);
}
