import React from "react";
import { Button } from "antd-mobile";

import styles from "./tile.module.css";

import ReactPlayer from "react-player";

import { BiChevronRight } from "react-icons/bi";

import { getStreamableUrl } from "../../../../Utils/getStreamableUrl";
import openExternalUrl from "../../../../Utils/openExternalUrl";

import clsx from "clsx";

const Tile = ({
  isDisabled = false,
  title = "",
  secondaryLabel,
  hasHeader = false,
  message = "",
  body,
  isActive = false,
  isBrief = false,
  references,
  onHeaderClick = () => {},
}) => {
  return (
    <div
      className={clsx(styles.container, {
        [styles.disabled]: isDisabled,
        [styles.active]: isActive,
      })}>
      <div
        className={clsx(styles.header, {
          [styles.activeHeader]: hasHeader,
        })}
        onClick={onHeaderClick}>
        <span className={styles.title}>{title}</span>
        <span
          style={{ display: "inline-flex", gap: "6px", alignItems: "center" }}>
          {secondaryLabel}
          <BiChevronRight size={"24px"} color={"rgba(0, 0, 0, 0.5)"} />
        </span>
      </div>
      <div style={{ padding: isBrief ? "0 20px 16px 20px" : "16px 20px" }}>
        {(message || (references || []).length >= 1) && (
          <div className={styles.message}>
            {message && <h3>Deliverable Require Changes</h3>}
            {message}
            {references &&
              references.map((item, idx) => {
                return (
                  <div key={idx} style={{ margin: "8px 0" }}>
                    {item.type === "video" ? (
                      <div className={styles.video}>
                        {item.platform === "youtube" ? (
                          <ReactPlayer
                            controls={true}
                            light={true}
                            url={item.url}
                            volume={1}
                            width="100%"
                            height="100%"
                          />
                        ) : (
                          <video
                            loop=""
                            muted={true}
                            src={`${getStreamableUrl(item.url)}#t=2`}
                            controls={true}
                            style={{
                              objectFit: "cover",
                            }}
                            width={"100%"}
                            height={"100%"}
                          />
                        )}
                      </div>
                    ) : item.type === "link" ? (
                      <Button
                        block
                        color="primary"
                        onClick={() => openExternalUrl(item.url)}>
                        Open Link
                      </Button>
                    ) : (
                      <div className={styles.image}>
                        <img
                          className={styles.draftImage}
                          src={getStreamableUrl(item.url)}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}

        {body && <>{body}</>}
      </div>
    </div>
  );
};

export default Tile;
