import { sendRequest } from "./sendRequest";

export const getCreatorData = (socialMediaKitId) => {
  const queryKey = ["creator", socialMediaKitId];

  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/social-media/kit-insights?socialMediaKitId=${socialMediaKitId}`
    ).then((res) => res.data);

  return [queryKey, queryFunction];
};
