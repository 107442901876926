import { sendRequest } from "../sendRequest";

export const addRule = ({ ig, mediaId }) => {
  return sendRequest(
    "post",
    `/influencer/smart-replies/workflow`,
    { ig, mediaId },
    undefined,
    false
  ).then((res) => res.data);
};
