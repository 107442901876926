import { sendRequest } from "../sendRequest";

export const getOldMessages = (conversationId) => {
  const queryKey = ["chats", conversationId];
  const queryFunction = ({ pageParam }) =>
    sendRequest(
      "get",
      `/influencer/chat/messages?conversationId=${conversationId}&limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
