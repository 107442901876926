import { Toast } from "antd-mobile";
import axios from "axios";
import { auth } from "../Utils/Firebase";
import { logBackend } from "./logBackend";

export const sendRequest = async (method, url, body, options, isV2 = false) => {
  let token = (await auth?.currentUser?.getIdToken()) || null;

  return axios({
    method,
    url: `${process.env.REACT_APP_BACKEND_URL}${isV2 ? "v2" : "v1"}${url}`,
    data: body,
    //withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
      ...options,
    },
  }).catch(async (err) => {
    if (process.env.REACT_APP_DEV_ERROR_SHOW) {
      var message = "";
      if (err?.response) {
        message = err?.response?.data?.message;
      } else if (err?.request) {
        message = "Request Error";
      } else {
        message = "No Defined";
      }
      await logBackend(url, body, JSON.stringify(err));
      Toast.show(message);
    }
    throw err;
  });
};
