import React from "react";
import { format } from "date-fns";
import { formatPrice } from "../../../Common/formatPrice";

import CustomDrawerWrapper from "../../../Common/CustomDrawerWrapper";

import styles from "./paymentSucces.module.css";

const PaymentSuccessDrawer = ({ isOpen, handleClose, paymentData }) => {
  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={() => handleClose()}
      title="">
      {isOpen && (
        <div className={styles.container}>
          <img
            src="/assets/icons/blueTickFilled.svg"
            alt="tick"
            style={{
              width: "90px",
              height: "90px",
              margin: "0 auto",
              marginBottom: "56px",
            }}
          />
          <span className={styles.amount}>
            ₹{formatPrice(paymentData.data.tnx.amount)}
          </span>

          <div style={{ marginTop: "2px" }}>
            <span className={styles.accountName}>
              To {paymentData.data.influencer.name || ""}{" "}
              {paymentData.data.influencer.lastname || ""}
            </span>
            <span className={styles.account}>
              {" "}
              {paymentData.data.fundAccountUsed.fundAccountType === "upi"
                ? paymentData.data.fundAccountUsed.vpaAddress
                : `${
                    paymentData.data.fundAccountUsed.bankAccountName +
                    " XX " +
                    paymentData.data.fundAccountUsed.bankAccountNumber.slice(-4)
                  }`}
            </span>
          </div>

          <div style={{ marginTop: "70px" }}>
            {/* <span className={styles.date}>30 September, 2022 at 23:09</span> */}
            <span className={styles.date}>
              {format(
                new Date(paymentData.data.tnx.createdOn),
                "d LLLL, yyyy H:mm"
              )}
            </span>
            <span className={styles.transactionId}>
              Transaction ID: {paymentData.data.tnx.id}
            </span>
          </div>
          <div className={styles.plainButton} onClick={() => handleClose()}>
            Got it
          </div>
        </div>
      )}
    </CustomDrawerWrapper>
  );
};

export default PaymentSuccessDrawer;
