import { useEffect } from "react";
import { useMutation } from "react-query";

import { InfiniteScroll, List } from "antd-mobile";
import { SpinLoading } from "antd-mobile";

import { useNavContext } from "context/NavContext";
import { useNotifContext } from "context/NotificationContext";
import NotificationItem from "./NotificationItem/NotificationItem";
import useUserStatusHook from "screens/Common/useUserStatusHook";
import { readNotifications } from "../../handlers/readNotifications";

const Notifications = () => {
  const { content, userStatusCode } = useUserStatusHook("show");
  const { notifications, loadMore, docSnapshot, setNewNotif } =
    useNotifContext();
  const { setOpenNav, setNavHeading } = useNavContext();
  const readNotificationsMutation = useMutation(readNotifications, {
    onError: (err) => {
      console.log(err.code, err.message, "error-message", err);
    },
  });

  useEffect(() => {
    setOpenNav(true);
    setNavHeading("Notifications");

    // To call backend API to read all notifications

    return () => {
      readNotificationsMutation.mutate();

      setNewNotif(false);
    };
  }, []);

  // Case to check if profile is not yet approved
  if (!userStatusCode === "Done" || !userStatusCode === "Platform-Disconnected")
    return <main style={{ width: "100%", padding: "18px" }}>{content}</main>;

  if (!notifications)
    return <SpinLoading color="default" style={{ margin: "0 auto" }} />;

  return (
    <div>
      <List
        style={{
          "--border-top": "none",
        }}>
        {notifications.map((item) => (
          <NotificationItem data={item} key={item.id} />
        ))}
      </List>

      {/* Used as a in viewport to load more data */}
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={docSnapshot[docSnapshot.length - 1] !== undefined}
        loader={<SpinLoading color="default" style={{ margin: "0 auto" }} />}
        threshold={250}
      />
    </div>
  );
};

export default Notifications;
