import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";

import { Steps, SpinLoading, Button } from "antd-mobile";

import CustomModalWrapper from "../../Common/CustomModalWrapper";

import PaymentDetailModal from "../PaymentDetailModal";

import { getEarningsTnxDetail } from "../../../handlers/wallet";

const EarningTimeline = ({
  openModal,
  setOpenModal,
  internalIdempotentKey,
}) => {
  const { Step } = Steps;
  const { queryKey, queryFunction } = getEarningsTnxDetail({
    internalIdempotentKey,
  });

  const { data: timelineData, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: openModal,
  });
  const [timeLine, setTimeline] = useState({
    acquired: new Date().toUTCString(),
    released: new Date().toUTCString(),
    approved: new Date().toUTCString(),
    cancelled: new Date().toUTCString(),
  });

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  useEffect(() => {
    if (timelineData) {
      let tempTimelineObject = {
        acquired: new Date().toUTCString(),
        released: new Date().toUTCString(),
        approved: new Date().toUTCString(),
        cancelled: new Date().toUTCString(),
      };

      timelineData.data.escrowStatusTimeline.forEach((item) => {
        switch (item.type) {
          case "Acquired":
            tempTimelineObject = {
              ...tempTimelineObject,
              acquired: new Date(item.createDateTime).toUTCString(),
            };
            break;
          case "Released":
            tempTimelineObject = {
              ...tempTimelineObject,
              released: new Date(item.createDateTime).toUTCString(),
            };
            break;
          case "Approved":
            tempTimelineObject = {
              ...tempTimelineObject,
              approved: new Date(item.createDateTime).toUTCString(),
            };
            break;
          case "Cancel":
            tempTimelineObject = {
              ...tempTimelineObject,
              cancelled: new Date(item.createDateTime).toUTCString(),
            };
            break;
          default:
            break;
        }
      });
      setTimeline(tempTimelineObject);
    }
  }, [timelineData]);

  const greyTileStyles = {
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "17px",
    color: "#999999",
  };

  return (
    <CustomModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      heading={"Timeline"}
      modalHeight={"60%"}>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}>
          <SpinLoading color="primary" />
        </div>
      ) : (
        timelineData && (
          <div style={{ width: "100%" }}>
            {timelineData?.data.escrowChequeStatus === "Acquired" ? (
              <Steps direction="vertical">
                <Step
                  title="Campaign finalised, payment acquired."
                  status="finish"
                  description={format(
                    new Date(timeLine["acquired"]),
                    " d LLLL, yyyy H:mm"
                  )}
                />
                <Step
                  title={
                    <div style={greyTileStyles}>
                      payment will be approved once all your deliverables are
                      approved
                    </div>
                  }
                  status=" wait "
                />
                <Step
                  title={
                    <div style={greyTileStyles}>
                      payment will be credited within 24 hours after approval
                    </div>
                  }
                  status=" wait "
                />
              </Steps>
            ) : timelineData?.data.escrowChequeStatus === "Approved" ? (
              <Steps direction="vertical">
                <Step
                  title="Campaign finalised, payment acquired."
                  status="finish"
                  description={format(
                    new Date(timeLine["acquired"]),
                    " d LLLL, yyyy H:mm"
                  )}
                />
                <Step
                  title="Campaign complete, payment approved."
                  status="finish"
                  description={format(
                    new Date(timeLine["approved"]),
                    " d LLLL, yyyy H:mm"
                  )}
                />
                <Step
                  title={
                    <div style={greyTileStyles}>
                      payment will be credited on{" "}
                      {format(
                        new Date(timelineData.data.escrowChequeReleaseDate),
                        "d LLLL, yyyy H:mm"
                      )}
                    </div>
                  }
                  status=" wait "
                />
              </Steps>
            ) : timelineData?.data.escrowChequeStatus === "Cancel" ? (
              <Steps direction="vertical">
                <Step
                  title="Campaign finalised, payment acquired."
                  status="finish"
                  description={format(
                    new Date(timeLine["acquired"]),
                    " d LLLL, yyyy H:mm"
                  )}
                />

                <Step
                  title="Cancelled, as per your conversation with the admin"
                  status="error"
                  description={format(
                    new Date(timeLine["cancelled"]),
                    " d LLLL, yyyy H:mm"
                  )}
                />
              </Steps>
            ) : (
              <div>
                <Steps direction="vertical">
                  <Step
                    title="Campaign finalised, payment acquired."
                    status="finish"
                    description={format(
                      new Date(timeLine["acquired"]),
                      " d LLLL, yyyy H:mm"
                    )}
                  />
                  <Step
                    title="Campaign complete, payment approved."
                    description={format(
                      new Date(timeLine["approved"]),
                      " d LLLL, yyyy H:mm"
                    )}
                    status="finish"
                  />
                  <Step
                    title="Payment Credited."
                    description={format(
                      new Date(timeLine["released"]),
                      "d LLLL, yyyy H:mm"
                    )}
                    status="finish"
                  />
                </Steps>
                <Button
                  fill="none"
                  style={{
                    fontSize: "13px",
                    lineHeight: "18px",
                    padding: 0,
                    textDecorationLine: "underline",
                    color: " #2130FF",
                  }}
                  onClick={() => setPaymentModalOpen(true)}>
                  View transaction in wallet
                </Button>
              </div>
            )}
            {timelineData?.data?.tnxDetails && (
              <PaymentDetailModal
                offerDetail={timelineData.data?.tnxDetails}
                openModal={paymentModalOpen}
                handleClose={() => setPaymentModalOpen(false)}
              />
            )}
          </div>
        )
      )}
    </CustomModalWrapper>
  );
};

export default EarningTimeline;
