import { Input } from "antd";

const { TextArea } = Input;

const CustomInput = ({
  label,
  label2,
  errorText,
  style,
  containerStyle,
  multiline,
  ...otherProps
}) => {
  return (
    <div
      style={{
        maxWidth: "350px",
        width: "100%",
        marginTop: "10px",
        ...containerStyle,
      }}>
      <div style={{ fontSize: "14px", fontWeight: "500", marginBottom: "4px" }}>
        {label}
      </div>

      {otherProps?.children ? (
        otherProps?.children
      ) : (
        <>
          {multiline ? (
            <TextArea
              status={errorText ? "error" : "success"}
              {...otherProps}
              style={{
                ...style,
                paddingTop: style?.paddingTop ? style?.paddingTop : "11px",
                paddingBottom: style?.paddingBottom
                  ? style?.paddingBottom
                  : "11px",
                border: style?.border ? style?.border : "1.5px solid #CCCCCC",
                borderRadius: style?.borderRadius ? style?.borderRadius : "8px",
              }}
            />
          ) : (
            <Input
              status={errorText ? "error" : "success"}
              {...otherProps}
              style={{
                ...style,
                paddingTop: style?.paddingTop ? style?.paddingTop : "11px",
                paddingBottom: style?.paddingBottom
                  ? style?.paddingBottom
                  : "11px",
                border: style?.border ? style?.border : "1.5px solid #CCCCCC",
                borderRadius: style?.borderRadius ? style?.borderRadius : "8px",
              }}
            />
          )}
        </>
      )}
      <div style={{ fontSize: "12px", color: "#999999", marginTop: "4px" }}>
        {label2}
      </div>
      {errorText && <h6 style={{ color: "red" }}>{errorText}</h6>}
    </div>
  );
};

export default CustomInput;
