import { Popup, Button } from "antd-mobile";
import { Modal } from "antd";

import { CloseOutline } from "antd-mobile-icons";
import useWindowWidth from "../../hooks/useWindowWidth";

const CustomModalWrapper = ({
  openModal,
  setOpenModal,
  heading,
  children,
  modalHeight,
  handleInvalidate,
  modalWidth,
}) => {
  const isDesktop = useWindowWidth();

  if (isDesktop) {
    return (
      <Modal
        width={modalWidth || undefined}
        // centre÷d
        destroyOnClose
        open={openModal}
        closable={false}
        // width={1100}
        title={heading}
        onCancel={() => {
          if (handleInvalidate) {
            handleInvalidate();
          }
          setOpenModal(false);
        }}
        footer={null}>
        <div
          style={{
            overflowY: "auto",
            minHeight: "73vh",
            // paddingTop: "52px",
          }}>
          {children}
        </div>
      </Modal>
    );
  }

  return (
    <Popup
      visible={openModal}
      destroyOnClose
      bodyStyle={{
        height: modalHeight ? modalHeight : "90%",
        borderRadius: "8px 8px 0px 0px",
        // "--z-index": "100",
      }}
      // style={{
      //   "--z-index": "1300",
      // }}
      onClose={() => {
        if (handleInvalidate) {
          handleInvalidate();
        }
        setOpenModal(false);
      }}
      onMaskClick={() => {
        if (handleInvalidate) {
          handleInvalidate();
        }
        setOpenModal(false);
      }}>
      <div
        style={{
          height: "60px",
          top: 0,
          borderBottom: "1px solid rgb(0,0,0,12%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          marginTop: "5px",
          touchAction: "none",
        }}>
        <Button
          onClick={() => {
            if (handleInvalidate) {
              handleInvalidate();
            }
            setOpenModal(false);
          }}
          style={{ padding: 0, width: "fit-content" }}
          fill="none">
          <CloseOutline
            fontSize="16px"
            fontWeight="600"
            style={{ fontWeight: "800" }}
          />
        </Button>
        <div style={{ width: "100%", textAlign: "center", fontWeight: "600" }}>
          {heading}
        </div>
        <div style={{ width: "20%" }} />
      </div>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "calc(100% - 60px)",
          overflow: "auto",
        }}>
        {children}
      </div>
      <div style={{ margin: "50px" }} />
    </Popup>
  );
};

export default CustomModalWrapper;
