import { sendRequest } from "../sendRequest";

export const getSocialMediaInfo = ({
  platform,
  typeDel = "",
  socialMediaKitId = "",
}) => {
  const queryKey = ["chat", platform, typeDel];

  const queryFunction = ({ pageParam }) =>
    sendRequest(
      "get",
      `/influencer/social-media/${platform}/${
        platform === "instagram"
          ? typeDel === "Story"
            ? "stories"
            : "feed"
          : "videos"
      }?socialMediaKitId=${socialMediaKitId}&limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
