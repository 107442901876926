import { sendRequest } from "../sendRequest";

export const getUserStatus = (enabled = true) => {
  const queryKey = ["users", "status"];
  const queryFunction = () =>
    enabled
      ? sendRequest("get", `/influencer/me/app-state`)
          .then((res) => res.data)
          .catch((err) => console.log(err))
      : {};
  return { queryKey, queryFunction };
};
