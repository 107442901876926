import React, { useState } from "react";

import RequirementDrawer from "../RequirementDrawer";
import { useQueryClient, useMutation } from "react-query";

import DraftsTimeline from "./DraftsTimeline";
import LiveTimeline from "./LiveTimeline";

import { BsCheckCircleFill } from "react-icons/bs";
import { Button, Steps, Toast } from "antd-mobile";
import Tile from "../Tile";
import Chip from "../Chip";

import YoutubeDraft from "components/Common/YoutubeDraft";
import LiveUploadModal from "components/Common/LiveUploadModal";
import SendMedia from "../sendMedia";

import { uploadDraftsV2 } from "../../../../handlers/chat/v2/uploadDraftsV2";

import styles from "./style.module.css";

import { TbCalendarTime } from "react-icons/tb";

const { Step } = Steps;

const DeliverableTimeline = ({
  handleClose,
  selectedWorkflow,
  workflowData,
  finalizedOfferId,
  queryKey,
  socialMediaId,
}) => {
  const [requirementDrawerOpen, setRequirementDrawerOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [draftTimelineOpen, setDraftTimelineOpen] = useState(false);
  const [liveTimelineOpen, setLiveTimelineOpen] = useState(false);

  const queryClient = useQueryClient();

  const sendDraftMutation = useMutation(uploadDraftsV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      Toast.show("Draft Uploaded");
    },
    onError: (err) => {
      console.log(err.code, err.message, "error-message", err);
    },
  });

  const handleMessageSend = (url, mediaType) => {
    sendDraftMutation.mutate({
      workflowId: selectedWorkflow?.workflowId,
      references: [
        {
          url,
          type: mediaType,
          platform: selectedWorkflow?.deliverable?.platform,
        },
      ],
    });
  };

  const getIconType = (tileName) => {
    if (!selectedWorkflow) return "";
    if (tileName === "Brief")
      return selectedWorkflow?.isRequirementsNotSent ? "wait" : "finish";

    if (tileName === "Draft")
      return (!selectedWorkflow.isDraftsToSent ||
        selectedWorkflow?.isDraftsClosed) &&
        !selectedWorkflow?.isRequirementsNotSent
        ? "finish"
        : selectedWorkflow?.isDraftsStarted
        ? "process"
        : "wait";

    return selectedWorkflow?.isLiveClosed ? "finish" : "wait";
  };

  const getChipAttributes = (type) => {
    if (type === "Drafts" && selectedWorkflow?.draftTimeline[0]) {
      if (selectedWorkflow?.isDraftsClosed) {
        return {
          color: "green",
          title: "Approved",
        };
      } else if (
        (selectedWorkflow?.draftTimeline || [{ name: "" }])
          .at(-1)
          .name.startsWith("Send")
      ) {
        return {
          color: "orange",
          title: "Submit Draft",
        };
      } else if (
        (selectedWorkflow?.draftTimeline || [{ name: "" }])
          .at(-1)
          .name.startsWith("Waiting")
      ) {
        return {
          color: "blue",
          title: "Waiting for Approval",
        };
      }
    } else if (type === "Live" && selectedWorkflow?.liveTimeline[0]) {
      if (selectedWorkflow?.isLiveClosed) {
        return {
          color: "green",
          title: "Approved",
        };
      } else if (
        (selectedWorkflow?.liveTimeline || [{ name: "" }])
          .at(-1)
          .name.startsWith("Go")
      ) {
        return {
          color: "orange",
          title: "Submit Live Link",
        };
      } else if (
        (selectedWorkflow?.liveTimeline || [{ name: "" }])
          .at(-1)
          .name.startsWith("Waiting")
      ) {
        return {
          color: "blue",
          title: "Waiting for Approval",
        };
      }
    }
    return {
      color: "transparent",
      title: "",
    };
  };

  if (!selectedWorkflow) {
    return null;
  }

  return (
    <div>
      <Steps direction="vertical" style={{ padding: "0", paddingTop: "8px" }}>
        <Step
          style={{ "--icon-size": "16px", "--indicator-margin-right": "4px" }}
          icon={
            <BsCheckCircleFill
              style={{
                background: "white",
                position: "absolute",
                left: "-8px",
              }}
            />
          }
          title={
            <Tile
              title="Brief"
              hasHeader
              secondaryLabel={
                selectedWorkflow?.isRequirementsNotSent ? (
                  <Chip color="blue" title="Waiting" />
                ) : (
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "14px",
                      textAlign: "right",
                      color: "rgba(0, 0, 0, 0.5)",
                    }}>
                    Received
                  </p>
                )
              }
              onHeaderClick={() => {
                !selectedWorkflow?.isRequirementsNotSent &&
                  setRequirementDrawerOpen(true);
              }}
              body={
                selectedWorkflow?.isRequirementsNotSent ? (
                  <div className={styles.message}>
                    We’re waiting for the brand to send a detailed brief
                  </div>
                ) : (
                  <Button
                    fill="none"
                    color="primary"
                    style={{ textDecoration: "underline", padding: 0 }}
                    onClick={() => setRequirementDrawerOpen(true)}>
                    Read brief -&gt;
                  </Button>
                )
              }
              // isBrief
            />
          }
          status={getIconType("Brief")}
        />
        <Step
          style={{ "--icon-size": "16px", "--indicator-margin-right": "4px" }}
          icon={
            <BsCheckCircleFill
              // color="#00B578"
              style={{
                background: "white",
                position: "absolute",
                left: "-8px",
              }}
            />
          }
          title={
            <Tile
              onHeaderClick={() => {
                !selectedWorkflow?.isRequirementsNotSent &&
                  selectedWorkflow?.isDraftsToSent &&
                  setDraftTimelineOpen(true);
              }}
              title="Draft"
              references={selectedWorkflow?.latestDraftReferences}
              secondaryLabel={
                selectedWorkflow?.isRequirementsNotSent ||
                !selectedWorkflow?.isDraftsToSent ? (
                  <div />
                ) : (
                  <Chip
                    color={getChipAttributes("Drafts").color}
                    title={getChipAttributes("Drafts").title}
                  />
                )
              }
              isActive={
                selectedWorkflow?.isDraftsToSent &&
                !selectedWorkflow?.isDraftsClosed
              }
              message={
                selectedWorkflow?.draftTimeline.length > 1 &&
                selectedWorkflow?.draftTimeline
                  .at(-2)
                  .name.startsWith("Drafts Require")
                  ? selectedWorkflow?.draftTimeline.at(-2).message
                  : null
              }
              hasHeader
              body={
                <>
                  {selectedWorkflow?.isRequirementsNotSent ? (
                    <p className={styles.message}>
                      You would be able to upload a draft after you recieve a
                      brief
                    </p>
                  ) : selectedWorkflow?.isDraftsToSent ? (
                    <>
                      {selectedWorkflow?.draftTimeline.at(-1) !== null &&
                        selectedWorkflow?.draftTimeline
                          .at(-1)
                          .name.startsWith("Send") && (
                          <>
                            <Button
                              type="button"
                              color="primary"
                              block
                              style={{ borderRadius: "6px" }}
                              onClick={() => {
                                if (
                                  selectedWorkflow?.deliverable?.platform ===
                                  "instagram"
                                )
                                  return setOpenMediaModal(true);
                                return setOpenModal(true);
                              }}>
                              Upload Draft
                            </Button>
                            <h4
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "8px",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "17px",
                              }}>
                              {selectedWorkflow?.workflowClosureDate && (
                                <>
                                  <TbCalendarTime
                                    size={"16px"}
                                    style={{ marginRight: "2px" }}
                                  />
                                  Deadline:{" "}
                                  {selectedWorkflow?.workflowClosureDate}
                                </>
                              )}
                            </h4>
                          </>
                        )}
                      {selectedWorkflow?.draftTimeline.at(-1) !== null &&
                        selectedWorkflow?.draftTimeline
                          .at(-1)
                          .name.startsWith("Waiting") && (
                          <p className={styles.message}>
                            You have uploaded the draft, waiting for the brand
                            to approve it.
                          </p>
                        )}
                      {selectedWorkflow?.draftTimeline.at(-1) !== null &&
                        selectedWorkflow?.draftTimeline
                          .at(-1)
                          .name.startsWith("Drafts Approved") && (
                          <p className={styles.message}>
                            The draft has been approved, now you can go live.
                          </p>
                        )}
                    </>
                  ) : (
                    <p className={styles.message}>
                      Draft not required for this deliverable
                    </p>
                  )}
                </>
              }
            />
          }
          status={getIconType("Draft")}
        />
        <Step
          style={{ "--icon-size": "16px", "--indicator-margin-right": "4px" }}
          icon={
            <BsCheckCircleFill
              // color="#00B578"
              style={{
                background: "white",
                position: "absolute",
                left: "-8px",
              }}
            />
          }
          title={
            <Tile
              references={selectedWorkflow?.latestLiveReferences}
              title="Live"
              secondaryLabel={
                selectedWorkflow?.isRequirementsNotSent ? (
                  <div />
                ) : (
                  <Chip
                    color={getChipAttributes("Live").color}
                    title={getChipAttributes("Live").title}
                  />
                )
              }
              onHeaderClick={() => {
                !selectedWorkflow?.isRequirementsNotSent &&
                  selectedWorkflow?.isLiveToSent &&
                  setLiveTimelineOpen(true);
              }}
              isActive={
                selectedWorkflow?.isLiveStarted &&
                !selectedWorkflow?.isLiveClosed
              }
              message={
                selectedWorkflow?.liveTimeline.length > 1 &&
                selectedWorkflow?.liveTimeline
                  .at(-2)
                  ?.name.startsWith("Live Require")
                  ? selectedWorkflow?.liveTimeline?.at(-2)?.message
                  : null
              }
              hasHeader
              body={
                <>
                  {selectedWorkflow?.isRequirementsNotSent ||
                  !selectedWorkflow?.isDraftsClosed ? (
                    <p className={styles.message}>
                      You can go live after you draft has been approved
                    </p>
                  ) : (
                    selectedWorkflow?.liveTimeline.at(-1) !== null && (
                      <>
                        {selectedWorkflow?.liveTimeline
                          .at(-1)
                          ?.name.startsWith("Go") && (
                          <>
                            <Button
                              type="button"
                              color="primary"
                              block
                              style={{ borderRadius: "6px" }}
                              onClick={() => setOpenLiveModal(true)}>
                              Upload Live Link
                            </Button>
                            <h4
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "8px",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "17px",
                              }}>
                              {selectedWorkflow?.workflowClosureDate && (
                                <>
                                  <TbCalendarTime
                                    size={"16px"}
                                    style={{ marginRight: "2px" }}
                                  />
                                  Deadline:{" "}
                                  {selectedWorkflow?.workflowClosureDate}
                                </>
                              )}
                            </h4>
                          </>
                        )}
                        {selectedWorkflow?.liveTimeline.at(-1) !== null &&
                          selectedWorkflow?.liveTimeline
                            .at(-1)
                            .name.startsWith("Waiting") && (
                            <p className={styles.message}>
                              You have uploaded the Live, waiting for the brand
                              to approve it.
                            </p>
                          )}
                        {selectedWorkflow?.liveTimeline.at(-1) !== null &&
                          selectedWorkflow?.liveTimeline
                            .at(-1)
                            .name.startsWith("Live Approved") && (
                            <p className={styles.message}>
                              Hurray your deliverables has been approved, you
                              have completed the deliverable. You can check the
                              status of you payment in
                            </p>
                          )}
                      </>
                    )
                  )}
                </>
              }
            />
          }
          status={getIconType("Live")}
        />
      </Steps>
      <RequirementDrawer
        isOpen={requirementDrawerOpen}
        handleClose={() => setRequirementDrawerOpen(false)}
        selectedWorkflow={selectedWorkflow}
        workflowData={workflowData}
      />
      <YoutubeDraft
        finalizedOfferId={finalizedOfferId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        workflowId={selectedWorkflow.workflowId}
        platform={selectedWorkflow.deliverable.platform}
      />
      <LiveUploadModal
        finalizedOfferId={finalizedOfferId}
        openLiveModal={openLiveModal}
        setOpenLiveModal={setOpenLiveModal}
        isEdit={false}
        platform={selectedWorkflow.deliverable.platform}
        typeDel={selectedWorkflow.deliverable.deliverablesDetails.typeDel}
        workflowId={selectedWorkflow.workflowId}
        socialMediaId={workflowData?.data?.socialMediaKitId}
      />
      <SendMedia
        openModal={openMediaModal}
        setOpenModal={setOpenMediaModal}
        handleMessageSend={handleMessageSend}
      />
      <DraftsTimeline
        isOpen={draftTimelineOpen}
        handleClose={() => setDraftTimelineOpen(false)}
        selectedWorkflow={selectedWorkflow}
      />
      <LiveTimeline
        isOpen={liveTimelineOpen}
        handleClose={() => setLiveTimelineOpen(false)}
        selectedWorkflow={selectedWorkflow}
      />
    </div>
  );
};

export default DeliverableTimeline;
