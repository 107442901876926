import React, { useEffect, useState } from "react";
import { PullToRefresh, Button } from "antd-mobile";
import { AiFillDownCircle } from "react-icons/ai";
import { BsArrowUpCircleFill } from "react-icons/bs";
import { Skeleton } from "antd";

import Message from "./Message";

import styles from "./chatWindow.module.css";

import { useChatContext } from "context/ChatContext";
import useWindowWidth from "../../hooks/useWindowWidth";

const ChatBody = () => {
  const isDesktop = useWindowWidth();
  const {
    brandId,
    conversationId,
    messages,
    isLoading,
    fetchNextPage,
    isFirst,
    newMessageLoading,
    setNewMessageLoading,
    hasNextPage,
    isKeyboardOpen,
  } = useChatContext();

  const lastMessageRef = React.useRef();
  const bodyRef = React.useRef();
  const firstMessageRef = React.useRef();
  const fRef = React.useRef();
  const [lastMessage, setLastMessage] = useState(null);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (lastMessage && newMessageLoading) {
      lastMessage.scrollIntoView({
        block: "start",
        behavior: "auto",
      });
    }
  });

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.addEventListener("scroll", (e) => {
        if (
          e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop >
          500
        ) {
          setEnable(true);
        } else {
          setEnable(false);
        }
      });
    }

    return () => {
      if (bodyRef.current) {
        bodyRef.current.removeEventListener("scroll", (e) => {
          if (
            e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop >
            500
          ) {
            setEnable(true);
          } else {
            setEnable(false);
          }
        });
      }
    };
  }, []);

  //use to scroll to last message when new message is added
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const start = async () => {
      await sleep(300);
      if (newMessageLoading) {
        setNewMessageLoading(false);
      }
      if (lastMessageRef.current && !newMessageLoading) {
        lastMessageRef.current.scrollIntoView({
          behavior: "smooth",
          top: 0,
        });
      }
    };
    start();
  }, [messages, isKeyboardOpen]);

  if (isLoading)
    return Array.apply(null, Array(10)).map((i) => (
      <div
        className={isDesktop ? styles.chatsDesktop : styles.chats}
        id="scrollableDiv">
        <Skeleton avatar paragraph={{ rows: 1 }} style={{ padding: "2em" }} />
      </div>
    ));

  if (isFirst)
    return (
      <div
        className={isDesktop ? styles.chatsDesktop : styles.chats}
        id="scrollableDiv">
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <h5>Say "Hello" 👋 to start the conversation</h5>
        </div>
      </div>
    );
  return (
    <div
      className={isDesktop ? styles.chatsDesktop : styles.chats}
      id="scrollableDiv"
      ref={bodyRef}>
      <PullToRefresh
        threshold={10}
        pullingText="Pull to Load More"
        refreshingText="Loading..."
        completeText="Loaded"
        canReleaseText="Release to Load More"
        disabled={!hasNextPage}
        onRefresh={async () => {
          setLastMessage(
            document.getElementById(messages[messages.length - 1]._id)
          );
          await fetchNextPage();
          setNewMessageLoading(true);
          // return true;
        }}>
        <div
          id="innerDiv"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            width: "100%",
            padding: "1em",
          }}>
          <div ref={lastMessageRef} />

          {messages.length > 0 &&
            messages.map((item, i) => (
              <Message
                currentRef={fRef}
                key={item._id}
                id={item._id}
                conversationId={conversationId}
                messageItem={item}
                brandId={brandId}
                previousMessageAuthor={
                  i !== messages.length - 1 ? messages[i + 1].author : ""
                }
                previousMessageDate={
                  i !== messages.length - 1
                    ? new Date(messages[i + 1].createDateTime)
                    : new Date(1970, 1, 1)
                }
              />
            ))}
          <div
            ref={firstMessageRef}
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {hasNextPage && (
              <>
                {isDesktop ? (
                  <Button
                    size="small"
                    onClick={async () => {
                      setLastMessage(
                        document.getElementById(
                          messages[messages.length - 1]._id
                        )
                      );
                      setNewMessageLoading(true);
                      await fetchNextPage();
                    }}>
                    {newMessageLoading ? "Loading..." : "Load More Messages"}
                  </Button>
                ) : (
                  <BsArrowUpCircleFill />
                )}
              </>
            )}
          </div>
        </div>
      </PullToRefresh>
      {enable && (
        <div
          style={{
            position: "fixed",
            bottom: 100,
            right: 20,
          }}
          onClick={() => {
            lastMessageRef.current.scrollIntoView({
              behavior: "smooth",
              top: 0,
            });
          }}>
          <AiFillDownCircle color="white" size={30} />
        </div>
      )}
    </div>
  );
};

export default ChatBody;
