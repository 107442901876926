import React from "react";
import { Row } from "antd";
import { useParams } from "react-router-dom";
import { CgShare } from "react-icons/cg";

import CampaignDetail from "../components/Dashboard/CampaignDetail";

import copyTextToClipboard from "../Utils/copyToClipboard";

const CampaignDetailScreen = () => {
  const { campaignId, dealId } = useParams();

  return (
    <div>
      <Row justify="space-between" style={{ marginBlock: 40 }}>
        <h2>Campaign Detail</h2>
        <CgShare
          style={{ fontSize: "20px" }}
          onClick={() => {
            try {
              if (process.env.REACT_APP_DEV_ERROR_SHOW === "true") {
                return copyTextToClipboard(
                  `Dev ki main site nhi hai , islie redirect nhi kia #pro `,
                  "Link copied to clipboard"
                );
              }
              return copyTextToClipboard(
                `https://cloutflow.com/creators/ongoing-campaigns/${campaignId}/${dealId}`,
                "Link copied to clipboard"
              );
            } catch (error) {
              console.log("from here", error);
            }
          }}
        />
      </Row>
      <CampaignDetail
        campaignId={campaignId}
        dealId={dealId}
        unregistered={false}
      />
    </div>
  );
};

export default CampaignDetailScreen;
