import { sendRequest } from "../../sendRequest";

export const uploadDraftsV2 = ({ workflowId, references }) => {
  // console.log("hemlo", workflowId, references);
  return sendRequest(
    "put",
    `/influencer/execution/drafts/upload`,
    {
      workflowId,
      references,
    },
    null,
    true
  ).then((res) => {
    return res.data;
  });
  // return { queryKey, queryFunction };
};
