import React from "react";
import styles from "./chatWindow.module.css";
import { useEffect } from "react";
import { useChatContext } from "context/ChatContext";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import ChatBody from "./ChatBody";

const ChatWindow = ({ brandId, conversationId }) => {
  //Set values for current ref
  const { setBrandId, setConversationId } = useChatContext();

  useEffect(() => {
    setBrandId(brandId);
    setConversationId(conversationId);
  }, []);

  return (
    <main className={styles.container}>
      <ChatHeader />
      <ChatBody />
      <ChatInput />
    </main>
  );
};

export default ChatWindow;
