import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Toast, Button, Radio } from "antd-mobile";
import { Controller, useForm } from "react-hook-form";

import CustomInput from "../../Common/CustomInput";
import CustomModalWrapper from "../../Common/CustomModalWrapper";

import { getAccounts, addBankAccount, addUPI } from "../../../handlers/wallet";

import useWindowWidth from "../../../hooks/useWindowWidth";

const AddAccountModal = ({ openModal, setOpenModal }) => {
  const { queryKey } = getAccounts();
  const queryClient = useQueryClient();

  const isDesktop = useWindowWidth();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bank: {
        bankAccountName: null,
        bankIfsc: null,
        bankAccountNumber: null,
      },
      upi: {
        vpa: null,
      },
      type: null,
    },
  });

  const addUPIMutation = useMutation(addUPI, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      Toast.show({
        icon: "success",
        content: "UPI verification in progress",
        position: "middle",
      });
      setOpenModal(false);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
        position: "middle",
      });
    },
  });
  const addBankAccountMutation = useMutation(addBankAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      Toast.show({
        icon: "success",
        content: "Band Account verification in progress",
        position: "middle",
      });
      setOpenModal(false);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
        position: "middle",
      });
    },
  });

  const watchType = watch("type", null);

  const onSubmitHandler = (data) => {
    // console.log("data", data);
    if (watchType === "upi") {
      return addUPIMutation.mutate(data.upi.vpa);
    }

    if (watchType === "bank") {
      return addBankAccountMutation.mutate({
        bankAccountName: data.bank.bankAccountName,
        bankAccountNumber: data.bank.bankAccountNumber,
        bankIfsc: data.bank.bankIfsc,
      });
    }
  };

  return (
    <CustomModalWrapper
      heading={"Add Account"}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalHeight="70%">
      <div style={{ width: "100%" }}>
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, value } }) => (
            <>
              <Radio.Group value={value} onChange={onChange}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginBottom: "28px",
                  }}>
                  <Radio value={"bank"}> Bank </Radio>
                  <Radio value={"upi"}> UPI </Radio>
                </div>
              </Radio.Group>

              {watchType !== null && (
                <>
                  {watchType === "upi" ? (
                    <div>
                      <Controller
                        control={control}
                        name="upi.vpa"
                        rules={{
                          required: {
                            value: true,
                            message: "UPI is required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomInput
                            label="Enter UPI ID"
                            placeholder="Enter UPI ID"
                            value={value}
                            onChange={onChange}
                            errorText={errors?.upi?.vpa?.message}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "grid", gap: "16px" }}>
                      <Controller
                        control={control}
                        name="bank.bankAccountName"
                        rules={{
                          required: {
                            value: true,
                            message: "Account Name  is required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomInput
                            label="Enter account name"
                            placeholder="Enter your account name"
                            value={value}
                            onChange={onChange}
                            errorText={errors?.bank?.bankAccountName?.message}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="bank.bankAccountNumber"
                        rules={{
                          required: {
                            value: true,
                            message: "Account No.  is required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomInput
                            label="Enter account no."
                            placeholder="Enter your account number"
                            value={value}
                            onChange={onChange}
                            errorText={errors?.bank?.bankAccountNumber?.message}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="bank.bankIfsc"
                        rules={{
                          required: {
                            value: true,
                            message: "IFSC is required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomInput
                            label="Enter IFSC code"
                            placeholder="Enter IFSC code"
                            value={value}
                            onChange={onChange}
                            errorText={errors?.bank?.bankIfsc?.message}
                          />
                        )}
                      />
                    </div>
                  )}
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "14px",
                      color: "#717171",
                      marginTop: "8px",
                    }}>
                    <img
                      src="/assets/icons/checkShield.svg"
                      alt="tick"
                      style={{
                        width: "19px",
                        height: "21px",
                        display: "inline-block",
                        marginRight: "6px",
                      }}
                    />
                    {`Your ${
                      watchType === "upi" ? "UPI Id" : "account Details"
                    } will be encrypted and 100% safe with us.`}
                  </p>
                </>
              )}
            </>
          )}
        />
        <div style={{ marginBottom: "70px" }} />
        <div
          style={{
            position: isDesktop ? "absolute" : "fixed",
            bottom: "0",
            padding: "12.5px 32px",
            background: "#FFFFFF",
            borderTop: " 1px solid rgba(0, 0, 0, 0.12)",
            width: "100%",
            left: 0,
          }}>
          <Button
            color="primary"
            block
            onClick={handleSubmit(onSubmitHandler)}
            disabled={watchType === null}
            loading={
              addBankAccountMutation.isLoading || addUPIMutation.isLoading
            }>
            Verify
          </Button>
        </div>
      </div>
    </CustomModalWrapper>
  );
};

export default AddAccountModal;
