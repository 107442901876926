import { Button, Toast } from "antd-mobile";
import { useState, useEffect, useRef } from "react";
import CountrySelector from "../Common/CountrySelector";
import CustomInput from "../Common/CustomInput";
import { useAuthContext } from "../../context/AuthContext";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../Utils/Firebase";
import { logBackend } from "../../handlers/logBackend";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getGlobalLocation } from "../../handlers/getGlobalLocation";

import openInAppUrl from "../../Utils/openInAppUrl";
import countries from "../../Utils/countryArray";

const PhoneNumberModal = ({ setType, setVerificationId, setPhoneNumberG }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const { sendOtp } = useAuthContext();
  const [countryCode, setCountryCode] = useState("+91");
  const firstRef = useRef(true);

  const { queryKey: userLocationKey, queryFunction: userLocationFn } =
    getGlobalLocation();

  const { data: locationData, isLoading: locationDataLoading } = useQuery(
    userLocationKey,
    userLocationFn,
    {
      staleTime: Infinity,
    }
  );

  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      if (phoneNumber === "696969") {
        return navigate("/public/custom");
      }
      if (
        countries
          ?.flat()
          ?.findIndex((cItem) => cItem?.value === countryCode) === -1
      ) {
        return setDisabled(true);
      }
      // auth.settings.appVerificationDisabledForTesting = true;
      if (phoneNumber.length < 7 || phoneNumber.length > 12) {
        return Toast.show({
          content: "Please enter a valid phone number",
          position: "top",
        });
      }
      if (phoneNumber.startsWith("0") || phoneNumber.startsWith("+")) {
        return Toast.show({
          content: "Enter the number without country code",
          position: "top",
        });
      }

      const phone = countryCode + phoneNumber;
      setLoading(true);
      let appVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );
      sendOtp(phone, appVerifier)
        .then((result) => {
          if (result?.verificationId) {
            setType("otp");
            setPhoneNumberG(phone);
            setVerificationId(result?.verificationId);
          } else {
            Toast.show({
              content: "We detected a suspicious login, Please refresh the app",
              position: "top",
            });
          }
          setLoading(false);
          appVerifier.clear();
        })
        .catch(async (err) => {
          await logBackend("firebase/sendOTP", "", {
            err: JSON.stringify(err),
            phoneNumber,
          });
          Toast.show({
            content: err?.code,
            position: "top",
          });
          setLoading(false);
          appVerifier.clear();
        });
    } catch (err) {
      // backend Api to throw error
      console.log("err", err);
      await logBackend("firebase", "", {
        err: JSON.stringify(err),
        phoneNumber,
      });
      Toast.show({
        content:
          err?.code + "We detected a suspicious login, Please refresh the app",
        position: "top",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("here", locationData);
    if (firstRef.current && locationData?.country_calling_code) {
      // console.log(locationData);
      setCountryCode(locationData?.country_calling_code);
      // setUserInputData((userInputData) => ({
      //   ...userInputData,
      //   countryCode: locationData?.country_code ?? "IN",
      // }));
      firstRef.current = false;
    }
  }, [firstRef.current, locationData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}>
      <div
        style={{
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "34px",
          color: "#09121F",
          margin: "24px 0",
          textAlign: "center",
        }}>
        Login / Register to continue
      </div>
      <div style={{ alignSelf: "center" }}>
        <CountrySelector
          value={countryCode}
          setValue={setCountryCode}
          setDisabled={setDisabled}
          disabled={disabled}
        />
        <CustomInput
          onChange={(e) => setPhoneNumber(e.target.value)}
          label="Phone number"
          type="number"
          pattern="\d*"
          placeholder="Enter your phone number"
          label2="We'll send you a text to confirm your number. Standard message rates may apply"
        />
        <div
          id="recaptcha-container"
          style={{ minHeight: "15px", margin: "5px" }}
        />
        <div>
          <Button
            loading={loading}
            onClick={handleSignIn}
            disabled={locationDataLoading}
            style={{
              width: "100%",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#2130FF",
              color: "white",
              fontWeight: "600",
              marginTop: "5px",
              maxWidth: "350px",
            }}>
            Continue
          </Button>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#333333",
              marginTop: "5px",
              maxWidth: "350px",
            }}>
            By clicking, I agree to Cloutflow's Terms of Service, Payments{" "}
            <span
              // href="https://cloutflow.com/terms-condition/"
              style={{ color: "#1890ff" }}
              onClick={() =>
                openInAppUrl("https://cloutflow.com/terms-condition/")
              }>
              Terms of Service
            </span>{" "}
            and acknowledge the{" "}
            <span
              style={{ color: "#1890ff" }}
              onClick={() =>
                openInAppUrl("https://cloutflow.com/privacy-policy/")
              }>
              Privacy Policy
            </span>
            .
          </div>
        </div>
      </div>

      {/* To make it safe from overriding */}
    </div>
  );
};

export default PhoneNumberModal;
