import React from "react";
import { Popover } from "antd";

import { BiInfoCircle } from "react-icons/bi";

const InfoPopOver = ({ desc }) => {
  return (
    <Popover
      content={desc}
      title={null}
      trigger="hover"
      placement="top"
      arrowPointAtCenter>
      <BiInfoCircle size={15} style={{ cursor: "pointer" }} />
    </Popover>
  );
};

export default InfoPopOver;
