import React from "react";
import Lottie from "react-lottie";
import { useEffect } from "react";
import { Divider } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import openExternalUrl from "../../Utils/openExternalUrl";

import animationData from "../../assets/lottie/duck.json";

import { getUser } from "../../handlers/me/getUser";

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: animationData,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

const VerifyKyc = () => {
  const { queryKey: userKey, queryFunction: userFn } = getUser(true);
  const { data: userData } = useQuery(userKey, userFn, {
    refetchInterval: 5000,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.data?.kyc?.status === "Verified") {
      return navigate("/");
    }
  }, [userData]);

  return (
    <div
      style={{ padding: "30px 24px", height: "100vh", position: "relative" }}>
      {/* <Lottie
        options={defaultOptions}
        height={150}
        width={150}
        style={{ marginBottom: 20 }}
      /> */}
      <h2 style={{ marginBottom: 20 }}>
        We couldn't verify your profile automatically
      </h2>
      <h4 style={{ marginBottom: 10 }}>
        There was a technical issue while verifying your profile.
      </h4>
      <h4 style={{ marginBottom: 20 }}>
        Verification will just take some extra time, we'll verify your profile
        in 24hrs.{" "}
        <span
          style={{
            color: "rgb(0, 100, 224)",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => openExternalUrl("https://cloutflow.com")}>
          Learn More
        </span>
      </h4>
      {/* <h5 style={{ textAlign: "center" }}>
        Taking too long ?{" "}
        <span>
          <a
            onClick={() =>
              openExternalUrl("https://wa.me/message/TGONBHANLW34C1")
            }>
            {" "}
            Contact Us
          </a>
        </span>
      </h5> */}
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          width: "calc(100% - 48px)",
        }}>
        <Divider />
        <h3 style={{ textAlign: "center" }}>
          Verification taking too long?{" "}
          <span
            style={{
              color: "rgb(0, 100, 224)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              openExternalUrl("https://wa.me/message/TGONBHANLW34C1")
            }>
            Contact Us
          </span>
        </h3>
        {/* <Button
          block
          onClick={() =>
            openExternalUrl("https://wa.me/message/TGONBHANLW34C1")
          }
          color="primary">
          Contact Us
        </Button> */}
      </div>
    </div>
  );
};

export default VerifyKyc;
