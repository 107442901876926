import CustomModalWrapper from "components/Common/CustomModalWrapper";
import { PlusOutlined } from "@ant-design/icons";
import { message, Upload, Input } from "antd";
import { useEffect, useState } from "react";
import { Button, Divider } from "antd-mobile";

import cuid from "cuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import { isLinkValid } from "Utils/isLinkValid";

const SendMedia = ({ openModal, setOpenModal, handleMessageSend }) => {
  return (
    <CustomModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      heading="Send Draft">
      <SendMediaComponent
        setOpenModal={setOpenModal}
        handleMessageSend={handleMessageSend}
      />
    </CustomModalWrapper>
  );
};

export default SendMedia;

const SendMediaComponent = ({ setOpenModal, handleMessageSend }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [showErr, setShowErr] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const handleSendMessage = () => {
    if (videoLink && !isLinkValid(videoLink)) {
      return setShowErr(true);
    }
    handleMessageSend(
      imageUrl ? imageUrl : videoLink,
      imageUrl ? mediaType : "link"
    );
    setOpenModal(false);
    setImageUrl("");
    setVideoLink("");
    setIsUploaded(false);
  };

  useEffect(() => {
    return () => {
      console.log("unmounting");
    };
  }, []);

  const props = {
    name: "file",
    customRequest: (options) => {
      const { onSuccess, file, onError } = options;
      setIsUploading(true);
      const storage = getStorage();
      const fileName = file.name;

      const storageRef = ref(
        storage,
        `/drafts/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentDisposition: "",
      });

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          setIsUploading(false);
          console.log("firebaseError", error.code, error.message);
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);

          setMediaType(file?.type?.split("/")[0]);
          setImageUrl(downloadURL);
          setIsUploading(false);
        }
      );
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setIsUploaded(true);
        message.success(`${info?.file?.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info?.file?.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Upload
          {...props}
          accept="image/*,video/*"
          maxCount={1}
          listType="picture-card"
          onRemove={(e) => setIsUploaded(false)}>
          {!isUploaded && !isUploading && uploadButton}
        </Upload>
      </div>
      <div style={{ width: "100%" }}>
        <Divider>OR</Divider>

        <div
          style={{
            display: "flex",
            flex: "0.5",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            marginBottom: "10px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}>
            <p style={{ fontWeight: 500, fontSize: "14px" }}>
              Enter Sharable Link
            </p>
            <Input
              placeholder="Enter link"
              onChange={(e) => setVideoLink(e.target.value)}
              size="large"
              value={videoLink}
            />
            {showErr && !isLinkValid(videoLink) && (
              <h6 style={{ color: "red" }}>Invalid Link</h6>
            )}
          </div>
        </div>
      </div>

      <Button
        style={{ position: "absolute", bottom: 12, width: "90%" }}
        type="primary"
        color="primary"
        onTouchEnd={() => handleSendMessage()}
        onClick={() => handleSendMessage()}
        disabled={!isUploaded && !videoLink}>
        Send
      </Button>
    </>
  );
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}>
      Upload
    </div>
  </div>
);
