const DateSelector = ({
  value,
  setValue,
  label,
  errorText,
  error,
  disabled = false,
  ...otherProps
}) => {
  const handleChange = (value) => {
    //Date to timestamp

    // setDate(value);
    // const timestamp = Date.parse(value);

    if (value === "") {
      return setValue(new Date().toUTCString());
    }
    setValue(new Date(value).toUTCString());
  };

  return (
    <div style={{ maxWidth: "350px", width: "100%", marginTop: "10px" }}>
      <div style={{ fontSize: "14px", fontWeight: "500", marginBottom: "4px" }}>
        {label}
      </div>
      <input
        type="date"
        value={new Date(value || Date()).toISOString().substring(0, 10)}
        onChange={(e) => handleChange(e.target.value)}
        style={{
          height: "48px",
          border: "1.5px solid #d9d9d9",
          borderRadius: "8px",
          width: "100%",
          padding: "10px",
          WebkitAppearance: "none",
          outline: "none",
          color: disabled && "rgba(0,0,0,.25)",
          backgroundColor: disabled ? "#f5f5f5" : "white",

          boxShadow: disabled && "none",
          cursor: disabled && "not-allowed",
          opacity: disabled && "1",
        }}
        disabled={disabled}
      />
      <div>
        {errorText && <h6 style={{ color: "red" }}>{errorText}</h6>}
        {error?.type === "isBefore" && (
          <h6 style={{ color: "red" }}>
            To sign up, you need to be at least 16
          </h6>
        )}
      </div>
    </div>
  );
};

export default DateSelector;
