import React from "react";
import { Steps } from "antd-mobile";

import { CheckCircleFill } from "antd-mobile-icons";

const { Step } = Steps;

const RegisterProgressBar = ({ currIndex }) => {
  return (
    <>
      <Steps
        direction="horizontal"
        current={currIndex}
        style={{
          "--title-font-size": "17px",
          "--description-font-size": "15px",
          "--icon-size": "24px",
        }}
      >
        <Step icon={<CheckCircleFill />} />
        <Step icon={<CheckCircleFill />} />
        <Step icon={<CheckCircleFill />} />
        <Step icon={<CheckCircleFill />} />
        <Step icon={<CheckCircleFill />} />
      </Steps>
    </>
  );
};

export default RegisterProgressBar;
