import React, { useEffect } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { PullToRefresh, DotLoading, Skeleton, Badge } from "antd-mobile";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { MdMarkChatUnread, MdOutlineChatBubbleOutline } from "react-icons/md";

import CampaignCard from "./Campaigncard";
import OngoingCampaigns from "./OngoingCampaigns";
import InvitedCampaignCard from "./InvitedCampaign";
import ExploreCard from "../Common/ExploreCard";
import PromoCard from "./Promo/PromoCard";
import Card from "../Restraunts/Card";

import { useNavContext } from "../../context/NavContext";

import { getAllCampaigns } from "../../handlers/campaign/getAllCampains";
import { getCampaignOffers } from "../../handlers/campaign/offers";
import RegisteredCard from "components/AdviceCards/RegisteredCard";
import { getUserStatus } from "../../handlers/me/getUserStatus";

import { useModalContext } from "context/ModalContext";
import { useChatNotificationContext } from "context/ChatNotificationContext";

const Dashboard = () => {
  const { queryKey, queryFunction } = getAllCampaigns();
  const { queryKey: userStatusKey } = getUserStatus();
  const { queryKey: ongoingCampaignsKey } = getCampaignOffers("On-going");
  const { queryKey: invitedCampaignsKey } = getCampaignOffers("Invited");
  const { setOpenNav, setNavHeading, setNavButton } = useNavContext();

  const { setExploreCampaignModal } = useModalContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    setOpenNav(true);
    setNavHeading(
      <img
        src="/assets/FAVICON.png"
        style={{ height: "30px", width: "30px", objectFit: "contain" }}
        alt="logo"
      />
    );
    setNavButton({
      open: true,
      button: (
        <Badge content={Badge.dot} style={{ "--right": "10%", "--top": "15%" }}>
          <Chat />
        </Badge>
      ),
    });

    return () => {
      setNavButton({
        open: false,
        button: null,
      });
    };
  }, []);

  const { data: campaignData, isLoading } = useInfiniteQuery(
    queryKey,
    queryFunction,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );

  return (
    <PullToRefresh
      onRefresh={async () => {
        await queryClient.invalidateQueries(queryKey);
        await queryClient.invalidateQueries(ongoingCampaignsKey);
        await queryClient.invalidateQueries(invitedCampaignsKey);
        await queryClient.invalidateQueries(userStatusKey);
        // window && window.location.reload();
      }}
      refreshingText={
        <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
      }
      canReleaseText={<AiOutlineArrowUp size={"20px"} />}
      pullingText={<AiOutlineArrowDown size={"20px"} />}
      completeText={<div />}>
      <Card />
      {/* <PromoCard /> */}
      <RegisteredCard />
      <OngoingCampaigns />
      <InvitedCampaignCard />
      <div style={{ padding: "0px 12px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}>
          <p
            style={{
              fontWeight: 500,
              fontSize: "24px",
            }}>
            Explore Campaigns
          </p>{" "}
          <span
            onClick={() => setExploreCampaignModal(true)}
            style={{ textDecoration: "underline" }}>
            {" "}
            See All
          </span>
        </div>

        <div
          style={{
            marginBottom: "20px",
            display: "grid",
            gap: "24px",
          }}>
          {isLoading
            ? new Array(5).fill(0).map((_, idx) => {
                return (
                  <Skeleton
                    style={{
                      "--width": " 100%",
                      "--height": "250px",
                      "--border-radius": "8px",
                      padding: "0 2px",
                    }}
                    key={idx}
                  />
                );
              })
            : campaignData?.pages[0]?.data?.brandCampaign
                ?.slice(0, 5)
                .map((item, idx) => {
                  return (
                    <div key={idx}>
                      <CampaignCard key={item._id} campaign={item} />
                    </div>
                  );
                })}
        </div>

        <ExploreCard
          title={"Like what you see?"}
          description={"Click here to explore more campaigns"}
          handleClick={() => setExploreCampaignModal(true)}
        />
      </div>
    </PullToRefresh>
  );
};

export default Dashboard;

const Chat = () => {
  const { setMessagesModal } = useModalContext();
  const { unreadMessageFlag, setUnreadMessageFlag } =
    useChatNotificationContext();

  return (
    <>
      {unreadMessageFlag ? (
        <MdMarkChatUnread
          style={{ fontSize: "20px" }}
          onClick={() => {
            setMessagesModal({ open: true });
            setUnreadMessageFlag(false);
          }}
        />
      ) : (
        <MdOutlineChatBubbleOutline
          style={{ fontSize: "20px" }}
          onClick={() => setMessagesModal({ open: true })}
        />
      )}
    </>
  );
};
