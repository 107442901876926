import { Button, Dialog, Skeleton } from "antd-mobile";
import KycDrawer from "components/GlobalDrawers/KycDrawer";
import { useAuthContext } from "context/AuthContext";
import { useModalContext } from "context/ModalContext";
import { useNavContext } from "context/NavContext";

import { getUser } from "handlers/me/getUser";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ProfileDrawers from "./Drawers";
import styles from "./profile.module.css";

import openExternalUrl from "../../Utils/openExternalUrl";

import { AiOutlineRight } from "react-icons/ai";

const Profile = () => {
  const { setOpenNav, setNavHeading, setNavButton } = useNavContext();
  const { signOutDevice, signedIn } = useAuthContext();
  const [openKycModal, setOpenKycModal] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const { setOpenConnectPlatformModal } = useModalContext();
  const { queryKey, queryFunction } = getUser(signedIn);
  const { data: profileInfo, isLoading } = useQuery(queryKey, queryFunction);

  useEffect(() => {
    setOpenNav(true);
    setNavHeading("Profile");

    return () =>
      setNavButton({
        open: false,
        button: null,
      });
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "31px 33px 24px 16px",
          gap: "12px",
        }}
        onClick={() => setDrawerType("EDIT_PROFILE")}>
        {isLoading && (
          <Skeleton
            style={{
              "--width": "100%",
              "--height": " 120px",
              "--border-radius": "8px",
            }}
            animated
          />
        )}
        <div className={styles.profilePicContainer}>
          {!isLoading && profileInfo?.data?.profilePicUrl && (
            <img
              className={styles.profilePic}
              src={profileInfo?.data?.profilePicUrl}
              alt=""
            />
          )}
          <h3
            style={{
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "28px",
            }}>
            {profileInfo?.data?.name} {profileInfo?.data?.lastname}
          </h3>
        </div>
        {/* <div
          style={{
            minWidth: "79%",
            borderBottom: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "22px",
          }}>
          <h3 style={{ fontWeight: 600, fontSize: "20px", lineHeight: "28px" }}>
            {profileInfo?.data?.name} {profileInfo?.data?.lastname}
          </h3>
          <AiOutlineRight />
        </div> */}
        <AiOutlineRight />
      </div>
      <div className={styles.options}>
        <div
          onClick={() => {
            if (profileInfo?.data.kyc.status === "Not-Initiated")
              setOpenKycModal(true);
            else setDrawerType("KYC");
          }}
          className={styles.option}>
          <p>KYC</p>
          <AiOutlineRight />
        </div>
        <div
          className={styles.option}
          onClick={() => {
            setOpenConnectPlatformModal(true);
          }}>
          <p>Manange Platform</p>
          <AiOutlineRight />
        </div>
        <div
          onClick={() => {
            setDrawerType("NOTIFICATION");
          }}
          className={styles.option}>
          <p>Notification Settings</p>
          <AiOutlineRight />
        </div>
        <div
          className={styles.option}
          onClick={() => {
            openExternalUrl("https://wa.me/message/WPYV54AG54APF1");
            // window.open("https://cloutflow.com/", "_blank");
          }}>
          <p>Help</p>
          <AiOutlineRight />
        </div>

        <Button
          onClick={() => {
            Dialog.confirm({
              content: (
                <h1 style={{ fontSize: "14px", fontWeight: "400" }}>
                  Are you sure you want to logout?
                </h1>
              ),
              cancelText: "Cancel",
              confirmText: "Confirm",
              onConfirm: async () => {
                await signOutDevice();
              },
            });
          }}
          className={styles.logout}
          fill="none">
          Log Out
        </Button>
      </div>
      {profileInfo?.data && (
        <ProfileDrawers
          type={drawerType}
          setDrawerType={setDrawerType}
          profileData={profileInfo?.data}
        />
      )}
      <KycDrawer
        isOpen={openKycModal}
        handleClose={() => setOpenKycModal(false)}
      />
    </div>
  );
};

export default Profile;
