import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";
import Stats from "./Home/Stats";

const MediaKitDrawer = ({ mediaKitModal, setMediaKitModal }) => {
  return (
    <CustomDrawerWrapper
      isOpen={mediaKitModal?.open}
      title={"Insights"}
      handleClose={() =>
        setMediaKitModal({
          open: false,
          props: { platform: null, creatorId: null },
        })
      }>
      <div style={{ paddingBottom: "50px" }}>
        <Stats creatorId={mediaKitModal?.props?.creatorId} />
      </div>
    </CustomDrawerWrapper>
  );
};

export default MediaKitDrawer;
