import React from "react";
import { Progress, Row } from "antd";

import styles from "../demographic.module.css";

const CircularStatsCard = ({ title, items }) => {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <Row justify="center">
        <Progress
          type="circle"
          success={{
            percent: items[0].percent,
            strokeColor: "#2130FF",
          }}
          strokeColor="rgba(33, 48, 255, 0.4)"
          strokeLinecap="square"
          percent={Number(items[0].percent) + Number(items[1].percent)}
          showInfo={false}
          strokeWidth={10}
          size={"default"}
          width={200}
        />
      </Row>
      <div style={{ display: "grip", gap: "12px", marginTop: "40px" }}>
        <Row justify="space-between" style={{ padding: "0 24px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <div
              style={{
                background: "#2130FF",
                borderRadius: "2px",
                width: "12px",
                height: "12px",
              }}
            />
            <div>
              <p className={styles.label}>{items[0].label}</p>
              <p className={styles.percent}>{items[0].percent + "%"}</p>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <div
              style={{
                background: "rgba(33, 48, 255, 0.4)",
                borderRadius: "2px",
                width: "12px",
                height: "12px",
              }}
            />
            <div>
              <p className={styles.label}>{items[1].label}</p>
              <p className={styles.percent}>{items[1].percent + "%"}</p>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <div
              style={{
                background: "#f5f5f5",
                borderRadius: "2px",
                width: "12px",
                height: "12px",
              }}
            />
            <div>
              <p className={styles.label}>{items[2].label}</p>
              <p className={styles.percent}>{items[2].percent + "%"}</p>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default CircularStatsCard;
