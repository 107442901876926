import { getStorage, ref, getMetadata } from "firebase/storage";

const openInAppUrl = (url, encoded = false) => {
  if (!url) return null;

  if (window?.ReactNativeWebView) {
    if (!url.includes("firebaseStorage")) {
      return window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "openInAppUrl",
          metadata: {
            url: encoded ? encodeURI(url) : url,
            type: "",
            name: "",
          },
        })
      );
    }

    const storage = getStorage();
    const firebaseRef = ref(storage, url);

    getMetadata(firebaseRef)
      .then((metadata) => {
        return window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "openInAppUrl",
            metadata: {
              url: url,
              type: metadata?.contentType || "",
              name: metadata?.name || "",
            },
          })
        );
      })
      .catch(() => {
        return window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "openInAppUrl",
            metadata: {
              url: url,
              type: "",
              name: "",
            },
          })
        );
      });
  } else {
    return window.open(url, "_self");
  }
  return null;
};

export default openInAppUrl;
