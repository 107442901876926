// import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { SpinLoading } from "antd-mobile";
import RegisterRoute from "../routes/RegisterRoute";
// import CustomNavBar from "../components/Common/CustomNavBar";
import GlobalDrawers from "components/GlobalDrawers";

const RegisterLayout = () => {
  const { loading } = useAuthContext();

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}>
        <SpinLoading color="primary" />
      </div>
    );
  }

  return (
    <div>
      <RegisterRoute />
      <GlobalDrawers />
    </div>
  );
};
export default RegisterLayout;
