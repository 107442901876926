import CustomModalWrapper from "components/Common/CustomModalWrapper";

import { Button } from "antd-mobile";

import timeDifference from "Utils/timeDifference";

import styles from "./InsightRequestDetail.module.less";

const InsightRequestDetail = ({
  open,
  setOpen,
  data,
  insightReqAcceptStatus,
  insightAcceptLoading,
  onBtnClick,
}) => {
  const infoList = [
    {
      heading: "Verified business",
      text: "The brand asking for insights is verified by us",
    },
    {
      heading: "Secured Sharing",
      text: "The brand can only see the data you agree to share.",
    },
    {
      heading: "Complete Control",
      text: " You can revoke the access of the data from the brand anytime you want.",
    },
  ];

  return (
    <CustomModalWrapper
      openModal={open}
      setOpenModal={setOpen}
      heading={"Insight Request"}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header__row}>
            <img
              className={styles.header__logo}
              src={data.logo}
              alt="brandLogo"
            />
            <div className={styles.header__text}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.msg,
                }}></div>
            </div>
          </div>

          <span className={styles.header__timeText}>
            {timeDifference(new Date(), data.createdDateTime.toDate())}
          </span>
        </div>
        <div
          style={{ border: ".5px solid #eee", marginTop: 20, marginBottom: 15 }}
        />
        <div>
          <span className={styles.question}>Why brands need this?</span>
          <span className={styles.answer}>
            Brands promote to the audience that see you. For that they need the
            insights to get a better understanding of your audience. So that you
            as well as brand gets the best results.
          </span>
        </div>

        <div style={{ marginTop: 20 }}>
          <span className={styles.question}>What is being shared?</span>
          <span className={styles.answer}>
            We share the following data points:
            <ul className={styles.answerList}>
              <li>Audience age range</li>
              <li>Audience gender</li>
              <li>Audience location</li>
            </ul>
          </span>
        </div>

        <div
          style={{ border: ".5px solid #eee", marginTop: 20, marginBottom: 15 }}
        />

        {infoList.map((item, index) => (
          <div key={index} className={styles.infoItem}>
            <span className={styles.infoItemHeading}>{item.heading}</span>
            <span className={styles.infoItemText}>{item.text}</span>
          </div>
        ))}

        <div className={styles.btnContainer}>
          <Button
            color="primary"
            fill="solid"
            onClick={onBtnClick}
            block
            loading={insightAcceptLoading}
            // disabled={insightReqAcceptStatus}
          >
            {insightReqAcceptStatus === false ? "Accept" : "Withdraw"}
          </Button>
        </div>
      </div>
    </CustomModalWrapper>
  );
};

export default InsightRequestDetail;
