import React from "react";
import { Card, Divider } from "antd";
import { ProgressBar } from "antd-mobile";
import nf from "@tuplo/numberfmt";

import { format } from "date-fns";
import { useModalContext } from "../../../../context/ModalContext";

import styles from "./campaignCard.module.css";

const CampaignCard = ({ campaignData }) => {
  const { setOngoingCampaignModal } = useModalContext();

  // const navigate = useNavigate();
  if (!campaignData) {
    return null;
  }

  return (
    <Card
      title={null}
      bodyStyle={{ padding: 0 }}
      className={styles.container}
      onClick={() => {
        // navigate(`/ongoing-campaign/${campaignData.minFinalizedOfferId}`);
        setOngoingCampaignModal({
          open: true,
          props: {
            offerId: campaignData.minFinalizedOfferId,
          },
        });
      }}>
      <img
        src={campaignData?.campaign?.campaignBannerUrl}
        alt="banner"
        width={"100%"}
        height={"130px"}
        style={{ objectFit: "cover", borderRadius: "8px 8px 0 0" }}
      />
      <div style={{ padding: "14px 16px 16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>
            <p className={styles.cName}>{campaignData.campaign.campaignName}</p>
            <p className={styles.bName}>
              {campaignData.brand.businessDetails.name}
            </p>
          </div>
          <img
            src={`/assets/img/${campaignData?.campaign?.platform}-disabled.png`}
            width={"24px"}
            height={"24px"}
            alt="platform"
          />
        </div>

        <Divider style={{ margin: "12px 0" }} />

        {campaignData.minMessageToDisplay !== "None" ? (
          <p
            style={{ marginBottom: "12px", display: "flex", gap: "2px" }}
            className={styles.description}>
            Send {campaignData.minMessageToDisplay} for
            {"  "}
            {campaignData.minDeliverable.deliverablesDetails.typeDel}{" "}
            {campaignData.minDeliverable.deliverablesDetails.count}
            {"  "}
            {"by  "}
            <u>
              {format(new Date(campaignData.minExpectedClosureDate), "LLL d")}
            </u>
          </p>
        ) : (
          <p style={{ marginBottom: "12px", height: "32px" }}></p>
        )}

        <p className={styles.progress}>
          Overall progress{" "}
          {nf((100 - Number(campaignData.percentageNotCompleted)) * 0.01, "%", {
            locale: "en-US",
          })}
        </p>
        <ProgressBar
          percent={100 - Number(campaignData.percentageNotCompleted)}
          rounded
        />
      </div>
    </Card>
  );
};

export default CampaignCard;
