import { LeftOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { useChatContext } from "context/ChatContext";
import { useModalContext } from "context/ModalContext";
import { getUserProfile } from "handlers/chat";
import { useEffect } from "react";
import { useQuery } from "react-query";
import styles from "./chatWindow.module.css";
import clsx from "clsx";

import useWindowWidth from "../../hooks/useWindowWidth";

const ChatHeader = () => {
  const isDesktop = useWindowWidth();
  const { brandId } = useChatContext();
  const { setChatModal } = useModalContext();
  const { queryKey: getUserProfileKey, queryFunction: getUserProfileFn } =
    getUserProfile(brandId);
  const {
    data: brandProfile,
    isLoading,
    isError,
  } = useQuery(getUserProfileKey, getUserProfileFn);

  useEffect(() => {}, [brandProfile]);

  if (isLoading)
    return (
      <div className={isDesktop ? styles.headerDesktop : styles.header}>
        <Skeleton avatar paragraph={{ rows: 0 }} style={{ padding: "2em" }} />
      </div>
    );

  return (
    <div className={isDesktop ? styles.headerDesktop : styles.header}>
      {!isDesktop && (
        <LeftOutlined
          onClick={() => {
            setChatModal({
              open: false,
              props: {
                brandId: null,
                conversationId: null,
              },
            });
          }}
        />
      )}
      <img
        src={brandProfile?.data?.businessDetails?.logoUrl}
        style={{
          width: "2.5rem",
          height: "2.5rem",
          borderRadius: "50%",
          objectFit: "cover",
          border: "1px solid #ccc",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-around",
        }}>
        <h4>{brandProfile?.data?.name}</h4>
        <h4
          style={{
            color: "#999999",
          }}>
          {brandProfile?.data?.businessDetails?.name}
        </h4>
      </div>
    </div>
  );
};

export default ChatHeader;
