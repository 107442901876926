import RegisterLayout from "./layout/RegisterLayout";
import "./App.less";
import { useAuthContext } from "./context/AuthContext";
import { useVersionContext } from "./context/VersionContext";
import MainLayout from "./layout/mainLayout";
import { getUserStatus } from "./handlers/me/getUserStatus";
import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import LoadingScreen from "./screens/Common/LoadingScreen";
import { useLocation, useNavigate } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import Redirect from "screens/Public/Redirect";
import Lottie from "react-lottie";
import { useSearchParams } from "react-router-dom";

import WebLayout from "./layout/WebLayout";

import animationData from "./assets/lottie/newNotification.json";

import { Modal } from "antd-mobile";

import Test from "./components/Test";

// import ReconnectPlatformModal from "./components/ReconnectPlatformModal";
import { VerifyPlatforms } from "./components/VerifyPlatforms";

import { useServiceWorker } from "./hooks/useServiceWorker";

import { getUser } from "./handlers/me/getUser";

const App = () => {
  const { signedIn, loading: isAuthLoading } = useAuthContext();
  const { reloadApp } = useVersionContext();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { queryKey, queryFunction } = getUserStatus(signedIn);
  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: signedIn,
  });
  const { queryKey: userKey, queryFunction: userFn } = getUser(signedIn);
  const { data: userData, isLoading: userDataLoading } = useQuery(
    userKey,
    userFn,
    {
      enabled: signedIn,
    }
  );

  const [main, setMain] = useState(false);
  // const [reconnectVisible, setReconnectVisible] = useState(true);
  const [verifyPlatformsVisible, setVerifyPlatformsVisible] = useState(false);
  const firstRender = useRef(true);
  const onbaordingRef = useRef(true);

  const location = useLocation();

  useEffect(() => {
    if (window && window.swUpdateReady) {
      window.swUpdateReady = false;
      window.stop();
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (reloadApp || (showReload && waitingWorker)) {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      Modal.alert({
        content: (
          <>
            <Lottie options={defaultOptions} height={80} width={80} />
            <h4 style={{ textAlign: "center" }}>
              Some new content is available, Please click the below to refresh
              the app
            </h4>
          </>
        ),
        confirmText: "Reload",
        onConfirm: () => {
          // if (window) {
          //   if (window.ReactNativeWebView) {
          //     return window.ReactNativeWebView.postMessage(
          //       JSON.stringify({ type: "reloadApp" })
          //     );
          //   }
          return reloadPage();
          // return window.location.reload();
        },
      });
    }
  }, [reloadApp, waitingWorker, showReload, reloadPage]);

  let hasQuery = false;
  console.log("Jai Shree Ram");

  useEffect(() => {
    if (data && userData && !userDataLoading && !isLoading && !isAuthLoading) {
      let isRegistered = data?.data?.isOnBoarded || false;
      let isIgRevoked = data?.data?.isIgRevoked || false;
      let isYtRevoked = data?.data?.isYtRevoked || false;

      if (isRegistered && signedIn) {
        setMain(true);
      }

      // setReconnectVisible(isIgRevoked || isYtRevoked);
      if (location.pathname === "/kycProcessing") {
        return setVerifyPlatformsVisible(false);
      }
      if (
        onbaordingRef.current &&
        userData?.data &&
        searchParams.get("type") === "onbaording"
      ) {
        setVerifyPlatformsVisible(
          userData?.data?.userPlatforms
            ?.filter(
              (item) =>
                item.platform === "instagram" || item.platform === "youtube"
            )
            ?.some((userItem) => !Boolean(userItem?.connectedThrough))
        );
        onbaordingRef.current = false;
        setSearchParams({});
      }

      if (firstRender.current && userData?.data) {
        // console.log("check");
        setVerifyPlatformsVisible(
          userData?.data?.userPlatforms
            ?.filter(
              (item) =>
                item.platform === "instagram" || item.platform === "youtube"
            )
            ?.some((userItem) => !Boolean(userItem?.connectedThrough)) &&
            userData?.data?.kyc?.status !== "Verified"
        );
        firstRender.current = false;
      }
    }

    if (!signedIn) setMain(false);
  }, [
    data,
    signedIn,
    userData?.data,
    location.pathName,
    userDataLoading,
    isLoading,
    isAuthLoading,
  ]);

  if (location.pathname === "/test") return <Test />;

  if (isLoading || isAuthLoading || userDataLoading) return <LoadingScreen />;
  if (location.pathname.startsWith("/app")) return <Redirect />;
  if (location.pathname.startsWith("/public")) return <PublicRoutes />;

  if (location.search.includes("?type=web")) {
    hasQuery = true;
  }

  // console.log("test", firstRender);

  return (
    <div>
      {hasQuery ? (
        <WebLayout />
      ) : (
        <>
          {main ? (
            <>
              <MainLayout />
              {/* <ReconnectPlatformModal
                visible={reconnectVisible}
                platform={data?.data?.isIgRevoked ? "instagram" : "youtube"}
              /> */}
              <VerifyPlatforms
                visible={verifyPlatformsVisible}
                handleClose={() => {
                  setVerifyPlatformsVisible(false);
                  navigate("/onboarding");
                }}
              />
            </>
          ) : (
            <RegisterLayout />
          )}
        </>
      )}
    </div>
  );
};

export default App;
