import React from "react";
import { Divider } from "antd-mobile";
import { formatPrice } from "../../Common/formatPrice";

const PriceDetailCard = ({ commercial }) => {
  return (
    <div
      style={{
        background: "#FAFAFA",
        border: "1px solid #EEEEEE",
        borderRadius: "12px",
        padding: "16px 20px",
      }}>
      {commercial.items.map((item) => {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: " 20px",
              color: "#999999",
              marginBottom: "8px",
            }}>
            <span>{item?.name}</span>
            <span>₹{formatPrice(item?.price)}</span>
          </div>
        );
      })}
      <Divider style={{ margin: "0" }} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "50% 50%",
          fontWeight: 400,
          color: "#09121F",
          marginTop: "8px",
        }}>
        <span style={{ fontSize: "14px", lineHeight: "20px" }}>Total</span>
        <span
          style={{
            fontSize: "20px",
            lineHeight: "23px",
            letterSpacing: "0.04em",
          }}>
          ₹{formatPrice(commercial?.price)}
        </span>
      </div>
    </div>
  );
};

export default PriceDetailCard;

// {
//     "price": 86985,
//     "items": [
//         {
//             "name": "Integrated Video",
//             "price": "2342.999991"
//         },
//         {
//             "name": "Dedicated Video",
//             "price": "82299.0000928"
//         },
//         {
//             "name": "Integrated Video",
//             "price": "2342.999991"
//         }
//     ],
//     "isPriceCardApproved": true
// }
