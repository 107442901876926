const openExternalUrl = (url) => {
  if (!url) return null;

  if (window?.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "openUrl",
        metadata: {
          url: url,
        },
      })
    );
  } else {
    window.open(url, "_self");
  }
  return null;
};

export default openExternalUrl;
