import { useQuery } from "react-query";
import { Button, Space } from "antd-mobile";

import { getUserSocial } from "handlers/me/getSocial";

import { getCommunityInvites } from "handlers/community";

import ConnectPlatform from "../ConnectPlatform/ConnectPlatform";

import openInAppUrl from "../../Utils/openInAppUrl";

const ConnectPlatformScreen = ({ setScreen, isWeb = false }) => {
  const { queryKey: socialQueryKey, queryFunction: socialQueryFunction } =
    getUserSocial(["Connected", "Session-Expired"]);

  const { data: socialData } = useQuery(socialQueryKey, socialQueryFunction);

  const isContinueEnabled = () => {
    if (
      socialData &&
      socialData?.data.length > 0 &&
      socialData.data.every(
        (item) => item?.deliverablePriceRequest?.status !== "Not-Initiated"
      )
    ) {
      return true;
    }
    return false;
  };

  const {
    queryKey: instaConnectRequestkey,
    queryFunction: instaConnectRequestFn,
  } = getCommunityInvites({ platform: "instagram" });

  const {
    queryKey: youtubeConnectRequestkey,
    queryFunction: youtubeConnectRequestFn,
  } = getCommunityInvites({ platform: "youtube" });

  const isInstaConnected = socialData?.data?.find(
    (item) => item.socialMediaPlatform === "instagram"
  )
    ? true
    : false;
  const isYoutubeConnected = socialData?.data?.find(
    (item) => item.socialMediaPlatform === "youtube"
  )
    ? true
    : false;

  const { data: instaRequestData } = useQuery(
    instaConnectRequestkey,
    instaConnectRequestFn,
    {
      enabled: isInstaConnected,
    }
  );

  const { data: youtubeRequestData } = useQuery(
    youtubeConnectRequestkey,
    youtubeConnectRequestFn,
    { enabled: isYoutubeConnected }
  );

  return (
    <>
      <div
        style={{
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "34px",
          color: "#09121F",
          marginBottom: "24px",
        }}>
        Connect platforms
      </div>
      <ConnectPlatform isWeb={isWeb} />
      <div style={{ opacity: "0", height: "100px" }}>h</div>
      <div style={{ display: "grid", gap: "5px" }}>
        <h2>Accept Community Invites</h2>
        <Button color="primary" fill="outline">
          Accept Instagram Invites
        </Button>
        <Button color="primary" fill="outline">
          Accept Youtube Invites
        </Button>
      </div>

      <Button
        onClick={() =>
          Boolean(isWeb)
            ? openInAppUrl("https://link.cloutflow.com/app")
            : setScreen(3)
        }
        style={{
          width: "100%",
          height: "48px",
          borderRadius: "8px",
          backgroundColor: "#2130FF",
          color: "white",
          fontWeight: "600",
          marginTop: "5px",
          maxWidth: "350px",
          position: "fixed",
          bottom: 10,
        }}
        disabled={!isContinueEnabled()}>
        {socialData && socialData?.data.length > 0
          ? !isContinueEnabled()
            ? "Add Commercials"
            : Boolean(isWeb)
            ? "Get Cloutflow App "
            : "Continue"
          : "Connect Platform"}
        {/* {isContinueEnabled()
          ? socialData && socialData?.data.length > 0
            ? "Add Commercials"
            : Boolean(isWeb)
            ? "Get Cloutflow App "
            : "Continue"
          : "Connect Platform"} */}
      </Button>
    </>
  );
};

export default ConnectPlatformScreen;
