import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { ProgressBar } from "antd-mobile";
import { useQuery } from "react-query";
import { useModalContext } from "context/ModalContext";
import { getUserStatus } from "../../handlers/me/getUserStatus";
import styles from "./Cards.module.css";
import openExternalUrl from "Utils/openExternalUrl";

const RegisteredCard = () => {
  const { setOpenKycModal, setOpenConnectPlatformModal } = useModalContext();
  const { queryKey: getUserStatusKey, queryFunction: getUserStatusFn } =
    getUserStatus();

  const {
    data: userStatus,
    isLoading,
    isFetching,
  } = useQuery(getUserStatusKey, getUserStatusFn);

  if (isLoading || isFetching) {
    return null;
  }

  if (
    userStatus.data.isPlatformConnected &&
    userStatus.data.isOnBoarded &&
    userStatus.data.kycStatus === "Verified"
  ) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div style={{ width: "80%" }}>
        <div className={styles.primaryText}>
          {!userStatus.data.isPlatformConnected ? (
            <> Brands are looking for you to collaborate with.</>
          ) : (
            <>
              Your Profile is under review{" "}
              <div
                className={styles.secondaryText}
                style={{ marginTop: "7px" }}
              >
                You’ll be able to participate in campaigns once your Profile is
                verified.
              </div>
            </>
          )}
        </div>
        {!userStatus.data.isPlatformConnected ? (
          <>
            <div className={styles.secondaryText} style={{ marginTop: "5px" }}>
              Create your Cloutflow profile so they can find you.
            </div>
            <ProgressBar
              text={<span className={styles.percent}>2/4</span>}
              percent={60}
              style={{
                "--fill-color": "#FFFFFF",
              }}
            />
            <div
              className={styles.actionButton}
              style={{ marginTop: "11px" }}
              onClick={() => setOpenConnectPlatformModal(true)}
            >
              Connect Platforms <HiArrowNarrowRight />
            </div>
          </>
        ) : (
          <>
            <div
              className={styles.secondaryActionText}
              style={{ marginTop: "50px" }}
              onClick={() =>
                openExternalUrl("https://wa.me/message/CAM3PTV6HUEBH1")
              }
            >
              Taking too long? Contact support
            </div>
          </>
        )}
      </div>

      <img
        src={
          !userStatus.data.isPlatformConnected
            ? "/assets/img/woman.png"
            : "/assets/icons/glass.svg"
        }
        style={{
          position: "absolute",
          right: !userStatus.data.isPlatformConnected ? "0" : "-5px",
          bottom: !userStatus.data.isPlatformConnected ? "-15px" : "27px",
        }}
        alt="wallet"
      />
    </div>
  );
};

export default RegisteredCard;
