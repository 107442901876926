import { useState, useEffect } from "react";
import { Button, Checkbox } from "antd-mobile";
import CustomDrawerWrapper from "../Common/CustomDrawerWrapper";
import openExternalUrl from "../../Utils/openExternalUrl";
import styles from "./adviceModal.module.css";

const ConnectAdviceModal = ({ isOpen, handleClose, url, platform }) => {
  const [checkMark, setCheckMark] = useState({ bool1: false, bool2: false });

  useEffect(() => {
    // console.log("mounted");
    return () => {
      // console.log("unmounted");
      setCheckMark({ bool1: false, bool2: false });
    };
  }, []);

  // console.log(checkMark);

  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        setCheckMark({ bool1: false, bool2: false });
      }}
      title=""
      footer={
        <div
          style={{
            padding: "1em",
            width: "100%",
            borderTop: "1px solid #E5E5E5",
            backgroundColor: "white",
          }}>
          {platform === "Instagram" && (
            <>
              <Checkbox
                style={{
                  "--icon-size": "18px",
                  "--font-size": "14px",
                  "--gap": "6px",
                  marginBottom: "1em",
                }}
                value={checkMark.bool1}
                onChange={(checked) =>
                  setCheckMark((checkMark) => {
                    return { ...checkMark, bool1: checked };
                  })
                }>
                <div className={styles.desc}>
                  I have an instagram business/creator account.{"  "}
                  <span
                    style={{
                      color: "#2130ff",
                      fontSize: "12px",
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      openExternalUrl(
                        "https://help.instagram.com/502981923235522"
                      )
                    }>
                    Learn More
                  </span>
                </div>
              </Checkbox>

              <Checkbox
                style={{
                  "--icon-size": "18px",
                  "--font-size": "14px",
                  "--gap": "6px",
                  marginBottom: "1em",
                }}
                value={checkMark.bool2}
                onChange={(checked) =>
                  setCheckMark((checkMark) => {
                    return { ...checkMark, bool2: checked };
                  })
                }>
                <div className={styles.desc}>
                  My instagram Account is connected to my Facebook page.{"  "}
                  <span
                    style={{
                      color: "#2130ff",
                      fontSize: "12px",
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      openExternalUrl(
                        "https://help.instagram.com/176235449218188"
                      )
                    }>
                    Learn More
                  </span>
                </div>
              </Checkbox>
            </>
          )}

          <Button
            block
            onClick={() => {
              openExternalUrl(url);
              handleClose();
            }}
            style={{
              width: "100%",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#2130FF",
              color: "white",
              fontWeight: "600",
              marginTop: "5px",
              // margin: "0 20px",
            }}
            disabled={
              platform === "Instagram" && !(checkMark.bool1 && checkMark.bool2)
            }>
            Continue
          </Button>
        </div>
      }>
      <div
        style={{
          padding: " 0 24px",
          overflowY: "auto",
          // paddingBottom: platform === "Youtube" ? "100px" : "200px",
        }}>
        <h2 className={styles.heading}>Securely connect your {platform}</h2>
        <div style={{ display: "grid", gap: "16px", padding: "20px 12px" }}>
          <div style={{ display: "flex", gap: "16px" }}>
            <img
              src="/assets/icons/verfied_person--green.svg"
              className={styles.icon}
              alt="person"
            />
            <div>
              <h3 className={styles.title}>Consent based sharing of data.</h3>
              <h5 className={styles.desc} style={{ marginTop: "2px" }}>
                You are in complete control of your data. We dont share any data
                about your profile to any third party.
              </h5>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <img
              src="/assets/icons/verified_fingerprint--green.svg"
              className={styles.icon}
              alt="fingerprint"
            />
            <div>
              <h3 className={styles.title}>Data is secure</h3>
              <h5 className={styles.desc} style={{ marginTop: "2px" }}>
                We don’t save any sensitive information related to your account.
              </h5>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <img
              src="/assets/icons/verified_tick--green.svg"
              className={styles.icon}
              alt="tick"
            />
            <div>
              <h3 className={styles.title}>We just get the data</h3>
              {platform === "Instagram" && (
                <h5 className={styles.desc} style={{ marginTop: "2px" }}>
                  The process of connecting the platform is handled by Meta
                  (facebook), after you give your permission to connect the
                  platfrom, facebook will share the data with us.
                </h5>
              )}
              {platform === "Youtube" && (
                <h5 className={styles.desc} style={{ marginTop: "2px" }}>
                  The process of connecting the platform is handled by Google
                  (Youtube), after you give your permission to connect the
                  platfrom, youtube will share the data with us.
                </h5>
              )}
              <div
                style={{
                  display: "flex",
                  padding: "5px 0",
                  gap: "16px",
                }}>
                {platform === "Instagram" && (
                  <img
                    src="/assets/img/meta_full.png"
                    alt="meta"
                    width="80px"
                    height="27px"
                  />
                )}
                {platform === "Youtube" && (
                  <img
                    src="/assets/img/yt_full.png"
                    alt="youtube"
                    width="91px"
                    height="29px"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomDrawerWrapper>
  );
};

export default ConnectAdviceModal;
