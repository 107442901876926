import React from "react";
import CustomModalWrapper from "./CustomModalWrapper";
import InstagramPosts from "./InstagramPosts";
import InstagramStory from "./InstagramStory";
import YoutubePosts from "./YoutubePosts";

import LiveUpload from "./LiveUpload";

const LiveUploadModal = ({
  openLiveModal,
  setOpenLiveModal,
  platform,
  typeDel,
  workflowId,
  isEdit,
  prevLiveLinkTimeLineId,
  finalizedOfferId,
  socialMediaId,
}) => {
  return (
    <CustomModalWrapper
      openModal={openLiveModal}
      setOpenModal={setOpenLiveModal}
      heading={`Select ${typeDel}`}>
      <div style={{ width: "100%" }}>
        {/* {platform === "instagram" ? (
          <>
            {typeDel === "Story" ? (
              <InstagramStory
                setOpenModal={setOpenLiveModal}
                platform={platform}
                workflowId={workflowId}
                typeDel="Story"
                isEdit={isEdit}
                prevLiveLinkTimeLineId={prevLiveLinkTimeLineId}
                finalizedOfferId={finalizedOfferId}
                socialMediaId={socialMediaId}
              />
            ) : (
              <InstagramPosts
                setOpenModal={setOpenLiveModal}
                platform={platform}
                workflowId={workflowId}
                typeDel
                isEdit={isEdit}
                prevLiveLinkTimeLineId={prevLiveLinkTimeLineId}
                finalizedOfferId={finalizedOfferId}
                socialMediaId={socialMediaId}
              />
            )}
          </>
        ) : (
          <YoutubePosts
            setOpenModal={setOpenLiveModal}
            platform={platform}
            workflowId={workflowId}
            isEdit={isEdit}
            prevLiveLinkTimeLineId={prevLiveLinkTimeLineId}
            finalizedOfferId={finalizedOfferId}
            socialMediaId={socialMediaId}
          />
        )} */}
        <LiveUpload
          setOpenModal={setOpenLiveModal}
          platform={platform}
          workflowId={workflowId}
          isEdit={isEdit}
          prevLiveLinkTimeLineId={prevLiveLinkTimeLineId}
          finalizedOfferId={finalizedOfferId}
        />
      </div>
    </CustomModalWrapper>
  );
};

export default LiveUploadModal;
