import { Toast } from "antd-mobile";
import { useQueryClient, useMutation } from "react-query";
import { sendRequest } from "../sendRequest";

export const updateMe = async ({ data }) => {
  const user = await sendRequest("put", "/influencer/me", { ...data });
  return user?.message;
};

export default function useUpdateMe(cb = () => {}) {
  const queryClient = useQueryClient();
  return useMutation(updateMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      cb();
    },
    onError: () => {
      Toast.show("Something went wrong");
    },
  });
}
