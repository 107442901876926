const SCOPE = [
  "https://www.googleapis.com/auth/youtube.readonly",
  "https://www.googleapis.com/auth/yt-analytics.readonly",
].join(" ");

const options = {
  scope: SCOPE,
  prompt: "consent",
  response_type: "code",
  access_type: "offline",
  redirect_uri: `${process.env.REACT_APP_URL}/public/authorise/youtube`,
  client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  include_granted_scopes: "true",
};

export const getYtOptions = (
  isReconnect = false,
  isWeb = false,
  isRedirect = false
) => {
  if (isRedirect) {
    return {
      ...options,
      redirect_uri: `${process.env.REACT_APP_CONNECT_URL}/public/accounts`,
    };
  }
  if (!isReconnect) {
    return {
      ...options,
      redirect_uri: `${process.env.REACT_APP_URL}/public/authorise/youtube${
        isWeb ? "/web" : ""
      }`,
    };
  }
  return {
    ...options,
    redirect_uri: `${process.env.REACT_APP_URL}/public/reauthorise/youtube${
      isWeb ? "/web" : ""
    }`,
  };
};
