import React from "react";
import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";

import { Button, Ellipsis, Toast, Image, Swiper } from "antd-mobile";
import { AiOutlineLink } from "react-icons/ai";
import { formatPrice } from "../../Common/formatPrice";

import styles from "./styles.module.css";
import openInAppUrl from "../../../Utils/openInAppUrl";

const RequirementDrawer = ({
  isOpen,
  handleClose,
  selectedWorkflow,
  workflowData,
}) => {
  const copyToClipboard = () => {
    if (selectedWorkflow) {
      // console.log("hey", navigator);
      navigator.clipboard.writeText(
        selectedWorkflow?.deliverable?.requirements?.caption
      );
      Toast.show({
        icon: "success",
        content: "Caption Copied",
      });
    }
    // console.log("hey", navigator);
  };

  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      title="Brief"
      //   style={{ width: "100vw" }}
    >
      <main
        style={{
          marginTop: "38px",
          display: "grid",
          gap: "20px",
          padding: "0px 24px",
        }}>
        <section
          style={{
            display: "grid",
            gap: "22px",
          }}
          className={styles.subSection}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <p className={styles.requirementHeading}> Requirement</p>
            </div>
            <div className={styles.description}>
              {selectedWorkflow?.deliverable?.deliverablesDetails?.duration &&
                `${
                  selectedWorkflow?.deliverable?.deliverablesDetails?.duration
                }${
                  selectedWorkflow?.deliverable.deliverablesDetails?.typeDel ===
                  "Reel"
                    ? " seconds"
                    : " minutes"
                }`}{" "}
              {/* {selectedWorkflow?.deliverable?.deliverablesDetails?.platform ===
              "youtube"
                ? `${selectedWorkflow?.deliverable?.deliverablesDetails?.type} ${selectedWorkflow?.deliverable?.deliverablesDetails?.typeDel}`
                : selectedWorkflow?.deliverable?.deliverablesDetails?.typeDel} */}
              {selectedWorkflow?.deliverable?.deliverablesDetails?.typeDel}
              {selectedWorkflow?.deliverable?.deliverablesDetails?.platform ===
                "youtube" && <span>Video</span>}
              <div>
                {selectedWorkflow?.deliverable?.deliverablesDetails
                  ?.contentRights
                  ? `[${selectedWorkflow?.deliverable?.deliverablesDetails?.contentRights}] content rights`
                  : ""}
              </div>
            </div>
            <p className={styles.description}>
              {selectedWorkflow?.deliverable?.deliverablesDetails?.description}
            </p>
            {selectedWorkflow?.deliverable?.requirements?.summary && (
              <div
                style={{ wordBreak: "break-all" }}
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: selectedWorkflow?.deliverable?.requirements?.summary,
                }}
              />
            )}
          </div>
          {/* <section>
            <p className={styles.requirementHeading}>
              {" "}
              {workflowData?.data?.deal?.isProduct
                ? "Products"
                : "Promotional Description"}
            </p>
            {!workflowData?.data?.deal?.isProduct ? (
              <p className={styles.description} style={{ marginTop: "4px" }}>
                {workflowData?.data?.deal?.promotionDescription}
              </p>
            ) : (
              <div style={{ marginTop: "4px", display: "grid", gap: "4px" }}>
                {workflowData.data?.deal?.productDetails?.map((item, idx) => {
                  return (
                    <p
                      key={idx}
                      onClick={() => openInAppUrl(item?.productLink)}>
                      <span>
                        {" "}
                        {item?.productTitle} [ ₹{formatPrice(item?.price)}{" "}
                        {"  "}]
                      </span>

                      <AiOutlineLink style={{ marginLeft: "10px" }} />
                    </p>
                  );
                })}
              </div>
            )}
          </section> */}

          {/* <p className={styles.requirementHeading}>References</p>
          <Swiper>
            {selectedWorkflow?.deliverable?.requirements?.references?.map(
              (reference, idx) => {
                return (
                  <Swiper.Item key={idx}>
                    <div className={styles.playerWrapper}>
                      {reference?.type?.includes("video") && (
                        <video
                          loop=""
                          muted={true}
                          src={`${reference.url}#t=2`}
                          controls={true}
                          style={{ objectFit: "cover" }}
                          width={"100%"}
                          height={"150px"}
                        />
                      )}
                      {reference?.type?.includes("image") && (
                        <Image
                          width={"100%"}
                          height={"150px"}
                          src={reference.url}
                          fit="cover"
                          alt="refernce media"
                        />
                      )}
                    </div>
                  </Swiper.Item>
                );
              }
            )}
          </Swiper> */}

          {/* <div>
            <p
              className={styles.requirementHeading}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              Caption
              <Button
                style={{ padding: 0 }}
                fill="none"
                onClick={copyToClipboard}>
                <img src="/assets/icons/copy.svg" alt="copy" />
              </Button>
            </p>
            <Ellipsis
              direction="end"
              style={{
                display: "grid",
                justifyContent: "space-between",
                gridTemplateColumns: "81% 19%",
                alignItems: "flex-end",
                marginTop: "4px",
              }}
              className={styles.bName}
              content={selectedWorkflow?.deliverable?.requirements?.caption}
              expandText="See more"
              collapseText="See Less"
              rows={1}
            />
          </div> */}
          {selectedWorkflow?.deliverable?.requirements?.mentions?.length >
            0 && (
            <div>
              <p className={styles.requirementHeading}>Mentions</p>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  flexWrap: "wrap",
                  marginTop: "4px",
                }}>
                {selectedWorkflow?.deliverable?.requirements?.mentions.map(
                  (item, idx) => {
                    return (
                      <span className={styles.mentionChip} key={idx}>
                        @{item}
                      </span>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </section>
      </main>
    </CustomDrawerWrapper>
  );
};

export default RequirementDrawer;
