import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import ConnectAdviceModal from "./ConnectAdviceModal";

import { getState } from "../../handlers/connectSocial/getState";

import { auth } from "../../Utils/Firebase";

import { getYtOptions } from "./getYtOptions";

const queryString = require("query-string");

const ConnectYoutube = ({ isConnected, isWeb = false }) => {
  const [enabled, setEnabled] = useState(false);
  const { queryKey, queryFunction } = getState(true);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: enabled,
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const url = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
    getYtOptions(false, isWeb, true)
  )}&state=${data?.data?.state}*youtube*${data?.data?.firebaseToken}`;

  const handleConnect = () => {
    // till yt server is down
    // if (isConnected) return null;
    queryClient.invalidateQueries(["state"]);
    setEnabled(true);
  };

  useEffect(() => {
    if (data && enabled) {
      setEnabled(false);

      // open Modal
      setModalOpen(true);
      // openExternalUrl(`${url1}&state=${data.data}`);
    }
  }, [data]);

  return (
    <div
      style={
        isLoading
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "150px",
              boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.14)",
              borderRadius: "8px",
              width: "100%",
              marginRight: "1em",
              backgroundColor: "#f0f0f0",
            }
          : {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "150px",
              boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.14)",
              borderRadius: "8px",
              width: "100%",
              marginRight: "1em",
            }
      }
      onClick={handleConnect}>
      <img
        style={{
          width: "40px",
          height: "40px",
        }}
        src={"./assets/img/youtube.png"}
        alt="youtube"
      />
      <h1
        style={{
          fontWeight: "700",
          fontSize: "16px",
          color: "rgba(0, 0, 0, 0.85)",
          marginTop: "1em",
        }}>
        {isLoading ? "Loading..." : "Youtube"}
      </h1>
      <ConnectAdviceModal
        isOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        url={url}
        platform="Youtube"
      />
    </div>
  );
};

export default ConnectYoutube;
