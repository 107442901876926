import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Popover, Dialog, Toast, Button } from "antd-mobile";

import AddAccountModal from "../AddAccountModal";

import CustomModalWrapper from "../../Common/CustomModalWrapper";

import { getLogo } from "../../../Utils/getLogo";

import { getAccounts } from "../../../handlers/wallet";

const SelectAccountModal = ({
  openModal,
  setOpenModal,
  setSelectedAccount,
  selectedAccount,
}) => {
  const { queryKey, queryFunction } = getAccounts();

  const { data: accountsData } = useQuery(queryKey, queryFunction);

  const [openAddModal, setOpenAddModal] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (openModal) {
      queryClient.invalidateQueries(queryKey);
    }
  }, [openModal]);

  return (
    <CustomModalWrapper
      heading={"Manage Accounts"}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalHeight="70%">
      <div style={{ width: "100%" }}>
        {accountsData.data.fundAccounts
          .filter((accountItem) => accountItem.isActive)
          .map((item, idx) => {
            return (
              <section
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0",
                }}
                key={idx}
                onClick={() => {
                  if (item.status === "Verified") {
                    setSelectedAccount(item);
                    return setOpenModal(false);
                  }
                  return Toast.show({
                    icon: "fail",
                    content: "This account can not be selected",
                  });
                }}>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}>
                  <img
                    src={getLogo(item.fundAccountType, item.bankAccountName)}
                    alt="bank "
                    style={{
                      width: "52px",
                      height: "52px",
                      border: "1px solid #EEEEEE",
                      borderRadius: "4px",
                      objectFit: "contain",
                    }}
                  />
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                      color: "#09121F",
                    }}>
                    {item.fundAccountType === "upi"
                      ? item.vpaAddress
                      : `${
                          item.bankAccountName +
                          " XX " +
                          item.bankAccountNumber.slice(-4)
                        }`}
                    {(item.status === "Verification-Created" ||
                      item.status === "Verification-Requesting") && (
                      <h6 style={{ fontStyle: "italic", color: "#999999" }}>
                        Verification Pending
                      </h6>
                    )}
                    {item.status === "Failed" && (
                      <h6 style={{ color: "red" }}>Error Occurred</h6>
                    )}
                  </div>
                </div>
                {selectedAccount === item && (
                  <img src="/assets/icons/blueTick.svg" alt={"blue tick"} />
                )}
              </section>
            );
          })}
        <div
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "22px",
            color: "#2130FF",
            marginTop: "18px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenAddModal(true);
          }}>
          <img
            src="/assets/icons/bank.svg"
            alt="bank"
            style={{
              height: "52px",
              width: "52px",
              padding: "16px",
              border: "1px dashed #216CFF",
              borderRadius: "4px",
              background: "#FFFFFF",
            }}
          />
          Add Account
        </div>
        <AddAccountModal
          openModal={openAddModal}
          setOpenModal={setOpenAddModal}
        />
      </div>
    </CustomModalWrapper>
  );
};

export default SelectAccountModal;
