import openInAppUrl from "../../Utils/openInAppUrl";

const Redirect = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <h2 onClick={() => openInAppUrl("https://link.cloutflow.com/app")}>
        Open the app
      </h2>
    </div>
  );
};

export default Redirect;
