import React, { useEffect, useState } from "react";
import { format } from "date-fns";
// import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { useInfiniteQuery } from "react-query";
import { Skeleton, Row, Spin, Space, Empty } from "antd";
import { PullToRefresh, DotLoading, InfiniteScroll } from "antd-mobile";
import { IoIosArrowBack } from "react-icons/io";
import { useQueryClient } from "react-query";

import { getAllConversations } from "../../handlers/chat";

import styles from "./sidebar.module.css";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "antd-mobile";
import { useModalContext } from "context/ModalContext";

const Messages = () => {
  const { currentUser, signedIn } = useAuthContext();
  const { queryKey, queryFunction } = getAllConversations();
  const queryClient = useQueryClient();

  const { setMessagesModal, setChatModal } = useModalContext();
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    queryKey,
    queryFunction,
    {
      enabled: signedIn,
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );
  const [convTileData, setConvTileData] = useState();

  useEffect(() => {
    setConvTileData(data);
  }, [data]);

  return (
    <div style={{ overflowY: "hidden" }}>
      <div
        style={{
          height: "100%",
          overflowY: "scroll",
          minHeight: "70vh",
          overflowX: "hidden",
          width: "100%",
        }}>
        {signedIn ? (
          <>
            {isLoading ? (
              <div style={{ padding: "18px", display: "grid", gap: "12px" }}>
                {Array.apply(null, Array(10)).map((_, index) => (
                  <Skeleton
                    avatar={{ shape: "circle" }}
                    paragraph={{ rows: 1 }}
                    key={index}
                  />
                ))}
              </div>
            ) : (
              <PullToRefresh
                onRefresh={async () => {
                  await queryClient.invalidateQueries(queryKey);
                }}
                refreshingText={
                  <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
                }
                canReleaseText={<AiOutlineArrowUp size={"20px"} />}
                pullingText={<AiOutlineArrowDown size={"20px"} />}
                completeText={<div />}>
                <div style={{ minHeight: "85vh" }}>
                  {isLoading ? (
                    <>
                      <span>Loading</span>
                      <DotLoading />
                    </>
                  ) : (
                    <>
                      {convTileData &&
                        convTileData.pages.map((page, i) => (
                          <div key={i}>
                            {page.data.userConversation.length !== 0 ? (
                              page.data.userConversation.map((user) => {
                                return (
                                  user.latestMessage?.data && (
                                    <div
                                      className={styles.chatTile}
                                      onClick={() => {
                                        setChatModal({
                                          open: true,
                                          props: {
                                            conversationId: user.conversationId,
                                            brandId: user.userInfo[0]._id,
                                          },
                                        });
                                        // setConversationId(user.conversationId);
                                        // setBrandId(user.userInfo[0]._id);
                                      }}
                                      key={user.userInfo[0]._id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          gap: "12px",
                                        }}>
                                        <img
                                          src={
                                            user.userInfo[0].businessDetails
                                              .logoUrl
                                          }
                                          alt="profile"
                                          className={styles.profile}
                                        />

                                        <div
                                          style={{
                                            maxWidth: "250px",
                                          }}>
                                          <p className={styles.name}>
                                            {user.userInfo[0].name || "Name"}.
                                          </p>
                                          <p>
                                            {
                                              user.userInfo[0].businessDetails
                                                .name
                                            }
                                          </p>
                                          <p className={styles.message}>
                                            {user.latestMessage.data?.type && (
                                              <>
                                                {user.latestMessage?.data
                                                  ?.type !== "string" ? (
                                                  <>
                                                    {user.latestMessage.data
                                                      .type !== "media" && (
                                                      <>
                                                        {user.latestMessage
                                                          ?.isDeleted
                                                          ? "Deleted Message"
                                                          : currentUser?.uid ===
                                                            user.latestMessage
                                                              .author
                                                          ? (
                                                              user
                                                                ?.latestMessage
                                                                ?.data?.cardName
                                                                ?.me || ""
                                                            ).length > 30
                                                            ? (
                                                                user
                                                                  ?.latestMessage
                                                                  ?.data
                                                                  ?.cardName
                                                                  ?.me || ""
                                                              ).substring(
                                                                0,
                                                                30
                                                              ) + "...."
                                                            : user
                                                                ?.latestMessage
                                                                ?.data?.cardName
                                                                ?.me || ""
                                                          : (
                                                              user
                                                                ?.latestMessage
                                                                ?.data?.cardName
                                                                ?.other || ""
                                                            ).length > 30
                                                          ? (
                                                              user
                                                                ?.latestMessage
                                                                ?.data?.cardName
                                                                ?.other || ""
                                                            ).substring(0, 30) +
                                                            "...."
                                                          : user?.latestMessage
                                                              ?.data?.cardName
                                                              ?.other || ""}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {user.latestMessage
                                                      ?.isDeleted
                                                      ? "Deleted Message"
                                                      : user?.latestMessage
                                                          ?.data?.msg?.length >
                                                        30
                                                      ? user.latestMessage?.data?.msg.substring(
                                                          0,
                                                          30
                                                        ) + "...."
                                                      : user.latestMessage?.data
                                                          ?.msg}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "flex-end",
                                        }}>
                                        {user.latestMessage?.createDateTime && (
                                          <p className={styles.time}>
                                            {format(
                                              new Date(
                                                user.latestMessage.createDateTime
                                              ),
                                              "hh:mm bb"
                                            )}
                                          </p>
                                        )}
                                        {user.unreadCount > 0 && (
                                          <p className={styles.unread}>
                                            {user.unreadCount}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "30px",
                                  alignItems: "center",
                                  height: "70vh",
                                }}>
                                <Empty description={"Empty Inbox"} />
                              </div>
                            )}
                          </div>
                        ))}
                    </>
                  )}
                </div>
                <InfiniteScroll
                  loadMore={() => fetchNextPage()}
                  hasMore={hasNextPage}>
                  <InfiniteScrollContent hasMore={hasNextPage} />
                </InfiniteScroll>
              </PullToRefresh>
            )}
          </>
        ) : (
          <div
            style={{
              borderTop: "1px solid #EEEEEE",
              height: "100%",
              overflowY: "scroll",
              minHeight: "70vh",
              overflowX: "hidden",
              width: "100%",
              padding: "2em",
            }}>
            <p style={{ fontSize: "16px", fontWeight: 500 }}>
              Log in to view your messages
            </p>
            <Button
              size="large"
              style={{
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#2130FF",
                color: "white",
                fontWeight: "600",
                marginTop: "1em",
                maxWidth: "350px",
                minWidth: "5em",
              }}>
              Login
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : (
        <span>--- No More Results ---</span>
      )}
    </>
  );
};
