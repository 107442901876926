import CustomModalWrapper from "components/Common/CustomModalWrapper";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { useState } from "react";
import { Button } from "antd-mobile";
import styles from "./chatInput.module.css";
import { Input } from "antd";
import cuid from "cuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useChatContext } from "context/ChatContext";
import useWindowWidth from "hooks/useWindowWidth";

const { TextArea } = Input;

const SendDocs = ({ openModal, setOpenModal }) => {
  return (
    <CustomModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      heading="Send Documents">
      <SendDocsComponent setOpenModal={setOpenModal} />
    </CustomModalWrapper>
  );
};

export default SendDocs;

const SendDocsComponent = ({ setOpenModal }) => {
  const isDesktop = useWindowWidth();
  const [isUploaded, setIsUploaded] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [messageL, setMessageL] = useState();
  const [mediaName, setMediaName] = useState("");
  const { handleMessageSend } = useChatContext();

  const handleSendMessage = () => {
    let val = "Document";
    if (messageL) {
      val = messageL;
    }
    handleMessageSend(val, "media", imageUrl, "application", mediaName);
    setOpenModal(false);
  };

  const props = {
    name: "file",

    customRequest: (options) => {
      const { onSuccess, file, onProgress, onError } = options;

      const storage = getStorage();
      const fileName = file.name;
      const storageRef = ref(
        storage,
        `/drafts/${fileName.slice(0, 10) + cuid()}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentDisposition: "",
      });
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          onError && onError(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onSuccess && onSuccess(downloadURL);
          setMediaName(fileName);
          setImageUrl(downloadURL);
        }
      );
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setIsUploaded(true);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <div
      style={
        isDesktop
          ? {
              display: "grid",
              height: "100%",
              width: "100%",
              alignItems: "flex-end",
            }
          : {}
      }>
      <div
        style={
          !isDesktop
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }
            : { width: 200, margin: "0 auto" }
        }>
        <Upload
          {...props}
          maxCount={1}
          multiple={false}
          onRemove={(e) => setIsUploaded(false)}>
          <Button>
            Click to Upload
            <UploadOutlined
              style={{
                marginLeft: "10px",
              }}
            />
          </Button>
        </Upload>
      </div>
      <div className={isDesktop ? styles.containerDesktop : styles.container}>
        <TextArea
          placeholder="Add a caption"
          style={{ borderRadius: "8px" }}
          value={messageL}
          defaultValue={""}
          onChange={(event) => setMessageL(event.target.value)}
          onBlur={() => null}
          autoSize={{ minRows: 1, maxRows: 2 }}
        />

        <Button
          type="primary"
          size="md"
          color="primary"
          //ref={areaRef}
          onTouchEnd={() => handleSendMessage()}
          onClick={() => handleSendMessage()}
          disabled={!isUploaded}>
          Send
        </Button>
      </div>
    </div>
  );
};
