import React from "react";
import { Button, SpinLoading } from "antd-mobile";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { formatPrice } from "../../Common/formatPrice";

import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";
import { getTxnDetail } from "../../../handlers/wallet";

import { getLogo } from "../../../Utils/getLogo";

import copyTextToClipboard from "../../../Utils/copyToClipboard";

import useWindowWidth from "hooks/useWindowWidth";

import styles from "./styles.module.css";

const PaymentDetailModal = ({ offerDetail, openModal, handleClose }) => {
  const isDesktop = useWindowWidth();
  const { queryKey, queryFunction } = getTxnDetail({
    type: offerDetail.type,
    amount: offerDetail.amount,
    createDateTime: offerDetail.createDateTime,
    tnxId: offerDetail.tnxId,
    label: offerDetail.label,
    offerId: offerDetail.offerId,
    fundAccountId: offerDetail.fundAccountId,
  });

  const { data: txnData, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: openModal,
  });

  return (
    <>
      <CustomDrawerWrapper
        title={
          offerDetail.isInternal
            ? "Payment Credited"
            : offerDetail.isPositive
            ? "Withdrawal Refunded"
            : "Withdrawal Successful"
        }
        isOpen={openModal}
        handleClose={() => {
          handleClose();
        }}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}>
            <SpinLoading color="primary" />
          </div>
        ) : (
          <div style={{ width: "100%", marginTop: isDesktop ? 0 : "25%" }}>
            {txnData && (
              <div className={styles.container}>
                {offerDetail.isInternal ? (
                  <div className={styles.billTile}>
                    <div style={{ display: "grid", gap: "6px" }}>
                      <div className={styles.amountTile}>
                        <p className={styles.amountDetail}>Amount</p>
                        <p className={styles.amountPrice}>
                          ₹
                          {formatPrice(
                            txnData?.data?.billingInfo?.influencerPrice
                          )}
                        </p>
                      </div>
                      {txnData.data.billingInfo.isTdsCollectedInfluencer && (
                        <div className={styles.amountTile}>
                          <p className={styles.amountDetail}>TDS</p>
                          <p className={styles.amountPrice}>
                            -₹
                            {formatPrice(
                              txnData.data.billingInfo
                                .tdsCollectedInfluencerPrice
                            )}
                          </p>
                        </div>
                      )}
                      {txnData.data.billingInfo.isInfluencerGstApplicible && (
                        <div className={styles.amountTile}>
                          <p className={styles.amountDetail}>GST</p>
                          <p className={styles.amountPrice}>
                            +₹
                            {formatPrice(
                              txnData.data.billingInfo.influencerGstPrice
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className={styles.amountTile}>
                      <p className={styles.total}>Total</p>
                      <p
                        className={styles.total}
                        style={{ textAlign: "right" }}>
                        +₹
                        {formatPrice(
                          txnData.data.billingInfo.paidToInfluencerPrice
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 500,
                      fontSize: "36px",
                      lineHeight: "50px",
                      letterSpacing: "0.02em",
                      marginTop: "100px",
                    }}>
                    ₹{formatPrice(txnData.data.amount)}
                    <img
                      src="/assets/icons/tick.png"
                      alt="tick"
                      style={{ width: "42px", height: "42px" }}
                    />
                  </div>
                )}
                <div className={styles.description}>
                  {offerDetail.isInternal ? "Credited on" : "Completed •"}
                  {format(new Date(), " d LLLL,  yyyy  HH:mm")}
                </div>

                <section className={styles.transCard}>
                  <div>
                    <div className={styles.amountDetail}>
                      {offerDetail.isInternal ? "For" : "From"}
                    </div>
                    <div className={styles.amountTile}>
                      <div>
                        <div className={styles.label}>
                          {txnData.data.from.labelName}
                        </div>
                        <div className={styles.amountDetail}>
                          {txnData.data.from.labelTitle}
                        </div>
                      </div>
                      <img
                        src={getLogo(
                          txnData.data.from.label,
                          txnData.data.from.labelRef
                        )}
                        alt="brand"
                        style={{
                          width: "44px",
                          height: "44px",
                          borderRadius: "8px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.amountDetail}>To your</div>
                    <div className={styles.amountTile}>
                      <div>
                        <div className={styles.label}>
                          {" "}
                          {txnData.data.to.labelName}
                        </div>
                        <div className={styles.amountDetail}>
                          {" "}
                          {txnData.data.to.labelTitle}
                        </div>
                      </div>
                      <img
                        src={getLogo(
                          txnData.data.to.label,
                          txnData.data.to.labelRef
                        )}
                        alt="brand"
                        style={{
                          width: "44px",
                          height: "44px",
                          borderRadius: "8px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className={styles.amountDetail}> Transaction ID</div>
                    <div className={styles.label}>
                      {txnData.data.tnxId}
                      <Button
                        color="primary"
                        fill="none"
                        onClick={async () => {
                          await copyTextToClipboard(txnData?.data?.tnxId);
                          console.log("copied");
                        }}>
                        {" "}
                        Copy
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        )}
      </CustomDrawerWrapper>
    </>
  );
};

export default PaymentDetailModal;
