import { sendRequest } from "../../sendRequest";

export const getCampaignV2 = (campaignId) => {
  const queryKey = ["campaigns", campaignId];
  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/campaign/by-id?campaignId=${campaignId}`,
      {},
      null,
      true
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
