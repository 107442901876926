import { sendRequest } from "../sendRequest";
import { queryParamsArrayBuilder } from "Utils/queryParamsArrayBuilder";

export const getSocialMediaList = ({ platform, connectionStatus = [] }) => {
  const connectionStatusMapped = queryParamsArrayBuilder(
    "connectionStatus",
    connectionStatus
  );
  const queryKey = ["socialMedia", "Connected", connectionStatusMapped];

  const url = `/influencer/social-media/connected?platform=${platform}${
    connectionStatus.length ? `&${connectionStatusMapped}` : ""
  }`;

  const queryFunction = () =>
    sendRequest("get", url, {}, null, true).then((res) => res.data);
  return { queryKey, queryFunction };
};
