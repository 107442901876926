import { useEffect, useState } from "react";

import styles from "./NotificationItem.module.less";

import { Button, Toast } from "antd-mobile";

import { useNavigate } from "react-router-dom";

import { doc, updateDoc } from "firebase/firestore";

import { db } from "Utils/Firebase";

import timeDifference from "Utils/timeDifference";

import { sendRequest } from "handlers/sendRequest";

import { useModalContext } from "../../../context/ModalContext";

import InsightRequestModal from "./Modals/InsightRequestDetail/InsightRequestDetail";

const NotificationItem = ({ data }) => {
  const navigate = useNavigate();

  const { setCampaignModal } = useModalContext();
  const { setOngoingCampaignModal } = useModalContext();

  const [insightReqVisible, setInsightReqVisible] = useState(false);

  const [isRead, setIsRead] = useState(data.data.isRead);

  useEffect(() => {
    setIsRead(data.data.isRead);
  }, [data]);

  const [insightReqAcceptStatus, setInsightReqAcceptStatus] = useState(null);

  const [insightAcceptLoading, setInsightAcceptLoading] = useState(false);

  let containerStyle = {};
  let newNotificationDot = {
    opacity: 0,
  };

  if (!isRead) {
    newNotificationDot = {
      opacity: 1,
    };
    containerStyle = {
      backgroundColor: "#2130ff10",
    };
  }

  const handleClick = async () => {
    if (data.data.type === "Platform:Brand-Ask-Insights") {
      setInsightReqVisible(true);
    } else if (data.data.type === "SaaS:Brand-Ask-Insight") {
      setInsightReqVisible(true);
    } else if (data.data.type === "Brand-Campaign-Invite")
      setCampaignModal({
        open: true,
        props: {
          campaignId: data.data.ref.campaignId,
          dealId: data.data.ref.dealId,
        },
      });
    else if (data.data.type === "Campaign-Started")
      setOngoingCampaignModal({
        open: true,
        props: {
          offerId: data.data.ref.finalizedOfferId,
        },
      });
    else if (data.data.type === "Reminder-Deadline-Today-Upload-Live-Links")
      setOngoingCampaignModal({
        open: true,
        props: {
          offerId: data.data.ref.finalizedOfferId,
        },
      });
    else if (data.data.type === "Reminder-Deadline-Today-Upload-Draft")
      setOngoingCampaignModal({
        open: true,
        props: {
          offerId: data.data.ref.finalizedOfferId,
        },
      });
    else if (data.data.type === "Reminder-Deadline-Approaching-Upload-Draft")
      setOngoingCampaignModal({
        open: true,
        props: {
          offerId: data.data.ref.finalizedOfferId,
        },
      });
    else if (data.data.type === "Kyc-Approved") navigate("/");
    else if (data.data.type === "Payment-Credited") navigate("/wallet");
    else if (data.data.type === "Campaign-Completed") navigate("/wallet");
    // else if (data.data.type === 'Kyc-Issue') {
    // }

    // Logic for isRead
    const notificationRef = doc(db, "Notifications", data.id);

    try {
      await updateDoc(notificationRef, {
        isRead: true,
      });

      setIsRead(true);
    } catch (err) {}
  };

  const getInsightAcceptStatus = async () => {
    let result;
    try {
      result = await sendRequest("put", "/influencer/notification", {
        type: data.data.type,
        ref: { ...data.data.ref },
      });
    } catch (err) {
      //send this error to error logging service
    }

    setInsightReqAcceptStatus(result.data.data.isAccepted);
  };

  //type - 'kyc-issue' has no onClick so it is set isRead automatically after being displayed but is reflected in UI only on revisiting the notification screen
  useEffect(() => {
    const updateIsRead = async () => {
      if (data.data.type === "Kyc-Issue") {
        const notificationRef = doc(db, "Notifications", data.id);

        try {
          await updateDoc(notificationRef, {
            isRead: true,
          });
        } catch (err) {}
      }
    };

    updateIsRead();
  }, []);

  useEffect(() => {
    if (data.data.type === "Platform:Brand-Ask-Insights") {
      getInsightAcceptStatus();
    } else if (data.data.type === "SaaS:Brand-Ask-Insight") {
      getInsightAcceptStatus();
    }
  }, []);

  const acceptInsightRequest = async () => {
    setInsightAcceptLoading(true);

    try {
      if (data.data.type === "SaaS:Brand-Ask-Insight") {
        await sendRequest(
          "patch",
          `/influencer/insight/request/${data.id}/accept`
        );
      } else if (data.data.type === "Platform:Brand-Ask-Insights") {
        await sendRequest(
          "post",
          "/influencer/brand/accept-media-kit-insights-request",
          { ...data.data.ref }
        );
      }
      await getInsightAcceptStatus();
      setInsightAcceptLoading(false);
    } catch (err) {
      setInsightAcceptLoading(false);

      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    }
  };

  const removeInsightRequest = async () => {
    setInsightAcceptLoading(true);

    try {
      if (data.data.type === "SaaS:Brand-Ask-Insight") {
        await sendRequest(
          "patch",
          `/influencer/insight/request/${data.id}/accept`
        );
      } else if (data.data.type === "Platform:Brand-Ask-Insights") {
        await sendRequest(
          "post",
          "/influencer/brand/revoke-media-kit-insights-request",
          { ...data.data.ref }
        );
      }
      await getInsightAcceptStatus();
      setInsightAcceptLoading(false);
    } catch (err) {
      setInsightAcceptLoading(false);

      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    }
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      style={containerStyle}>
      <div className={styles.innerContainer}>
        <div className={styles.row}>
          <div
            className={styles.newNotificationDot}
            style={newNotificationDot}
          />
          {data.data.logo ? (
            <img className={styles.logo} src={data.data.logo} alt="brandLogo" />
          ) : (
            <img className={styles.logo} src="logo.png" alt="brandLogo" />
          )}

          <div className={styles.text}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.data.msg,
              }}></div>
          </div>
        </div>
        {(data.data.type === "Platform:Brand-Ask-Insights" ||
          data.data.type === "SaaS:Brand-Ask-Insight") &&
          insightReqAcceptStatus !== null && (
            <Button
              color="primary"
              fill={insightReqAcceptStatus === false ? "fill" : "none"}
              // onClick={acceptInsightRequest}
              size="small"
              loading={insightAcceptLoading}
              disabled={insightReqAcceptStatus}
              style={{ marginLeft: 10 }}>
              {insightReqAcceptStatus === false ? "Accept" : "Accepted"}
            </Button>
          )}
      </div>
      <span className={styles.timeText}>
        {timeDifference(new Date(), data.data.createdDateTime.toDate())}
      </span>

      <InsightRequestModal
        open={insightReqVisible}
        setOpen={setInsightReqVisible}
        onBtnClick={
          insightReqAcceptStatus ? removeInsightRequest : acceptInsightRequest
        }
        insightAcceptLoading={insightAcceptLoading}
        data={data.data}
        insightReqAcceptStatus={insightReqAcceptStatus}
      />
    </div>
  );
};

export default NotificationItem;
