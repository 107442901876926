import { sendRequest } from "../sendRequest";
export const getAllConversations = () => {
  const queryKey = ["chats"];

  const queryFunction = ({ pageParam }) =>
    sendRequest(
      "get",
      `/influencer/chat/conversation?limit=100${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`
    ).then((res) => {
      return res.data;
    });

  return { queryKey, queryFunction };
};
