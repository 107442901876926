import React, { useState, useEffect } from "react";
import { Button, Dialog } from "antd-mobile";
// import { Space } from "antd-mobile";

import { IoIosArrowBack } from "react-icons/io";

import ConnectPlatformScreen from "components/Onboarding/ConnectPlatformScreen";
import Login from "components/Onboarding/Login";

import { useAuthContext } from "../../context/AuthContext";
import { useNavContext } from "../../context/NavContext";

import HelpModalButton from "../../components/Common/HelpModalButton";

import RegisterProgressBar from "../../components/Common/RegisterProgressBar";

import { LoadingOutlined } from "@ant-design/icons";

const Onboarding = () => {
  const [screen, setScreen] = useState(0);
  const { signedIn, loading } = useAuthContext();

  const { setOpenNav, setNavHeading } = useNavContext();

  useEffect(() => {
    if (!signedIn) {
      return setScreen(0);
    }
    return setScreen(1);
  }, [signedIn]);

  useEffect(() => {
    setOpenNav(false);
    setNavHeading("");
  }, []);

  if (loading) {
    return <LoadingOutlined />;
  }

  return (
    <div
      style={{
        height: "100vh",

        overflow: "scroll",
      }}>
      {screen === 0 ? (
        <></>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "24px 20px 16px 24px",
          }}>
          <Button
            fill="none"
            style={{ padding: "0" }}
            onClick={() => {
              if (screen === 1) {
                return Dialog.confirm({
                  content: "Are you sure you want to go back?",
                  confirmText: "Yes",
                  cancelText: "No",
                  onConfirm: async () => {
                    setScreen((screen) => screen - 1);
                  },
                });
              }
              setScreen((screen) => screen - 1);
            }}>
            <IoIosArrowBack size={24} />
          </Button>
          <HelpModalButton />
        </div>
      )}

      {/* {screen !== 0 && <RegisterProgressBar currIndex={screen} />} */}

      <div style={{ padding: "0 24px", marginTop: "24px" }}>
        {screen === 0 ? (
          <Login />
        ) : screen === 1 ? (
          <ConnectPlatformScreen setScreen={setScreen} isWeb />
        ) : (
          <div>Hurray Screen</div>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
