import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import AuthoriseInstagram from "../screens/Public/AuthoriseInstagram";
import SucessConnect from "screens/Public/SucessConnected";
import Error from "components/Common/Error";
import AuthoriseYoutube from "screens/Public/AuthoriseYoutube";
import { SpinLoading } from "antd-mobile";
import AppLinkIOS from "../screens/Public/AppLinkIOS";

import CustomSignIn from "../components/CustomSignIn";

const PublicRoutes = () => {
  return (
    <ErrorBoundary
      FallbackComponent={<Error />}
      onReset={window.location.reload}>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}>
            <SpinLoading color="primary" />
          </div>
        }>
        <Routes>
          {/* Instargam routes */}

          <Route
            path="/public/authorise/instagram"
            element={<AuthoriseInstagram />}
          />
          <Route
            path="/public/reauthorise/instagram"
            element={<AuthoriseInstagram isReconnect />}
          />
          <Route
            path="/public/authorise/instagram/web"
            element={<AuthoriseInstagram isWeb={true} />}
          />
          <Route
            path="/public/reauthorise/instagram/web"
            element={<AuthoriseInstagram isReconnect isWeb={true} />}
          />
          <Route
            path="/public/authorise/instagram/sucess"
            element={<SucessConnect />}
          />
          <Route
            path="/public/authorise/instagram/sucess/web"
            element={<SucessConnect isWeb={true} />}
          />

          {/* Youtube routes */}

          <Route
            path="/public/authorise/youtube"
            element={<AuthoriseYoutube />}
          />
          <Route
            path="/public/authorise/youtube/web"
            element={<AuthoriseYoutube isWeb={true} />}
          />
          <Route
            path="/public/reauthorise/youtube"
            element={<AuthoriseYoutube isReconnect={true} />}
          />
          <Route
            path="/public/reauthorise/youtube/web"
            element={<AuthoriseYoutube isReconnect={true} isWeb={true} />}
          />

          <Route path="/public/custom/" element={<CustomSignIn />} />
          <Route path="/public/custom/:uid" element={<CustomSignIn />} />

          {/* <Route
            path="/.well-known/apple-app-site-association"
            element={<AppLinkIOS />}
          /> */}
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default PublicRoutes;
