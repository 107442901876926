import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { MessageOutlined } from "@ant-design/icons";
import { MdChatBubbleOutline } from "react-icons/md";

import { useModalContext } from "../../context/ModalContext";
import { useNavigate } from "react-router-dom";

import CustomDrawerWrapper from "../Common/CustomDrawerWrapper";
import { getWorkFlowDetailsV2 } from "../../handlers/campaign/workflows/v2/getWorkFlowDetailsV2";

// import OngoingCampaignDetail from "../Dashboard/OngoingCampaignDetail";
import OngoingCampaignDetailUpdated from "../Dashboard/OngoingCampaignDetailUpdated";

import useWindowWidth from "hooks/useWindowWidth";

// import Test from "../Test";

const OngoingCampaignDrawer = ({ openModalProps, setOpenModalProps }) => {
  const isDesktop = useWindowWidth();
  const navigate = useNavigate();

  const { queryKey, queryFunction } = getWorkFlowDetailsV2(
    openModalProps.props.offerId
  );
  const { data: workflowData } = useQuery(queryKey, queryFunction, {
    enabled: Boolean(openModalProps.props.offerId),
  });

  const { setChatModal } = useModalContext();

  useEffect(() => {
    if (isDesktop && openModalProps.open) {
      navigate(`/ongoingCampaign/${openModalProps.props.offerId}`);

      setOpenModalProps((openModalProps) => {
        return { ...openModalProps, open: false };
      });
    }
  }, [isDesktop, openModalProps.open]);

  return (
    <CustomDrawerWrapper
      isOpen={openModalProps.open && !isDesktop}
      handleClose={() =>
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        })
      }
      title={"Ongoing Campaign"}
      secondaryButton={<MdChatBubbleOutline style={{ fontSize: "20px" }} />}
      secondaryButtonOnClick={() =>
        workflowData &&
        setChatModal({
          open: true,
          props: {
            brandId: workflowData?.data.brand._id,
          },
        })
      }>
      {openModalProps?.open && (
        <OngoingCampaignDetailUpdated
          finalizedOfferId={openModalProps.props.offerId}
        />
      )}
    </CustomDrawerWrapper>
  );
};

export default OngoingCampaignDrawer;
