import ReactPlayer from "react-player";
import React from "react";
import { format } from "date-fns";
import { Button } from "antd-mobile";

import CustomDrawerWrapper from "../../../Common/CustomDrawerWrapper";

import { getStreamableUrl } from "../../../../Utils/getStreamableUrl";
import openExternalUrl from "../../../../Utils/openExternalUrl";

import styles from "../styles.module.css";

const LiveTimeline = ({ handleClose, isOpen, selectedWorkflow }) => {
  return (
    <CustomDrawerWrapper
      handleClose={handleClose}
      isOpen={isOpen}
      title="Live Timeline">
      {selectedWorkflow && (
        <div
          style={{
            borderLeft: "1px solid #2130FF",
            display: "grid",
            gap: "24px",
            margin: "20px 0",
            marginLeft: "16px",
          }}>
          {selectedWorkflow?.liveTimeline?.map((item, idx) => {
            return (
              <>
                {item && (
                  <div
                    style={{
                      paddingLeft: "10px",
                    }}
                    key={idx}>
                    <p
                      className={
                        item?.type === "Grey"
                          ? styles.lastSubTileHeading
                          : styles.subTileHeading
                      }
                      style={{ marginBottom: "2px" }}>
                      {item?.name}
                    </p>
                    {item?.type !== "Grey" && (
                      <>
                        <p
                          className={styles.subTileDescription}
                          style={{ marginBottom: "2px" }}>
                          {format(
                            new Date(item?.createDateTime),
                            "d MMM , yyyy "
                          ) +
                            "at" +
                            format(
                              new Date(item?.createDateTime),
                              " h:mm aaaa"
                            )}
                        </p>
                        {(item?.type === "Live-Require-Changes" ||
                          item?.type === "Draft-Require-Changes") && (
                          <p className={styles.description}>{item?.message}</p>
                        )}
                      </>
                    )}
                    {item?.message && (
                      <div className={styles.changesContainer}>
                        {item?.message}
                      </div>
                    )}
                    {item?.references &&
                      item?.references?.map((item, idx) => {
                        return (
                          <div key={idx} style={{ margin: "8px 0" }}>
                            {item?.type === "video" ? (
                              <div className={styles.video}>
                                {item?.platform === "youtube" ? (
                                  <ReactPlayer
                                    controls={true}
                                    light={true}
                                    url={item.url}
                                    volume={1}
                                    width="100%"
                                    height="100%"
                                  />
                                ) : (
                                  <video
                                    loop=""
                                    muted={true}
                                    src={`${getStreamableUrl(item?.url)}#t=2`}
                                    controls={true}
                                    style={{
                                      objectFit: "cover",
                                    }}
                                    width={"100%"}
                                    height={"100%"}
                                  />
                                )}
                              </div>
                            ) : item.type === "link" ? (
                              <Button
                                color="primary"
                                onClick={() => openExternalUrl(item.url)}>
                                Open Media
                              </Button>
                            ) : (
                              <div className={styles.image}>
                                <img
                                  className={styles.draftImage}
                                  src={getStreamableUrl(item?.url)}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            );
          })}
        </div>
      )}
    </CustomDrawerWrapper>
  );
};

export default LiveTimeline;
