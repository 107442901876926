import axios from "axios";

import { logBackend } from "./logBackend";

export const getGlobalLocation = () => {
  const queryKey = ["user-location"];
  const queryFunction = () => {
    return axios({
      method: "get",
      url: "https://ipapi.co/json",
      data: {},
    })
      .catch(async (err) => {
        // if (process.env.REACT_APP_DEV_ERROR_SHOW) {
        //   var message = "";
        //   if (err?.response) {
        //     message = err?.response?.data?.message;
        //   } else if (err?.request) {
        //     message = "Request Error";
        //   } else {
        //     message = "No Defined";
        //   }
        // }
        await logBackend("https://ipapi.co/json", {}, JSON.stringify(err));
        throw err;
      })
      .then((res) => res.data);
  };

  return { queryKey, queryFunction };
};
