import React, { useState, useEffect } from "react";
import { Button, SpinLoading, PullToRefresh, DotLoading } from "antd-mobile";
import { useQuery } from "react-query";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import Lottie from "react-lottie";

import { useQueryClient } from "react-query";

import Chip from "../OngoingCampaignDetail/Chip";

import DeliverableTimeline from "../OngoingCampaignDetail/DeliverableTimeline";

import { getWorkFlowDetailsV2 } from "handlers/campaign/workflows/v2/getWorkFlowDetailsV2";

import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { TbCalendarTime } from "react-icons/tb";

import animationData from "../../../assets/lottie/waitingTimer.json";

import { useModalContext } from "context/ModalContext";

import styles from "./styles.module.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const getChipColor = (status) => {
  if (status === "Awaiting Brief" || status === "Waiting For Approval")
    return "blue";
  if (status === "Upload Draft" || status === "Upload Live") return "orange";
  return "green";
};

const OngoingCampaignDetailUpdated = ({ finalizedOfferId }) => {
  const queryClient = useQueryClient();
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const { queryKey, queryFunction } = getWorkFlowDetailsV2(finalizedOfferId);

  const { setCampaignModal } = useModalContext();

  const {
    data: workflowData,
    isLoading,
    isError,
  } = useQuery(queryKey, queryFunction);

  useEffect(() => {
    if (workflowData && selectedWorkflow) {
      const updatedWorkflow = workflowData?.data?.workflows?.find(
        (workflowItem) =>
          workflowItem?.workflowId === selectedWorkflow?.workflowId
      );
      if (updatedWorkflow) {
        setSelectedWorkflow(updatedWorkflow);
      } else {
        setSelectedWorkflow(null);
      }
    }
  }, [workflowData]);

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SpinLoading color="primary" />
      </div>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <PullToRefresh
      onRefresh={async () => {
        await queryClient.invalidateQueries(queryKey);
      }}
      refreshingText={
        <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
      }
      canReleaseText={<AiOutlineArrowUp size={"20px"} />}
      pullingText={<AiOutlineArrowDown size={"20px"} />}
      completeText={<div />}>
      <div style={{ position: "relative", background: "black" }}>
        <img
          src={workflowData?.data?.campaign?.campaignBannerUrl}
          className={styles.banner}
          alt="banner"
        />
        <div className={styles.header}>
          <img
            src={workflowData?.data?.brand?.businessDetails?.logoUrl}
            className={styles.logo}
            alt="logo"
          />
          <section className={styles.cDetail}>
            <div>
              <h4 className={styles.title}>
                {workflowData?.data?.brand?.businessDetails?.name}
              </h4>
              <p className={styles.desc} style={{ marginBottom: "2px" }}>
                {workflowData?.data?.campaign?.campaignName}
              </p>
            </div>
            <img
              src={`/assets/img/${workflowData?.data?.campaign?.platform}-disabled.png`}
              style={{ width: "24px", height: "24px" }}
              alt="platform"
            />
          </section>
          <Button
            color="primary"
            fill="none"
            style={{
              textDecoration: "underline",
              padding: 0,
              marginTop: "12px",
            }}
            onClick={() =>
              setCampaignModal({
                open: true,
                props: {
                  campaignId: workflowData?.data?.campaign?._id,
                  dealId: workflowData?.data?.deal?._id,
                },
              })
            }>
            Campaign details
          </Button>
          <section className={styles.deliverables}>
            <div className={styles.title}> Your Deliverables</div>
            <div
              style={{
                display: "grid",
                gap: "12px",
                marginTop: "20px",
              }}>
              {workflowData?.data?.workflows.map((workflowItem, idx) => {
                return (
                  <div className={styles.tile} key={idx}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        if (!selectedWorkflow) {
                          return setSelectedWorkflow(workflowItem);
                        }
                        if (
                          workflowItem?.workflowId ===
                          selectedWorkflow?.workflowId
                        ) {
                          return setSelectedWorkflow(null);
                        }
                        return setSelectedWorkflow(workflowItem);
                      }}>
                      <span className={styles.title} style={{ font: "400" }}>
                        {
                          workflowItem?.deliverable?.deliverablesDetails
                            ?.typeDel
                        }{" "}
                        {/* {" #"}
                        {""}
                        {workflowItem?.deliverable?.deliverablesDetails?.count} */}
                      </span>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "flex-start",
                        }}>
                        <div>
                          <Chip
                            color={getChipColor(workflowItem?.workflowStatus)}
                            title={workflowItem?.workflowStatus}
                          />

                          <h4
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "17px",
                            }}>
                            {workflowItem?.workflowClosureDate && (
                              <>
                                <TbCalendarTime
                                  size={"16px"}
                                  style={{ marginRight: "2px" }}
                                />
                                Deadline: {workflowItem?.workflowClosureDate}
                              </>
                            )}
                          </h4>
                        </div>
                        {selectedWorkflow &&
                        workflowItem?.workflowId ===
                          selectedWorkflow?.workflowId ? (
                          <BiChevronUp
                            size={"24px"}
                            color={"rgba(0, 0, 0, 0.5)"}
                          />
                        ) : (
                          <BiChevronDown
                            size={"24px"}
                            color={"rgba(0, 0, 0, 0.3)"}
                          />
                        )}
                      </div>
                    </div>
                    {selectedWorkflow &&
                      workflowItem?.workflowId ===
                        selectedWorkflow?.workflowId && (
                        <DeliverableTimeline
                          selectedWorkflow={selectedWorkflow}
                          finalizedOfferId={finalizedOfferId}
                          workflowData={workflowData}
                          queryKey={queryKey}
                          socialMediaId={workflowData?.data?.socialMediaKitId}
                        />
                      )}
                  </div>
                );
              })}
            </div>
          </section>

          {workflowData?.data?.workflows?.length === 0 && (
            <section className={styles.congratsSection}>
              <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                style={{ margin: "50px auto 0" }}
              />

              <div style={{ marginBottom: "6px" }}>Campaign Started</div>
              <p>Waiting for Brand to send Deliverables</p>
            </section>
          )}

          {workflowData?.data?.isCompleted && (
            <section className={styles.congratsSection}>
              <img
                alt="completed"
                src="/assets/img/completed.png"
                style={{ height: "126px", width: "120px", margin: "0 auto" }}
              />
              <div style={{ marginBottom: "6px" }}>Campaign Complete</div>
              <p>Your payment will be credited to your wallet</p>
            </section>
          )}
        </div>
      </div>
    </PullToRefresh>
  );
};

export default OngoingCampaignDetailUpdated;
