import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import UDashboard from "../screens/Unregisterd/UDashboard";
import Onboarding from "../screens/Unregisterd/Onboarding";
import OnboardingNew from "../components/Onboarding/OnboardingNew";
import OnboardingTut from "../screens/Unregisterd/OnboardingTut";
import { SpinLoading } from "antd-mobile";
import { Navigate } from "react-router-dom";

const RegisterRoute = ({ setOpenRegisterModal }) => {
  return (
    <ErrorBoundary FallbackComponent={Error} onReset={window.location.reload}>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}>
            <SpinLoading color="primary" />
          </div>
        }>
        <Routes>
          <Route path="/" element={<OnboardingTut />} />
          <Route
            path="/explore"
            element={<UDashboard setOpenRegisterModal={setOpenRegisterModal} />}
          />
          <Route path="/profile" element={<Navigate to="/" replace={true} />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/onboardingNew" element={<OnboardingNew />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default RegisterRoute;
