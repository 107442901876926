import { Picker } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { useState } from "react";

const GenderSelector = ({
  value,
  setValue,
  errorText,
  error,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        maxWidth: "350px",
        width: "100%",
        marginTop: "10px",
      }}
    >
      <div style={{ fontSize: "14px", fontWeight: "500" }}>Gender</div>
      <div
        onClick={() => !disabled && setOpen(true)}
        style={{
          width: "100%",
          height: "48px",
          borderRadius: "8px",
          border: "1.5px solid #d9d9d9",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          color: disabled && "rgba(0,0,0,.25)",
          backgroundColor: disabled && "#f5f5f5",
          boxShadow: disabled && "none",
          cursor: disabled && "not-allowed",
          opacity: disabled && "1",
        }}
      >
        <div style={{ fontSize: "14px", fontWeight: "500" }}>
          {gender[0].find((o) => (o.value === value ? value : null)).label}
        </div>
        <DownOutline />
      </div>
      <Picker
        columns={gender}
        visible={open}
        onClose={() => setOpen(false)}
        value={value}
        onConfirm={(v) => {
          setValue(v[0]);
        }}
        cancelText="Cancel"
        confirmText="Confirm"
      />
      <div>
        {errorText && <h6 style={{ color: "red" }}>{errorText}</h6>}
        {error?.type === "isNone" && (
          <h6 style={{ color: "red" }}>Please select your gender</h6>
        )}
      </div>
    </div>
  );
};

export default GenderSelector;

const gender = [
  [
    {
      label: "None",
      value: "None",
    },
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ],
];
