import { sendRequest } from "../sendRequest";

export const getAccounts = () => {
  const queryKey = ["wallet", "accounts"];
  const queryFunction = () =>
    sendRequest("get", `/influencer/wallet/razorpay/linked-account`).then(
      (res) => res.data
    );
  return { queryKey, queryFunction };
};
