import React from "react";
import { Popup, Button } from "antd-mobile";
import { MdArrowBackIos } from "react-icons/md";
import { SwipeableDrawer } from "@mui/material";
import { Modal } from "antd";
import useWindowWidth from "../../hooks/useWindowWidth";

const CustomDrawerWrapper = ({
  isOpen,
  handleClose,
  children,
  hasHeader = false,
  header,
  closeButton = "",
  secondaryButton = <div />,
  secondaryButtonOnClick = () => {},
  footer = <div />,
  title = "",
}) => {
  const isDesktop = useWindowWidth();

  if (isDesktop) {
    return (
      <Modal
        centered
        destroyOnClose
        open={isOpen}
        closable={false}
        width={1100}
        title={
          <>
            {hasHeader ? (
              header
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "12px 20px",
                  // position: "fixed",
                  // top: "0px",
                  width: "100%",
                  // zIndex: "1000",
                  backgroundColor: "white",
                }}>
                {/* <Button
                  type="button"
                  fill="none"
                  style={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "20px",
                  }}
                  onClick={handleClose}>
                  {!closeButton ? (
                    <MdArrowBackIos
                      // size="14px"
                      style={{ height: "14px", width: "14px" }}
                      // style={{ position: "absolute", top: "-10px" }}
                    />
                  ) : (
                    { closeButton }
                  )}
                </Button> */}
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#09121F",
                  }}>
                  {title}
                </div>

                <div>
                  <Button
                    type="button"
                    fill="none"
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "20px",
                    }}
                    // onTouchEnd={secondaryButtonOnClick}
                    onClick={secondaryButtonOnClick}>
                    {secondaryButton}
                  </Button>
                </div>
              </div>
            )}
          </>
        }
        onCancel={() => handleClose()}
        footer={footer}>
        <div
          style={{
            overflowY: "auto",
            height: "70vh",
            // paddingTop: "52px",
          }}>
          {children}
        </div>
      </Modal>
    );
  }

  return (
    <Popup
      visible={isOpen}
      position="right"
      onClose={() => {}}
      bodyStyle={{
        width: "100vw",
        background: "transparent",
      }}
      destroyOnClose
      style={{ background: "transparent" }}
      maskStyle={{ background: "transparent" }}>
      <div style={{ position: "relative" }}>
        <SwipeableDrawer
          anchor="right"
          open={isOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "100vw",
              zIndex: "1000",
              position: "absolute",
              height: "100%",
            },
          }}
          ModalProps={{ closeAfterTransition: true }}
          transitionDuration={200}
          style={{
            zIndex: "1000",

            height: "100%",
            width: "100vw",
          }}
          variant="temporary"
          hideBackdrop
          disableBackdropTransition>
          {hasHeader ? (
            header
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px 20px",
                position: "fixed",
                top: "0px",
                width: "100%",
                zIndex: "1000",
                backgroundColor: "white",
              }}>
              <Button
                type="button"
                fill="none"
                style={{
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "20px",
                }}
                onClick={handleClose}>
                {!closeButton ? (
                  <MdArrowBackIos
                    // size="14px"
                    style={{ height: "14px", width: "14px" }}
                    // style={{ position: "absolute", top: "-10px" }}
                  />
                ) : (
                  { closeButton }
                )}
              </Button>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#09121F",
                }}>
                {title}
              </div>

              <div>
                {
                  <Button
                    type="button"
                    fill="none"
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "20px",
                    }}
                    onTouchEnd={secondaryButtonOnClick}>
                    {secondaryButton}
                  </Button>
                }
              </div>
            </div>
          )}

          <div
            style={{
              overflowY: "auto",
              height: "100vh",
              paddingTop: "52px",
            }}>
            {children}
          </div>
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100vw",
            }}>
            {footer}
          </div>
        </SwipeableDrawer>
      </div>
    </Popup>
  );
};

export default CustomDrawerWrapper;
