import { sendRequest } from "../sendRequest";
import { queryParamsArrayBuilder } from "Utils/queryParamsArrayBuilder";

export const getUserSocial = (connectionStatus = []) => {
  const connectionStatusMapped = queryParamsArrayBuilder(
    "connectionStatus",
    connectionStatus
  );
  const queryKey = ["userSocial", connectionStatusMapped];
  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/social-media/connected${
        connectionStatus?.length ? `?${connectionStatusMapped}` : ""
      }`,
      {},
      null,
      true
    )
      .then((res) => res.data)
      .catch((err) => console.log(err));
  return { queryKey, queryFunction };
};
