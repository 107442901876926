import { sendRequest } from "../sendRequest.js";

export const getUCampaigns = () => {
  const queryKey = ["unregistered", "campaigns"];
  const queryFunction = ({ pageParam }) =>
    sendRequest(
      "get",
      `/public/campaign?limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
