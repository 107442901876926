import { Dialog, DotLoading, Toast, Button, Tag, Space } from "antd-mobile";
import { useQuery, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { getUserSocial } from "handlers/me/getSocial";
import useDisconnectSocial from "handlers/connectSocial/disconnectSocial";
import { getSocialMediaList } from "handlers/connectSocial/getSocialMediaList";
import { getState } from "handlers/connectSocial/getState";

import HelpModalButton from "../Common/HelpModalButton";

import ConnectYoutube from "../ConnectPlatform/ConnectYoutube";
import ConnectInstagramCard from "../ConnectPlatform/ConnectInstagramCard";
import AddPlatformModal from "../VerifyPlatforms/AddPlatformModal";
import SendMessageTemplate from "./SendMessageTemplate";

import { useModalContext } from "context/ModalContext";

import openExternalUrl from "Utils/openExternalUrl";

import { auth } from "Utils/Firebase";

const checkIsNull = (obj) => {
  if (obj && obj !== "null" && obj !== "undefined") return false;
  return true;
};

const ConnectPlatform = ({ isWeb = false }) => {
  const { queryKey: socialQueryKey, queryFunction: socialQueryFunction } =
    getUserSocial(["Connected", "Session-Expired"]);

  const [addPlatformProps, setAddPlatformProps] = useState({
    visible: false,
    platform: "",
    askMessaging: false,
  });

  const { queryKey: socialMediaListInstagramKey } = getSocialMediaList({
    platform: "instagram",
    connectionStatus: ["Connected", "Session-Expired"],
  });
  const { queryKey: socialMediaListYoutubeKey } = getSocialMediaList({
    platform: "youtube",
    connectionStatus: ["Connected", "Session-Expired"],
  });

  const { data: socialData, isLoading: isSocialLoading } = useQuery(
    socialQueryKey,
    socialQueryFunction
  );

  // const [isInstagramConnected, setIsInstagramConnected] = useState(false);
  // const [isYoutubeConnected, setIsYoutubeConnected] = useState(false);

  const queryClient = useQueryClient();

  const isTestUser = auth?.currentUser?.phoneNumber === "+911234567899";

  // useEffect(() => {
  //   if (socialData) {
  //     socialData.data.forEach((item) => {
  //       if (item.socialMediaPlatform === "instagram") {
  //         setIsInstagramConnected(true);
  //       }
  //       if (item.socialMediaPlatform === "youtube") {
  //         setIsYoutubeConnected(true);
  //       }
  //     });
  //   }
  // }, [socialData]);

  const handleInvalidate = () => {
    queryClient.invalidateQueries(socialQueryKey);
    queryClient.invalidateQueries(socialMediaListInstagramKey);
    queryClient.invalidateQueries(socialMediaListYoutubeKey);
  };

  return (
    <div style={{ width: "100%", maxWidth: "500px", height: "100%" }}>
      {isSocialLoading ? (
        <DotLoading />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "90%",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {socialData?.data?.length === 0 && (
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Connect the platforms that you want to work with.
              </h1>
            )}

            {socialData?.data?.map((item) => {
              return (
                <ConnectedCard
                  item={item}
                  handleInvalidate={handleInvalidate}
                  setAddPlatformProps={setAddPlatformProps}
                  isTestUser={isTestUser}
                />
              );
            })}
          </div>
          <div style={{ width: "100%" }}>
            <Button
              style={{ marginBottom: 10 }}
              block
              onClick={() =>
                setAddPlatformProps({
                  visible: true,
                  platform: "instagram",
                  askMessaging: isTestUser,
                })
              }
            >
              Add Instagram Account
            </Button>
            <Button
              block
              onClick={() =>
                setAddPlatformProps({ visible: true, platform: "youtube" })
              }
            >
              Add Youtube Account
            </Button>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <ConnectInstagramCard
                isConnected={isInstagramConnected}
                isWeb={isWeb}
              />
              <ConnectYoutube isConnected={isYoutubeConnected} isWeb={isWeb} />
            </div> */}
            {/* <HelpModalButton
              message={
                <div
                  style={{
                    marginTop: "2em",
                    color: "#999999",
                    textAlign: "center",
                    fontSize: "12px",
                  }}>
                  Having trouble Connecting? Contact us
                </div>
              }
            /> */}
          </div>
        </div>
      )}
      <AddPlatformModal
        visible={addPlatformProps.visible}
        platform={addPlatformProps.platform}
        askMessagingPermission={addPlatformProps?.askMessaging || false}
        handleClose={() => {
          setAddPlatformProps({ visible: false, platform: "instagram" });
          queryClient.invalidateQueries(socialMediaListInstagramKey);
          queryClient.invalidateQueries(socialMediaListYoutubeKey);
        }}
      />
    </div>
  );
};

export default ConnectPlatform;

const ConnectedCard = ({
  item,
  handleInvalidate,
  setAddPlatformProps,
  isTestUser = false,
}) => {
  // const queryClient = useQueryClient();

  const [isOptionOpen, setIsOptionOpen] = useState(
    // item.deliverablePriceRequest.status === "Not-Initiated" ? true : false
    false
  );

  const [messageAutomationProps, setMessageAutomationProps] = useState({
    visible: false,
    socialMediaId: undefined,
  });
  // console.log(item.deliverablePriceRequest.status);
  const type = item.socialMediaPlatform;
  const { isSuccess, isError, data, mutateAsync } = useDisconnectSocial();
  const {
    setIgCommercialsModal,
    setYoutubeCommercialsModal,
    setMediaKitModal,
  } = useModalContext();

  useEffect(() => {
    if (isSuccess) {
      if (data?.data?.status === "ERR_IN_CAMPAIGN") {
        if (!checkIsNull(data?.data?.data?.inBrandCommunity)) {
          Dialog.alert({
            content:
              "Can’t disconnect, your profile is a part of Community. If you wish to disconnect this profile, you need to first leave Community",
            confirmText: "OK",
          });
          return;
        }
        Dialog.alert({
          content:
            "Can’t disconnect, your profile is linked with campaigns right now. If you wish to disconnect this profile, you need to withdraw any applications and complete any ongoing campaigns.",
          confirmText: "OK",
        });
        return;
      }
      if (data?.data?.status === "SUCCESS") {
        handleInvalidate();
        Toast.show({
          content: "Disconnected",
        });
      }
    }
    if (isError) {
      Toast.show({
        content: "Some error occured",
      });
    }
  }, [isSuccess, isError]);

  const handleDisconnect = (item) => {
    Dialog.confirm({
      content: (
        <h1 style={{ fontSize: "14px", fontWeight: "400" }}>
          Are you sure you want to disconnect this profile? <br /> Your profile
          will be removed from our database.
          <br /> You can connect again anytime.
        </h1>
      ),
      cancelText: "Cancel",
      confirmText: "Disconnect",
      onConfirm: async () => {
        await mutateAsync({
          data: {
            socialMediaKitId: item._id,
          },
        });
      },
    });
  };

  const { queryKey, queryFunction } = getState(true);
  const { data: appStateData, isLoading } = useQuery(queryKey, queryFunction);

  const url = `${process.env.REACT_APP_CONNECT_URL}/v1?authCode=${appStateData?.data?.firebaseToken}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "8px",
        boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.14)",
        width: "100%",
        marginBottom: "1em",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "10px 16px 10px 16px",
        }}
        onClick={() => {
          // if (item.dataProvider === "default") {
          setIsOptionOpen(!isOptionOpen);
          // }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={item?.[type]?.user?.profilePicUrl}
              alt="icon"
              style={{
                width: "53px",
                height: "53px",
                marginRight: "1em",
                borderRadius: "53px",
                maxWidth: "60%",
              }}
            />
            <div>
              <h1
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#09121F",
                }}
              >
                {type === "instagram" && "@"}
                {item?.socialMediaName}
              </h1>
              <Space>
                {/* {item.deliverablePriceRequest.status === "Not-Initiated" && (
                  <Tag
                    color={"#C03316"}
                    round
                    style={{ padding: "5px", margin: "0.5em 0" }}>
                    Add Commercials
                  </Tag>
                )} */}
                {item.connectionStatus === "Session-Expired" && (
                  <Tag
                    color={"red"}
                    round
                    style={{ padding: "5px", margin: "0.5em 0" }}
                  >
                    Revoked
                  </Tag>
                )}
                {item.dataProvider !== "default" && (
                  <Tag
                    color={"warning"}
                    round
                    style={{ padding: "5px", margin: "0.5em 0" }}
                  >
                    Not Verified
                  </Tag>
                )}

                {item.dataProvider === "default" &&
                  item.canSendIgMessageStatus === "Success" &&
                  item.socialMediaPlatform === "instagram" &&
                  isTestUser && (
                    <Tag
                      color={"success"}
                      round
                      style={{ padding: "5px", margin: "0.5em 0" }}
                    >
                      Messaging Enabled
                    </Tag>
                  )}
              </Space>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              style={{ width: "20px", height: "20px", marginRight: "1em" }}
              src={
                type === "instagram"
                  ? "./assets/img/instagram.png"
                  : "./assets/img/youtube.png"
              }
              alt="ig"
            />
            <div
              style={{
                height: "100%",
                width: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setIsOptionOpen(!isOptionOpen)}
            >
              {isOptionOpen ? <AiFillCaretUp /> : <AiFillCaretDown />}
            </div>
          </div>
        </div>
        {/* {item.deliverablePriceRequest.status === "Not-Initiated" && (
          <h5
            style={{
              textAlign: "center",
              marginTop: "10px",
              color: "#2130FF",
            }}>
            Add your commercials to continue
          </h5>
        )} */}
      </div>
      {isOptionOpen && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            borderTop: "1px solid #E5E5E5",
          }}
        >
          {/* <h1
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#09121F",
              padding: "10px 16px 10px 16px",
              width: "100%",
              textAlign: "center",
              borderBottom: "1px solid #E5E5E5",
              margin: 0,
            }}
            onClick={() =>
              setMediaKitModal({
                open: true,
                props: {
                  platform: type,
                  creatorId: item._id,
                },
              })
            }>
            View Profile
          </h1> */}
          {/* <h1
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#09121F",
              padding: "10px 16px 10px 16px",
              width: "100%",
              textAlign: "center",
              outline:
                item.deliverablePriceRequest.status === "Not-Initiated"
                  ? "2px solid #2075f3"
                  : "1px solid transparent",
              borderBottom: "1px solid #E5E5E5",
              borderRadius: "4px",
              margin: 0,
            }}
            onClick={() =>
              handleOpenCharges(
                item,
                setIgCommercialsModal,
                setYoutubeCommercialsModal
              )
            }>
            Add Commercials
          </h1> */}
          <h1
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#09121F",
              padding: "10px 16px 10px 16px",
              width: "100%",
              textAlign: "center",
              margin: 0,
            }}
            onClick={() => {
              if (isLoading) {
                return;
              }
              openExternalUrl(url);
            }}
          >
            {isLoading
              ? "Loading..."
              : item.dataProvider !== "default"
              ? "Connect Now"
              : "Manage Platform"}
          </h1>
          {item.dataProvider === "default" &&
            item.canSendIgMessageStatus !== "Success" &&
            item.socialMediaPlatform === "instagram"}

          {item.dataProvider === "default" &&
            item.canSendIgMessageStatus === "Success" &&
            item.socialMediaPlatform === "instagram"}
        </div>
      )}
      <SendMessageTemplate
        handleClose={() => {
          setMessageAutomationProps({
            visible: false,
            socialMediaId: undefined,
          });
        }}
        socialMediaId={messageAutomationProps.socialMediaId}
        visible={messageAutomationProps.visible}
      />
    </div>
  );
};

const handleOpenCharges = (
  item,
  setIgCommercialsModal,
  setYoutubeCommercialsModal
) => {
  if (item.socialMediaPlatform === "youtube") {
    setYoutubeCommercialsModal({
      open: true,
      props: {
        socialMediaKitId: item._id,
      },
    });
    return null;
  }
  if (item.socialMediaPlatform === "instagram") {
    setIgCommercialsModal({
      open: true,
      props: {
        socialMediaKitId: item._id,
      },
    });
    return null;
  }
};
