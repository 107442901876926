import React, { useState } from "react";
import { FileTextOutlined, DownloadOutlined } from "@ant-design/icons";
import { Popover, SpinLoading } from "antd-mobile";
import { BiChevronDown, BiDownload } from "react-icons/bi";
import { CgCloseO } from "react-icons/cg";
import { useMutation, useQueryClient } from "react-query";

import handleDownload from "../../../../Utils/handleDownload";
import { handleConcat } from "../../../../Utils/handleConcat";

import openInAppUrl from "../../../../Utils/openInAppUrl";

import { deleteMessage, getAllConversations } from "../../../../handlers/chat";

import { useChatContext } from "../../../../context/ChatContext";

import styles from "../message.module.css";

const MediaCard = ({ item }) => {
  const [downloading, setDownloading] = useState(false);
  const { setMessages } = useChatContext();
  const queryClient = useQueryClient();
  const { queryKey: getAllConversationsKey } = getAllConversations();

  const actions = [
    {
      key: "download",
      text: "Download",
    },
    item.isAuthor && {
      key: "delete",
      text: "Delete Message",
    },
  ];

  const deleteMessageMutation = useMutation(deleteMessage, {
    onSuccess: () => {
      setMessages((messages) =>
        messages.map((message) =>
          message._id !== item._id ? message : { ...message, isDeleted: true }
        )
      );
      queryClient.invalidateQueries(getAllConversationsKey);
    },
    onError: () => {
      console.log("error occurred");
    },
  });

  const onAction = (node) => {
    if (node.key === "delete") {
      return deleteMessageMutation.mutate({ messageId: item._id });
    }
    if (node.key === "download") {
      setDownloading(true);
      new Promise((_) => setTimeout(() => setDownloading(false), 3000));
      if (window.ReactNativeWebView) {
        return handleDownload(item.mediaUrl, item.mediaName);
      }
      return openInAppUrl(item.mediaUrl);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
        }}>
        {!item.isDeleted && (
          <Popover.Menu
            actions={actions}
            placement="bottom-start"
            onAction={(node) => onAction(node)}
            trigger="click">
            {downloading ? (
              <SpinLoading style={{ "--size": "14px" }} />
            ) : (
              <BiChevronDown size={14} />
            )}
          </Popover.Menu>
        )}
      </div>
      <div style={{ margin: "0.5em" }}>
        {item.isDeleted ? (
          <p className={styles.deletedMessage}>
            <CgCloseO style={{ marginRight: "4px" }} />
            Message Deleted
          </p>
        ) : (
          <>
            {item.mediaType === "video" && (
              <video
                loop=""
                muted={true}
                src={`${item?.mediaUrl}#t=2`}
                controls={true}
                style={{ objectFit: "cover", borderRadius: "8px" }}
                width={"100%"}
                height={"100%"}
              />
            )}
            {item.mediaType === "image" && (
              <img
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                src={item?.mediaUrl}
                onClick={() => {
                  item?.mediaUrl && openInAppUrl(item.mediaUrl);
                }}
                alt="Attachment"
              />
            )}
            {item.mediaType === "application" && (
              <div
                style={{
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#f0f0f0",
                  position: "relative",
                }}
                onClick={() => openInAppUrl(item.mediaUrl)}>
                <p style={{ color: "black", marginRight: "5px" }}>
                  {handleConcat(
                    item.mediaName,
                    window?.screen?.availWidth < 300 ? 14 : 22
                  )}
                </p>
                <FileTextOutlined
                  style={{
                    fontSize: "16px",
                    color: "black",
                    marginLeft: "10px",
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MediaCard;
