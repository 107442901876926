import React, { useState } from "react";
import { Button, Toast, SpinLoading } from "antd-mobile";
import { Row } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";

import useUpdateMe from "handlers/me/useUpdateMe";
import { getUser } from "handlers/me/getUser";
import { acceptOffer, getCampaignOfferPriceV2 } from "handlers/campaign/offers";

import CustomModalWrapper from "../../../Common/CustomModalWrapper";
import CustomInput from "components/Common/CustomInput";
import PriceDetailCard from "../../PriceDetailCard";

const AcceptModal = ({
  open,
  setOpen,
  offerId,

  handleInvalidate,
}) => {
  const queryClient = useQueryClient();
  const acceptOfferMutation = useMutation(acceptOffer, {
    onSuccess: () => {
      setOpen(false);
      handleInvalidate();

      Toast.show({
        icon: "success",
        content: "Offer Accepted",
      });
      queryClient.invalidateQueries(["campaigns"]);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  // const { queryKey, queryFunction } = getUser();

  // const { data: meData, isLoading: isMeDataLoading } = useQuery(
  //   queryKey,
  //   queryFunction
  // );

  // const {
  //   mutate: updateMe,
  //   isLoading: isUpdateLoading,
  //   isError,
  // } = useUpdateMe();

  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues: {
  //     city: meData?.data?.deliveryAddress?.city || "",
  //     country: meData?.data?.deliveryAddress?.country || "",
  //     line: meData?.data?.deliveryAddress?.line || "",
  //     state: meData?.data?.deliveryAddress?.state || "",
  //     pincode: meData?.data?.deliveryAddress?.pincode || "",
  //   },
  // });

  // const [addressUpdate, setAddressUpdate] = useState(false);

  const submitAccept = (data) => {
    // console.log(data);

    // if (addressUpdate) {
    //   return updateMe(
    //     {
    //       data: {
    //         deliveryAddress: {
    //           line: data.line,
    //           city: data.city,
    //           state: data.state,
    //           pincode: data.pincode,
    //           country: data.country,
    //         },
    //       },
    //     },
    //     {
    //       onSuccess: () => {
    //         acceptOfferMutation.mutate({ offerId });
    //       },
    //     },
    //     {
    //       onError: () => {
    //         Toast.show({
    //           icon: "fail",
    //           content: "Some error Occurred",
    //         });
    //       },
    //     }
    //   );
    // }
    return acceptOfferMutation.mutate({ offerId });
  };

  const { queryKey: commercialKey, queryFunction: commercialFn } =
    getCampaignOfferPriceV2({
      offerId,
      isCampaignId: false,
    });

  const { data: commercialData, isLoading: isCommercialLoading } = useQuery(
    commercialKey,
    commercialFn,
    {
      enabled: Boolean(open),
    }
  );

  return (
    <CustomModalWrapper
      openModal={open}
      setOpenModal={setOpen}
      heading={"Accept Offer"}>
      <div style={{ width: "100%" }}>
        <p style={{ fontSize: 12, color: " #999999" }}>
          Please review all the details before applying.
        </p>
        <span
          style={{
            display: "block",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: 12,
            color: " #999999",
          }}>
          Your Commercial
        </span>

        {isCommercialLoading && (
          <Row justify="center">
            <SpinLoading />
          </Row>
        )}

        {commercialData?.data && (
          <PriceDetailCard commercial={commercialData?.data} />
        )}

        {/* {addressUpdate && (
          <Button
            color="primary"
            fill="none"
            onClick={() => setAddressUpdate(false)}
            size="mini"
            style={{
              padding: 0,
              display: "block",
              textAlign: "left",
              marginTop: "20px",
            }}>
            Don't Update Address
          </Button>
        )} */}
        {/* <span
          style={{
            display: "block",
            marginTop: "20px",
            fontSize: 12,
            color: " #999999",
          }}>
          Delivery Address
        </span> */}
        <div style={{ marginBottom: "60px" }}>
          {/* {addressUpdate ? (
            <>
              <Controller
                control={control}
                name="line"
                rules={{
                  required: { value: true, message: "This is required" },
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    onChange={onChange}
                    value={value}
                    placeholder="Address"
                    containerStyle={{ width: "100%", maxWidth: "100%" }}
                    errorText={errors?.line?.message}
                    multiline
                    rows={1}
                  />
                )}
              />

              <Row justify="space-between">
                <Controller
                  control={control}
                  name="country"
                  rules={{
                    required: { value: true, message: "This is required" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      onChange={onChange}
                      value={value}
                      placeholder="Country"
                      containerStyle={{ width: "46%" }}
                      errorText={errors?.country?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="state"
                  rules={{
                    required: { value: true, message: "This is required" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      onChange={onChange}
                      value={value}
                      placeholder="State"
                      containerStyle={{ width: "46%" }}
                      errorText={errors?.state?.message}
                    />
                  )}
                />
              </Row>

              <Row justify="space-between">
                <Controller
                  control={control}
                  name="city"
                  rules={{
                    required: { value: true, message: "This is required" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      onChange={onChange}
                      value={value}
                      placeholder="City"
                      containerStyle={{ width: "46%" }}
                      errorText={errors?.city?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="pincode"
                  rules={{
                    required: { value: true, message: "This is required" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      onChange={onChange}
                      value={value}
                      placeholder="Pin code"
                      type="number"
                      pattern="\d*"
                      containerStyle={{ width: "46%" }}
                      errorText={errors?.pincode?.message}
                    />
                  )}
                />
              </Row>
            </>
          ) : (
            <>
              {meData?.data?.deliveryAddress?.line},{" "}
              {meData?.data?.deliveryAddress?.city},{" "}
              {meData?.data?.deliveryAddress?.state},{" "}
              {meData?.data?.deliveryAddress?.country}-{" "}
              {meData?.data?.deliveryAddress?.pincode}
              <Button
                color="primary"
                fill="none"
                onClick={() => setAddressUpdate(true)}
                size="mini"
                style={{ padding: 0, display: "block" }}>
                Change Address
              </Button>
            </>
          )} */}
        </div>
        {/* <CustomSwipe
          text="Swipe to Accept"
          loading={acceptOfferMutation.isLoading}
          onSubmit={handleSubmit(submitAccept)}
          containerStyle={{ margin: "0 auto" }}
          error={
            Object.keys(errors).length > 0 ||
            acceptOfferMutation.isError ||
            useUpdateMe.isError
              ? true
              : false
          }
        /> */}
        <Button
          loading={acceptOfferMutation.isLoading}
          // onClick={handleSubmit(submitAccept)}
          // onClick={handleSubmit(submitAccept)}
          onClick={() => submitAccept()}
          color="primary"
          fill="solid"
          block>
          Accept Offer
        </Button>
      </div>
    </CustomModalWrapper>
  );
};

export default AcceptModal;
