import React from "react";
import { Modal, Space } from "antd-mobile";
import { useQuery } from "react-query";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import { getState } from "handlers/connectSocial/getState";

import { getYtOptions } from "../../ConnectPlatform/getYtOptions";
import { getIgPermissions } from "Utils/getIgPermssions";
import openExternalUrl from "Utils/openExternalUrl";

import { auth } from "Utils/Firebase";

import styles from "../styles.module.css";

const queryString = require("query-string");

const AddPlatformModal = ({
  visible,
  handleClose,
  platform,
  askMessagingPermission = false,
}) => {
  const { queryKey, queryFunction } = getState(true);
  const { data, isLoading } = useQuery(queryKey, queryFunction, {
    enabled: visible,
  });

  const ytUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString.stringify(
    getYtOptions(false, false, true)
  )}&state=${data?.data?.state}*youtube*${data?.data?.firebaseToken}`;

  const igPermissions = getIgPermissions(askMessagingPermission);

  const fbUrl = `https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=${
    process.env.REACT_APP_FB_KEY
  }&redirect_uri=${
    process.env.REACT_APP_CONNECT_URL
  }/public/accounts&scope=${igPermissions}&state=${
    data?.data?.state
  }*instagram*${data?.data?.firebaseToken}*${
    askMessagingPermission ? "advanced" : "basic"
  }`;

  return (
    <Modal
      visible={visible}
      showCloseButton
      onClose={() => handleClose()}
      content={
        <div style={{ padding: "0 18px" }}>
          <p className={styles.powered}>
            {" "}
            Powered by{" "}
            {platform === "instagram" ? (
              <img
                className={styles.meta}
                src="/assets/img/metaOnlyLogo.png"
                alt="meta"
              />
            ) : (
              <img
                className={styles.meta}
                src="/assets/img/googleLogoFull.png"
                alt="google"
                style={{ height: 16, width: 50 }}
                // height={16}
                // width={50}
              />
            )}
          </p>
          <p className={styles.desc}>
            💁🏽{" "}
            {platform === "instagram"
              ? "You need a business/creator Instagram account and the account connected with a Facebook page to connect."
              : " Youtube connection is handled by Google, to continue sign in with google."}
          </p>
        </div>
      }
      actions={[
        {
          key: "SignIn",
          text: isLoading ? (
            "Loading..."
          ) : (
            <div
              style={{
                fontSize: "16",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}>
              {platform === "instagram" ? (
                <FaFacebook size={"18"} />
              ) : (
                <FcGoogle size={"18"} />
              )}
              Continue with {platform === "instagram" ? "Facebook" : "Google"}
            </div>
          ),
          primary: true,
          disabled: isLoading,
          style:
            platform === "youtube"
              ? {
                  background: "white",
                  color: "rgb(51, 51, 51)",
                  borderColor: "rgba(51, 51, 51,0.2)",
                }
              : {},

          onClick: () => {
            openExternalUrl(platform === "instagram" ? fbUrl : ytUrl);
            setTimeout(() => {
              handleClose();
            }, 2000);
          },
        },
      ]}
      closeOnMaskClick
    />
  );
};

export default AddPlatformModal;
