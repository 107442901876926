import React from "react";
import { Progress } from "antd";

import styles from "../demographic.module.css";

const StatsCard = ({ title, items }) => {
  return (
    <div className={styles.card}>
      <p className={styles.title}>{title}</p>
      <section>
        {items.map((item) => {
          return (
            <div>
              <p className={styles.label}>{item.label}</p>
              <Progress
                percent={Number(item.percent)}
                status="active"
                strokeColor={"#2130FF"}
                format={(percent) => (
                  <span className={styles.linePercent}> {percent}%</span>
                )}
                // success={{ percent: 100, strokeColor: "#2130FF" }}
              />
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default StatsCard;
