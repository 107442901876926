import React, { useState } from "react";

import PaymentDetailModal from "../PaymentDetailModal";
import EarningTimeline from "../EarningTimeline";

import styles from "./tile.module.css";

const Tile = ({
  heading,
  description,
  logo,
  priceString,
  priceDesc,
  offer,
  isEarning,
  internalIdempotentKey,
}) => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isTimelineOpen, setTimelineOpen] = useState(false);

  return (
    <>
      <section
        className={styles.container}
        onClick={() => {
          if (isEarning) {
            return setTimelineOpen(true);
          }
          return setIsDetailModalOpen(true);
        }}>
        <div style={{ display: "flex", gap: "12px" }}>
          <img src={logo} alt="payment " className={styles.image} />
          <div>
            <div className={styles.heading}>{heading}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>

        <div>
          <div className={styles.heading}>{priceString}</div>
          <div
            className={styles.description}
            style={{
              textAlign: "right",
              color: priceDesc === "Cancelled" ? "red" : "#999999",
            }}>
            {priceDesc}
          </div>
        </div>
      </section>
      {!isEarning ? (
        <PaymentDetailModal
          // openModal={isDetailModalOpen}
          // setOpenModal={setIsDetailModalOpen}
          // offerDetail={offer}
          openModal={isDetailModalOpen}
          handleClose={() => setIsDetailModalOpen(false)}
          offerDetail={offer}
        />
      ) : (
        <EarningTimeline
          openModal={isTimelineOpen}
          setOpenModal={setTimelineOpen}
          handleClose={() => setTimelineOpen(false)}
          internalIdempotentKey={internalIdempotentKey}
        />
      )}
    </>
  );
};

export default Tile;
