import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import { Divider, Radio, Button, Toast } from "antd-mobile";
import { Controller, useForm } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { updateKyc } from "../../../handlers/me/updateKYC";
import { getUser } from "../../../handlers/me/getUser";
import { getUserStatus } from "../../../handlers/me/getUserStatus";

import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";

import { useAuthContext } from "../../../context/AuthContext";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import CustomInput from "./CustomInput";
import cuid from "cuid";
import useUserStatusHook from "screens/Common/useUserStatusHook";

const primaryLabelStyle = {
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "19px",
  color: " #09121F",
};

const secondaryLabelStyle = {
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#999999",
  marginTop: "2px ",
  marginBottom: "8px",
};

const uploadButton = <div>{<PlusOutlined />}</div>;

const KycDrawer = ({ isOpen, handleClose }) => {
  const { queryKey: userStatusKey } = getUserStatus();
  const { userStatusCode } = useUserStatusHook("none");
  // console.log("from sddsds", userStatusCode);

  const { queryKey, queryFunction } = getUser();

  const { data: meData } = useQuery(queryKey, queryFunction, {
    enabled: isOpen,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentAddress: {
        address: meData?.data?.deliveryAddress?.line || null,
        country: meData?.data?.deliveryAddress?.country || null,
        state: meData?.data?.deliveryAddress?.state || null,
        city: meData?.data?.deliveryAddress?.city || null,
        pin: meData?.data?.deliveryAddress?.pincode || null,
      },
      billingAddress: {
        address: meData?.data?.kyc?.kycDetails?.address?.line || null,
        country: meData?.data?.kyc?.kycDetails?.address?.country || null,
        state: meData?.data?.kyc?.kycDetails?.address?.state || null,
        city: meData?.data?.kyc?.kycDetails?.address?.city || null,
        pin: meData?.data?.kyc?.kycDetails?.address?.pincode || null,
      },
      gstRegistered: meData?.data?.kyc?.kycDetails?.isGstRegistered || null,
      panNo: meData?.data?.kyc?.kycDetails?.pan || null,
      cName: meData?.data?.kyc?.kycDetails?.companyName || null,
      gst: meData?.data?.kyc?.kycDetails?.gst || null,
      cPan: meData?.data?.kyc?.kycDetails?.pan || null,
    },
  });

  const [verificationDocs, setVerificationDocs] = useState({
    pan: [],
    gstRef: [],
    cPanRef: [],
  });

  const { currentUser } = useAuthContext();
  const queryClient = useQueryClient();

  const updateKycMutation = useMutation(updateKyc, {
    onSuccess: () => {
      Toast.show({
        icon: "success",
        content: "KYC updated",
      });
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(userStatusKey);
      handleClose();
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  useEffect(() => {
    if (meData) {
      const temp = {
        pan: meData?.data?.kyc?.kycDetails?.panVerificationDocRef || [],
        gstRef: meData?.data?.kyc?.kycDetails?.gstVerificationDocRef || [],
        cPanRef: meData?.data?.kyc?.kycDetails?.panVerificationDocRef || [],
      };

      setVerificationDocs(temp);
    }
  }, [meData]);

  const watchGST = watch("gstRegistered", null);

  const onSubmitHandler = (data) => {
    if (userStatusCode === "Kyc-Not-Initiated") {
      updateKycMutation.mutate({
        kycDetails: {
          pan: watchGST ? data?.cPan : data?.panNo,
          panVerificationDocRef: verificationDocs[watchGST ? "pan" : "cPanRef"],
          isGstRegistered: watchGST,
          gst: data.gst || "",
          gstVerificationDocRef: verificationDocs["gstRef"],
          companyName: data.cName || "",
          address: !watchGST
            ? {
                line: data.currentAddress.address,
                city: data.currentAddress.city,
                pincode: data.currentAddress.pin,
                country: data.currentAddress.country,
                state: data.currentAddress.state,
              }
            : {
                line: data.billingAddress.address || "",
                city: data.billingAddress.city || "",
                pincode: data.billingAddress.pin || "",
                country: data.billingAddress.country || "",
                state: data.billingAddress.state || "",
              },
        },
        deliveryAddress: {
          line: data.currentAddress.address,
          city: data.currentAddress.city,
          pincode: data.currentAddress.pin,
          country: data.currentAddress.country,
          state: data.currentAddress.state,
        },
      });
    } else handleClose();
  };

  const getUploadButtonProps = (docName) => {
    return {
      name: "file",
      multiple: false,
      onDrop(e) {},
      customRequest: (options) => {
        const { onSuccess, file, onProgress, onError } = options;
        const storage = getStorage();
        const imageName = file.name;
        const storageRef = ref(
          storage,
          `/influencer/${currentUser?.uid}/documents/${docName + cuid()}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file, {
          contentDisposition: "",
        });
        // setLoading(true);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            onProgress &&
              onProgress({
                percent:
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              });
          },
          (error) => {
            console.log("firebaseError", error.code, error.message);
            onError && onError(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            // console.log("image uploaded", downloadURL);

            onSuccess && onSuccess(downloadURL);
            setVerificationDocs((verificationDocs) => {
              return { ...verificationDocs, [docName]: [downloadURL] };
            });
          }
        );
      },
      accept: "image/png, image/jpeg, application/pdf",
      onRemove(file) {
        setVerificationDocs((verificationDocs) => {
          return { ...verificationDocs, [docName]: [] };
        });
      },
    };
  };

  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      closable={false}
      title={"KYC"}
      footer={
        userStatusCode === "Kyc-Not-Initiated" ? (
          <Button
            color="primary"
            block
            onClick={handleSubmit(onSubmitHandler)}
            loading={updateKycMutation.isLoading}>
            Submit
          </Button>
        ) : (
          <div />
        )
      }>
      <div style={{ padding: "24px", marginTop: "30px" }}>
        {userStatusCode === "Kyc-Not-Initiated" && (
          <h1
            style={{
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "34px",
              color: "#09121F",
              margin: "0",
              marginBottom: "4px",
            }}>
            KYC
          </h1>
        )}
        <h2>
          {userStatusCode === "Kyc-Needs-Attention" &&
            "There has been some error with your KYC verification. Someone from our side will call you for further proceeding"}
          {userStatusCode === "Kyc-Under-Review" && "Your KYC is under review"}
        </h2>
        {userStatusCode === "Kyc-Not-Initiated" && (
          <>
            <Divider style={{ margin: "22px 0 33px" }} />
            <Controller
              control={control}
              name="currentAddress.address"
              rules={{
                required: { value: true, message: "Address is required" },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  label="Enter your current address"
                  label2="Any products or shipments would be sent to this address"
                  placeholder="Complete Address"
                  value={value}
                  onChange={onChange}
                  errorText={errors?.currentAddress?.address?.message}
                />
              )}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Controller
                control={control}
                name="currentAddress.country"
                rules={{
                  required: { value: true, message: "Country is required" },
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    placeholder="Country"
                    inputWidth="48%"
                    value={value}
                    onChange={onChange}
                    errorText={errors?.currentAddress?.country?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="currentAddress.state"
                rules={{
                  required: { value: true, message: "State is required" },
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    placeholder="State"
                    inputWidth={"48%"}
                    value={value}
                    onChange={onChange}
                    errorText={errors?.currentAddress?.state?.message}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Controller
                control={control}
                name="currentAddress.city"
                rules={{
                  required: { value: true, message: "City is required" },
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    placeholder="City"
                    inputWidth="48%"
                    value={value}
                    onChange={onChange}
                    errorText={errors?.currentAddress?.city?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="currentAddress.pin"
                rules={{
                  required: { value: true, message: "Pin is required" },
                  maxLength: 6,
                  minLength: 6,
                  validate: (value) => value.length === 6,
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    placeholder="PIN code"
                    inputWidth={"48%"}
                    value={value}
                    onChange={onChange}
                    errorText={errors?.currentAddress?.pin?.message}
                    type="number"
                    maxLength={6}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name="gstRegistered"
              // rules={{
              //   required: { value: true, message: "Please select Gst type" },
              // }}
              render={({ field: { onChange, value } }) => (
                <>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "19px",
                      color: "#09121F",
                      margin: "40px 0 12px ",
                    }}>
                    Are you GST registered?
                  </div>
                  <Radio.Group value={value} onChange={onChange}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Radio value={true}> Yes </Radio>
                      <Radio value={false}> No </Radio>
                    </div>
                  </Radio.Group>
                </>
              )}
            />

            {watchGST !== null && (
              <>
                {watchGST ? (
                  <div
                    style={{ display: "grid", gap: "16px", marginTop: "32px" }}>
                    <Controller
                      control={control}
                      name="cName"
                      rules={{
                        required: {
                          value: true,
                          message: "Company Name is required",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <CustomInput
                          label="Registered Company Name"
                          placeholder="Enter Company Name"
                          value={value}
                          onChange={onChange}
                          errorText={errors?.cName?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="gst"
                      rules={{
                        required: { value: true, message: "GST is required" },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <CustomInput
                          label="Enter GST no."
                          placeholder="GST no."
                          value={value}
                          onChange={onChange}
                          errorText={errors?.gst?.message}
                        />
                      )}
                    />

                    <div>
                      <div style={primaryLabelStyle}>
                        Upload GST certification
                      </div>
                      <div style={secondaryLabelStyle}>
                        Upload your GST certificate
                      </div>
                      <Upload
                        listType="picture-card"
                        {...getUploadButtonProps("gstRef")}
                        fileList={
                          verificationDocs["gstRef"].length > 0
                            ? [
                                {
                                  name: decodeURIComponent(
                                    verificationDocs["gstRef"][0]
                                  )
                                    .split("/")
                                    .at(-1)
                                    .split("?")[0],
                                  status: "done",
                                  url: verificationDocs["gstRef"][0],
                                  uid: verificationDocs["gstRef"][0],
                                },
                              ]
                            : undefined
                        }>
                        {!verificationDocs["gstRef"].length > 0 && uploadButton}
                      </Upload>
                    </div>

                    <Controller
                      control={control}
                      name="cPan"
                      rules={{
                        required: { value: true, message: "PAN is required" },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <CustomInput
                          label="Company PAN no."
                          placeholder="Enter PAN"
                          value={value}
                          onChange={onChange}
                          errorText={errors?.cPan?.message}
                        />
                      )}
                    />
                    <div>
                      <div style={primaryLabelStyle}>Upload Company PAN</div>
                      <div style={secondaryLabelStyle}>
                        Upload a clear picture of your company PAN card
                      </div>
                      <Upload
                        listType="picture-card"
                        {...getUploadButtonProps("cPanRef")}
                        fileList={
                          verificationDocs["cPanRef"].length > 0
                            ? [
                                {
                                  name: decodeURIComponent(
                                    verificationDocs["cPanRef"][0]
                                  )
                                    .split("/")
                                    .at(-1)
                                    .split("?")[0],
                                  status: "done",
                                  url: verificationDocs["cPanRef"][0],
                                  uid: verificationDocs["cPanRef"][0],
                                },
                              ]
                            : undefined
                        }>
                        {!verificationDocs["cPanRef"].length > 0 &&
                          uploadButton}
                      </Upload>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="billingAddress.address"
                        rules={{
                          required: {
                            value: true,
                            message: "Address is required",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomInput
                            label="Billing Address"
                            placeholder="Complete Address"
                            value={value}
                            onChange={onChange}
                            errorText={errors?.billingAddress?.address?.message}
                          />
                        )}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Controller
                          control={control}
                          name="billingAddress.country"
                          rules={{
                            required: {
                              value: true,
                              message: "Country is required",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <CustomInput
                              placeholder="Country"
                              inputWidth="48%"
                              value={value}
                              onChange={onChange}
                              errorText={
                                errors?.billingAddress?.country?.message
                              }
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="billingAddress.state"
                          rules={{
                            required: {
                              value: true,
                              message: "State is required",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <CustomInput
                              placeholder="State"
                              inputWidth={"48%"}
                              value={value}
                              onChange={onChange}
                              errorText={errors?.billingAddress?.state?.message}
                            />
                          )}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Controller
                          control={control}
                          name="billingAddress.city"
                          rules={{
                            required: {
                              value: true,
                              message: "City is required",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <CustomInput
                              placeholder="City"
                              inputWidth="48%"
                              value={value}
                              onChange={onChange}
                              errorText={errors?.billingAddress?.city?.message}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="billingAddress.pin"
                          rules={{
                            required: {
                              value: true,
                              message: "Pin is required",
                            },
                            maxLength: 6,
                            minLength: 6,
                            validate: (value) => value.length === 6,
                          }}
                          render={({ field: { onChange, value } }) => (
                            <CustomInput
                              placeholder="PIN code"
                              inputWidth={"48%"}
                              value={value}
                              onChange={onChange}
                              errorText={errors?.billingAddress?.pin?.message}
                              type="number"
                              maxLength={6}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ display: "grid", gap: "16px", marginTop: "32px" }}>
                    <Controller
                      control={control}
                      name="panNo"
                      rules={{
                        required: { value: true, message: "PAN is required" },
                      }}
                      render={({ field, formState }) => (
                        <>
                          <CustomInput
                            label="Enter PAN no."
                            placeholder="Enter PAN"
                            value={field.value}
                            onChange={field.onChange}
                            errorText={errors?.panNo?.message}
                          />
                        </>
                      )}
                    />
                    <div>
                      <div style={primaryLabelStyle}>Upload PAN</div>
                      <div style={secondaryLabelStyle}>
                        Upload a clear picture of your PAN card
                      </div>
                      <Upload
                        listType="picture-card"
                        {...getUploadButtonProps("pan")}
                        fileList={
                          verificationDocs["pan"].length > 0
                            ? [
                                {
                                  name: decodeURIComponent(
                                    verificationDocs["pan"][0]
                                  )
                                    .split("/")
                                    .at(-1)
                                    .split("?")[0],
                                  status: "done",
                                  url: verificationDocs["pan"][0],
                                  uid: verificationDocs["pan"][0],
                                },
                              ]
                            : undefined
                        }>
                        {!verificationDocs["pan"].length > 0 && uploadButton}
                      </Upload>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </CustomDrawerWrapper>
  );
};

export default KycDrawer;
