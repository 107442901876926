import { sendRequest } from "../sendRequest";

export const getUserProfile = (userId) => {
  const queryKey = ["chat", userId];
  const queryFunction = () =>
    sendRequest("get", `/influencer/chat/user-profile?userId=${userId}`).then(
      (res) => res.data
    );

  return { queryKey, queryFunction };
};
