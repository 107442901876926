import { sendRequest } from "../sendRequest";

export const getTxnDetail = ({
  type,
  offerId,
  fundAccountId,
  label,
  amount,
  createDateTime,
  tnxId,
}) => {
  const queryKey = [
    "wallet",
    "tnx",
    type,
    offerId,
    fundAccountId,
    label,
    amount,
    createDateTime,
    tnxId,
  ];
  const url = `/influencer/wallet/recent/tnx-details?type=${type}${
    offerId ? `&offerId=${offerId}` : ""
  }&label=${label}&amount=${amount}&createDateTime=${createDateTime}&tnxId=${tnxId}${
    fundAccountId ? `&fundAccountId=${fundAccountId}` : ""
  }`;

  const queryFunction = () => sendRequest("get", url).then((res) => res.data);
  return { queryKey, queryFunction };
};
