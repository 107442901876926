import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { format } from "date-fns";
import { useInfiniteQuery } from "react-query";
import { SpinLoading } from "antd-mobile";

import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";
import Tile from "../Tile";
import PaymentDetailModal from "../PaymentDetailModal";

import { getEarnings } from "../../../handlers/wallet";

const EarningsDrawer = ({ isOpen, handleClose }) => {
  const { queryKey: earningsKey, queryFunction: earningsFn } = getEarnings();
  const {
    data: earningsData,
    isLoading: isEarningsDataLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(earningsKey, earningsFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      title={"Your Earnings"}>
      {isEarningsDataLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinLoading color="primary" />
        </div>
      )}
      {earningsData && (
        <InfiniteScroll
          loadMore={() => {
            fetchNextPage();
          }}
          hasMore={hasNextPage}
          loader={<h4>Loading data...</h4>}>
          {earningsData.pages.map((pageItem) => {
            return pageItem.data.earningsTnx.map((item, idx) => {
              return (
                <Tile
                  key={idx}
                  description={format(new Date(item.timestamp), "d MMMM,uuuu")}
                  heading={item.brand.businessDetails.name}
                  logo={item.brand.businessDetails.logoUrl}
                  priceDesc={item.status}
                  priceString={item.amount}
                  internalIdempotentKey={item.internalIdempotentKey}
                  isEarning={true}
                />
              );
            });
          })}
        </InfiniteScroll>
      )}
    </CustomDrawerWrapper>
  );
};

export default EarningsDrawer;
