import { sendRequest } from "../sendRequest";

export const getEarningsTnxDetail = ({ internalIdempotentKey }) => {
  const queryKey = ["wallet", "earnings", "tnx", internalIdempotentKey];

  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/wallet/earnings/tnx-details?internalIdempotentKey=${internalIdempotentKey}`
    ).then((res) => res.data);
  return { queryKey, queryFunction };
};
