import React from "react";
import { format, eachDayOfInterval } from "date-fns";
import { Popover } from "antd-mobile";
import styles from "./message.module.css";
import MediaCard from "./Cards/MediaCard";
import { useAuthContext } from "context/AuthContext";
import { BsCheckAll } from "react-icons/bs";
import { BiTime, BiChevronDown } from "react-icons/bi";
import clsx from "clsx";
import { useMutation } from "react-query";
import { CgCloseO } from "react-icons/cg";
import { useQueryClient } from "react-query";

import { useChatContext } from "../../../context/ChatContext";

import { deleteMessage, getAllConversations } from "../../../handlers/chat";

const Message = ({
  messageItem,
  previousMessageAuthor,
  currentRef,
  previousMessageDate,
}) => {
  const { currentUser } = useAuthContext();
  const { setMessages } = useChatContext();

  const queryClient = useQueryClient();

  const { queryKey: getAllConversationsKey } = getAllConversations();

  const resultArrayLength = (
    eachDayOfInterval({
      start: previousMessageDate,
      end: new Date(messageItem.createDateTime),
    }) || []
  ).length;

  const deleteMessageMutation = useMutation(deleteMessage, {
    onSuccess: () => {
      setMessages((messages) =>
        messages.map((message) =>
          message._id !== messageItem._id
            ? message
            : { ...message, isDeleted: true }
        )
      );
      queryClient.invalidateQueries(getAllConversationsKey);
    },
    onError: () => {
      console.log("error occurred");
    },
  });
  const actions = [
    {
      key: "delete",
      text: "Delete Message",
    },
  ];

  const onAction = (node) => {
    if (node.key === "delete") {
      return deleteMessageMutation.mutate({ messageId: messageItem._id });
    }
  };

  return (
    <>
      <div
        id={messageItem._id}
        style={{
          marginTop: `${
            messageItem.author !== previousMessageAuthor ? "1em" : "0.5em"
          }`,
        }}
        className={clsx(
          currentUser.uid === messageItem.author
            ? styles.recievedMain
            : styles.sentMain,
          messageItem.isDeleted && styles.deletedMessage
        )}>
        <div>
          {messageItem.data.type === "string" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}>
                {!messageItem.isDeleted &&
                  currentUser.uid === messageItem.author && (
                    <Popover.Menu
                      actions={actions}
                      placement="bottom-start"
                      onAction={(node) => onAction(node)}
                      trigger="click">
                      <BiChevronDown size={14} />
                    </Popover.Menu>
                  )}
              </div>
              <p
                className={
                  currentUser.uid === messageItem.author
                    ? styles.recievedMsg
                    : styles.sentMsg
                }>
                {messageItem.isDeleted ? (
                  <p className={styles.deletedMessage}>
                    <CgCloseO style={{ marginRight: "4px" }} />
                    Message Deleted
                  </p>
                ) : (
                  <>{messageItem.data.msg}</>
                )}
              </p>
            </>
          )}
          {messageItem.data.type === "media" && (
            <>
              <MediaCard
                item={{
                  mediaUrl: messageItem.data.mediaUrl || "",
                  mediaType: messageItem.data.mediaType || "",
                  cardTimestamp: messageItem.data.cardTimestamp,
                  mediaName: messageItem.data.mediaName,
                  _id: messageItem._id,
                  isAuthor: currentUser.uid === messageItem.author,
                  isDeleted: messageItem.isDeleted,
                }}
              />
              {!messageItem.isDeleted && (
                <p
                  className={
                    currentUser.uid === messageItem.author
                      ? styles.recievedMsg
                      : styles.sentMsg
                  }>
                  {messageItem?.data?.msg}
                </p>
              )}
            </>
          )}
        </div>
        {!messageItem.isDeleted && (
          <div
            className={
              currentUser?.uid === messageItem.author
                ? styles.bottomMe
                : styles.bottomOther
            }>
            <p
              className={
                currentUser?.uid === messageItem.author
                  ? styles.timeMe
                  : styles.timeOther
              }>
              {format(new Date(messageItem.createDateTime), "hh:mm")}
            </p>
            {currentUser?.uid === messageItem.author &&
              (messageItem.sending ? (
                <BiTime
                  className={
                    currentUser?.uid === messageItem.author
                      ? styles.iconMe
                      : styles.iconOther
                  }
                />
              ) : (
                <BsCheckAll
                  className={
                    currentUser?.uid === messageItem.author
                      ? styles.iconMe
                      : styles.iconOther
                  }
                />
              ))}
          </div>
        )}
      </div>
      {resultArrayLength > 1 && (
        <p className={styles.dateContainer}>
          {format(new Date(messageItem?.createDateTime), "EEE, dd/LL/yyyy")}
        </p>
      )}
    </>
  );
};

export default Message;
