import { Button, Toast } from "antd-mobile";
import CustomDrawerWrapper from "components/Common/CustomDrawerWrapper";
import CustomInput from "components/Common/CustomInput";
import { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { useForm, Controller } from "react-hook-form";

import { getCommercialCharges } from "../../handlers/connectSocial/getCommercialCharges";
import { getSocialMediaList } from "../../handlers/connectSocial/getSocialMediaList";

import { editCommercialCharges } from "../../handlers/connectSocial/editCommercialCharges";

import { getUserSocial } from "../../handlers/me/getSocial";

const EditCommertialsYt = ({ openModalProps, setOpenModalProps }) => {
  const { queryKey, queryFunction } = getCommercialCharges({
    socialId: openModalProps.props.socialMediaKitId,
  });
  const { queryKey: socialQueryKey } = getUserSocial();

  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const [isEditShown, setIsEditShown] = useState(false);

  const { queryKey: socialMediaListYoutubeKey } = getSocialMediaList({
    platform: "youtube",
  });

  const queryClient = useQueryClient();

  const { data: commercialData } = useQuery(queryKey, queryFunction, {
    enabled: openModalProps.open,
  });

  const editCommercialMutation = useMutation(editCommercialCharges, {
    onSuccess: () => {
      queryClient.invalidateQueries(socialQueryKey).then(() => {
        queryClient.invalidateQueries(socialMediaListYoutubeKey);
        Toast.show({
          icon: "success",
          content: "Prices Updated",
        });
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        });
      });
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      dedicatedVideo: null,
      integratedVideo: null,
      shortsVideo: null,
    },
  });

  useEffect(() => {
    if (commercialData) {
      if (commercialData?.data.status !== "Not-Initiated") {
        setIsEditShown(true);
        setIsEditEnabled(false);
      }
      setValue(
        "dedicatedVideo",
        commercialData?.data?.priceCard?.dedicated > 0
          ? commercialData?.data?.priceCard?.dedicated
          : null
      );
      setValue(
        "integratedVideo",
        commercialData?.data?.priceCard?.integrated > 0
          ? commercialData?.data?.priceCard?.integrated
          : null
      );
      setValue(
        "shortsVideo",
        commercialData?.data?.priceCard?.shorts > 0
          ? commercialData?.data?.priceCard?.shorts
          : null
      );
    }
  }, [commercialData]);

  const submitCommercial = (data) => {
    return editCommercialMutation.mutate({
      socialId: openModalProps.props.socialMediaKitId,
      platform: "youtube",
      prices: {
        dedicated: Number(data?.dedicatedVideo || 0),
        integrated: Number(data?.integratedVideo || 0),
        shorts: Number(data?.shortsVideo || 0),
      },
    });
  };

  return (
    <CustomDrawerWrapper
      isOpen={openModalProps.open}
      handleClose={() =>
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        })
      }>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "1em",
          maxWidth: "500px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}>
          <h1
            style={{
              fontWeight: 600,
              fontSize: "24px",
              lineHeight: "34px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}>
            How do you wanna price your services for Youtube?
            {isEditShown && (
              <Button
                color="primary"
                fill="none"
                onClick={() => setIsEditEnabled(true)}>
                <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}>
                  Edit
                  <AiFillEdit />
                </div>
              </Button>
            )}
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "16px",
            borderTop: "1px solid #E5E5E5",
          }}>
          <Controller
            control={control}
            name="shortsVideo"
            rules={{
              required: { value: true, message: "This is required" },
              validate: {
                isMin: (value) =>
                  value >= 1500 || "Price should be greater than 1500",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                onChange={onChange}
                value={value}
                containerStyle={{ width: "100%", maxWidth: "100%" }}
                errorText={errors?.shortsVideo?.message}
                label="Shorts "
                type="number"
                pattern="\d*"
                placeholder="Enter Price for Shorts "
                disabled={!isEditEnabled}
              />
            )}
          />
          <Controller
            control={control}
            name="dedicatedVideo"
            rules={{
              required: { value: true, message: "This is required" },
              validate: {
                isMin: (value) =>
                  value >= 1500 || "Price should be greater than 1500",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                onChange={onChange}
                value={value}
                containerStyle={{ width: "100%", maxWidth: "100%" }}
                errorText={errors?.dedicatedVideo?.message}
                label="Dedicated Video"
                type="number"
                pattern="\d*"
                placeholder="Enter Price for Dedicated Video"
                disabled={!isEditEnabled}
              />
            )}
          />

          <Controller
            control={control}
            name="integratedVideo"
            rules={{
              required: { value: true, message: "This is required" },
              validate: {
                isMin: (value) =>
                  value >= 1500 || "Price should be greater than 1500",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                onChange={onChange}
                value={value}
                containerStyle={{ width: "100%", maxWidth: "100%" }}
                errorText={errors?.integratedVideo?.message}
                label="Integrated Video"
                type="number"
                pattern="\d*"
                placeholder="Enter Price for Dedicated Video"
                disabled={!isEditEnabled}
              />
            )}
          />
        </div>
        <div
          style={{
            padding: "16px",
            marginTop: "1em",
          }}>
          {isEditEnabled && (
            <Button
              block
              color="primary"
              size="large"
              onClick={handleSubmit(submitCommercial)}
              loading={editCommercialMutation.isLoading}>
              Continue
            </Button>
          )}
        </div>
      </div>
    </CustomDrawerWrapper>
  );
};

export default EditCommertialsYt;
