export const handleDownload = async (fileUrl, fileName) => {
  if (window?.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "downloadFile",
        body: {
          fileUrl,
          fileName,
        },
      })
    );
  }
};

export default handleDownload;
