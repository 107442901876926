import React, { useState, useEffect } from "react";
import { Input } from "antd";
import styles from "./chatInput.module.css";
import { BiSend } from "react-icons/bi";
import { useRef } from "react";
import { Button } from "antd";
import { IoMdAdd, IoMdPhotos } from "react-icons/io";
import { useChatContext } from "context/ChatContext";
import { HiDocumentDuplicate } from "react-icons/hi";
import { Popover } from "antd-mobile";
import SendDocs from "./SendDocs";
import SendMedia from "./SendMedia";

import useWindowWidth from "../../../hooks/useWindowWidth";

const { TextArea } = Input;

const ChatInput = () => {
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const { handleMessageSend, isKeyboardOpen, setIsKeyboardOpen } =
    useChatContext();

  const isDesktop = useWindowWidth();

  const areaRef = useRef(null);
  const [showDocs, setShowDocs] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [sendEnabled, setSendEnabled] = useState(false);

  useEffect(() => {
    if (message.length > 0 && message.trim().length > 0) {
      setSendEnabled(true);
    } else {
      setSendEnabled(false);
    }
  }, [message]);

  useEffect(() => {
    document.getElementById("input_box").addEventListener("touchend", (e) => {
      if (e.target.id === "input_box1" || e.target.id === "close_button") {
        return null;
      }
      e.preventDefault();
    });
  }, []);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.length > 0) {
      handleMessageSend(message, "string");
      setMessage("");
    }
    return null;
  };

  const handleSelectOptions = (option) => {
    setShowPopup(false);
    if (option.key === "photo") {
      setShowMedia(true);
    } else if (option.key === "document") {
      setShowDocs(true);
    }
  };

  return (
    <div
      className={isDesktop ? styles?.containerDesktop : styles.container}
      id="input_box">
      <Popover.Menu
        actions={actions}
        placement="top-start"
        onAction={(node) => handleSelectOptions(node)}
        visible={showPopup}
        style={{ zIndex: "5000" }}>
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onTouchEnd={() => {
            setShowPopup(!showPopup);
          }}
          onClick={() => {
            setShowPopup(!showPopup);
          }}
          icon={<IoMdAdd id="close_icon" />}
          shape="circle"
          id="close_button"
        />
      </Popover.Menu>

      <TextArea
        placeholder="Write a reply"
        style={{ borderRadius: "8px", border: "none" }}
        value={message}
        defaultValue={""}
        onChange={(event) => setMessage(event.target.value)}
        onBlur={() => null}
        autoSize={{ minRows: 1, maxRows: 2 }}
        id="input_box1"
        onFocus={() => {
          setIsKeyboardOpen(!isKeyboardOpen);
        }}
      />
      {sendEnabled && (
        <Button
          style={{
            backgroundColor: "#2130FF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.3s ease",
            border: "none",
          }}
          shape="circle"
          onClick={(e) => handleSendMessage(e)}
          icon={<BiSend style={{ color: "#ffff" }} />}
          size="large"
          ref={areaRef}
          onTouchEnd={(e) => handleSendMessage(e)}
        />
      )}
      <SendDocs openModal={showDocs} setOpenModal={setShowDocs} />
      <SendMedia openModal={showMedia} setOpenModal={setShowMedia} />
    </div>
  );
};

export default ChatInput;

const actions = [
  { key: "photo", icon: <IoMdPhotos />, text: "Photo/Video" },
  { key: "document", icon: <HiDocumentDuplicate />, text: "Document" },
];
