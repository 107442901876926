import { sendRequest } from "../../../sendRequest";

export const getCampaignOfferPriceV2 = ({
  isCampaignId,
  campaignId = "",
  offerId = "",
  socialMediaKitId = "",
}) => {
  const queryKey = [
    "campaigns",
    campaignId,
    offerId,
    "price",
    isCampaignId,
    socialMediaKitId,
  ];

  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/campaign/offer/price-card?${
        isCampaignId ? `campaignId=${campaignId}` : `offerId=${offerId}`
      }${socialMediaKitId && `&socialMediaKitId=${socialMediaKitId}`}`,
      {},
      null,
      true
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
