import React from "react";
import { useModalContext } from "../../context/ModalContext";

import CampaignDetailDrawer from "./CampaignDetailDrawer";
import OngoingCampaignDrawer from "./OngoingCampaignDrawer";
import ChatDrawer from "./ChatDrawer";
import ConnectPlatformModal from "components/ConnectPlatform/ConnectPlatformModal";
import EditCommertialsIg from "components/ConnectPlatform/EditCommercialsIg";
import EditCommertialsYt from "components/ConnectPlatform/EditCommercialsYt";
import KycDrawer from "./KycDrawer";
import MessagesDrawer from "./MessagesDrawer";
import ExploreCampaignsDrawer from "./ExploreCampaignsDrawer";
import MediaKitDrawer from "./MediaKitDrawer";

const GlobalDrawers = () => {
  const {
    openConnectPlatformModal,
    setOpenConnectPlatformModal,
    ongoingCampaignModal,
    setOngoingCampaignModal,
    chatModal,
    setChatModal,
    campaignModal,
    setCampaignModal,
    igCommercialsModal,
    setIgCommercialsModal,
    youtubeCommercialsModal,
    setYoutubeCommercialsModal,
    setOpenKycModal,
    openKycModal,
    messagesModal,
    setMessagesModal,
    exploreCampaignModal,
    setExploreCampaignModal,
    setMediaKitModal,
    mediaKitModal,
  } = useModalContext();

  return (
    <>
      <ConnectPlatformModal
        openConnectPlatformModal={openConnectPlatformModal}
        setOpenConnectPlatformModal={setOpenConnectPlatformModal}
      />
      <CampaignDetailDrawer
        openModalProps={campaignModal}
        setOpenModalProps={setCampaignModal}
      />
      <OngoingCampaignDrawer
        openModalProps={ongoingCampaignModal}
        setOpenModalProps={setOngoingCampaignModal}
      />
      <ChatDrawer openModalProps={chatModal} setOpenModalProps={setChatModal} />
      <MessagesDrawer
        openModalProps={messagesModal}
        setOpenModalProps={setMessagesModal}
      />
      <EditCommertialsIg
        openModalProps={igCommercialsModal}
        setOpenModalProps={setIgCommercialsModal}
      />
      <EditCommertialsYt
        openModalProps={youtubeCommercialsModal}
        setOpenModalProps={setYoutubeCommercialsModal}
      />
      <KycDrawer
        isOpen={openKycModal}
        handleClose={() => setOpenKycModal(false)}
      />
      <ExploreCampaignsDrawer
        isOpen={exploreCampaignModal}
        handleClose={() => setExploreCampaignModal(false)}
      />
      <MediaKitDrawer
        mediaKitModal={mediaKitModal}
        setMediaKitModal={setMediaKitModal}
      />
    </>
  );
};

export default GlobalDrawers;
