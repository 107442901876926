import { sendRequest } from "../../sendRequest";

export const editLiveV2 = ({
  workflowId,
  references,
  prevLiveLinkTimeLineId,
}) => {
  return sendRequest(
    "put",
    `/influencer/execution/live/edit`,
    {
      workflowId,
      references,
      prevLiveLinkTimeLineId,
    },
    null,
    true
  ).then((res) => {
    return res.data;
  });
};
