import { useEffect } from "react";
import { Button, DotLoading } from "antd-mobile";

import useConnectYt from "handlers/connectSocial/useConnectYt";
import useReConnectYt from "../../handlers/connectSocial/useReconnectYt";

import ConnectErrorYt from "components/ConnectPlatform/ConnectErrorYt";

import openInAppUrl from "../../Utils/openInAppUrl";

const AuthoriseYoutube = ({ isReconnect, isWeb = false }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const isLinkError = urlParams.get("error");
  const { mutate, isLoading, isError, error } = useConnectYt();

  const {
    mutate: reConnectMutateYt,
    isLoading: reconnectLoading,
    isError: reconnectError,
    error: reconnectErrorData,
  } = useReConnectYt();

  const handlegotoApp = () => {
    openInAppUrl(
      !Boolean(isWeb)
        ? "https://link.cloutflow.com/app"
        : `${process.env.REACT_APP_URL}?type=web`
    );
  };

  useEffect(() => {
    if (code && state) {
      if (isReconnect) {
        return reConnectMutateYt({
          data: {
            code: code,
            redirectUri: `${
              process.env.REACT_APP_URL
            }/public/reauthorise/youtube${Boolean(isWeb) ? "/web" : ""}`,
          },
          token: state,
        });
      }

      mutate({
        data: {
          code: code,
          redirectUri: `${process.env.REACT_APP_URL}/public/authorise/youtube${
            Boolean(isWeb) ? "/web" : ""
          }`,
        },
        token: state,
      });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}>
      <div style={{ padding: "20px", width: "100%" }}>
        <img src={"/assets/FAVICON.png"} style={{ width: "50px" }} alt="logo" />
      </div>

      {/* main screen */}
      {isLoading || reconnectLoading ? (
        <DotLoading />
      ) : isError || isLinkError || !code || !state || reconnectError ? (
        <ConnectErrorYt
          error={Boolean(isReconnect) ? reconnectErrorData : error}
          isWeb={isWeb}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            maxWidth: "500px",
            height: "70%",
            padding: "24px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItem: "center",
              justifyContent: "space-around",
            }}>
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#09121F",
                textAlign: "center",
              }}>
              Profile Connected
            </h1>
            <h2
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#09121F",
                fontWeight: "400",
                marginBottom: "20px",
              }}>
              {!Boolean(isWeb)
                ? "Go back to the app to continue"
                : "Continue to Add Commercials"}
            </h2>
          </div>
          <Button
            size="large"
            fill="solid"
            color="primary"
            block
            onClick={handlegotoApp}>
            Continue {Boolean(isWeb) ? "" : "on app"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AuthoriseYoutube;
