import React, { useState, useEffect } from "react";
import { Popup, Button } from "antd-mobile";
import { Row, Spin, Modal } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { IoChevronBack } from "react-icons/io5";

import PlatformTile from "./PlatformTile";

import { useAuthContext } from "../../context/AuthContext";

import { getUser } from "../../handlers/me/getUser";
import { getUserSocial } from "../../handlers/me/getSocial";

import styles from "./styles.module.css";

import ApplicationSuccessDrawer from "../Onboarding/ApplicationSuccessDrawer";

export const VerifyPlatforms = ({ visible, handleClose }) => {
  const { signedIn } = useAuthContext();
  const { queryKey: userKey, queryFunction: userFn } = getUser(signedIn);
  const { data: userData, isLoading: isLoadingUsrData } = useQuery(
    userKey,
    userFn,
    {
      enabled: signedIn,
    }
  );

  const { queryKey: socialQueryKey, queryFunction: socialQueryFunction } =
    getUserSocial(["Connected", "Session-Expired"]);

  const { data: socialData, isLoading: isLoadingSocialData } = useQuery(
    socialQueryKey,
    socialQueryFunction,
    {
      enabled: signedIn,
    }
  );

  const queryClient = useQueryClient();

  const [showSuccesModal, setShowSuccesModal] = useState(false);

  const mergedAccounts = new Set();

  userData?.data?.userPlatforms.map((socialItem) =>
    mergedAccounts.add(
      JSON.stringify({
        username: String(socialItem.username).toLowerCase(),
        platform: socialItem.platform,
        connectedThrough: Boolean(socialItem?.connectedThrough),
      })
    )
  );

  socialData?.data?.map((socialItem) =>
    mergedAccounts.add(
      JSON.stringify({
        username: String(socialItem.socialMediaName).toLowerCase(),
        platform: socialItem.socialMediaPlatform,
        connectedThrough: true,
      })
    )
  );

  useEffect(() => {
    queryClient.invalidateQueries(socialQueryKey);
    queryClient.invalidateQueries(userKey);
  }, []);

  // const windowWidth = useWindowWidth();

  // return (
  //   <Popup
  //     visible={visible}
  //     position="right"
  //     onClose={() => {
  //       handleClose();
  //     }}
  //     bodyStyle={{
  //       width: "100vw",
  //       // padding: "20px",
  //       // background: "transparent",
  //     }}
  //     destroyOnClose

  //     // style={{ background: "transparent" }}
  //     // maskStyle={{ background: "transparent" }}
  //   >
  //     <section
  //       style={{ position: "relative", height: "100vh", overflowY: "scroll" }}>
  //       <Button
  //         type="button"
  //         fill="none"
  //         style={{
  //           // padding: 0,
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           height: "20px",
  //           padding: "24px 16px",
  //         }}
  //         onClick={handleClose}>
  //         <IoChevronBack size={24} />
  //       </Button>
  //       <div style={{ padding: "0 34px" }}>
  //         <h2>Verify your Socials</h2>

  //         <div style={{ display: "grid", gap: "25px", marginTop: 40 }}>
  //           {/* <p className={styles.socialMediaHeading}>
  //             Social Media Accounts Listed
  //           </p> */}
  //           {isLoadingSocialData || isLoadingUsrData ? (
  //             <Row justify="center" style={{ margin: "50px 0" }}>
  //               <Spin />
  //             </Row>
  //           ) : (
  //             <>
  //               {Array.from(mergedAccounts).map((socialMediaItem) => {
  //                 const itemObject = JSON.parse(socialMediaItem);
  //                 return (
  //                   <PlatformTile
  //                     isVerified={Boolean(itemObject?.connectedThrough)}
  //                     platform={itemObject.platform}
  //                     username={itemObject.username}
  //                   />
  //                 );
  //               })}
  //             </>
  //           )}
  //         </div>
  //         <Button
  //           style={{
  //             position: "absolute",
  //             bottom: "40px",
  //             width: "calc(100% - 68px)",
  //           }}
  //           onClick={() => setShowSuccesModal(true)}
  //           color="primary"
  //           // disabled={userData?.data?.userPlatforms
  //           //   ?.filter(
  //           //     (item) =>
  //           //       item.platform === "instagram" || item.platform === "youtube"
  //           //   )
  //           //   ?.some((userItem) => !Boolean(userItem?.connectedThrough))}
  //           disabled={(socialData?.data?.length || 0) <= 0}>
  //           Continue
  //         </Button>

  //         <ApplicationSuccessDrawer
  //           showSuccess={showSuccesModal}
  //           handleClose={() => {
  //             handleClose();

  //             setShowSuccesModal(false);
  //           }}
  //         />
  //       </div>
  //     </section>
  //   </Popup>
  // );

  return (
    <Modal
      open={visible}
      // position="right"
      onClose={() => {
        handleClose();
      }}
      bodyStyle={
        {
          // width: "100vw",
          // padding: "20px",
          // background: "transparent",
        }
      }
      destroyOnClose
      onCancel={() => handleClose()}
      footer={null}

      // style={{ background: "transparent" }}
      // maskStyle={{ background: "transparent" }}
    >
      <section
        style={{ position: "relative", height: "60vh", overflowY: "scroll" }}>
        {/* <Button
          type="button"
          fill="none"
          style={{
            // padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20px",
            padding: "24px 16px",
          }}
          onClick={handleClose}>
          <IoChevronBack size={24} />
        </Button> */}
        <div style={{ padding: "0 34px" }}>
          <h2>Verify your Socials</h2>

          <div style={{ display: "grid", gap: "25px", marginTop: 40 }}>
            {/* <p className={styles.socialMediaHeading}>
              Social Media Accounts Listed
            </p> */}
            {isLoadingSocialData || isLoadingUsrData ? (
              <Row justify="center" style={{ margin: "50px 0" }}>
                <Spin />
              </Row>
            ) : (
              <>
                {Array.from(mergedAccounts).map((socialMediaItem) => {
                  const itemObject = JSON.parse(socialMediaItem);
                  return (
                    <PlatformTile
                      isVerified={Boolean(itemObject?.connectedThrough)}
                      platform={itemObject.platform}
                      username={itemObject.username}
                    />
                  );
                })}
              </>
            )}
          </div>
          <Button
            style={{
              position: "absolute",
              bottom: "40px",
              width: "calc(100% - 68px)",
            }}
            onClick={() => setShowSuccesModal(true)}
            color="primary"
            // disabled={userData?.data?.userPlatforms
            //   ?.filter(
            //     (item) =>
            //       item.platform === "instagram" || item.platform === "youtube"
            //   )
            //   ?.some((userItem) => !Boolean(userItem?.connectedThrough))}
            disabled={(socialData?.data?.length || 0) <= 0}>
            Continue
          </Button>

          <ApplicationSuccessDrawer
            showSuccess={showSuccesModal}
            handleClose={() => {
              handleClose();

              setShowSuccesModal(false);
            }}
          />
        </div>
      </section>
    </Modal>
  );
};
