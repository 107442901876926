import { sendRequest } from "../sendRequest.js";

export const getAllCampaigns = () => {
  const queryKey = ["campaigns"];
  const queryFunction = ({ pageParam }) =>
    sendRequest(
      "get",
      `/influencer/campaign/all?limit=20${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
