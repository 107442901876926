import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { message } from "antd";
import { useQueryClient } from "react-query";
import useSound from "use-sound";

import messageSound from "assets/sounds/messageSound.mp3";

import { useAuthContext } from "./AuthContext";
import { getAllConversations } from "../handlers/chat";

const chatNotificationContext = createContext(undefined);

export const ChatNotificationContextProvider = ({ children }) => {
  const { currentUser } = useAuthContext();
  const queryClient = useQueryClient();
  const { queryKey: getAllConversationsKey } = getAllConversations();
  const [unreadMessageFlag, setUnreadMessageFlag] = useState(false);

  const [play] = useSound(messageSound);

  const connectSocket = async () => {
    const token = await currentUser?.getIdToken();
    const conversationSocket = io(
      `${process.env.REACT_APP_SOCKET_URL}/global`,
      {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    conversationSocket.on("connect", () => {
      console.log("global socket connected");
    });
    conversationSocket.on("new-message", (args) => {
      // console.log("audio played");
      playAudio();
    });
    conversationSocket.on("connect_error", () => {
      console.log("connect_error");
    });
    conversationSocket.on("disconnect", () => {
      console.log("global socket disconnected");
      conversationSocket.disconnect();
      conversationSocket.close();
    });
    return conversationSocket;
  };

  useEffect(() => {
    if (currentUser !== null) {
      connectSocket();
    }
  }, [currentUser]);

  function playAudio() {
    play({ volume: 1, forceSoundEnabled: true, soundEnabled: true });
    message.success("New Message");
    setUnreadMessageFlag(true);
    queryClient.invalidateQueries(getAllConversationsKey);
  }

  return (
    <chatNotificationContext.Provider
      value={{ unreadMessageFlag, setUnreadMessageFlag }}>
      {children}
    </chatNotificationContext.Provider>
  );
};

export const useChatNotificationContext = () => {
  const context = useContext(chatNotificationContext);

  if (!context) {
    throw new Error("useAuth must be used in <AuthProvider />");
  }

  return context;
};
