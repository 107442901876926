import React, { useState, useEffect } from "react";
import { CapsuleTabs, Skeleton, PullToRefresh, DotLoading } from "antd-mobile";
import { useInfiniteQuery, useQueryClient } from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { useLocation } from "react-router-dom";

// OngoingCampignDetailCard;
import CampaignCard from "../Dashboard/OngoingCampaigns/CampaignCard";
// CampaignDetailCard
import Campaigncard from "../Dashboard/Campaigncard";

import ExploreCard from "../Common/ExploreCard";

import { useNavContext } from "../../context/NavContext";
import useUserStatusHook from "screens/Common/useUserStatusHook";
import { useModalContext } from "../../context/ModalContext";

import { getCampaignOffers } from "../../handlers/campaign/offers/getOffers";

import styles from "./styles.module.css";

const Campaigns = () => {
  const { content, isQueryEnabled, userStatusCode } = useUserStatusHook("show");
  const [key, setKey] = useState("On-going");
  const queryClient = useQueryClient();

  const { setOpenNav, setNavHeading } = useNavContext();
  const { setExploreCampaignModal } = useModalContext();

  useEffect(() => {
    setOpenNav(true);
    setNavHeading("My campaigns");
  }, []);

  const { queryKey, queryFunction } = getCampaignOffers(key);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.isOnDashboard) {
      setKey("Invited");
    }
  }, [location]);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    queryKey,
    queryFunction,
    {
      enabled: isQueryEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );

  return (
    <>
      {userStatusCode === "Done" ||
      userStatusCode === "Platform-Disconnected" ? (
        <PullToRefresh
          onRefresh={async () => {
            await queryClient.invalidateQueries(queryKey);
          }}
          refreshingText={
            <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
          }
          canReleaseText={<AiOutlineArrowUp size={"20px"} />}
          pullingText={<AiOutlineArrowDown size={"20px"} />}
          completeText={<div />}>
          <div className={styles.container}>
            <CapsuleTabs
              activeKey={key}
              heading={{ borderBottom: 0 }}
              onChange={(key) => setKey(key)}>
              <CapsuleTabs.Tab title="Ongoing" key="On-going" />

              <CapsuleTabs.Tab title="Invited" key="Invited" />

              <CapsuleTabs.Tab title="Applied" key="Applied" />

              <CapsuleTabs.Tab title="Completed Campaigns" key="Completed" />

              <CapsuleTabs.Tab title="Past/Inactive Offers" key="Past" />
            </CapsuleTabs>
            {isLoading ? (
              <div>
                <Skeleton className={styles.customSkeleton} />
                <Skeleton className={styles.customSkeleton} />
                <Skeleton className={styles.customSkeleton} />
                <Skeleton className={styles.customSkeleton} />
              </div>
            ) : (
              <div style={{ display: "grid", gap: "12px", marginTop: "36px" }}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => fetchNextPage}
                  hasMore={hasNextPage}
                  style={{ display: "grid", gap: "16px" }}>
                  {data &&
                    data?.pages?.map((pageItem) => {
                      return pageItem?.data?.offer?.map((item, idx) => {
                        return (
                          <>
                            {key === "On-going" ? (
                              <CampaignCard key={idx} campaignData={item} />
                            ) : (
                              <Campaigncard
                                key={idx}
                                campaign={item}
                                isInvited={key === "Invited"}
                              />
                            )}
                          </>
                        );
                      });
                    })}
                </InfiniteScroll>
                {data && data?.pages[0]?.data?.offer?.length === 0 && (
                  <ExploreCard
                    title={"No campaigns to see here"}
                    description={
                      "Explore campaigns and apply to more to increase your chances of getting selected."
                    }
                    handleClick={() => setExploreCampaignModal(true)}
                    containerStyles={{
                      marginTop: "100px",
                      background: "transparent",
                      borderRadius: 0,
                      boxShadow: "none",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </PullToRefresh>
      ) : (
        <main style={{ width: "100%", padding: "18px" }}>{content}</main>
      )}
    </>
  );
};

export default Campaigns;
