import { useEffect } from "react";
import Lottie from "react-lottie";

import animationData from "../../assets/lottie/91779-basic-loading-progress-bar.json";

const LoadingScreen = () => {
  useEffect(() => {
    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "changeSafeAreaColor",
          metadata: {
            color: "#00003A",
          },
        })
      );
    }
    return () => {
      if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "changeSafeAreaColor",
            metadata: {
              color: "#ffffff",
            },
          })
        );
      }
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00003A",
        paddingTop: "env(safe-area-inset-top)",
      }}>
      <img
        src={"/assets/FAVICON.png"}
        width={120}
        height={120}
        style={{ objectFit: "contain" }}
        className="logo"
        alt="logo"
      />

      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
};

export default LoadingScreen;
