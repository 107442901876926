export const getIgPermissions = (isMessaging = false) => {
  const basicPermissions = [
    "instagram_basic",
    "pages_show_list",
    "instagram_manage_insights",
    "pages_read_engagement",
  ];
  const messagingPermissions = [
    "instagram_manage_comments",
    "pages_manage_metadata",
    "instagram_manage_messages",
    "business_management",
    "pages_messaging",
  ];

  return (
    basicPermissions.join(",") +
    (isMessaging ? "," + messagingPermissions.join(",") : "")
  );
};
