import React from "react";
import styles from "./tile.module.css";
const InsightTile = ({ value, label }) => {
  return (
    <div className={styles.container}>
      <p className={styles.value}>{value}</p>
      <p className={styles.label}>{label}</p>
    </div>
  );
};

export default InsightTile;
