import { sendRequest } from "../../sendRequest.js";

export const getCampaignOffers = (status) => {
  const queryKey = ["campaigns", status];
  const queryFunction = ({ pageParam }) =>
    sendRequest(
      "get",
      `/influencer/campaign/offer?limit=20&status=${status}${
        pageParam ? `&nextPageToken=${pageParam}` : ""
      }`
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
