import { TabBar, Badge } from "antd-mobile";
import { AiOutlineHome } from "react-icons/ai";
import { MdMarkChatUnread } from "react-icons/md";

import { CgWorkAlt, CgProfile } from "react-icons/cg";
import { BiWallet } from "react-icons/bi";
import { VscBell, VscBellDot } from "react-icons/vsc";
import CustomNavBar from "../components/Common/CustomNavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useNavContext } from "../context/NavContext";
import MainRoutes from "../routes/MainRoutes";
import GlobalDrawers from "../components/GlobalDrawers";
import { useNotifContext } from "context/NotificationContext";
import { Layout, Menu } from "antd";
import React, { useState, useRef } from "react";

import useWindowWidth from "../hooks/useWindowWidth";

import OnboardingNew from "../components/Onboarding/OnboardingNew";

import ErrorBoundary from "../ErrorBoundary";

import VerifyKyc from "../components/Onboarding/VerifyKyc";

import { getUser } from "../handlers/me/getUser";
import { useQuery } from "react-query";

const { Sider, Content } = Layout;

const MainLayout = () => {
  const { newNotif } = useNotifContext();

  const isDesktop = useWindowWidth();

  let tabs = [
    {
      key: "/",
      title: "Home",
      icon: <AiOutlineHome />,
    },
    {
      key: "/campaign",
      title: "Campaign",
      icon: <CgWorkAlt />,
    },
    {
      key: "/wallet",
      title: "Wallet",
      icon: <BiWallet />,
    },
    {
      key: "/notification",
      title: "Notification",
      icon: newNotif ? <VscBellDot /> : <VscBell />,
    },
    {
      key: "/profile",
      title: "Profile",
      icon: <CgProfile />,
    },
  ];

  if (isDesktop) {
    tabs = [
      ...tabs.slice(0, 4),
      {
        key: "/chats",
        title: "Messages",
        icon: <MdMarkChatUnread />,
      },
      tabs.at(-1),
    ];
  }

  const { setNavHeading, setOpenNav, openNav } = useNavContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = location;

  const setRouteActive = (route) => {
    if (isDesktop) {
      return navigate(route);
    }
    setOpenNav(false);
    setNavHeading(null);
    navigate(route);
  };

  const { queryKey: userKey, queryFunction: userFn } = getUser(true);
  const { data: userData } = useQuery(userKey, userFn);

  const showKyc =
    userData?.data?.kyc?.status === "Under-Review" &&
    userData?.data?.userPlatforms
      ?.filter(
        (item) => item.platform === "instagram" || item.platform === "youtube"
      )
      ?.every((userItem) => Boolean(userItem?.connectedThrough));

  if (pathname === "/onboarding") {
    return (
      <ErrorBoundary FallbackComponent={Error} onReset={window.location.reload}>
        <div
          style={{
            height: "100vh",
            overflow: "scroll",
          }}>
          <div style={{ marginTop: "24px" }}>
            <div
              style={{
                margin: "0 auto",
                marginTop: "-24px",
                maxWidth: 700,
              }}>
              <OnboardingNew isRegistered />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
  if (pathname === "/kycProcessing" || showKyc) {
    return (
      <ErrorBoundary FallbackComponent={Error} onReset={window.location.reload}>
        <VerifyKyc />
      </ErrorBoundary>
    );
  }

  if (isDesktop) {
    return (
      <div
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <CustomNavBar />
        <Layout
          style={{
            background: "#ffffff",
            width: "100vw",
            marginTop: openNav || isDesktop ? "54px" : 0,
          }}>
          <Sider
            collapsible
            collapsed={false}
            // onCollapse={(value) => setCollapsed(value)}
            // onMouseEnter={() => setCollapsed(false)}
            // onMouseLeave={() => setCollapsed(true)}
            trigger={null}>
            <Menu
              theme="light"
              // mode="inline"
              _internalRenderMenuItem={(node, menuItemProps, stateProps) => {
                return React.cloneElement(node, {
                  style: {
                    ...node.props.style,
                    backgroundColor: stateProps?.selected ? "#533AF51A" : "",
                    borderRadius: "8px",
                    padding: collapsed ? undefined : "0 10px ",
                    height: "fit-content",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: collapsed ? undefined : "10px",
                  },
                });
              }}
              selectedKeys={[tabs?.find((item) => item.key === pathname)?.key]}
              style={{
                height: "100%",
                borderRight: "1px solid rgba(0, 0, 0, 0.10)",
                background: "#ECEEF3",
                padding: "20px",
              }}
              items={tabs?.map((tabItem) => ({
                ...tabItem,
                onClick: () => setRouteActive(tabItem.key),
                label: tabItem?.title,
              }))}
            />
          </Sider>

          <Content
            style={{
              // marginTop: openNav ? "54px" : 0,
              width: "100%",
              // maxWidth: "900px",
              height: "calc(100vh - 54px)",
              overflow: "scroll",
            }}>
            <div
              style={{
                marginTop: 0,
                width: "100%",
                maxWidth: "900px",
                margin: "0 auto",
                height: "calc(100vh - 54px)",
                overflow: "scroll",
                paddingBottom: isDesktop ? 10 : "60px",
                // backgroundColor: "#FFFBFE",
              }}>
              <MainRoutes />
            </div>
          </Content>
        </Layout>
        {/** Keeping modals at global level */}
        <GlobalDrawers />
        {/** Footer is defined here */}
      </div>
    );
  }

  return (
    <div
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <CustomNavBar />
      <div
        style={{
          marginTop: openNav ? "54px" : 0,
          width: "100%",
          maxWidth: "900px",
          height: "calc(100vh - 54px)",
          overflow: "scroll",
          paddingBottom: "60px",
          // backgroundColor: "#FFFBFE",
        }}>
        <MainRoutes />
      </div>
      {/** Keeping modals at global level */}
      <GlobalDrawers />
      {/** Footer is defined here */}
      {!isDesktop && (
        <div style={{ flex: 0 }}>
          <TabBar
            safeArea
            activeKey={pathname}
            onChange={(value) => setRouteActive(value)}
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              paddingTop: "5px",
              backgroundColor: "white",
            }}>
            {tabs.map((item) => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
          </TabBar>
        </div>
      )}
    </div>
  );
};
export default MainLayout;
