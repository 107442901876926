import { Button } from "antd-mobile";

import openInAppUrl from "../../Utils/openInAppUrl";

const SucessConnect = ({ isWeb = false }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}>
      <div style={{ padding: "20px", width: "100%" }}>
        <img src={"/assets/FAVICON.png"} style={{ width: "50px" }} alt="logo" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          maxWidth: "500px",
          height: "70%",
          padding: "24px",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "space-around",
          }}>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#09121F",
              textAlign: "center",
            }}>
            Profile Connected!
          </h1>
          {!Boolean(isWeb) && (
            <h2
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#09121F",
                fontWeight: "400",
                marginBottom: "20px",
              }}>
              Open the app to see your profile
            </h2>
          )}
        </div>
        <Button
          fill="solid"
          block
          color="primary"
          size="large"
          onClick={() => {
            openInAppUrl(
              !Boolean(isWeb)
                ? "https://link.cloutflow.com/app"
                : `${process.env.REACT_APP_URL}?type=web`
            );
          }}>
          {!Boolean(isWeb) ? " Continue on App" : "Continue to add Commercials"}
        </Button>
      </div>
    </div>
  );
};

export default SucessConnect;
