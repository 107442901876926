
const Error = ({ resetErrorBoundary }) => {
    return (
        <div>
            <h1>Something went wrong</h1>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};

export default Error;
