import { Button, Picker } from "antd-mobile";
import { Input } from "antd";
import { DownOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react";
import countries from "../../Utils/countryArray";

const CountrySelector = ({
  value,
  setValue,
  disabled,
  setDisabled = (val) => {},
}) => {
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    if (!value.split("+").at(-1).trim()) {
      return setPrefix("World");
    }
    return setPrefix(
      (
        countries?.flat().find((item) => {
          return item.value === value;
        }) || { label: ")World" }
      )?.label // }) //   return item.value.includes(value); // countries?.flat().find((item) => { // ||
        .split(")")
        .at(-1)
    );
  }, [value]);

  return (
    <div style={{ maxWidth: "350px", width: "100%" }}>
      <div style={{ fontSize: "14px", fontWeight: "500" }}>Country code</div>
      <Input
        status={disabled ? "error" : undefined}
        // status={errorText ? "error" : "success"}
        onChange={(e) => {
          setDisabled(false);
          setValue(e.target.value || "+");
        }}
        prefix={prefix}
        // label="Country Code"
        type="tel"
        pattern="\d*"
        value={value}
        // placeholder="Enter your phone number"
        // label2="We'll send you a text to confirm your number. Standard message rates may apply"
        style={{
          paddingTop: "11px",
          paddingBottom: "11px",
          border: "1.5px solid #CCCCCC",
          borderRadius: "8px",
        }}
      />
      {disabled && prefix === "World" && (
        <h6 style={{ color: "red", marginTop: 5 }}>Invalid Country Code</h6>
      )}
    </div>
  );
};

export default CountrySelector;
