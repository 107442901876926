import { sendRequest } from "../../../sendRequest";

export const getWorkFlowDetailsV2 = (finalizedOfferID) => {
  const queryKey = ["campaigns", "workflow", finalizedOfferID];
  const queryFunction = () =>
    sendRequest(
      "get",
      `/influencer/execution/workflow?finalizedOfferId=${finalizedOfferID}`,
      {},
      null,
      true
    ).then((res) => res.data);

  return { queryKey, queryFunction };
};
