import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Skeleton,
  PullToRefresh,
  DotLoading,
  Ellipsis,
} from "antd-mobile";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { ExclamationCircleFill, LinkOutline } from "antd-mobile-icons";
import { MessageOutlined } from "@ant-design/icons";

import AcceptModal from "./Modals/AcceptModal";
import WithdrawModal from "./Modals/WithdrawModal";
import RejectModal from "./Modals/RejectModal";
import ApplyModal from "./Modals/ApplyModal";
import KycDrawer from "../../GlobalDrawers/KycDrawer";

import PostedInfoRow from "components/Dashboard/PostedInfoRow/PostedInfoRow";
import Chip from "components/Dashboard/Campaigncard/Chip";
import PriceDetailCard from "../PriceDetailCard";

import { getCampaignV2 } from "../../../handlers/campaign/v2/getCampaignV2";
import { getCampaignOfferPriceV2 } from "handlers/campaign/offers/";
import { getUCampaign } from "handlers/public/getUCampaign";

// import { capitalizeFirstLetter } from "../../../Utils/capitalizeFirstLetter";
import { capitalizeFirstLetter } from "../../../Utils/capitalizeFirstLetter";
import openInAppUrl from "../../../Utils/openInAppUrl";

import { formatPrice } from "../../Common/formatPrice";

import styles from "./styles.module.css";

import { useModalContext } from "context/ModalContext";
import useUserStatusHook from "screens/Common/useUserStatusHook";
import useWindowWidth from "../../../hooks/useWindowWidth";

const CampaignDetail = ({ campaignId, dealId, unregistered = false }) => {
  const { userStatusCode, openKycModal, setOpenKycModal, setIsAction } =
    useUserStatusHook("action");

  const isDesktop = useWindowWidth();

  const { setCampaignModal, setOpenConnectPlatformModal } = useModalContext();

  const [applyVisible, setApplyVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [withDrawVisible, setWithDrawVisible] = useState(false);
  const [acceptVisible, setAcceptVisible] = useState(false);

  const [campaignData, setCampaignData] = useState(null);
  const [priceProps, setPriceProps] = useState({
    campaignId: "",
    offerId: "",
    isCampaignId: true,
    isQueryEnabled: false,
  });

  const [commercial, setCommercial] = useState(null);

  const queryClient = useQueryClient();
  const { queryKey, queryFunction } = getCampaignV2(campaignId);

  const {
    queryKey: getCampaignOfferPriceKey,
    queryFunction: getCampaignOfferPriceFn,
  } = getCampaignOfferPriceV2({ ...priceProps });

  const {
    queryKey: uCampaignDetailKey,
    queryFunction: uCampaignDetailFunction,
  } = getUCampaign(campaignId, dealId);

  const navigate = useNavigate();

  const { data: campaign } = useQuery(queryKey, queryFunction, {
    enabled: (campaignId || dealId) && !unregistered,
  });
  const { data: uCampaign } = useQuery(
    uCampaignDetailKey,
    uCampaignDetailFunction,
    {
      enabled: unregistered,
    }
  );

  const { data: campaignOfferPrice, isLoading: offerLoading } = useQuery(
    getCampaignOfferPriceKey,
    getCampaignOfferPriceFn,
    {
      enabled: priceProps.isQueryEnabled,
    }
  );

  const handleInvalidate = () => {
    queryClient.invalidateQueries(queryKey);
  };

  useEffect(() => {
    if (uCampaign || campaign) {
      setCampaignData(unregistered ? uCampaign : campaign);
    }
  }, [uCampaign, campaign, unregistered]);

  useEffect(() => {
    if (campaignData) {
      setPriceProps({
        campaignId: campaignData.data._id,
        offerId: campaignData.data.offer?.currentlyRunningOfferId || "",
        isCampaignId: campaignData.data.offer?.canApply,
        isQueryEnabled: unregistered ? false : true,
      });
    }
  }, [campaignData]);

  useEffect(() => {
    if (campaignOfferPrice) {
      // console.log(campaignOfferPrice.data);
      setCommercial(campaignOfferPrice.data);
    }
  }, [campaignOfferPrice]);

  useEffect(() => {
    if (uCampaign || campaign) {
      setCampaignData(unregistered ? uCampaign : campaign);
    }

    return () => {
      setCampaignModal({ open: false });
      setCampaignData(null);
    };
  }, []);

  if (!campaignData) {
    return null;
  }

  const getCampaignButtons = (campaign) => {
    if (!unregistered) {
      if (campaign.offer.isCurrentlyRunningOffer) {
        if (campaign.offer.currentlyRunningOffer.status === "Invited") {
          return (
            <Row justify="space-between">
              <Button
                style={{
                  width: "45%",
                  backgroundColor: "#E9F0FF8A",
                  border: "1px solid #E9F0FF8A",
                  color: "#2130FF",
                }}
                onClick={() => {
                  if (userStatusCode !== "Done") {
                    if (userStatusCode === "Not-Registered") {
                      setCampaignModal({ open: false });
                      return navigate("/onboarding");
                    } else return setIsAction(true);
                  } else return setRejectVisible(true);
                }}>
                Reject
              </Button>
              <Button
                style={{
                  width: "45%",
                  backgroundColor: "#2130FF",
                  border: "1px solid #2130FF",
                  color: "#fff",
                }}
                onClick={() => {
                  if (userStatusCode !== "Done") {
                    if (userStatusCode === "Not-Registered") {
                      setCampaignModal({ open: false });
                      return navigate("/onboarding");
                    } else {
                      return setIsAction(true);
                    }
                  } else return setAcceptVisible(true);
                }}>
                Accept
              </Button>
            </Row>
          );
        } else if (campaign.offer.currentlyRunningOffer.status === "Applied") {
          return (
            <Button
              style={{
                width: "100%",
                backgroundColor: "#E9F0FF8A",
                border: "1px solid #E9F0FF8A",
                color: "#2130FF",
              }}
              onClick={() => {
                return setWithDrawVisible(true);
              }}>
              Withdraw
            </Button>
          );
        } else if (
          campaign.offer.currentlyRunningOffer.status === "Shortlisted"
        ) {
          if (campaign.offer?.isCurrentlyRunningOfferApplied)
            return (
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#E9F0FF8A",
                  border: "1px solid #E9F0FF8A",
                  color: "#2130FF",
                }}
                onClick={() => {
                  return setWithDrawVisible(true);
                }}>
                Withdraw
              </Button>
            );
          return (
            <h4>
              You are shortlisted for the campaign, we are waiting for a final
              go ahead to finalise the offer for you.
            </h4>
          );
        } else {
          return (
            <Button
              style={{
                width: "100%",
                backgroundColor: "#E9F0FF8A",
                border: "1px solid #E9F0FF8A",
                color: "#2130FF",
              }}
              onClick={() =>
                isDesktop
                  ? window.history.back()
                  : setCampaignModal({ open: false })
              }>
              Go Back
            </Button>
          );
        }
      } else {
        if (campaign.offer.isBrandRejectedOffer) {
          return (
            <Button disabled style={{ width: "100%" }}>
              Brand Rejected your applied application
            </Button>
          );
        } else {
          return (
            <Button
              style={{
                width: "100%",
                backgroundColor: "#2130FF",
                border: "1px solid #2130FF",
                color: "#fff",
              }}
              onClick={() => {
                if (userStatusCode !== "Done") {
                  if (userStatusCode === "Not-Registered") {
                    setCampaignModal({ open: false });
                    return navigate("/onboarding");
                  } else return setIsAction(true);
                }
                // if (campaignOfferPrice?.data.price === 0) {
                //   Modal.alert({
                //     header: (
                //       <ExclamationCircleFill
                //         style={{
                //           fontSize: 64,
                //           color: "var(--adm-color-warning)",
                //         }}
                //       />
                //     ),
                //     confirmText: "Ok",
                //     title: "Not Eligible",
                //     content: (
                //       <>
                //         <p>
                //           To be able to participate in campaigns with Cloutflow,
                //           you need to be in the campaign's followers range.
                //         </p>
                //       </>
                //     ),
                //   });
                // } else {
                if (campaignOfferPrice?.data)
                  // setCommercial(campaignOfferPrice.data.price);
                  setApplyVisible(true);
                // }
              }}
              // disabled={!commercial?.isPriceCardApproved}
              disabled={!commercial?.isSocialMediaExist}>
              Apply
            </Button>
          );
        }
      }
    } else {
      return (
        <Button
          style={{
            width: "100%",
            backgroundColor: "#2130FF",
            border: "1px solid #2130FF",
            color: "#fff",
          }}
          onClick={() => {
            setCampaignModal({ open: false });
            return navigate("/onboarding");
          }}>
          Apply
        </Button>
      );
    }
  };

  const getDeliverableDetails = (item) => {
    return (
      <>
        <p className={styles.deliverableHeading}>
          {!item?.repost ? item?.duration : "Repost "}
          {item?.duration &&
            (item?.typeDel === "Reel" || item?.typeDel === "Story"
              ? " seconds"
              : " minutes")}{" "}
          {item?.type} {item.typeDel}
        </p>
        {item.contentRights && (
          <p className={styles.delDesc}>
            Content rights ({item.contentRights})
          </p>
        )}
        {item?.link && (
          <p className={styles.delDesc} style={{ wordBreak: "break-all" }}>
            <span onClick={() => openInAppUrl(item?.link)}>
              Swipe Up Link
              <LinkOutline style={{ marginLeft: "6px" }} color="#316bff" />
            </span>
          </p>
        )}

        {item?.description && (
          <p className={styles.delDesc}>Brief: {item?.description}</p>
        )}

        {item?.repostData?.instructions && (
          <p className={styles.delDesc}>
            Brief: {item?.repostData?.instructions}
          </p>
        )}
        {(item?.products || []).length > 0 && (
          <>
            <p className={styles.delDesc}> Products To Promote </p>
            {item?.products.map((product) => {
              return (
                <p className={styles.delDesc}>
                  {"-> "}

                  {
                    campaignData?.data?.deal[0]?.productDetails.find(
                      (ele) => ele._id === product
                    )?.productTitle
                  }
                </p>
              );
            })}
          </>
        )}
      </>
    );
  };

  return (
    <PullToRefresh
      onRefresh={async () => {
        await queryClient.invalidateQueries(queryKey);
        if (!unregistered)
          await queryClient.invalidateQueries(getCampaignOfferPriceKey);
        // await queryClient.invalidateQueries(ongoingCampaignsKey);
        // await queryClient.invalidateQueries(invitedCampaignsKey);
        // await queryClient.invalidateQueries(userStatusKey);
        // window && window.location.reload();
      }}
      refreshingText={
        <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
      }
      canReleaseText={<AiOutlineArrowUp size={"20px"} />}
      pullingText={<AiOutlineArrowDown size={"20px"} />}
      completeText={<div />}>
      <main style={{ background: "#F5F5F5" }}>
        <div>
          <img
            style={{ width: "100%", height: "200px", objectFit: "cover" }}
            src={campaignData.data.campaignBannerUrl}
            alt="banner"
          />
        </div>
        <div style={{ display: "grid", gridGap: "7px" }}>
          <div style={{ backgroundColor: "#fff" }}>
            <div className={styles.campaignHeader}>
              <div>
                <p className={styles.cName}>{campaignData.data.campaignName}</p>
                <p className={styles.bName}>
                  {campaignData.data.brand.businessDetails.name}
                </p>
              </div>
              <img
                style={{ width: "24px", height: "24px" }}
                src={`/assets/img/${campaignData?.data?.platform}.png`}
                alt="platform"
              />
            </div>

            <PostedInfoRow
              style={{ marginBottom: "20px" }}
              postedDate={campaignData.data.createdDateTime}
            />

            <section className={styles.cDetails}>
              {campaignData.data.filter.niche.length > 0 && (
                <>
                  <p className={styles.tileHeading}>Community</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "11px",
                      marginBottom: "28px",
                    }}>
                    {campaignData.data.filter.niche.map((item, idx) => (
                      <Chip label={item} key={idx} />
                    ))}
                  </div>
                </>
              )}
              {/* {campaignData.data.filter.followerCategory.length > 0 && (
              <>
                <p className={styles.tileHeading}>Follower range</p>
                <div style={{ marginTop: "10px", display: "grid", gap: "4px" }}>
                  {campaignData.data.filter.followerCategory.map(
                    (item, idx) => {
                      return <p key={idx}>{capitalizeFirstLetter(item)}</p>;
                    }
                  )}
                </div>
              </>
            )} */}
            </section>
          </div>

          <section className={styles.productList}>
            {campaignData.data.deal[0].isProduct ? (
              <>
                <p className={styles.tileHeading}>Products</p>
                <ul
                  style={{
                    marginTop: "10px",
                    display: "grid",
                    gap: "16px",
                    marginLeft: "12px",
                  }}>
                  {campaignData.data.deal[0].productDetails.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <p onClick={() => openInAppUrl(item?.productLink)}>
                          {item.productTitle}{" "}
                          <LinkOutline
                            style={{ marginLeft: "6px" }}
                            color="#316bff"
                          />
                        </p>
                        <p>₹ {formatPrice(item.price)}/-</p>
                      </li>
                    );
                  })}
                </ul>
                <p
                  className={styles.delDesc}
                  style={{
                    fontStyle: "italic",
                    lineHeight: "16px",
                    fontSize: "12px",
                  }}>
                  * There are the list of all the products that the brand wants
                  to promote for this campaign, You might need to promote
                  one/multiple products in the list
                </p>
              </>
            ) : (
              <>
                <p className={styles.tileHeading}>Product Description</p>
                <span style={{ display: "block", marginTop: "10px" }}>
                  {campaignData.data.deal[0].promotionDescription}
                </span>
              </>
            )}
          </section>

          {campaignData?.data?.paymentTerms && (
            <section className={styles.bGuidelines}>
              <p className={styles.tileHeading}>Payment Terms</p>

              <div style={{ marginTop: "14px" }} />

              <Ellipsis
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "break-spaces",
                  fontSize: "14px",
                  color: "#09121F",
                }}
                content={campaignData.data.paymentTerms}
                expandText="read more"
                collapseText="collapse"
              />
            </section>
          )}
          <section className={styles.deliverables}>
            <p className={styles.tileHeading}>Deliverables</p>
            <ul
              style={{
                display: "grid",
                gap: "20px",
                marginTop: "14px",
                marginLeft: "12px",
              }}>
              {campaignData.data.deal[0].deliverables.map((item, idx) => {
                return <li key={idx}>{getDeliverableDetails(item)}</li>;
              })}
            </ul>
          </section>
          <section className={styles.bGuidelines}>
            <p className={styles.tileHeading}>Brand Guidelines</p>

            <div style={{ marginTop: "14px" }} />

            <Ellipsis
              style={{
                wordBreak: "break-word",
                whiteSpace: "break-spaces",
                fontSize: "14px",
                color: "#09121F",
              }}
              content={campaignData.data.brandGuidelines}
              expandText="read more"
              collapseText="collapse"
            />
          </section>
          {campaignData.data.offer?.currentlyRunningOfferId && (
            <section className={styles.commercials}>
              <div
                className={styles.tileHeading}
                style={{ marginBottom: "10px" }}>
                Your Commercial
              </div>
              {offerLoading && (
                <Skeleton
                  style={{
                    "--width": "100%",
                    "--height": "130px",
                    "--border-radius": "8px",
                  }}
                />
              )}
              {commercial && (
                <>
                  <PriceDetailCard commercial={commercial} />

                  {/* {!commercial.isPriceCardApproved ? (
                  <div className={styles.commercialCard}>
                    <div className={styles.cardHeading}>
                      {!commercial.isSocialMediaExist
                        ? `Connect your ${capitalizeFirstLetter(
                            
                          )} profile`
                        : commercial.isDelivarablePriceRequested
                        ? "Commercials under review"
                        : `Add your ${capitalizeFirstLetter(campaignData?.data?.platform
                            
                          )} commercials`}
                    </div>
                    <div className={styles.cardDesc}>
                      {!commercial.isSocialMediaExist
                        ? `This is an ${capitalizeFirstLetter(
                            campaignData?.data?.platform
                          )} campaign, so to apply to this or to see your commercials you need to connect your ${capitalizeFirstLetter(
                            campaignData?.data?.platform
                          )} profile first.`
                        : commercial.isDelivarablePriceRequested
                        ? "Our team is reviewing the commercials that you’ve added. You can participate in campaigns as soon as they’re verified. If it’s taking too long, write to us at support@cloutflow.com"
                        : `Your platform is connected but you haven’t submitted your commercials yet. Add your commercials to activate your profile.`}
                    </div>
                    {!commercial.isSocialMediaExist && (
                      <Button
                        onClick={() => setOpenConnectPlatformModal(true)}
                        color="primary"
                        fill="none"
                        style={{
                          textDecoration: "underline",
                          paddingLeft: "0",
                        }}>
                        Connect {capitalizeFirstLetter(campaignData?.data?.platform)} now
                      </Button>
                    )}
                  </div>
                ) : (
                  <PriceDetailCard commercial={commercial} />
                )} */}
                </>
              )}

              {/* <Button
              style={{
                display: "flex",
                marginTop: "60px",
                alignItems: "center",
                justifyContent: "center",
              }}
              fill="none"
              onClick={() =>
                setChatModal({
                  open: true,
                  props: {
                    brandId: campaignData.data.brand._id,
                  },
                })
              }>
              <MessageOutlined style={{ color: "#2130FF", fontSize: "16px" }} />
              <span
                style={{
                  marginLeft: "5px",
                  fontWeight: 600,
                  color: "#2130FF",
                }}
                onClick={() =>
                  setChatModal({
                    open: true,
                    props: {
                      brandId: campaignData.data.brand._id,
                    },
                  })
                }>
                Chat with Campaign Manager
              </span>
            </Button> */}
            </section>
          )}
          {commercial && !commercial?.isSocialMediaExist && (
            <div className={styles.commercialCard}>
              <div className={styles.cardHeading}>
                Connect your
                {capitalizeFirstLetter(campaignData?.data?.platform)}
              </div>
              <div className={styles.cardDesc}>
                This is an {capitalizeFirstLetter(campaignData?.data?.platform)}{" "}
                campaign, so to apply to this or to see your commercials you
                need to connect your{" "}
                {capitalizeFirstLetter(campaignData?.data?.platform)} profile
                first.
              </div>
              {!commercial.isSocialMediaExist && (
                <Button
                  onClick={() => setOpenConnectPlatformModal(true)}
                  color="primary"
                  fill="none"
                  style={{
                    textDecoration: "underline",
                    paddingLeft: "0",
                  }}>
                  Connect {capitalizeFirstLetter(campaignData?.data?.platform)}{" "}
                  now
                </Button>
              )}
            </div>
          )}

          <section style={{ backgroundColor: "#fff", padding: "16px 21px" }}>
            {getCampaignButtons(campaignData.data)}
          </section>
        </div>
        {!unregistered && (
          <>
            <AcceptModal
              open={acceptVisible}
              setOpen={setAcceptVisible}
              offerId={campaignData.data?.offer?.currentlyRunningOfferId}
              handleInvalidate={handleInvalidate}
              commercial={commercial}
            />
            <ApplyModal
              open={applyVisible}
              setOpen={setApplyVisible}
              campaignId={campaignData.data?.offer?.campaignId}
              handleInvalidate={handleInvalidate}
              platform={campaignData?.data?.platform}
              dealId={campaignData?.data?.defaultDeal}
            />
            <WithdrawModal
              open={withDrawVisible}
              setOpen={setWithDrawVisible}
              offerId={campaignData.data?.offer?.currentlyRunningOfferId}
              handleInvalidate={handleInvalidate}
            />
            <RejectModal
              open={rejectVisible}
              setOpen={setRejectVisible}
              offerId={campaignData?.data?.offer?.currentlyRunningOfferId}
              handleInvalidate={handleInvalidate}
              socialMediaKitId={
                campaignData?.data?.offer?.currentlyRunningOffer
                  ?.socialMediaKitId
              }
              campaignId={campaignData.data?.offer?.campaignId}
            />
          </>
        )}
        <KycDrawer
          isOpen={openKycModal}
          handleClose={() => setOpenKycModal(false)}
        />
      </main>
    </PullToRefresh>
  );
};

export default CampaignDetail;
