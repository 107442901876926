import { Button, Toast } from "antd-mobile";
import CustomDrawerWrapper from "components/Common/CustomDrawerWrapper";
import CustomInput from "components/Common/CustomInput";
import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { AiFillEdit } from "react-icons/ai";

import { getCommercialCharges } from "handlers/connectSocial/getCommercialCharges";
import { editCommercialCharges } from "handlers/connectSocial/editCommercialCharges";
import { getUserSocial } from "handlers/me/getSocial";
import { getSocialMediaList } from "handlers/connectSocial/getSocialMediaList";

const EditCommertialsIg = ({ openModalProps, setOpenModalProps }) => {
  // const { queryKey: userKey } = getUserStatus();
  const { queryKey: socialQueryKey } = getUserSocial();
  const queryClient = useQueryClient();

  const { queryKey: socialMediaListInstagramKey } = getSocialMediaList({
    platform: "instagram",
  });

  const { queryKey, queryFunction } = getCommercialCharges({
    socialId: openModalProps.props.socialMediaKitId,
    platform: "instagram",
  });

  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const [isEditShown, setIsEditShown] = useState(false);

  const { data: commercialData } = useQuery(queryKey, queryFunction, {
    enabled: openModalProps.open,
  });

  const editCommercialMutation = useMutation(editCommercialCharges, {
    onSuccess: () => {
      queryClient.invalidateQueries(socialQueryKey).then(() => {
        queryClient.invalidateQueries(socialMediaListInstagramKey);
        Toast.show({
          icon: "success",
          content: "Prices Updated",
        });
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        });
      });
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      story: null,
      post: null,
      reel: null,
      video: null,
    },
  });

  useEffect(() => {
    if (commercialData) {
      if (commercialData?.data.status !== "Not-Initiated") {
        setIsEditShown(true);
        setIsEditEnabled(false);
      }
      setValue(
        "story",
        commercialData?.data?.priceCard?.story > 0
          ? commercialData?.data?.priceCard?.story
          : null
      );
      setValue(
        "post",
        commercialData?.data?.priceCard?.post > 0
          ? commercialData?.data?.priceCard?.post
          : null
      );
      setValue(
        "reel",
        commercialData?.data?.priceCard?.reel > 0
          ? commercialData?.data?.priceCard?.reel
          : null
      );
      setValue(
        "video",
        commercialData?.data?.priceCard?.video > 0
          ? commercialData?.data?.priceCard?.video
          : null
      );
    }
  }, [commercialData]);

  const submitCommercial = (data) => {
    return editCommercialMutation.mutate({
      socialId: openModalProps.props.socialMediaKitId,
      prices: {
        reel: Number(data?.reel || 0),

        post: Number(data?.post || 0),

        video: Number(data?.video || 0),

        story: Number(data?.story || 0),
      },
    });
  };

  return (
    <CustomDrawerWrapper
      isOpen={openModalProps.open}
      handleClose={() =>
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        })
      }>
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "16px",
            maxWidth: "500px",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}>
            <h1
              style={{
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "34px",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}>
              How do you wanna price your services for Instagram?
              {isEditShown && (
                <Button
                  color="primary"
                  fill="none"
                  onClick={() => setIsEditEnabled(true)}>
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}>
                    Edit
                    <AiFillEdit />
                  </div>
                </Button>
              )}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "16px",
              borderTop: "1px solid #E5E5E5",
            }}>
            <Controller
              control={control}
              name="post"
              rules={{
                required: { value: true, message: "This is required" },
                validate: {
                  isMin: (value) =>
                    value >= 1000 || "Price should be greater than 1000",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  onChange={onChange}
                  value={value}
                  containerStyle={{ width: "100%", maxWidth: "100%" }}
                  errorText={errors?.post?.message}
                  type="number"
                  label="Post"
                  pattern="\d*"
                  placeholder="Enter Price for a Post"
                  disabled={!isEditEnabled}
                />
              )}
            />
            <Controller
              control={control}
              name="story"
              rules={{
                required: { value: true, message: "This is required" },
                validate: {
                  isMin: (value) =>
                    value >= 500 || "Price should be greater than 500",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  onChange={onChange}
                  value={value}
                  containerStyle={{ width: "100%", maxWidth: "100%" }}
                  errorText={errors?.story?.message}
                  type="number"
                  label="Story"
                  pattern="\d*"
                  placeholder="Enter Price for a Story"
                  disabled={!isEditEnabled}
                />
              )}
            />
            <Controller
              control={control}
              name="video"
              rules={{
                required: { value: true, message: "This is required" },
                validate: {
                  isMin: (value) =>
                    value >= 1500 || "Price should be greater than 1500",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  onChange={onChange}
                  value={value}
                  containerStyle={{ width: "100%", maxWidth: "100%" }}
                  errorText={errors?.video?.message}
                  type="number"
                  label="Video"
                  pattern="\d*"
                  placeholder="Enter Price for a Video"
                  disabled={!isEditEnabled}
                />
              )}
            />
            <Controller
              control={control}
              name="reel"
              rules={{
                required: { value: true, message: "This is required" },
                validate: {
                  isMin: (value) =>
                    value >= 1500 || "Price should be greater than 1500",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  onChange={onChange}
                  value={value}
                  containerStyle={{ width: "100%", maxWidth: "100%" }}
                  errorText={errors?.reel?.message}
                  type="number"
                  label="Reel"
                  pattern="\d*"
                  placeholder="Enter Price for a Reel"
                  disabled={!isEditEnabled}
                />
              )}
            />
          </div>
          <div
            style={{
              padding: "16px",
              marginTop: "1em",
            }}>
            {isEditEnabled && (
              <Button
                block
                color="primary"
                size="large"
                onClick={handleSubmit(submitCommercial)}
                loading={editCommercialMutation.isLoading}>
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </CustomDrawerWrapper>
  );
};

export default EditCommertialsIg;
