import { useMutation } from "react-query";
import { sendRequest } from "../sendRequest";

export const disconnectSocial = async ({ data }) => {
  const response = await sendRequest(
    "put",
    `/influencer/social-media-disconnect?socialMediaKitId=${data.socialMediaKitId}`,
    undefined,
    undefined,
    true
  );
  return response;
};

export default function useDisconnectSocial() {
  return useMutation(disconnectSocial);
}
