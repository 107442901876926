import React from "react";

import ChatWindow from "components/Chat/index.jsx";
import ChatContextProvider from "context/ChatContext";
import { useParams } from "react-router-dom";

const ChatDScreen = () => {
  const { brandId, conversationId } = useParams();
  return (
    <ChatContextProvider>
      <ChatWindow brandId={brandId} conversationId={conversationId} />
    </ChatContextProvider>
  );
};

export default ChatDScreen;
