import {
  onAuthStateChanged,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";

import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
} from "react";
import { useQueryClient } from "react-query";

import { Dialog } from "antd-mobile";

import { auth } from "../Utils/Firebase";

import { registerUser } from "../handlers/me/registerUser";
import { sendOTP } from "../handlers/me/sendOTP";
import { verifyOTP } from "../handlers/me/verifyOTP";

//import { sendRequest } from "handlers/sendRequest";

const AuthContext = createContext(null);

let unsubscribe = null;

export default function AuthContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const queryClient = useQueryClient();
  // const [expoPushToken, setExpoPushToken] = useState();

  const sendOtp = async (phoneNumber, appVerifier) => {
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    // return sendOTP({ phoneNumber, retryCount });
  };

  const verifyOtp = async (otp, verificationCode) => {
    const credential = PhoneAuthProvider.credential(verificationCode, otp);
    return signInWithCredential(auth, credential).then((res) => {
      setLoading(true);
      return res;
    });
    // return verifyOTP({ otp, uid }).then((res) => {
    //   setLoading(true);
    //   return res;
    // });
  };

  const signOutDevice = async () => {
    try {
      let token = (await auth?.currentUser?.getIdToken()) || null;
      setLoading(true);
      handleWebviewEvent(token, "logout");
      await signOut(auth);
      setSignedIn(false);
      setCurrentUser(null);
      window.location.reload();

      // window.location.reload();
    } catch (err) {
      Dialog.alert({
        content: `${err.message}`,
        confirmText: "OK",
      });
    }
  };

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  if (!unsubscribe) {
    unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user != null) {
        await registerUser();
        let token = await user.getIdToken(true);
        handleWebviewEvent(token, "login");
        setSignedIn(true);
        setCurrentUser(user);
        setLoading(false);
      } else {
        // navigate("/");
        // console.log("user1");
        queryClient.removeQueries();
        setSignedIn(false);
        setCurrentUser(null);
        setLoading(false);
      }
    });
  }

  const handleWebviewEvent = (userToken, type) => {
    if (window.ReactNativeWebView) {
      if (type === "login")
        return window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "authTokenLogin", body: userToken })
        );
      if (type === "logout")
        return window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "authTokenLogout", body: userToken })
        );
    }
  };

  const customSignIn = (token) => {
    return signInWithCustomToken(auth, token)
      .then(async (user) => {
        if (currentUser) {
          await registerUser();
          let token = await user.user.getIdToken(true);
          handleWebviewEvent(token, "login");
          setSignedIn(true);
          setCurrentUser(user);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .then();
  };

  const value = useMemo(
    () => ({
      currentUser,
      signedIn,
      loading,
      setCurrentUser,
      setSignedIn,
      sendOtp,
      signOutDevice,
      verifyOtp,
      customSignIn,
    }),
    [currentUser, loading, signedIn]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  if (!authContext)
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider"
    );
  return authContext;
};
