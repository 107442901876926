import React, { useState } from "react";
import { Button, Toast, Divider } from "antd-mobile";

import { useMutation, useQueryClient } from "react-query";

import CustomModalWrapper from "components/Common/CustomModalWrapper";

import { rejectOffer, acceptSpecialOfferV2 } from "handlers/campaign/offers";

import CustomInput from "components/Common/CustomInput";

const RejectModal = ({
  open,
  setOpen,
  offerId,
  handleInvalidate,
  campaignId = "",
  socialMediaKitId = "",
}) => {
  const [inputVal, setInputVal] = useState("");
  const queryClient = useQueryClient();

  const [customPrice, setCustomPrice] = useState(undefined);

  const rejectOfferMutation = useMutation(rejectOffer, {
    onSuccess: () => {
      setOpen(false);
      handleInvalidate();
      Toast.show({
        icon: "success",
        content: "Offer Rejected",
      });
      queryClient.invalidateQueries(["campaigns"]);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  const acceptOfferMutation = useMutation(acceptSpecialOfferV2, {
    onSuccess: () => {
      setOpen(false);
      handleInvalidate();
      Toast.show({
        icon: "success",
        content: "Accepted",
      });
      queryClient.invalidateQueries(["campaigns"]);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred while accepting offer",
      });
    },
  });

  const rejectSpecialOfferMutation = useMutation(rejectOffer, {
    onSuccess: () => {
      acceptOfferMutation.mutate({
        campaignId,
        price: Number(customPrice),
        socialMediaKitId: socialMediaKitId,
      });
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred while accepting offer",
      });
    },
  });

  return (
    <CustomModalWrapper
      openModal={open}
      setOpenModal={setOpen}
      heading={"Reject Offer"}>
      <div style={{ width: "100%" }}>
        <p> Please help us understand why you don't wanna accept this invite</p>

        <CustomInput
          onChange={(e) => setInputVal(e.target.value)}
          value={inputVal}
          placeholder={`Try to help us understand, why you don't want to accept this invitation`}
          containerStyle={{
            width: "100%",
            maxWidth: "100%",
            marginBottom: "20px",
          }}
          multiline
          rows={4}
        />

        <Button
          loading={rejectOfferMutation.isLoading}
          onClick={() =>
            rejectOfferMutation.mutate({ offerId, rejectReason: inputVal })
          }
          color="default"
          disabled={inputVal.length <= 0}
          style={{ width: "100%" }}>
          Reject
        </Button>

        <Divider style={{ margin: 20 }}>OR</Divider>
        <p>
          If you still want to do the campaign, U can apply with your desireable
          price{" "}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #d9d9d9",
            borderRadius: 6,
            height: "46px",
            width: "150px",
            marginBlock: 20,
          }}>
          <span
            style={{
              width: "30%",
              fontSize: "20px",
              backgroundColor: "rgba(0,0,0,.08)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}>
            ₹
          </span>
          <CustomInput
            onChange={(e) => setCustomPrice(e.target.value)}
            value={customPrice}
            type="number"
            placeholder={`Commercials`}
            containerStyle={{
              width: "70%",
              maxWidth: "100%",
              margin: 0,
            }}
            style={{ border: "none", borderRadius: "0 6px 6px 0" }}
          />
        </div>

        <Button
          loading={
            acceptOfferMutation.isLoading ||
            rejectSpecialOfferMutation.isLoading
          }
          onClick={() =>
            rejectSpecialOfferMutation.mutate({
              offerId,
              rejectReason: "Commercials Negotiated",
            })
          }
          color="primary"
          disabled={customPrice ? customPrice < 0 : true}
          style={{ width: "100%" }}>
          Apply
        </Button>

        {/* <Row justify='space-between'>
        <Button onClick={() => setOpen(false)}>NO</Button>
        <Button
          loading={rejectOfferMutation.isLoading}
          onClick={() =>
            rejectOfferMutation.mutate({ offerId, rejectReason: inputVal })
          }
        >
          Reject
        </Button>
      </Row> */}
      </div>
    </CustomModalWrapper>
  );
};

export default RejectModal;
