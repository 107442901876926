import React from "react";
import { Button, Toast } from "antd-mobile";
import { Row } from "antd";
import { useMutation, useQueryClient } from "react-query";

import CustomModalWrapper from "../../../Common/CustomModalWrapper";

import { withdrawOffer } from "../../../../handlers/campaign/offers";

const WithdrawModal = ({ open, setOpen, offerId, handleInvalidate }) => {
  // console.log("from here", open);
  const queryClient = useQueryClient();
  const withdrawOfferMutation = useMutation(withdrawOffer, {
    onSuccess: () => {
      setOpen(false);
      handleInvalidate();
      Toast.show({
        icon: "success",
        content: "Offer Withdrawn",
      });
      queryClient.invalidateQueries(["campaigns"]);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  return (
    <CustomModalWrapper
      openModal={open}
      setOpenModal={setOpen}
      heading={"Withdraw"}>
      <div style={{ width: "100%" }}>
        <p>Are you sure you want to withdraw from this offer?</p>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Button
            onClick={() => setOpen(false)}
            style={{ width: "45%", color: "#2130FF" }}>
            Cancel
          </Button>
          <Button
            loading={withdrawOfferMutation.isLoading}
            onClick={() => withdrawOfferMutation.mutate({ offerId })}
            style={{ width: "45%", color: "#2130FF", fontWeight: 500 }}>
            Yes, withdraw
          </Button>
        </div>
      </div>
    </CustomModalWrapper>
  );
};

export default WithdrawModal;
