import { Toast } from "antd-mobile";
const copyTextToClipboard = async (
  text,
  secondaryText = "Text Copied to clipboard"
) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand("copy", true, text);
  }
  return Toast.show({ content: secondaryText, icon: "success" });
};

export default copyTextToClipboard;
