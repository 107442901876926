import React from "react";
import { Card } from "antd";

// import PostedInfoRow from 'components/Dashboard/PostedInfoRow/PostedInfoRow';

import styles from "./UcampaignCard.module.css";
import { useModalContext } from "context/ModalContext";
import Chip from "components/Dashboard/Campaigncard/Chip";

const UCampaignCard = ({ campaign, setOpenRegisterModal, unregistered }) => {
  const { setCampaignModal } = useModalContext();
  return (
    <Card
      className={styles.container}
      style={{ borderRadius: "8px" }}
      bodyStyle={{ padding: 0 }}
      onClick={() => {
        setCampaignModal({
          open: true,
          props: {
            campaignId: campaign._id,
            dealId: campaign.defaultDeal,
            setOpenRegisterModal: setOpenRegisterModal,
            unregistered: unregistered,
          },
        });
      }}>
      <div style={{ position: "relative" }}>
        <img
          className={styles.banner}
          src={campaign.campaignBannerUrl}
          alt="banner"
        />
      </div>
      <div
        style={{
          padding: "12px",
          display: "grid",
          gap: "8px",
          borderRadius: "0px 0px 8px 8px",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>
            <p style={{ marginBottom: "2px" }} className={styles.cName}>
              {campaign?.campaignName}
            </p>
            <p className={styles.bName}>
              {campaign?.brand.businessDetails.name}
            </p>
          </div>
          <img
            style={{ width: "24px", height: "24px" }}
            src={`/assets/img/${campaign.platform}.png`}
            alt="platform"
          />
        </div>

        {/* <span className={styles.cPrice}>
          {campaign.isVariablePriced
            ? "Negotiable"
            : `₹ ${Math.min(
                ...Object.values(campaign.fixedPrices).filter(
                  (item) => item !== 0
                )
              )} - ₹ ${Math.max(
                ...Object.values(campaign.fixedPrices).filter(
                  (item) => item !== 0
                )
              )}`}
        </span> */}

        {campaign?.filter?.niche?.length > 0 && (
          <div
            style={{
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
            }}>
            {campaign?.filter?.niche.map((item, idx) => (
              <Chip label={item} key={idx} />
            ))}
          </div>
        )}
      </div>
      {/* <PostedInfoRow borderBottom */}
    </Card>
  );
};

export default UCampaignCard;
