import React from "react";
import { CgShare } from "react-icons/cg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "hooks/useWindowWidth";

import CampaignDetail from "../Dashboard/CampaignDetail";
// import CampaignDetailUpdated from "../Test";
import CustomDrawerWrapper from "../Common/CustomDrawerWrapper";

import copyTextToClipboard from "../../Utils/copyToClipboard";

const CampaignDetailDrawer = ({ openModalProps, setOpenModalProps }) => {
  // console.log(openModalProps);
  const isDesktop = useWindowWidth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop && openModalProps.open) {
      navigate(
        `/campaignDetail/${openModalProps.props?.campaignId}/${openModalProps.props?.dealId}`
      );

      setOpenModalProps((openModalProps) => {
        return { ...openModalProps, open: false };
      });
    }
  }, [isDesktop, openModalProps.open]);

  return (
    <CustomDrawerWrapper
      isOpen={openModalProps.open && !isDesktop}
      handleClose={() =>
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        })
      }
      title="Campaign Detail"
      secondaryButton={<CgShare style={{ fontSize: "20px" }} />}
      secondaryButtonOnClick={() => {
        try {
          if (process.env.REACT_APP_DEV_ERROR_SHOW === "true") {
            return copyTextToClipboard(
              `Dev ki main site nhi hai , islie redirect nhi kia #pro `,
              "Link copied to clipboard"
            );
          }
          return copyTextToClipboard(
            `https://cloutflow.com/creators/ongoing-campaigns/${openModalProps?.props?.campaignId}$${openModalProps?.props?.dealId}`,
            "Link copied to clipboard"
          );
        } catch (error) {
          console.log("from here", error);
        }
      }}>
      {openModalProps?.open && (
        <CampaignDetail
          campaignId={openModalProps.props?.campaignId}
          dealId={openModalProps.props?.dealId}
          unregistered={openModalProps.props?.unregistered}
        />
      )}
    </CustomDrawerWrapper>
  );
};

export default CampaignDetailDrawer;
