import {
  checkConversation,
  createConversation,
  getOldMessages,
} from "handlers/chat";
import React, { useState, createContext, useContext } from "react";
import { useEffect } from "react";
import { useInfiniteQuery } from "react-query";
import { io } from "socket.io-client";
import { useAuthContext } from "./AuthContext";
import useSound from "use-sound";

import { useQueryClient } from "react-query";

import { getAllConversations } from "handlers/chat";

import messageSound from "assets/sounds/messageSound.mp3";

let socket = null;

const ChatContext = createContext(null);

export default function ChatContextProvider({ children }) {
  const [brandId, setBrandId] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [isFirst, setIsFirst] = useState();
  const [messages, setMessages] = useState([]);
  const { currentUser } = useAuthContext();
  const [newMessageLoading, setNewMessageLoading] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const { queryKey: getAllConversationsKey } = getAllConversations();

  const queryClient = useQueryClient();

  //query to get old messages
  const { queryKey: getOldMessagesKey, queryFunction: getOldMessagesFn } =
    getOldMessages(conversationId);

  const {
    data: conversationData,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(getOldMessagesKey, getOldMessagesFn, {
    enabled: conversationId ? true : false,
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });
  const [play] = useSound(messageSound);

  function playAudio() {
    console.log("audio played");
    play({ volume: 1, forceSoundEnabled: true, soundEnabled: true });
  }

  // ------------------ Conversation ------------------
  //First useEffect getting conersationId and handling isFirst
  useEffect(() => {
    console.log(conversationId, brandId);
    try {
      const checkConvo = async () => {
        //if we get conversation id chat exists
        if (conversationId) {
          setIsFirst(false);
          return null;
        }
        //if we get brand id only we check if there is any conversation with that brand
        else {
          const response = await checkConversation({
            userId: brandId,
          });

          //if we dont get a conversation its first time chat
          if (!response.data.conversationId) {
            setIsFirst(true);

            return null;
          }
          //if we get a conversation we set the conversation id
          setIsFirst(false);
          setConversationId(response.data.conversationId);
          return null;
        }
      };
      if (brandId) checkConvo();
    } catch (err) {
      console.log(err);
    }
  }, [brandId, conversationId]);

  //------------------ Socket ------------------

  //Second useEffect handling socket connection
  useEffect(() => {
    console.log("socket connection");

    const connectSocket = async () => {
      if (!conversationId) return null;
      if (socket?.connected) return null;
      const token = await currentUser?.getIdToken({
        forceRefresh: false,
      });

      socket = io(
        `${process.env.REACT_APP_SOCKET_URL}/conversation?conversationId=${conversationId}`,
        {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
          forceNew: true,
        }
      );

      //on new message
      socket.on("new-message", (...args) => {
        setMessages((messages) => [args[0], ...messages]);
        queryClient.invalidateQueries(getAllConversationsKey);
      });

      //Error while connecting
      socket.on("connect_error", (...args) => {
        socket.close();
        connectSocket();
      });

      socket.on("send-message-sent", (...args) => {
        //Event for message that is sent by current socket (current user)
        playAudio();
        handleSentMessage(args[0]);
        queryClient.invalidateQueries(getAllConversationsKey);
      });

      //When socket disconnects after connection
      socket.on("disconnect", (reason) => {
        socket.close();
        if (reason === "io server disconnect") {
          connectSocket();
        }
      });
    };

    connectSocket();

    return () => {
      console.log("disconnecting");
      if (socket) {
        socket.close();
      }
    };
  }, [conversationId]);

  // ------------------ Messages ------------------
  //If its not first time chat and loading of conversation is done old messages are fetched in parallel

  useEffect(() => {
    if (!isLoading && !isFirst && conversationData) {
      const oldMessages = conversationData.pages.map(
        (page) => page.data.messages
      );
      setMessages(oldMessages.flat());
    }
  }, [isLoading, conversationData, isFirst]);

  // ------------------ Sending Messages ------------------

  const handleMessageSend = async (
    val,
    type,
    mediaUrl,
    mediaType,
    mediaName
  ) => {
    if (isFirst) return handleFirstMessage(val);
    console.log("mediaName", mediaName);
    //using a local id to identify the message when sent and when recieved
    const localId = Math.random();
    //Locally set message with type as sending
    setMessages((messages) => [
      {
        sending: true,
        conversationId: conversationId,
        author: currentUser?.uid,
        createDateTime: new Date().toISOString(),
        data: {
          localId: localId,
          type: type,
          msg: val,
          mediaUrl,
          mediaType,
          mediaName,
        },
        __v: 0,
        _id: localId,
      },
      ...messages,
    ]);

    try {
      socket.emit("send-message", {
        localId: localId,
        type: type,
        msg: val,
        mediaUrl,
        mediaType,
        mediaName,
      });
      //Causing issue as it rerenders parent component
      //Used to render all conversations
      // queryClient.invalidateQueries(getAllConversationsKey);
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  // ------------------ Handling Sent Message ------------------
  const handleSentMessage = async (args) => {
    await sleep(200);
    setMessages((messages) => {
      let tempMesssages = messages;
      tempMesssages.forEach((message, index) => {
        if (message.data.localId === args.data.localId) {
          tempMesssages[index].sending = false;
        }
      });
      return [...tempMesssages];
    });
    return null;
  };

  // ------------------ Handling First Message Sent  ------------------
  //Case when there is no conversationId is created

  const handleFirstMessage = async (val) => {
    try {
      await createConversation({ message: val, userId: brandId }).then(
        (response) => {
          if (response.data.conversationId) {
            setConversationId(response.data.conversationId);
          }
        }
      );
    } catch (error) {
      console.log("err", error);
    }
  };

  const value = {
    brandId,
    conversationId,
    setBrandId,
    setConversationId,
    messages,
    isLoading,
    isFirst,
    fetchNextPage,
    hasNextPage,
    handleMessageSend,
    newMessageLoading,
    setNewMessageLoading,
    setMessages,
    isKeyboardOpen,
    setIsKeyboardOpen,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export const useChatContext = () => {
  const chatContext = useContext(ChatContext);
  if (!chatContext)
    throw new Error(
      "useChatContext must be used within an ChatContextProvider"
    );
  return chatContext;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
