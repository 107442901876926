import { sendRequest } from "../../sendRequest";

export const sendLiveV2 = ({ workflowId, references }) => {
  return sendRequest(
    "put",
    `/influencer/execution/live/upload`,
    {
      workflowId,
      references,
    },
    null,
    true
  ).then((res) => {
    return res.data;
  });
};
