import { sendRequest } from "../sendRequest";

export const addBankAccount = ({
  bankAccountName,
  bankIfsc,
  bankAccountNumber,
}) => {
  return sendRequest("post", `/influencer/wallet/razorpay/link-bank-account`, {
    bankAccountName,
    bankAccountNumber,
    bankIfsc,
  }).then((res) => res.data);
};
