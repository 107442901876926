import { useModalContext } from "context/ModalContext";
import { useNavContext } from "../../context/NavContext";
import { useChatNotificationContext } from "../../context/ChatNotificationContext";

import useWindowWidth from "hooks/useWindowWidth";

import { MdMarkChatUnread, MdOutlineChatBubbleOutline } from "react-icons/md";

const CustomNavBar = () => {
  const { openNav, navHeading, navButton } = useNavContext();
  const { setMessagesModal } = useModalContext();
  const { unreadMessageFlag, setUnreadMessageFlag } =
    useChatNotificationContext();

  const isDesktop = useWindowWidth();

  if (isDesktop) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          height: "54px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          padding: "0px 24px",
          zIndex: "1000",
        }}>
        <p
          style={{
            fontSize: "20px",
            fontColor: "rgba(9, 18, 31, 1)",
            fontWeight: "600",
          }}>
          <img
            src="/assets/FAVICON.png"
            style={{ height: "30px", width: "30px", objectFit: "contain" }}
            alt="logo"
          />
        </p>
      </div>
    );
  }

  if (!openNav) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "white",
        height: "54px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        padding: "0px 24px",
        zIndex: "1000",
      }}>
      <p
        style={{
          fontSize: "20px",
          fontColor: "rgba(9, 18, 31, 1)",
          fontWeight: "600",
        }}>
        {navHeading}
      </p>
      {navButton?.open === true && navButton.button}
    </div>
  );
};

export default CustomNavBar;
