import timeDifference from "Utils/timeDifference";

import styles from "./PostedInfoRow.module.css";

const PostedInfoRow = ({ borderBottom, style, postedDate }) => {
  let newStyle = { ...style };

  if (borderBottom) {
    newStyle = {
      ...newStyle,
      borderBottom: "1px solid #217aff",
      borderRadius: " 0px 0px 8px 8px",
    };
  }

  return (
    <span className={styles.rowText} style={newStyle}>
      Posted {timeDifference(new Date(), new Date(postedDate))} ago
    </span>
  );
};

export default PostedInfoRow;
