import { sendRequest } from "./sendRequest";

export const getCategories = () => {
  const queryKey = ["categories"];
  const queryFunction = () =>
    sendRequest("get", `/influencer/register-form/categories`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  return { queryKey, queryFunction };
};
