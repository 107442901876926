import axios from "axios";
import { auth } from "../Utils/Firebase";

export const logBackend = async (url, body, err) => {
  let user = (await auth?.currentUser) || null;
  console.log("sds", "from here");

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_URL}v1/dump-frontend-logs`,
    data: {
      bodyContent: body,
      url,
      currentUser: user,
      app: "creatorApp",
      err,
    },
  }).catch((error) => {
    console.log("sds", "from here2");
    console.log("error", error);
    // throw error;
  });
};
