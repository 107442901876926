export default function calcDate(date1, date2) {
  let diff = Math.floor(date1.getTime() - date2.getTime());
  let secs = Math.floor(diff / 1000);
  let mins = Math.floor(secs / 60);
  let hours = Math.floor(mins / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 31);
  let years = Math.floor(months / 12);
  months = Math.floor(months % 12);
  days = Math.floor(days % 31);
  hours = Math.floor(hours % 24);
  mins = Math.floor(mins % 60);
  secs = Math.floor(secs % 60);
  let message = '';

  if (years > 0) {
    message = years + (years > 1 ? ' years' : ' year');
  } else if (months > 0) {
    message = months + (months > 1 ? ' months' : ' month');
  } else if (days > 0) {
    message = days + (days > 1 ? ' days' : ' day');
  } else if (hours > 0) {
    message = hours + (hours > 1 ? ' hrs' : ' hrs');
  } else if (mins > 0) {
    message = mins + (mins > 1 ? ' mins' : ' min');
  } else if (secs > 0) {
    message = secs + (secs > 1 ? ' secs' : ' sec');
  }

  return message;
}
