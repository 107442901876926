import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Popover, Dialog, Toast, Button, DotLoading } from "antd-mobile";

import AddAccountModal from "../AddAccountModal";

import CustomModalWrapper from "../../Common/CustomModalWrapper";

import { getLogo } from "../../../Utils/getLogo";

import { getAccounts, unlinkAccount } from "../../../handlers/wallet";

const AccountModal = ({ openModal, setOpenModal }) => {
  const { queryKey, queryFunction } = getAccounts();

  const { data: accountsData, isLoading } = useQuery(queryKey, queryFunction);

  const deleteAccountMutation = useMutation(unlinkAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      Toast.show({
        icon: "success",
        content: "Account removed successfully",
        position: "center",
      });
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
        position: "center",
      });
    },
  });

  const [openAddModal, setOpenAddModal] = useState(false);
  const queryClient = useQueryClient();

  return (
    <CustomModalWrapper
      heading={"Manage Accounts"}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalHeight="70%">
      <div style={{ width: "100%", maxWidth: "500px", height: "100%" }}>
        {isLoading ? (
          <DotLoading />
        ) : (
          <div>
            {accountsData.data?.fundAccounts && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {accountsData?.data?.fundAccounts
                  .filter((accountItem) => accountItem.isActive)
                  .map((item, idx) => {
                    return (
                      <section
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 0",
                        }}
                        key={idx}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                          }}>
                          <img
                            src={getLogo(
                              item.fundAccountType,
                              item.bankAccountName
                            )}
                            alt="bank "
                            style={{
                              width: "52px",
                              height: "52px",
                              border: "1px solid #EEEEEE",
                              borderRadius: "4px",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            style={{
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "22px",
                              color: "#09121F",
                            }}>
                            {item.fundAccountType === "upi"
                              ? item.vpaAddress
                              : `${
                                  item.bankAccountName +
                                  " XX " +
                                  item.bankAccountNumber.slice(-4)
                                }`}
                            {(item.status === "Verification-Created" ||
                              item.status === "Verification-Requesting") && (
                              <h6
                                style={{
                                  fontStyle: "italic",
                                  color: "#999999",
                                }}>
                                Verification Pending
                              </h6>
                            )}
                            {item.status === "Failed" && (
                              <h6 style={{ color: "red" }}>Error Occurred</h6>
                            )}
                          </div>
                        </div>
                        <Popover.Menu
                          actions={[
                            {
                              key: "Remove",
                              text: "Remove",
                            },
                          ]}
                          onAction={() =>
                            Dialog.confirm({
                              content:
                                "Are you sure you want to remove this account?",
                              onConfirm: async () => {
                                await deleteAccountMutation.mutateAsync(
                                  item.fundAccountId
                                );
                                // sleep(3000);
                              },
                              confirmText: "Remove",
                              cancelText: "Cancel",
                            })
                          }
                          trigger="click"
                          placement="bottom-end">
                          <img alt="options" src="/assets/icons/options.svg" />
                        </Popover.Menu>
                      </section>
                    );
                  })}
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#2130FF",
                    marginTop: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenAddModal(true);
                  }}>
                  <img
                    src="/assets/icons/bank.svg"
                    alt="bank"
                    style={{
                      height: "52px",
                      width: "52px",
                      padding: "16px",
                      border: "1px dashed #216CFF",
                      borderRadius: "4px",
                      background: "#FFFFFF",
                    }}
                  />
                  Add Account
                </div>
                <AddAccountModal
                  openModal={openAddModal}
                  setOpenModal={setOpenAddModal}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </CustomModalWrapper>
  );
};

export default AccountModal;
