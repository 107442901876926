import React, { useState, useEffect } from "react";
import ConnectAdviceModal from "./ConnectPlatform/ConnectAdviceModal";
// import {
//   Button,
//   SpinLoading,
//   PullToRefresh,
//   DotLoading,
//   Modal,
// } from "antd-mobile";
// import { Row } from "antd";
// import { useQuery } from "react-query";
// import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
// import { useQueryClient } from "react-query";
// import { BiChevronUp, BiChevronDown } from "react-icons/bi";
// import { TbCalendarTime } from "react-icons/tb";
// import { useNavigate } from "react-router-dom";
// import { ExclamationCircleFill } from "antd-mobile-icons";

// import Chip from "./Dashboard/OngoingCampaignDetail/Chip";
// import DeliverableTimeline from "./Dashboard/OngoingCampaignDetail/DeliverableTimeline";

// import useUserStatusHook from "../screens/Common/useUserStatusHook";
// import { useModalContext } from "context/ModalContext";

// import { getCampaign } from "../handlers/campaign/getCampaign";
// import { getUCampaign } from "../handlers/public/getUCampaign";
// import { getCampaignOfferPrice } from "../handlers/campaign/offers/getCampaignOfferPrice";

// import styles from "./test.module.css";

// const getChipColor = (status) => {
//   if (status === "Awaiting Brief" || status === "Waiting For Approval")
//     return "blue";
//   if (status === "Upload Draft" || status === "Upload Live") return "orange";
//   return "green";
// };

// const CampaignDetailUpdated = ({
//   campaignId,
//   dealId,
//   unregistered = false,
// }) => {
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   const { userStatusCode, setIsAction } = useUserStatusHook("action");
//   const {
//     setCampaignModal,
//     setChatModal,
//     setOpenConnectPlatformModal,
//     setOngoingCampaignModal,
//     setOpenKycModal,
//   } = useModalContext();

//   const [applyVisible, setApplyVisible] = useState(false);
//   const [rejectVisible, setRejectVisible] = useState(false);
//   const [withDrawVisible, setWithDrawVisible] = useState(false);
//   const [acceptVisible, setAcceptVisible] = useState(false);
//   const [campaignData, setCampaignData] = useState(null);
//   const [priceProps, setPriceProps] = useState({
//     campaignId: "",
//     offerId: "",
//     isCampaignId: true,
//     isQueryEnabled: false,
//   });
//   const [commercial, setCommercial] = useState(null);

//   const { queryKey, queryFunction } = getCampaign(campaignId, dealId);

//   const {
//     queryKey: getCampaignOfferPriceKey,
//     queryFunction: getCampaignOfferPriceFn,
//   } = getCampaignOfferPrice({ ...priceProps });

//   const {
//     queryKey: uCampaignDetailKey,
//     queryFunction: uCampaignDetailFunction,
//   } = getUCampaign(campaignId, dealId);
//   const {
//     data: campaign,
//     isLoading: isCampaignDataLoading,
//     isError: isCampaignError,
//   } = useQuery(queryKey, queryFunction, {
//     enabled: !unregistered,
//   });
//   const {
//     data: uCampaign,
//     isLoading: isUCampaignDataLoading,
//     isError: isUCampaignError,
//   } = useQuery(uCampaignDetailKey, uCampaignDetailFunction, {
//     enabled: unregistered,
//   });

//   const { data: campaignOfferPrice } = useQuery(
//     getCampaignOfferPriceKey,
//     getCampaignOfferPriceFn,
//     {
//       enabled: priceProps.isQueryEnabled,
//     }
//   );

//   const handleInvalidate = () => {
//     queryClient.invalidateQueries(queryKey);
//   };

//   useEffect(() => {
//     if (uCampaign || campaign) {
//       setCampaignData(unregistered ? uCampaign : campaign);
//     }
//   }, [uCampaign, campaign, unregistered]);

//   useEffect(() => {
//     if (campaignData) {
//       setPriceProps({
//         campaignId: campaignData.data._id,
//         offerId: campaignData.data.offer?.currentlyRunningOfferId || "",
//         isCampaignId: campaignData.data.offer?.canApply,
//         isQueryEnabled: unregistered ? false : true,
//       });
//     }
//   }, [campaignData]);

//   useEffect(() => {
//     if (campaignOfferPrice) {
//       setCommercial(campaignOfferPrice.data);
//     }
//   }, [campaignOfferPrice]);

//   useEffect(() => {
//     return () => {
//       setCampaignModal({ open: false });
//       setCampaignData(null);
//     };
//   }, []);

//   if (!campaignData) {
//     return null;
//   }

//   const getCampaignButtons = (campaign) => {
//     if (!unregistered) {
//       if (campaign.offer.isCurrentlyRunningOffer) {
//         if (campaign.offer.currentlyRunningOffer.status === "Invited") {
//           return (
//             <Row justify="space-between">
//               <Button
//                 style={{
//                   width: "45%",
//                   backgroundColor: "#E9F0FF8A",
//                   border: "1px solid #E9F0FF8A",
//                   color: "#2130FF",
//                 }}
//                 onClick={() => {
//                   if (userStatusCode !== "Done") {
//                     if (userStatusCode === "Not-Registered") {
//                       setCampaignModal({ open: false });
//                       return navigate("/onboarding");
//                     } else return setIsAction(true);
//                   } else return setRejectVisible(true);
//                 }}>
//                 Reject
//               </Button>
//               <Button
//                 style={{
//                   width: "45%",
//                   backgroundColor: "#2130FF",
//                   border: "1px solid #2130FF",
//                   color: "#fff",
//                 }}
//                 onClick={() => {
//                   if (userStatusCode !== "Done") {
//                     if (userStatusCode === "Not-Registered") {
//                       setCampaignModal({ open: false });
//                       return navigate("/onboarding");
//                     } else {
//                       return setIsAction(true);
//                     }
//                   } else return setAcceptVisible(true);
//                 }}>
//                 Accept
//               </Button>
//             </Row>
//           );
//         } else if (campaign.offer.currentlyRunningOffer.status === "Applied") {
//           return (
//             <Button
//               style={{
//                 width: "100%",
//                 backgroundColor: "#E9F0FF8A",
//                 border: "1px solid #E9F0FF8A",
//                 color: "#2130FF",
//               }}
//               onClick={() => {
//                 return setWithDrawVisible(true);
//               }}>
//               Withdraw
//             </Button>
//           );
//         } else if (
//           campaign.offer.currentlyRunningOffer.status === "Shortlisted"
//         ) {
//           if (campaign.offer?.isCurrentlyRunningOfferApplied)
//             return (
//               <Button
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#E9F0FF8A",
//                   border: "1px solid #E9F0FF8A",
//                   color: "#2130FF",
//                 }}
//                 onClick={() => {
//                   return setWithDrawVisible(true);
//                 }}>
//                 Withdraw
//               </Button>
//             );
//           return;
//         } else {
//           return (
//             <Button
//               style={{
//                 width: "100%",
//                 backgroundColor: "#E9F0FF8A",
//                 border: "1px solid #E9F0FF8A",
//                 color: "#2130FF",
//               }}
//               onClick={() => setCampaignModal({ open: false })}>
//               Go Back
//             </Button>
//           );
//         }
//       } else {
//         if (campaign.offer.isBrandRejectedOffer) {
//           return (
//             <Button disabled style={{ width: "100%" }}>
//               Brand Rejected your applied application
//             </Button>
//           );
//         } else {
//           return (
//             <Button
//               style={{
//                 width: "100%",
//                 backgroundColor: "#2130FF",
//                 border: "1px solid #2130FF",
//                 color: "#fff",
//               }}
//               onClick={() => {
//                 if (userStatusCode !== "Done") {
//                   if (userStatusCode === "Not-Registered") {
//                     setCampaignModal({ open: false });
//                     return navigate("/onboarding");
//                   } else return setIsAction(true);
//                 }
//                 if (campaignOfferPrice?.data.price === 0) {
//                   Modal.alert({
//                     header: (
//                       <ExclamationCircleFill
//                         style={{
//                           fontSize: 64,
//                           color: "var(--adm-color-warning)",
//                         }}
//                       />
//                     ),
//                     confirmText: "Ok",
//                     title: "Not Eligible",
//                     content: (
//                       <>
//                         <p>
//                           To be able to participate in campaigns with Cloutflow,
//                           you need to be in the campaign's followers range.
//                         </p>
//                       </>
//                     ),
//                   });
//                 } else {
//                   if (campaignOfferPrice?.data)
//                     // setCommercial(campaignOfferPrice.data.price);
//                     setApplyVisible(true);
//                 }
//               }}
//               disabled={!commercial?.isPriceCardApproved}>
//               Apply
//             </Button>
//           );
//         }
//       }
//     } else {
//       return (
//         <Button
//           style={{
//             width: "100%",
//             backgroundColor: "#2130FF",
//             border: "1px solid #2130FF",
//             color: "#fff",
//           }}
//           onClick={() => {
//             setCampaignModal({ open: false });
//             return navigate("/onboarding");
//           }}>
//           Apply
//         </Button>
//       );
//     }
//   };

//   if (isCampaignDataLoading || isUCampaignDataLoading) {
//     return (
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <SpinLoading color="primary" />
//       </div>
//     );
//   }

//   if (isCampaignError || isUCampaignError) {
//     return null;
//   }
//   console.log("campaignData", campaignData);
//   // return <div>Hello</div>;
//   return (
//     <PullToRefresh
//       onRefresh={async () => {
//         await queryClient.invalidateQueries(queryKey);
//       }}
//       refreshingText={
//         <DotLoading color={"primary"} style={{ fontSize: "20px" }} />
//       }
//       canReleaseText={<AiOutlineArrowUp size={"20px"} />}
//       pullingText={<AiOutlineArrowDown size={"20px"} />}
//       completeText={<div />}>
//       <div style={{ position: "relative", background: "black" }}>
//         <img
//           src={campaignData?.data?.campaignBannerUrl}
//           className={styles.banner}
//           alt="banner"
//         />
//         <div className={styles.header}>
//           <img
//             src={campaignData?.data?.brand?.businessDetails?.logoUrl}
//             className={styles.logo}
//             alt="logo"
//           />
//           <section className={styles.cDetail}>
//             <div>
//               <h4 className={styles.title}>
//                 {campaignData?.data?.brand?.businessDetails?.name}
//               </h4>
//               <p className={styles.desc} style={{ marginBottom: "2px" }}>
//                 {campaignData?.data?.campaignName}
//               </p>
//             </div>
//             <img
//               src={`/assets/img/${campaignData?.data?.platform}-disabled.png`}
//               style={{ width: "24px", height: "24px" }}
//               alt="platform"
//             />
//           </section>
//           {campaignData?.data?.finalizedOffers?.length > 0 && (
//             <Button
//               color="primary"
//               fill="none"
//               style={{
//                 textDecoration: "underline",
//                 padding: 0,
//                 marginTop: "12px",
//               }}
//               onClick={() =>
//                 setOngoingCampaignModal({
//                   open: true,
//                   props: {
//                     offerId: campaignData?.data?.finalizedOffers[0],
//                   },
//                 })
//               }>
//               Ongoing Campaign details
//             </Button>
//           )}
//           <section className={styles.deliverables}>
//             <div className={styles.title}> Your Deliverables</div>
//             <div
//               style={{
//                 display: "grid",
//                 gap: "12px",
//                 marginTop: "20px",
//               }}>
//               {[1, 2, 3].map((workflowItem, idx) => {
//                 return (
//                   <div className={styles.tile} key={idx}>
//                     <div
//                       style={{
//                         display: "flex",
//                         alignItems: "flex-start",
//                         justifyContent: "space-between",
//                       }}
//                       onClick={() => {}}>
//                       <span className={styles.title} style={{ font: "400" }}>
//                         {
//                           workflowItem?.deliverable?.deliverablesDetails
//                             ?.typeDel
//                         }{" "}
//                         {" #"}
//                         {""}
//                         {workflowItem?.deliverable?.deliverablesDetails?.count}
//                       </span>
//                       <div
//                         style={{
//                           display: "flex",
//                           gap: "8px",
//                           alignItems: "flex-start",
//                         }}>
//                         <div>
//                           <Chip
//                             color={getChipColor(workflowItem?.workflowStatus)}
//                             title={workflowItem?.workflowStatus}
//                           />

//                           <h4
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               marginTop: "8px",
//                               fontWeight: 400,
//                               fontSize: "14px",
//                               lineHeight: "17px",
//                             }}>
//                             {workflowItem?.workflowClosureDate && (
//                               <>
//                                 <TbCalendarTime
//                                   size={"16px"}
//                                   style={{ marginRight: "2px" }}
//                                 />
//                                 Deadline: {workflowItem?.workflowClosureDate}
//                               </>
//                             )}
//                           </h4>
//                         </div>
//                         {true ? (
//                           <BiChevronUp
//                             size={"24px"}
//                             color={"rgba(0, 0, 0, 0.5)"}
//                           />
//                         ) : (
//                           <BiChevronDown
//                             size={"24px"}
//                             color={"rgba(0, 0, 0, 0.3)"}
//                           />
//                         )}
//                       </div>
//                     </div>
//                     {/* {selectedWorkflow &&
//                       workflowItem.workflowId ===
//                         selectedWorkflow.workflowId && (
//                         <DeliverableTimeline
//                           selectedWorkflow={selectedWorkflow}
//                           finalizedOfferId={finalizedOfferId}
//                           workflowData={workflowData}
//                           queryKey={queryKey}
//                         />
//                       )} */}
//                     <div>Hello bro</div>
//                   </div>
//                 );
//               })}
//             </div>
//           </section>
//         </div>
//       </div>
//     </PullToRefresh>
//   );
// };

// export default CampaignDetailUpdated;

const Test = () => {
  const [click, setClick] = useState(false);
  return (
    <div>
      <button onClick={() => setClick(!click)}>Click</button>
      <ConnectAdviceModal
        isOpen={click}
        handleClose={() => setClick(false)}
        platform={"Youtube"}
        url={"https://www.youtube.com/watch?v=QH2-TGUlwu4"}
      />
    </div>
  );
};

export default Test;
