import CustomDrawerWrapper from "components/Common/CustomDrawerWrapper";
import Messages from "components/Messages/Index";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "hooks/useWindowWidth";

const MessagesDrawer = ({ openModalProps, setOpenModalProps }) => {
  const isDesktop = useWindowWidth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop && openModalProps.open) {
      navigate(`/chats`);

      setOpenModalProps((openModalProps) => {
        return { ...openModalProps, open: false };
      });
    }
  }, [isDesktop, openModalProps.open]);

  return (
    <CustomDrawerWrapper
      title="Messages"
      isOpen={openModalProps.open && !isDesktop}
      handleClose={() =>
        setOpenModalProps((openModalProps) => {
          return { ...openModalProps, open: false };
        })
      }
      zIndex={2500}>
      <Messages />
    </CustomDrawerWrapper>
  );
};

export default MessagesDrawer;
