import React from "react";
import { Button } from "antd-mobile";
import styles from "./explore.module.css";

const ExploreCard = ({
  title,
  description,
  handleClick,
  containerStyles = {},
}) => {
  return (
    <div className={styles.container} style={containerStyles}>
      <div style={{ marginBottom: "12px", textAlign: "center" }}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description} style={{ marginTop: "8px" }}>
          {description}
        </div>
      </div>
      <Button
        style={{ borderRadius: "4px", textAlign: "center" }}
        onClick={() => handleClick()}
        color="primary"
        size="middle"
      >
        Explore Campaigns
      </Button>
    </div>
  );
};

export default ExploreCard;
