import InfiniteScroll from "react-infinite-scroller";
import { format } from "date-fns";
import { useInfiniteQuery } from "react-query";
import { SpinLoading } from "antd-mobile";

import CustomDrawerWrapper from "../../Common/CustomDrawerWrapper";
import Tile from "../Tile";

import { getRecentTxns } from "../../../handlers/wallet";

import { formatPrice } from "../../Common/formatPrice";
import { getLogo } from "../../../Utils/getLogo";

const RecentTxnDrawer = ({ isOpen, handleClose }) => {
  const { queryKey: txnsKey, queryFunction: txnsFn } = getRecentTxns();
  const {
    data: txnsData,
    isLoading: isTxnsDataLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(txnsKey, txnsFn, {
    getNextPageParam: (lastPage) =>
      lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
  });

  return (
    <CustomDrawerWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      title={"Transaction History"}>
      {isTxnsDataLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinLoading color="primary" />
        </div>
      ) : (
        txnsData && (
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage();
            }}
            hasMore={hasNextPage}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}>
                <SpinLoading color="primary" />
              </div>
            }>
            {txnsData.pages.map((pageItem) => {
              return pageItem.data.recentTnx.map((item, idx) => {
                return (
                  <Tile
                    key={idx}
                    description={format(
                      new Date(item.createDateTime),
                      "d MMMM,uuuu"
                    )}
                    heading={item.labelName}
                    logo={getLogo(item.label, item.labelRef)}
                    priceDesc={item?.isTdsCollected ? "-TDS" : ""}
                    priceString={`${item.isPositive ? "+" : "-"}${formatPrice(
                      item.amount
                    )}`}
                    offer={item}
                  />
                );
              });
            })}
          </InfiniteScroll>
        )
      )}
    </CustomDrawerWrapper>
  );
};

export default RecentTxnDrawer;
