import React, { useState, createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
const ModalContext = createContext(null);

export default function ModalContextProvider({ children }) {
  const [openConnectPlatformModal, setOpenConnectPlatformModal] =
    useState(false);

  const [openKycModal, setOpenKycModal] = useState(false);

  const [exploreCampaignModal, setExploreCampaignModal] = useState(false);

  const [campaignModal, setCampaignModal] = useState({
    open: false,
    props: {
      campaignId: null,
      dealId: null,
    },
  });

  const [ongoingCampaignModal, setOngoingCampaignModal] = useState({
    open: false,
    props: {
      offerId: null,
    },
  });

  const [chatModal, setChatModal] = useState({
    open: false,
    props: {
      brandId: null,
      conversationId: null,
    },
  });

  const [igCommercialsModal, setIgCommercialsModal] = useState({
    open: false,
    props: {
      socialMediaId: null,
    },
  });
  const [youtubeCommercialsModal, setYoutubeCommercialsModal] = useState({
    open: false,
    props: {
      socialMediaId: null,
    },
  });

  const [messagesModal, setMessagesModal] = useState({
    open: false,
    props: {},
  });
  const [mediaKitModal, setMediaKitModal] = useState({
    open: false,
    props: {
      platform: null,
      creatorId: null,
    },
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    const isOpen = query.get("openModal");
    if (isOpen === "connectPlatform") {
      setOpenConnectPlatformModal(true);
    }
  }, [query]);

  const value = {
    openConnectPlatformModal,
    setOpenConnectPlatformModal,
    ongoingCampaignModal,
    setOngoingCampaignModal,
    chatModal,
    setChatModal,
    campaignModal,
    setCampaignModal,
    igCommercialsModal,
    setIgCommercialsModal,
    youtubeCommercialsModal,
    setYoutubeCommercialsModal,
    setOpenKycModal,
    openKycModal,
    messagesModal,
    setMessagesModal,
    exploreCampaignModal,
    setExploreCampaignModal,
    mediaKitModal,
    setMediaKitModal,
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext)
    throw new Error(
      "useModalContext must be used within an ModalContextProvider"
    );
  return modalContext;
};
