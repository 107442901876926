import React from "react";
import CampaignCard from "./CampaignCard";
import { Swiper, Button } from "antd-mobile";
import { getCampaignOffers } from "handlers/campaign/offers";
import { useInfiniteQuery } from "react-query";

import { useNavigate } from "react-router-dom";

const OngoingCampaigns = () => {
  const { queryKey, queryFunction } = getCampaignOffers("On-going");

  const { data: onGoingCampaignData } = useInfiniteQuery(
    queryKey,
    queryFunction,
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.nextPageToken ? lastPage.data.nextPageToken : null,
    }
  );
  const navigate = useNavigate();

  if (
    onGoingCampaignData === undefined ||
    onGoingCampaignData?.pages[0]?.data?.offer.length === 0
  )
    return null;

  return (
    <div style={{ marginBottom: "2em" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 12px",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "24px",
        }}
      >
        <p style={{ fontWeight: "500", fontSize: "24px" }}>Ongoing Campaigns</p>
        <Button
          onClick={() => navigate("/campaign")}
          style={{
            fontWeight: "400",
            fontSize: "12px",
            cursor: "pointer",
            padding: "0",
            textDecoration: "underline",
          }}
          fill="none"
        >
          See more
        </Button>
      </div>
      <Swiper
        style={{
          maxWidth: "100vw",
          paddingBottom: "20px",
          marginBottom: "40px",
        }}
      >
        {onGoingCampaignData?.pages[0]?.data?.offer
          ?.slice(0, 4)
          .map((item, idx) => {
            return (
              <Swiper.Item key={idx} style={{ padding: "0 24px" }}>
                <CampaignCard campaignData={item} />
              </Swiper.Item>
            );
          })}
      </Swiper>
    </div>
  );
};

export default OngoingCampaigns;
