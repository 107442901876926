import React, { useState } from "react";

import CustomModalWrapper from "../../Common/CustomModalWrapper";

import SelectMedia from "./SelectMedia";
import AddMessage from "./AddMessage";

const SendMessageTemplate = ({ visible, handleClose, socialMediaId }) => {
  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState({});

  return (
    <CustomModalWrapper
      openModal={visible}
      setOpenModal={(val) => {
        handleClose();
        setStep(1);
        setSelected({});
      }}
      heading="Message Automation"
      modalWidth={step == 2 ? 1100 : undefined}>
      {step === 1 ? (
        <SelectMedia
          handleClose={() => {
            handleClose();
          }}
          handleNext={() => {
            setStep(2);
          }}
          selected={selected}
          setSelected={setSelected}
          socialMediaId={socialMediaId}
        />
      ) : (
        <AddMessage
          handleBack={() => {
            setStep(1);
            setSelected({});
          }}
          handleNext={() => {
            setSelected({});
            handleClose();
            setStep(1);
          }}
          selected={selected}
        />
      )}
    </CustomModalWrapper>
  );
};

export default SendMessageTemplate;
