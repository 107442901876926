import { useEffect, useRef, useState } from "react";
import { Swiper, Button } from "antd-mobile";
import { useNavigate } from "react-router-dom";

const OnboardingTut = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [swiperIndex, setSwiperIndex] = useState(0);

  useEffect(() => {
    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "changeSafeAreaColor",
          metadata: {
            color: "#2554CC",
          },
        })
      );
    }
    return () => {
      if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "changeSafeAreaColor",
            metadata: {
              color: "#ffffff",
            },
          })
        );
      }
    };
  }, []);

  return (
    <div style={{ height: "100vh", background: "#2554CC" }}>
      <Swiper
        style={{ minHeight: "650px", paddingTop: "2em" }}
        ref={ref}
        indicatorProps={{
          style: {
            "--dot-color": "rgba(255, 255, 255, 0.4)",
            "--active-dot-color": "rgba(255, 255, 255, 0.8)",
            "--dot-size": "5px",
            "--active-dot-size": "16px",
            "--dot-border-radius": "50%",
            "--active-dot-border-radius": "15px",
          },
        }}
        onIndexChange={(index) => setSwiperIndex(index)}
      >
        <Swiper.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/img/Onbaording/screen1.png"
              style={{ width: "280px", height: "100%" }}
              alt="onboarding-1"
            />

            <div
              style={{
                marginTop: "40px",
                textAlign: "center",
                padding: "0 40px",
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "34px",
                  color: "rgba(255, 255, 255, 0.9)",
                }}
              >
                Get paid campaigns
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.5)",
                  marginTop: "5px",
                }}
              >
                Tired of barter? We are too. You’ll get paid for every campaign
                that you do with Cloutflow.
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/img/Onbaording/screen2.png"
              style={{ width: "280px", height: "100%" }}
              alt="onboarding-1"
            />

            <div
              style={{
                marginTop: "40px",
                textAlign: "center",
                padding: "0 40px",
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "34px",
                  color: "rgba(255, 255, 255, 0.9)",
                }}
              >
                Timely payments
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.5)",
                  marginTop: "5px",
                }}
              >
                The payment is reflected in your wallet as soon as campaign is
                completed.
              </div>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/img/Onbaording/screen3.png"
              style={{ width: "280px", height: "100%" }}
              alt="onboarding-1"
            />

            <div
              style={{
                marginTop: "40px",
                textAlign: "center",
                padding: "0 40px",
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "34px",
                  color: "rgba(255, 255, 255, 0.9)",
                }}
              >
                Track campaigns, get approvals
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.5)",
                  marginTop: "5px",
                }}
              >
                All within the Cloutflow app.
              </div>
            </div>
          </div>
        </Swiper.Item>
      </Swiper>

      <div
        style={{
          position: "fixed",
          bottom: "2em",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {swiperIndex !== 2 ? (
          <div
            style={{
              display: "flex",
              width: "90%",
              justifyContent: "space-between",
            }}
          >
            <Button
              fill="none"
              style={{
                color: "rgba(255, 255, 255, 0.9)",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
              }}
              onClick={() => navigate("/onboarding")}
            >
              SKIP
            </Button>

            <Button
              fill="none"
              style={{
                color: "rgba(255, 255, 255, 0.9)",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "21px",
              }}
              onClick={() => {
                ref.current?.swipeNext();
              }}
            >
              NEXT
            </Button>
          </div>
        ) : (
          <Button
            style={{
              background: "rgba(255, 255, 255, 0.9)",
              borderRadius: "8px",
              fontWeight: 500,
              fontSize: "18px",
              width: "80%",
              alignSelf: "center",
            }}
            size="large"
            onClick={() => navigate("/onboarding")}
          >
            Get Started
          </Button>
        )}
      </div>
    </div>
  );
};

export default OnboardingTut;
