import React, { useState } from "react";
import { Button, Toast, SpinLoading, Radio, Space } from "antd-mobile";
import { Row } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useForm, Controller } from "react-hook-form";

import useUpdateMe from "handlers/me/useUpdateMe";

import CustomModalWrapper from "../../../Common/CustomModalWrapper";

import { applyCampaign, acceptSpecialOfferV2 } from "handlers/campaign/offers";

import { getSocialMediaList } from "handlers/connectSocial/getSocialMediaList";
import { getUser } from "handlers/me/getUser";

import { useModalContext } from "context/ModalContext";

import CustomInput from "components/Common/CustomInput";
import PriceDetailCard from "../../PriceDetailCard";

const ApplyModal = ({
  open,
  setOpen,
  campaignId,
  handleInvalidate,
  platform,
  dealId,
}) => {
  const { setOpenConnectPlatformModal } = useModalContext();

  const queryClient = useQueryClient();
  const applyCampaignMutation = useMutation(acceptSpecialOfferV2, {
    onSuccess: () => {
      setOpen(false);
      handleInvalidate();
      Toast.show({
        icon: "success",
        content: "Applied to Campaign",
      });
      queryClient.invalidateQueries(["campaigns"]);
    },
    onError: () => {
      Toast.show({
        icon: "fail",
        content: "Some error Occurred",
      });
    },
  });

  const { queryKey, queryFunction } = getUser();

  const { queryKey: socialMediaKey, queryFunction: socialMediaFn } =
    getSocialMediaList({
      platform,
      connectionStatus: ["Connected", "Session-Expired"],
    });

  const { data: meData, isLoading: isMeDataLoading } = useQuery(
    queryKey,
    queryFunction
  );

  const { data: socialMediaData, isLoading: socialMediaLoading } = useQuery(
    socialMediaKey,
    socialMediaFn,
    { enabled: open }
  );

  const { mutate: updateMe, isLoading: isUpdateLoading } = useUpdateMe();

  // const isOneAccount = socialMediaData?.data?.length === 1;

  const [selectedAccount, setSelectedAccount] = useState(null);

  // const { queryKey: commercialKey, queryFunction: commercialFn } =
  //   getCampaignOfferPrice({
  //     campaignId,
  //     isCampaignId: true,
  //     socialMediaKitId: selectedAccount,
  //   });

  // const { data: commercialData, isLoading: isCommercialLoading } = useQuery(
  //   commercialKey,
  //   commercialFn,
  //   {
  //     enabled: Boolean(selectedAccount),
  //   }
  // );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      city: meData?.data?.deliveryAddress?.city || "",
      country: meData?.data?.deliveryAddress?.country || "",
      line: meData?.data?.deliveryAddress?.line || "",
      state: meData?.data?.deliveryAddress?.state || "",
      pincode: meData?.data?.deliveryAddress?.pincode || "",
    },
  });

  // const [addressUpdate, setAddressUpdate] = useState(false);
  const [influencerPrice, setInfluencerPrice] = useState(false);

  const submitApply = (data) => {
    // if (addressUpdate) {
    //   return updateMe(
    //     {
    //       data: {
    //         deliveryAddress: {
    //           line: data.line,
    //           city: data.city,
    //           state: data.state,
    //           pincode: data.pincode,
    //           country: data.country,
    //         },
    //       },
    //     },
    //     {
    //       onSuccess: () => {
    //         applyCampaignMutation.mutate({
    //           campaignId,
    //           socialMediaKitId: selectedAccount,
    //           price: influencerPrice,
    //         });
    //       },
    //     },
    //     {
    //       onError: () => {
    //         Toast.show({
    //           icon: "fail",
    //           content: "Some error Occurred",
    //         });
    //       },
    //     }
    //   );
    // }

    return applyCampaignMutation.mutate({
      campaignId,
      socialMediaKitId: selectedAccount,
      price: influencerPrice,
      // price: commercialPrice,
    });
  };

  return (
    <CustomModalWrapper
      openModal={open}
      setOpenModal={setOpen}
      heading={"Apply to Campaign"}>
      {isMeDataLoading || socialMediaLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}>
          <SpinLoading color="primary" />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: 16 }}>
            Please review all the details before applying.
          </p>
          {/* {!isOneAccount && ( */}
          <>
            <span
              style={{
                display: "block",
                marginTop: "20px",
                marginBottom: "10px",
                fontSize: 12,
                color: " #999999",
              }}>
              Please Select an account to proceed
            </span>

            <Radio.Group
              onChange={(val) => setSelectedAccount(val)}
              value={selectedAccount}>
              <Space direction="vertical">
                {socialMediaData?.data?.map((item) => {
                  return (
                    <>
                      <Radio
                        value={item?._id}
                        disabled={
                          item.dataProvider !== "default" ||
                          item.connectionStatus === "Session-Expired"
                        }>
                        {item?.socialMediaName}
                      </Radio>
                      {item.connectionStatus === "Session-Expired" && (
                        <p
                          style={{
                            marginTop: 5,
                            color: "red",
                            fontSize: 10,
                            paddingLeft: 30,
                          }}>
                          This account can not be selected <br />
                          as it's been revoked.{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: 700,
                              color: "rgb(33, 48, 255)",
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                            onClick={() => setOpenConnectPlatformModal(true)}>
                            Reconnect Now
                          </span>
                        </p>
                      )}
                      {item.dataProvider !== "default" && (
                        <p
                          style={{
                            marginTop: 5,
                            color: "red",
                            fontSize: 10,
                            paddingLeft: 30,
                          }}>
                          This account can not be selected <br />
                          as it's not verified.{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: 700,
                              color: "rgb(33, 48, 255)",
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                            onClick={() => setOpenConnectPlatformModal(true)}>
                            Connect Now
                          </span>
                        </p>
                      )}
                    </>
                  );
                })}
              </Space>
            </Radio.Group>
          </>
          {/* )} */}

          {/* {selectedAccount && (
            <>
              <span
                style={{
                  display: "block",
                  marginTop: "20px",
                  marginBottom: "10px",
                  fontSize: 12,
                  color: " #999999",
                }}>
                Your Commercial
              </span>

              {isCommercialLoading && (
                <Row justify="center">
                  <SpinLoading />
                </Row>
              )}
              {commercialData && (
                <PriceDetailCard commercial={commercialData?.data} />
              )}
            </>
          )} */}
          {/* {addressUpdate && (
            <Button
              color="primary"
              fill="none"
              onClick={() => setAddressUpdate(false)}
              size="mini"
              style={{
                padding: 0,
                display: "block",
                textAlign: "left",
                marginTop: "20px",
              }}>
              Don't Update Address
            </Button>
          )} */}
          {/* <span
            style={{
              display: "block",
              marginTop: "20px",
              fontSize: 12,
              color: " #999999",
            }}>
            Delivery Address
          </span> */}
          {/* <div>
            {addressUpdate ? (
              <>
                <Controller
                  control={control}
                  name="line"
                  rules={{
                    required: { value: true, message: "This is required" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      onChange={onChange}
                      value={value}
                      placeholder="Address"
                      containerStyle={{ width: "100%", maxWidth: "100%" }}
                      errorText={errors?.line?.message}
                      multiline
                      rows={1}
                    />
                  )}
                />

                <Row justify="space-between">
                  <Controller
                    control={control}
                    name="country"
                    rules={{
                      required: { value: true, message: "This is required" },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        value={value}
                        placeholder="Country"
                        containerStyle={{ width: "46%" }}
                        errorText={errors?.country?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="state"
                    rules={{
                      required: { value: true, message: "This is required" },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        value={value}
                        placeholder="State"
                        containerStyle={{ width: "46%" }}
                        errorText={errors?.state?.message}
                      />
                    )}
                  />
                </Row>

                <Row justify="space-between">
                  <Controller
                    control={control}
                    name="city"
                    rules={{
                      required: { value: true, message: "This is required" },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        value={value}
                        placeholder="City"
                        containerStyle={{ width: "46%" }}
                        errorText={errors?.city?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="pincode"
                    rules={{
                      required: { value: true, message: "This is required" },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        onChange={onChange}
                        value={value}
                        placeholder="Pin code"
                        type="number"
                        pattern="\d*"
                        containerStyle={{ width: "46%" }}
                        errorText={errors?.pincode?.message}
                      />
                    )}
                  />
                </Row>
              </>
            ) : (
              <>
                {meData?.data?.deliveryAddress?.line},{" "}
                {meData?.data?.deliveryAddress?.city},{" "}
                {meData?.data?.deliveryAddress?.state},{" "}
                {meData?.data?.deliveryAddress?.country}-{" "}
                {meData?.data?.deliveryAddress?.pincode}
                <Button
                  color="primary"
                  fill="none"
                  onClick={() => setAddressUpdate(true)}
                  size="mini"
                  style={{ padding: 0, display: "block" }}>
                  Change Address
                </Button>
              </>
            )}
          </div> */}

          <div style={{ marginTop: 20 }}>
            <p
              style={{
                display: "block",
                // marginTop: "20px",
                marginBottom: "10px",
                fontSize: 14,
                color: "#999999",
              }}>
              Commercial
            </p>
            <span style={{ fontSize: 11, color: "rgb(56 56 56)" }}>
              Please Add Combined Commercials for all the deliverables mentioned
              in brief
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #d9d9d9",
              borderRadius: 6,
              height: "46px",
              width: "200px",
              marginBlock: 10,
              marginBottom: 60,
            }}>
            <span
              style={{
                width: "30%",
                fontSize: "20px",
                backgroundColor: "rgba(0,0,0,.08)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              ₹
            </span>
            <CustomInput
              onChange={(e) => setInfluencerPrice(e.target.value)}
              value={influencerPrice}
              type="number"
              placeholder={`Commercials`}
              containerStyle={{
                width: "70%",
                maxWidth: "100%",
                margin: 0,
              }}
              style={{ border: "none", borderRadius: "0 6px 6px 0" }}
            />
          </div>
          {/* <CustomSwipe
            text="Swipe to Apply"
            loading={applyCampaignMutation.isLoading}
            onSubmit={handleSubmit(submitApply)}
            containerStyle={{ margin: "0 auto" }}
            error={Object.keys(errors).length > 0 ? true : false}
          /> */}
          <Button
            loading={applyCampaignMutation.isLoading || isUpdateLoading}
            onClick={handleSubmit(submitApply)}
            disabled={(influencerPrice || 0) < 0 || !Boolean(selectedAccount)}
            color="primary"
            fill="solid"
            size="large"
            block>
            Apply
          </Button>
        </div>
      )}
    </CustomModalWrapper>
  );
};

export default ApplyModal;
