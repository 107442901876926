import React from "react";

const Chip = (props) => {
  return (
    <div
      style={{
        padding: "1px 8px",
        background: "rgba(0, 0, 0, 0.04)",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "100px",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "17px",
        color: "#09121F",
      }}>
      {props.label}
    </div>
  );
};

export default Chip;
