import React from "react";
import { Row } from "antd";
import Messages from "components/Messages/Index";

const MessagesScreen = () => {
  return (
    <div>
      {" "}
      <Messages />
    </div>
  );
};

export default MessagesScreen;
